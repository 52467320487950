<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-05 20:27:36
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-11 14:49:36
-->
<template>
    <div class="introduction-content">
        <div class="part1">
            <div class="title">《内蒙古画报》</div>
            <div class="info">
                <p>
                    《内蒙古画报》创刊于1948年的内蒙古自治区唯一的以彩色图片编辑出版的时政类综合性期刊，具有70多年历史的《内蒙古画报》在其宣传报道和思想内容上具有权威性。是以直观、喜实、形象活泼的画面和简洁的文字，宣传党在各个历史时期的路线、方针、政策。
                </p>
                <p>
                    习近平总书记在全国宣传思想工作会议上指出:“充分运用新技术新应用创新媒体传播方式，占领信息传播制高点”。
                </p>
                <p>
                    70年来《内蒙古画报》始终坚持与时代同步伐、与祖国共命运、与人民齐奋斗。70年风雨兼程，春华秋实，《内蒙古画报》记录历史沧桑，见证时代巨变，怀抱赤诚之心，一代代《内蒙古画报》人，信念坚定，披荆斩棘、继往开来，在猎猎飘扬的党旗之下，用忠诚和心血，用笔头和镜头，谱就一曲曲历久弥新的华彩乐章！
                </p>
                <p>
                    《内蒙古画报》创刊于1948年的内蒙古自治区唯一的以彩色图片编辑出版的时政类综合性期刊，具有70多年历史的《内蒙古画报》在其宣传报道和思想内容上具有权威性。是以直观、喜实、形象活泼的画面和简洁的文字，宣传党在各个历史时期的路线、方针、政策。
                </p>
                <p>
                    习近平总书记在全国宣传思想工作会议上指出:“充分运用新技术新应用创新媒体传播方式，占领信息传播制高点”。
                </p>
                <p>
                    70年来《内蒙古画报》始终坚持与时代同步伐、与祖国共命运、与人民齐奋斗。70年风雨兼程，春华秋实，《内蒙古画报》记录历史沧桑，见证时代巨变，怀抱赤诚之心，一代代《内蒙古画报》人，信念坚定，披荆斩棘、继往开来，在猎猎飘扬的党旗之下，用忠诚和心血，用笔头和镜头，谱就一曲曲历久弥新的华彩乐章！
                </p>
                <p>
                    《内蒙古画报》创刊于1948年的内蒙古自治区唯一的以彩色图片编辑出版的时政类综合性期刊，具有70多年历史的《内蒙古画报》在其宣传报道和思想内容上具有权威性。是以直观、喜实、形象活泼的画面和简洁的文字，宣传党在各个历史时期的路线、方针、政策。
                </p>
                <p>
                    习近平总书记在全国宣传思想工作会议上指出:“充分运用新技术新应用创新媒体传播方式，占领信息传播制高点”。
                </p>
                <p>
                    70年来《内蒙古画报》始终坚持与时代同步伐、与祖国共命运、与人民齐奋斗。70年风雨兼程，春华秋实，《内蒙古画报》记录历史沧桑，见证时代巨变，怀抱赤诚之心，一代代《内蒙古画报》人，信念坚定，披荆斩棘、继往开来，在猎猎飘扬的党旗之下，用忠诚和心血，用笔头和镜头，谱就一曲曲历久弥新的华彩乐章！
                </p>
            </div>
        </div>
        <div class="outer">
            <div class="part2">
                <div class="item">
                    <div class="t">广告发行</div>
                    <div class="c">0471-4935278</div>
                </div>
                <div class="item">
                    <div class="t">0471-4935278</div>
                    <div class="c">0471-4935276</div>
                </div>
                <div class="item">
                    <div class="t">邮箱</div>
                    <div class="c">kuxiong@kujianzhan.com</div>
                    <div class="c c2">邮政编码:010010 邮发代码:16-4</div>
                </div>
                <div class="item">
                    <div class="t">地址</div>
                    <div class="c">呼和浩特市新城区塔利西路</div>
                    <div class="c c2">东源投资集团总部基地9层</div>
                </div>
            </div>
            <div class="part3">
                <img src="@/assets/images/introduction/map.png" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.introduction-content {
    width: 1500px;

    .part1 {
        box-sizing: border-box;
        width: 100%;
        padding: 40px 50px;
        margin-bottom: 40px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        border-radius: 4px;

        .title {
            color: #2b333b;
            font-size: 28px;
            margin-bottom: 40px;
            font-weight: 600;
            text-align: center;
        }

        .info {
            color: #2b333b;
            font-size: 20px;
            line-height: 40px;
            text-indent: 40px;
            text-align: left;

            p {
                margin: 0;
            }
        }
    }

    .outer {
        background-color: #ffffff;
        border-radius: 4px;
    }

    .part2 {
        width: 1500px;
        display: flex;
        justify-content: space-between;

        .item {
            width: 360px;
            height: 180px;
            border-radius: 4px;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
            margin-bottom: 20px;

            &:nth-child(1) {
                background-color: #88a0ff;
            }

            &:nth-child(2) {
                background-color: #ff8971;
            }

            &:nth-child(3) {
                background-color: #ffb93a;
            }

            &:nth-child(4) {
                background-color: #42a4ff;
            }

            .t {
                margin-top: 32px;
                color: #ffffff;
                font-size: 32px;
            }

            .c {
                margin-top: 32px;
                color: #ffffff;
                font-size: 20px;
                line-height: 32px;

                &.c2 {
                    margin-top: 0;
                }
            }
        }
    }

    .part3 {
        width: 1500px;
        height: 620px;
        border: 1px solid #00A2E8;
        box-sizing: border-box;

        img {
            width: 1498px;
            height: 618px;
        }
    }
}
</style>
