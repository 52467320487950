<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 14:54:47
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-24 17:38:48
-->
<template>
    <div class="home-search">
        <el-input class="input" v-model="state.keyword" placeholder="搜索更多资源" @keyup.enter="goSearch">
            <template #append>
                <el-button @click="goSearch">
                    <img src="@/assets/images/home/sousuo.png" />
                </el-button>
            </template>
        </el-input>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const state = reactive({
    keyword: "",
});

const router = useRouter()
const goSearch = () => {
    routerUtil.openNewWindow({
        path: `/search`,
        query: {
            keyword: state.keyword
        }
    } as any);
}

onMounted(() => {
    
})
</script>

<style lang="scss" scoped>
.home-search {
    .input {
        height: 64px;
        border-radius: 32px;
        box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.04);

        :deep(.el-input__wrapper) {
            box-sizing: border-box;
            border-top-left-radius: 32px;
            border-bottom-left-radius: 32px;
            border-right: none;
            box-shadow: none !important;
            font-size: 20px;
            padding-left: 0;
        }

        $place-holder-color: #a5a5a5;
        $place-holder-size: 20px;

        :deep(.el-input__inner) {
            font-size: $place-holder-size;
            height: 100%;
            padding-left: 18px;
        }

        :deep(.el-input__inner::placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-webkit-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-moz-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-ms-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input-group__append) {
            padding: 0;
            box-sizing: border-box;
            border-top-right-radius: 32px;
            border-bottom-right-radius: 32px;
            box-shadow: none !important;
            background-color: #ffffff;

            .el-button {
                width: 98px;
                height: 48px;
                line-height: 48px;
                border-radius: 24px;
                margin-right: 8px;
                background-color: #6e8b74;
                padding: 0;

                img {
                    width: 28px;
                    height: 28px;
                }
                .el-icon {
                    color: #ffffff;
                    font-size: 28px;
                }
            }
        }
    }

    :deep(.el-button > span) {
        width: 100%;
        height: 100%;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
