<template>
    <div class="book-category">
        <div class="item" v-for="(item, index) in state.category" :key="index" :id="`item-${ item.StrId }`">
            <div class="header">
                <div class="box">
                    <div class="han">{{ item.Name }}</div>
                    <div class="meng mFont">
                        <span
                            class="meng-vertical"
                            v-for="(iitem, iindex) in getMWords(item.MName)"
                            :key="iindex"
                            >{{ iitem }}</span
                        >
                    </div>

                </div>
                
                
                <div class="more" @click="goMore(item)">
                    <span>更多</span>
                    <img src="@/assets/images/home/gengduo.png" />
                </div>
            </div>
            <div class="body">
                <swiper
                    :modules="modules"
                    :loop="false"
                    :slides-per-view="3"
                    :space-between="102"
                    :navigation="navigation"
                    class="box"
                >
                    <div class="swiper-button-prev">
                        <img src="@/assets/images/common/zuojiantou2.png" />
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/images/common/youjiantou2.png" />
                    </div>
                    <swiper-slide
                        v-for="(iitem, iindex) in state.dataList[index]"
                        :key="iindex"
                        class="slider-item"
                        @click="goBook(iitem)"
                    >
                        <img class="item" v-lazy="iitem.Cover" />
                        <div class="title one-line">{{ iitem.Title }}</div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const emits = defineEmits(["handleComplete"]);

const state = reactive({
    category: [] as any[],
    dataList: [] as any[],
    id: ''
});

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const categoryList = () => {
    api.categoryList(
        { type: 2, showOther: false },
        async (res: any) => {
            state.category = res[0].children;
            for (let i = 0; i < state.category.length; i++) {
                const item = state.category[i] as any;
                state.dataList.push(await contentInfoNewList(item.StrId as string));
            }

            emits("handleComplete");
            
            if(state.id) {
                setTimeout(() => {
                    document.getElementById(`item-${ state.id }`)?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                }, 100)
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const route = useRoute()
const router = useRouter();
const goBook = (item: any) => {
    routerUtil.openNewWindow({
        path: `/bookdetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const contentInfoNewList = (categoryId: string) => {
    return new Promise((resolve, reject) => {
        api.contentInfoNewList(
            { objectType: 2, categoryId },
            (res: any) => {
                resolve(res);
            },
            (err: any) => {
                console.log(err);
            }
        );
    });
};

const modules = [Navigation];
const navigation = ref({
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
});

const goMore = (item: any) => {
    routerUtil.openNewWindow({
        path: `/search`,
        query: {
            id: item.StrId,
            index: 2
        },
    });
};

onMounted(() => {
    state.id = route.query.id
    categoryList();
});
</script>

<style lang="scss" scoped>
.book-category {
    margin: 0 auto;
    width: 1500px;
    border-radius: 4px;

    .item {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

        .header {
            position: relative;
            width: 100%;
            height: 96px;
            line-height: 96px;
            padding-left: 32px;
            box-sizing: border-box;
            border-bottom: 1px solid #d9dde1;
            text-align: left;

            .box {
                display: inline-block;
                border-bottom: 1px solid #2b333b;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
            }

            .han {
                display: inline-block;
                height: 96px;
                line-height: 96px;
                margin-right: 16px;
                font-size: 28px;
                color: #2b333b;
            }

            .meng {
                box-sizing: border-box;
                padding-top: 4px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                color: #787878;
                font-size: 20px;

                span {
                    line-height: 28px;
                }
            }

            .more {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 32px;
                right: 42px;
                top: 32px;
                border-radius: 16px;
                background-color: #f2e6ce;
                font-size: 16px;
                color: #000000;
                cursor: pointer;

                transition: all 0.2s;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }

                img {
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .body {
            box-sizing: border-box;
            width: 100%;
            height: 642px;
            padding-top: 40px;
            position: relative;

            .box {
                position: absolute;
                height: 602px;
                z-index: 100;
                left: 0px;
                right: 0px;
                padding-left: 50px;
                padding-right: 50px;
                box-sizing: border-box;

                .slider-item {
                    width: 398px !important;
                    height: 542px;
                    margin-right: 102px !important;
                    position: relative;
                    box-shadow: 16px 20px 20px 4px rgba(0, 0, 0, 0.2);
                    cursor: pointer;

                    .item {
                        width: 398px;
                        height: 542px;
                        object-fit: contain;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .item[lazy="loaded"] {
                        object-fit: cover;
                    }

                    .title {
                        position: absolute;
                        width: 398px;
                        height: 48px;
                        line-height: 48px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        font-size: 24px;
                        color: #ffffff;
                        background: rgba(0, 0, 0, 0.5);
                        z-index: 999;
                        bottom: 20px;
                        text-align: center;
                    }
                }

                .swiper-button-prev,
                .swiper-button-next {
                    width: 48px;
                    height: 48px;

                    &::after {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .swiper-button-prev {
                    margin-left: 42px;
                }

                .swiper-button-next {
                    margin-right: 42px;
                }
            }

            .banner {
                position: absolute;
                z-index: 99;
                left: 12px;
                right: 12px;
                bottom: 80px;
                height: 64px;
                line-height: 64px;
                background: linear-gradient(#e3d8c9, #f3ebe0, #e3d8c9);
                border-radius: 8px;
                box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

                &.num4 {
                    right: 12px;
                }

                &.num3 {
                    right: calc(12px + 356px);
                }

                &.num2 {
                    right: calc(12px + 356px * 2);
                }

                &.num1 {
                    right: calc(12px + 356px * 3);
                }

                &.num0 {
                    display: none;
                }
            }
        }
    }
}
</style>
