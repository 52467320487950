<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-07 19:41:53
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 13:35:44
-->
<template>
    <div class="notice-detail">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <notice-detail class="detail" @handleComplete="handleComplete"></notice-detail>
        </div>
        <footer-footer
            class="footer"
            :style="getMarginTopStyle(state.boxHeight)"
        ></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import NoticeDetail from "@/components/page/notice/NoticeDetail.vue";

import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle } from "@/utils/scale";
import FooterFooter from "@/components/footer/Footer.vue";

const router = useRouter();
const userStore = store.user() as any;
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const state = reactive({
    boxHeight: 0,
});

const box = ref(0);

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height;
    }, 10);
};

emitter.on('handleComplete', handleComplete)

onMounted(() => {});
</script>

<style lang="scss" scoped>
.notice-detail {
    width: 100%;

    .box {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 80px;
        background-color: #f8f8f8;
        box-sizing: border-box;

        .banner {
            width: 100%;
            margin-bottom: 64px;
        }

        .content {
            margin: 0 auto;
            margin-top: 40px;
        }
    }
}
</style>
