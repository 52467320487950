<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-26 20:13:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-05-24 13:51:10
-->
<template>
    <div class="image-more-search">
        <div class="gray">
            <div class="select one-line s2" @click="showType">
                <div class="placeholder" v-if="state.typeSelectStringList.length == 0">
                    全部分类
                </div>
                <div class="value one-line">
                    <ellipsis-view class="ellipsis" :title="state.typeSelectStringList.join('、')"></ellipsis-view>
                </div>
                <el-icon class="cicon" v-if="state.typeSelectStringList.length > 0" @click.prevent.stop="clearType">
                    <CircleClose />
                </el-icon>
                <el-icon class="micon">
                    <CaretBottom />
                </el-icon>
            </div>
            <div class="select one-line s1" @click="showArea">
                <div class="placeholder" v-if="state.areaSelectStringList.length == 0">
                    全部地区
                </div>
                <div class="value one-line">
                    <ellipsis-view class="ellipsis" :title="state.areaSelectStringList.join('、')"></ellipsis-view>
                </div>
                <el-icon class="cicon" v-if="state.areaSelectStringList.length > 0" @click.prevent.stop="clearArea">
                    <CircleClose />
                </el-icon>
                <el-icon class="micon">
                    <CaretBottom />
                </el-icon>
            </div>
            <div class="select one-line s3" @click="showYear">
                <div class="placeholder" v-if="!state.yearString">
                    全部时间范围
                </div>
                <div class="value one-line" v-else>
                    {{ state.yearString }}
                </div>
                <el-icon class="cicon" v-if="state.yearString" @click.prevent.stop="clearYear">
                    <CircleClose />
                </el-icon>
                <el-icon class="micon">
                    <CaretBottom />
                </el-icon>
            </div>
            <el-input class="input" v-model="state._keyword" placeholder="请输入检索词" @keyup.enter="handleSearch">
                <template #append>
                    <el-button @click="handleSearch">
                        <img src="@/assets/images/home/sousuo.png" />
                    </el-button>
                </template>
            </el-input>
        </div>
        
        <el-dialog
            v-model="state.showAreaDialog"
            append-to-body
            class="image-search-area-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :style="getScaleStyle()"
        >
            <div class="image-search-area-dialog-content" @wheel.stop="return">
                <img
                    class="image-search-area-dialog-close"
                    src="@/assets/images/common/close.png"
                    @click="areaCancel"
                />

                <div class="image-search-area-dialog-header">
                    <div class="image-search-area-dialog-header-left">
                        请选择地址：
                    </div>
                    <template v-if="state.areaSelectStringList.length == 0 && state.allAreaSelect">
                        <div class="image-search-area-dialog-header-right">
                            全部地区
                        </div>
                    </template>
                    <template v-else>
                        <div class="image-search-area-dialog-header-right">
                            {{ state.areaSelectStringList.join('；') }}
                        </div>
                    </template>
                </div>
                <div class="image-search-area-dialog-body">
                    <div class="image-search-area-dialog-body-area l1">
                        <span @click="allAreaClick" :class="{ select: state.allAreaSelect == true }">全部地区</span>
                    </div>
                    <div class="image-search-area-dialog-body-scroll">
                        <div class="image-search-area-dialog-body-area l2">
                            <div class="image-search-area-dialog-body-area-item" v-for="(item, index) in state.areaList" :key="index" :class="{ select: item.select }">
                                <span @click="areaClick(item)">{{ item.Name }}</span>
                            </div>
                        </div>
                        <template v-for="(item, index) in state.areaList" :key="index">
                            <template v-if="item.children.length > 0">
                                <div class="image-search-area-dialog-body-area l3" v-for="(iitem, iindex) in item.children" :key="iindex">
                                    <div class="image-search-area-dialog-body-area-title" :class="{ select: iitem.select }">
                                        <span @click="areaClick(iitem)">{{ iitem.Name }}</span>
                                    </div>
                                    <div class="image-search-area-dialog-body-area-content">
                                        <div class="image-search-area-dialog-body-area-item" v-for="(iiitem, iiindex) in iitem.children" :key="iiindex" :class="{ select: iiitem.select }">
                                            <span @click="areaClick(iiitem)">{{ iiitem.Name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="image-search-area-dialog-footer">
                    <div class="image-search-area-dialog-footer-submit" @click="areaSubmit">
                        确定
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            v-model="state.showTypeDialog"
            append-to-body
            class="image-search-type-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :style="getScaleStyle()"
        >
            <div class="image-search-type-dialog-content" @wheel.stop="return">
                <img
                    class="image-search-type-dialog-close"
                    src="@/assets/images/common/close.png"
                    @click="typeCancel"
                />

                <div class="image-search-type-dialog-header">
                    <div class="image-search-type-dialog-header-left">
                        请选择分类：
                    </div>
                    <template v-if="state.typeSelectStringList.length == 0 && state.allTypeSelect">
                        <div class="image-search-type-dialog-header-right">
                            全部分类
                        </div>
                    </template>
                    <template v-else>
                        <div class="image-search-type-dialog-header-right">
                            {{ state.typeSelectStringList.join('；') }}
                        </div>
                    </template>
                </div>
                <div class="image-search-type-dialog-body">
                    <div class="image-search-type-dialog-body-scroll">
                        <div class="image-search-type-dialog-body-area l2">
                            <div class="image-search-type-dialog-body-area-item" v-for="(item, index) in state.typeList" :key="index" @click="typeClick(item)" @dblclick="typeDBClick(item, index, 0)" :class="{ select: item.select, active: state.t1select == index && item.children.length > 0 }">
                                <span>{{ item.Name }}</span>

                                <el-icon class="micon" v-if="item.children.length > 0" @click.stop.prevent="typeDBClickFun(item, index, 0)">
                                    <CaretBottomG v-if="!item.select && state.t1select == index"/>
                                    <CaretBottom v-else/>
                                </el-icon>
                            </div>
                        </div>
                        
                        <template v-for="(item, index) in state.typeList" :key="index">
                            <div v-show="state.t1select == index">
                                <template v-if="item.children.length > 0">
                                    <div class="image-search-type-dialog-body-area l3">
                                        <template v-for="(iitem, iindex) in item.children" :key="iindex">
                                            <div class="image-search-type-dialog-body-area-item" @click="typeClick(iitem)" @dblclick="typeDBClick(iitem, iindex, 1)" :class="{ select: iitem.select, active: state.t2select == iindex && iitem.children.length > 0 }">
                                                <span>{{ iitem.Name }}</span>

                                                <el-icon class="micon" v-if="iitem.children.length > 0" @click.stop.prevent="typeDBClickFun(iitem, iindex, 1)">
                                                    <CaretBottomG v-if="!iitem.select && state.t2select == iindex"/>
                                                    <CaretBottom v-else/>
                                                </el-icon>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-if="state.typeList.length > 0 && state.typeList[state.t1select].children.length > 0">
                            <template v-for="(item, index) in state.typeList[state.t1select].children" :key="index">
                                <div v-show="state.t2select == index">
                                    <template v-if="item.children.length > 0">
                                        <div class="image-search-type-dialog-body-area l3">
                                            <template v-for="(iitem, iindex) in item.children" :key="iindex">
                                                <div class="image-search-type-dialog-body-area-item" @click="typeClick(iitem)" @dblclick="typeDBClick(iitem, iindex, 2)" :class="{ select: iitem.select, active: state.t3select == iindex && iitem.children.length > 0 }">
                                                    <span>{{ iitem.Name }}</span>

                                                    <el-icon class="micon" v-if="iitem.children.length > 0" @click.stop.prevent="typeDBClickFun(iitem, iindex, 2)">
                                                        <CaretBottomG v-if="!iitem.select && state.t3select == iindex"/>
                                                        <CaretBottom v-else/>
                                                    </el-icon>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>

                        <template v-if="state.typeList.length > 0 && state.typeList[state.t1select].children.length > 0 && state.typeList[state.t1select].children[state.t2select].children.length > 0">
                            <template v-for="(item, index) in state.typeList[state.t1select].children[state.t2select].children" :key="index">
                                <div v-show="state.t3select == index">
                                    <template v-if="item.children.length > 0">
                                        <div class="image-search-type-dialog-body-area l3">
                                            <template v-for="(iitem, iindex) in item.children" :key="iindex">
                                                <div class="image-search-type-dialog-body-area-item" @click="typeClick(iitem)" @dblclick="typeDBClick(iitem, iindex, 3)" :class="{ select: iitem.select, active: state.t4select == iindex && iitem.children.length > 0 }">
                                                    <span>{{ iitem.Name }}</span>

                                                    <el-icon class="micon" v-if="iitem.children.length > 0" @click.stop.prevent="typeDBClickFun(iitem, iindex, 3)">
                                                        <CaretBottom/>
                                                    </el-icon>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="image-search-type-dialog-footer">
                    <div class="image-search-type-dialog-footer-submit" @click="allTypeClick">
                        全部分类
                    </div>
                    <div class="image-search-type-dialog-footer-submit" @click="typeSubmit">
                        &emsp;确定&emsp;
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            v-model="state.showYearDialog"
            append-to-body
            class="image-search-year-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :style="getScaleStyle()"
        >
            <div class="image-search-year-dialog-content" @wheel.stop="return">
                <img
                    class="image-search-year-dialog-close"
                    src="@/assets/images/common/close.png"
                    @click="yearCancel"
                />

                <div class="image-search-year-dialog-header">
                    <div class="image-search-year-dialog-header-left">
                        请选择时间范围
                    </div>
                    <div class="image-search-year-dialog-header-years">
                        <el-date-picker
                            v-model="state.yearStart"
                            @change="yearChange"
                            type="year"
                            placeholder="开始时间"
                            class="image-search-year-dialog-header-yearselect"
                            format="YYYY年"
                            value-format="YYYY"
                            clear-icon
                            :editable="false"
                            :clearable="false"
                            :style="{ transform: `scale(${ 1 / getScale() })`, 'margin-right': (1 - getScale()) * 220 + 10 + 'px' }"
                        />
                        ~
                        <el-date-picker
                            v-model="state.yearEnd"
                            @change="yearChange"
                            type="year"
                            placeholder="结束时间"
                            class="image-search-year-dialog-header-yearselect"
                            format="YYYY年"
                            value-format="YYYY"
                            clear-icon
                            :editable="false"
                            :clearable="false"
                            :style="{ transform: `scale(${ 1 / getScale() })`, 'margin-left': (1 - getScale()) * 220 + 10 + 'px' }"
                        />
                    </div>
                </div>

                <div class="image-search-year-dialog-footer">
                    <div class="image-search-year-dialog-footer-submit" @click="yearAllSubmit">
                        全部时间
                    </div>
                    <div class="image-search-year-dialog-footer-submit" @click="yearSubmit">
                        &emsp;确定&emsp;
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import { getScaleStyle, getScale } from "@/utils/scale";
import _ from 'loadsh'
import { ElMessage } from 'element-plus'
import store from "@/pinia";

const userStore = store.user();

const props = defineProps({
    keyword: {
        type: String,
        default: "",
    },
    date: {
        type: String,
        default: "",
    },
    category: [] as any[]
});

watch(props, (newVal, oldVal) => {
    state._keyword = newVal.keyword

    if(state.typeList.length == 0) {
        state.typeList = newVal.category
    }
})

const state = reactive({
    _keyword: "",
    _date: "",

    showAreaDialog: false,
    areaListOld: [] as any[],
    areaList: [] as any[],
    areaSelectListOld: [] as any,
    areaSelectList: [] as any,
    areaSelectStringListOld: [] as any[],
    areaSelectStringList: [] as any[],
    allAreaSelectOld: false,
    allAreaSelect: false,

    typeFirstInit: true,
    showTypeDialog: false,
    typeListOld: [] as any[],
    typeList: [] as any[],
    typeSelectListOld: [] as any,
    typeSelectList: [] as any,
    typeSelectStringListOld: [] as any[],
    typeSelectStringList: [] as any[],
    allTypeSelectOld: false,
    allTypeSelect: false,
    t1select: 0,
    t2select: 0,
    t3select: 0,
    t4select: 0,

    showYearDialog: false,
    yearStart: '',
    yearStartOld: '',
    yearEnd: '',
    yearEndOld: '',
    yearString: '',
    yearStringOld: '',

    filterTypes: [],
    filterAreas: [],
    filterYears: [],
});


const emit = defineEmits(["update:keyword", "search", "filterTypesChange", "filterAreasChange", "filterYearsChange", "paramsChange"]);

const handleSearch = () => {
    setTimeout(() => {
        emit("update:keyword", state._keyword);
        emit("paramsChange", state.typeSelectList, state.areaSelectList, state.yearStart, state.yearEnd)
        initFilterInfo()
        emit("search", state._keyword);
    }, 500)
};

const initAreaInfoList = (list: any[], parent: any) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        item.select = false
        item.parent = parent
        if(item.children && item.children.length > 0) {
            initAreaInfoList(item.children, item)
        }
    }
}

const getAreaInfoList = () => {
    api.areaInfoList(
        {},
        (res: any) => {
            initAreaInfoList(res, null);
            state.areaList = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const showArea = () => {
    state.showAreaDialog = true
    state.areaListOld = _.cloneDeep(state.areaList)
    state.areaSelectListOld = _.cloneDeep(state.areaSelectList)
    state.areaSelectStringListOld = _.cloneDeep(state.areaSelectStringList)
    state.allAreaSelectOld = state.allAreaSelect

    if(state.areaList.length == 0) {
        getAreaInfoList()
    }
}

const getAreaChildSelect = (item: any) => {
    let result = `${item.parent.Name}-`
    let arr = []

    for (let i = 0; i < item.parent.children.length; i++) {
        const element = item.parent.children[i];
        if(element.select) {
            arr.push(element.Name)
        }
    }

    result += arr.join('、');
    return result
}

const getAreaSelect = (list: any[]) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if(item.select) {
            state.areaSelectList.push(item)

            if(item.parent && !item.parent.parent) {
                state.areaSelectStringList.push(item.Name)
            } else if(item.parent && item.parent.parent) {
                let str = getAreaChildSelect(item)
                let size = state.areaSelectStringList.filter((item: any) => {
                    return item == str
                }).length

                if(size == 0) {
                    state.areaSelectStringList.push(getAreaChildSelect(item))
                }
            } else if(!item.parent) {
                state.areaSelectStringList.push(item.Name)
            }
        } else {
            getAreaSelect(item.children)
        }
    }
}

const getAreaSelectList = () => {
    if(state.allAreaSelect) {
        state.areaSelectList = []
        state.areaSelectStringList = []
        return
    }

    state.areaSelectList = []
    state.areaSelectStringList = []
    getAreaSelect(state.areaList)
}

const allAreaClick = () => {
    state.allAreaSelect = !state.allAreaSelect

    for (let i = 0; i < state.areaList.length; i++) {
        const item = state.areaList[i];
        item.select = state.allAreaSelect
        areaChildrenCheck(item, state.allAreaSelect)
    }

    getAreaSelectList()
}

const areaChildrenCheck = (item: any, flag: boolean) => {
    if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
            const element = item.children[i];
            element.select = flag
            areaChildrenCheck(element, flag)
        }
    }
}

const areaParentCheck = (item: any) => {
    if(item.parent && item.parent.children && item.parent.children.length > 0) {
        let flag = true
        for (let i = 0; i < item.parent.children.length; i++) {
            const element = item.parent.children[i];

            if(!element.select) {
                flag = false
                break
            }
        }

        item.parent.select = flag
        areaParentCheck(item.parent)
    }
}

const areaAllCheck = () => {
    let flag = true

    for (let i = 0; i < state.areaList.length; i++) {
        const item = state.areaList[i];
        if(!item.select) {
            flag = false
            break
        }
    }

    state.allAreaSelect = flag
}

const areaClick = (item: any) => {
    item.select = !item.select
    areaChildrenCheck(item, item.select)
    areaParentCheck(item)

    areaAllCheck()
    getAreaSelectList()
}

const areaCancel = () => {
    state.showAreaDialog = false
    state.areaList = state.areaListOld
    state.areaSelectList = state.areaSelectListOld
    state.areaSelectStringList = state.areaSelectStringListOld
    state.allAreaSelect = state.allAreaSelectOld
}

const clearArea = () => {
    state.allAreaSelect = false

    for (let i = 0; i < state.areaList.length; i++) {
        const item = state.areaList[i];
        item.select = state.allAreaSelect
        areaChildrenCheck(item, state.allAreaSelect)
    }

    getAreaSelectList()
}

const areaSubmit = () => {
    state.showAreaDialog = false
    
    if(state.allAreaSelect) {
        clearArea()
    }
}

const initTypeList = (list: any[], parent: any) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        
        if(!('select' in item)) {
            item.select = false
        }

        item.parent = parent
        if(item.children && item.children.length > 0) {
            initTypeList(item.children, item)
        }
    }
}

const showType = () => {
    state.showTypeDialog = true
    state.typeListOld = _.cloneDeep(state.typeList)
    state.typeSelectListOld = _.cloneDeep(state.typeSelectList)
    state.typeSelectStringListOld = _.cloneDeep(state.typeSelectStringList)
    state.allTypeSelectOld = state.allTypeSelect

    state.t1select = 0
    state.t2select = 0
    state.t3select = 0
    state.t4select = 0

    initTypeList(state.typeList, null)
}

const during = 300

const typeClick = (item: any) => {
    if(item.timer) {
        clearTimeout(item.timer)
    }

    item.timer = setTimeout(() => {
        typeClickFun(item)
    }, during)
}

const typeChildrenCheck = (item: any, flag: boolean) => {
    if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
            const element = item.children[i];
            element.select = flag
            typeChildrenCheck(element, flag)
        }
    }
}

const typeParentCheck = (item: any) => {
    if(item.parent && item.parent.children && item.parent.children.length > 0) {
        let flag = true
        for (let i = 0; i < item.parent.children.length; i++) {
            const element = item.parent.children[i];

            if(!element.select) {
                flag = false
                break
            }
        }

        item.parent.select = flag
        typeParentCheck(item.parent)
    }
}

const getTypeSelect = (list: any[]) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if(item.select) {
            state.typeSelectList.push(item)

            if(item.parent && !item.parent.parent) {
                state.typeSelectStringList.push(item.Name)
            } else if(item.parent && item.parent.parent) {
                state.typeSelectStringList.push(item.Name)
            } else if(!item.parent) {
                state.typeSelectStringList.push(item.Name)
            }
        } else {
            getTypeSelect(item.children)
        }
    }
}

const getTypeSelectList = () => {
    if(state.allTypeSelect) {
        state.typeSelectList = []
        state.typeSelectStringList = []
        return
    }

    state.typeSelectList = []
    state.typeSelectStringList = []
    getTypeSelect(state.typeList)
}

const typeClickFun = (item: any) => {
    item.select = !item.select

    typeChildrenCheck(item, item.select)
    typeParentCheck(item)

    typeAllCheck()
    getTypeSelectList()
}

const typeDBClick = (item: any, index: number, level: number) => {
    if(item.timer) {
        clearTimeout(item.timer)
        item.timer = null
    }

    typeDBClickFun(item, index, level)
}

const typeDBClickFun = (item: any, index: number, level: number) => {
    if(item.children.length == 0) {
        return
    }

    if(level == 0) {
        state.t1select = index
        state.t2select = 0
        state.t3select = 0
        state.t4select = 0
    } else if(level == 1) {
        state.t2select = index
        state.t3select = 0
        state.t4select = 0
    } else if(level == 2) {
        state.t3select = index
        state.t4select = 0
    } else if(level == 3) {
        state.t4select = index
    }
}

const typeAllCheck = () => {
    let flag = true

    for (let i = 0; i < state.typeList.length; i++) {
        const item = state.typeList[i];
        if(!item.select) {
            flag = false
            break
        }
    }

    state.allTypeSelect = flag
}

const allTypeClick = () => {
    state.allTypeSelect = !state.allTypeSelect

    for (let i = 0; i < state.typeList.length; i++) {
        const item = state.typeList[i];
        item.select = state.allTypeSelect
        typeChildrenCheck(item, state.allTypeSelect)
    }

    getTypeSelectList()
}

const typeCancel = () => {
    state.showTypeDialog = false

    state.typeList = state.typeListOld
    state.typeSelectList = state.typeSelectListOld
    state.typeSelectStringList = state.typeSelectStringListOld
    state.allTypeSelect = state.allTypeSelectOld
}

const clearType = () => {
    state.allTypeSelect = false

    for (let i = 0; i < state.typeList.length; i++) {
        const item = state.typeList[i];
        item.select = state.allTypeSelect
        typeChildrenCheck(item, state.allTypeSelect)
    }

    getTypeSelectList()
}

const typeSubmit = () => {
    state.showTypeDialog = false
    
    if(state.allTypeSelect) {
        clearType()
    }
}

const showYear = () => {
    state.showYearDialog = true
    state.yearStartOld = state.yearStart
    state.yearEndOld = state.yearEnd
    state.yearStringOld = state.yearString
}

const yearCancel = () => {
    state.yearStart = state.yearStartOld
    state.yearEnd = state.yearEndOld
    state.yearString = state.yearStringOld
    state.showYearDialog = false
}

const yearChange = () => {
    if(!state.yearStart || !state.yearEnd) {
        return
    }

    if(Number(state.yearStart) > Number(state.yearEnd)) {
        let yearTemp = state.yearEnd
        state.yearEnd = state.yearStart
        state.yearStart = yearTemp
    }
}

const clearYear = () => {
    state.yearStart = ''
    state.yearEnd = ''
    state.yearString = ''
}

const yearAllSubmit = () => {
    state.showYearDialog = false
    state.yearString = ''
    state.yearStart = ''
    state.yearEnd = ''
}

const yearSubmit = () => {
    if(!state.yearStart && state.yearEnd) {
        ElMessage.error('请选择开始时间')
        return
    }

    if(state.yearStart && !state.yearEnd) {
        ElMessage.error('请选择结束时间')
        return
    }

    state.showYearDialog = false
    
    if(state.yearStart && state.yearEnd) {
        state.yearString = `${ state.yearStart }~${ state.yearEnd }`
    }
}

const initImageSearchInfo = () => {
    let imageSearchInfo = userStore.imageSearchInfo;
    if(imageSearchInfo) {
        state.areaList = imageSearchInfo.areaList ? imageSearchInfo.areaList : [];
        state.areaSelectList = imageSearchInfo.areaSelectList ? imageSearchInfo.areaSelectList : [];
        state.areaSelectStringList = imageSearchInfo.areaSelectStringList ? imageSearchInfo.areaSelectStringList : [];
        state.allAreaSelect = imageSearchInfo.allAreaSelect ? imageSearchInfo.allAreaSelect : false;

        state.typeFirstInit = 'typeFirstInit' in imageSearchInfo ? imageSearchInfo.typeFirstInit : true;

        state.typeList = imageSearchInfo.typeList ? imageSearchInfo.typeList : [];
        state.typeSelectList = imageSearchInfo.typeSelectList ? imageSearchInfo.typeSelectList : [];
        state.typeSelectStringList = imageSearchInfo.typeSelectStringList ? imageSearchInfo.typeSelectStringList : [];
        state.allTypeSelect = imageSearchInfo.allTypeSelect ? imageSearchInfo.allTypeSelect : false;

        state.yearStart = imageSearchInfo.yearStart ? imageSearchInfo.yearStart : '';
        state.yearEnd = imageSearchInfo.yearEnd ? imageSearchInfo.yearEnd : '';
        state.yearString = imageSearchInfo.yearString ? imageSearchInfo.yearString : '';
    }

    handleSearch()
}

const initFilterInfo = () => {
    let typeIds = ''

    if(!state.allTypeSelect) {
        typeIds = state.typeSelectList.map((item: any) => {
            return item.StrId
        }).join(',')
    }

    let areaIds = ''

    if(!state.allAreaSelect) {
        areaIds = state.areaSelectList.map((item: any) => {
            return item.StrId
        }).join(',')
    }

    let params = {
        keyword: state._keyword,
        objectType: 4,
        sYear: state.yearStart,
        eYear: state.yearEnd,
    } as any

    if(typeIds) {
        params.categoryIds = typeIds
    }

    if(areaIds) {
        params.areaIds = areaIds
    }

    api.contentInfoCategoryData(
        params,
            async (res: any) => {
                state.filterTypes = res[0].children
                emit("filterTypesChange", state.filterTypes)
            },
            (err: any) => {
                console.log(err);
            }
        );

    api.contentInfoYearData(
        params,
            async (res: any) => {
                state.filterYears = res
                emit("filterYearsChange", state.filterYears)
            },
            (err: any) => {
                console.log(err);
            }
        );

    api.contentInfoAreaData(
        params,
            async (res: any) => {
                state.filterAreas = res
                emit("filterAreasChange", state.filterAreas)
            },
            (err: any) => {
                console.log(err);
            }
        );
}

onMounted(() => {
    state._keyword = props.keyword;
    state._date = props.date;

    initImageSearchInfo()
});
</script>

<style lang="scss" scoped>
.image-more-search {
    .gray {
        background-color: rgb(216, 216, 216);
        box-sizing: border-box;
        padding: 8px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .select {
            height: 64px;
            border-radius: 12px;
            width: 340px;
            background-color: #FFFFFF;
            margin-right: 16px;
            position: relative;
            box-sizing: border-box;
            padding-right: 32px;
            cursor: pointer;
            
            .placeholder {
                padding-left: 12px;
                height: 64px;
                line-height: 64px;
                font-size: 20px;
                color: #a8abb2;
                text-align: left;
            }

            .value {
                padding-left: 12px;
                height: 64px;
                line-height: 64px;
                font-size: 20px;
                color: #2b333b;
                text-align: left;
                width: calc(100% - 32px);

                .ellipsis {
                    height: 64px;
                    line-height: 64px;
                }
            }

            .cicon {
                position: absolute;
                right: 32px;
                top: 24px;
                cursor: pointer;
            }

            .micon {
                position: absolute;
                right: 8px;
                top: 24px;
                cursor: pointer;
            }
        }
    }

    .input {
        height: 64px;
        width: 640px;
        border-radius: 32px;
        box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.04);

        :deep(.el-input__wrapper) {
            box-sizing: border-box;
            border-top-left-radius: 32px;
            border-bottom-left-radius: 32px;
            border-right: none;
            box-shadow: none !important;
            font-size: 20px;
            padding-left: 18px;
        }

        $place-holder-color: #a5a5a5;
        $place-holder-size: 20px;

        :deep(.el-input__inner) {
            font-size: $place-holder-size;
            height: 100%;
        }

        :deep(.el-input__inner::placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-webkit-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-moz-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-ms-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input-group__append) {
            padding: 0;
            box-sizing: border-box;
            border-top-right-radius: 32px;
            border-bottom-right-radius: 32px;
            box-shadow: none !important;
            background-color: #ffffff;

            .el-button {
                width: 98px;
                height: 48px;
                line-height: 48px;
                border-radius: 24px;
                margin-right: 8px;
                background-color: #6e8b74;
                padding: 0;

                img {
                    width: 28px;
                    height: 28px;
                }
                .el-icon {
                    color: #ffffff;
                    font-size: 28px;
                }
            }
        }
    }

    :deep(.el-button > span) {
        width: 100%;
        height: 100%;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}
</style>

<style lang="scss">
.image-search-area-dialog {
    width: 1504px;
    border-radius: 8px;
    transform-origin: 50% 50% !important;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 100%;
        padding: 0;

        .image-search-area-dialog-content {
            width: 100%;
            padding-top: 0px;
            box-sizing: border-box;
            position: relative;

            .image-search-area-dialog-close {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 32px;
                top: 12px;
                cursor: pointer;
            }

            .image-search-area-dialog-header {
                margin-left: 32px;
                margin-right: 32px;
                border-radius: 8px 8px 0px 0px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 24px;
                color: #2b333b;
                border-bottom: 1px solid #d9dde1;
                padding-bottom: 12px;
                font-weight: 500;

                .image-search-area-dialog-header-left {
                    font-size: 24px;
                    line-height: 48px;
                    font-weight: 500;
                    letter-spacing: 2px;
                    width: 160px;
                }

                .image-search-area-dialog-header-right {
                    font-size: 22px;
                    line-height: 48px;
                    max-height: 96px;
                    overflow-y: auto;
                    width: calc(100% - 160px);

                    &::-webkit-scrollbar {
                        width: 4px; /* 滚动条宽度 */
                        background-color: #f9f9f9; /* 滚动条背景色 */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px; /* 滚动条圆角 */
                        background-color: #c1c1c1; /* 滚动条颜色 */
                        background-clip: padding-box; /* 背景剪切 */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background-color: #f9f9f9; /* 滚动条轨道颜色 */
                    }
                }
            }

            .image-search-area-dialog-body {
                font-size: 20px;
                line-height: 48px;
                color: #2b333b;
                text-align: left;
                letter-spacing: 1px;
                margin-top: 0px;
                margin-bottom: 40px;
                margin-left: 32px;
                margin-right: 32px;

                .image-search-area-dialog-body-scroll {
                    max-height: calc(60vh - 160px);
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 4px; /* 滚动条宽度 */
                        background-color: #f9f9f9; /* 滚动条背景色 */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px; /* 滚动条圆角 */
                        background-color: #c1c1c1; /* 滚动条颜色 */
                        background-clip: padding-box; /* 背景剪切 */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background-color: #f9f9f9; /* 滚动条轨道颜色 */
                    }
                }

                .image-search-area-dialog-body-area {
                    padding-left: 18px;

                    .image-search-area-dialog-body-area-title {
                        margin-right: 32px;
                        display: block;

                        span {
                            height: 34px;
                            line-height: 34px;
                            padding: 0 8px 0px 8px;
                            border-radius: 4px;
                            cursor: pointer;
                        } 

                        &.select {
                            span {
                                color: #FFFFFF;
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    .image-search-area-dialog-body-area-content {
                        margin-left: 12px;
                        line-height: 32px;
                        padding-bottom: 0px;
                    }

                    .image-search-area-dialog-body-area-item {
                        margin-right: 32px;
                        display: inline-block;
                        height: 32px;
                        line-height: 32px;
                        padding: 0 8px 0px 8px;
                        border-radius: 4px;
                        
                        span {
                            display: inline-block;
                            cursor: pointer;
                        }

                        &.select {
                            color: #FFFFFF;
                            background-color: rgb(110, 139, 116);
                        }
                    }

                    &.l1 {
                        font-size: 24px;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        
                        span {
                            cursor: pointer;
                            display: inline-block;
                            height: 36px;
                            line-height: 36px;
                            padding: 0 8px 2px 8px;
                            border-radius: 4px;

                            &.select {
                                color: #FFFFFF;
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    &.l2 {
                        font-size: 22px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }

                    
                    &.l3 {
                        font-size: 20px;
                        display: block;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;

                        .image-search-area-dialog-body-area-title {
                            font-size: 20px;
                        }

                        .image-search-area-dialog-body-area-item {
                            font-size: 18px;
                            line-height: 32px;
                            margin-bottom: 12px;
                        }
                    }
                }
            }

            .image-search-area-dialog-footer {
                display: flex;
                justify-content: center;
                padding-bottom: 16px;

                .image-search-area-dialog-footer-submit {
                    color: #FFFFFF;
                    font-size: 20px;
                    padding: 8px 36px;
                    border-radius: 8px;
                    background-color: rgb(110, 139, 116);
                    display: inline-block;
                    margin: 0 auto;
                    cursor: pointer;
                }
            }
        }
    }
}

.image-search-type-dialog {
    width: 1504px;
    transform-origin: center !important;
    border-radius: 8px;
    margin-top: 20rem;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 100%;
        padding: 0;

        .image-search-type-dialog-content {
            width: 100%;
            padding-top: 0px;
            box-sizing: border-box;
            position: relative;

            .image-search-type-dialog-close {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 32px;
                top: 12px;
                cursor: pointer;
            }

            .image-search-type-dialog-header {
                margin-left: 32px;
                margin-right: 32px;
                border-radius: 8px 8px 0px 0px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 24px;
                color: #2b333b;
                border-bottom: 1px solid #d9dde1;
                padding-bottom: 12px;
                font-weight: 500;

                .image-search-type-dialog-header-left {
                    font-size: 24px;
                    line-height: 48px;
                    font-weight: 500;
                    letter-spacing: 2px;
                    width: 160px;
                }

                .image-search-type-dialog-header-right {
                    font-size: 22px;
                    line-height: 48px;
                    max-height: 96px;
                    overflow-y: auto;
                    width: calc(100% - 160px);

                    &::-webkit-scrollbar {
                        width: 4px; /* 滚动条宽度 */
                        background-color: #f9f9f9; /* 滚动条背景色 */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px; /* 滚动条圆角 */
                        background-color: #c1c1c1; /* 滚动条颜色 */
                        background-clip: padding-box; /* 背景剪切 */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background-color: #f9f9f9; /* 滚动条轨道颜色 */
                    }
                }
            }

            .image-search-type-dialog-body {
                font-size: 20px;
                line-height: 48px;
                color: #2b333b;
                text-align: left;
                letter-spacing: 1px;
                margin-top: 0px;
                margin-bottom: 40px;
                margin-left: 32px;
                margin-right: 32px;

                .image-search-type-dialog-body-scroll {
                    max-height: calc(60vh - 160px);
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 4px; /* 滚动条宽度 */
                        background-color: #f9f9f9; /* 滚动条背景色 */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px; /* 滚动条圆角 */
                        background-color: #c1c1c1; /* 滚动条颜色 */
                        background-clip: padding-box; /* 背景剪切 */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background-color: #f9f9f9; /* 滚动条轨道颜色 */
                    }
                }

                .image-search-type-dialog-body-area {
                    padding-left: 18px;

                    .image-search-type-dialog-body-area-title {
                        margin-right: 32px;
                        cursor: pointer;
                        display: block;

                        span {
                            height: 34px;
                            line-height: 34px;
                            padding: 0 8px 0px 8px;
                            border-radius: 4px;
                        } 

                        &.select {
                            span {
                                color: #FFFFFF;
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    .image-search-type-dialog-body-area-content {
                        margin-left: 12px;
                        line-height: 32px;
                        padding-bottom: 0px;
                    }

                    .image-search-type-dialog-body-area-item {
                        position: relative;
                        margin-right: 32px;
                        cursor: pointer;
                        display: inline-block;
                        height: 32px;
                        line-height: 32px;
                        padding: 0 8px 0px 8px;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        user-select: none;
                        
                        &:last-child {
                            margin-right: 0;
                        }

                        &.active {
                            color: rgb(110, 139, 116);
                            
                            span {
                                display: inline-block;
                            }

                            &::after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                left: 8px;
                                right: 10px;
                                bottom: -6px;
                                border-bottom: 2px solid rgb(110, 139, 116);
                            }
                            
                            &.select {
                                &::after {
                                    left: 0px;
                                    right: 0px;
                                }
                            }
                        }

                        &.select {
                            color: #FFFFFF;
                            background-color: rgb(110, 139, 116);
                        }
                    }

                    &.l1 {
                        font-size: 24px;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        
                        span {
                            cursor: pointer;
                            display: inline-block;
                            height: 36px;
                            line-height: 36px;
                            padding: 0 8px 2px 8px;
                            border-radius: 4px;

                            &.select {
                                color: #FFFFFF;
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    &.l2 {
                        font-size: 22px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }

                    
                    &.l3 {
                        font-size: 20px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }
                }
            }

            .image-search-type-dialog-footer {
                display: flex;
                justify-content: center;
                padding-bottom: 16px;

                .image-search-type-dialog-footer-submit {
                    color: #FFFFFF;
                    font-size: 20px;
                    padding: 8px 36px;
                    border-radius: 8px;
                    background-color: rgb(110, 139, 116);
                    display: inline-block;
                    margin: 0px 20px;
                    cursor: pointer;
                }
            }
        }
    }
}


.image-search-year-dialog {
    width: 764px;
    transform-origin: center !important;
    border-radius: 8px;
    margin-top: 20rem;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 100%;
        padding: 0;

        .image-search-year-dialog-content {
            width: 100%;
            padding-top: 0px;
            box-sizing: border-box;
            position: relative;

            .image-search-year-dialog-close {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 32px;
                top: 12px;
                cursor: pointer;
            }

            .image-search-year-dialog-header {
                padding-top: 0px;
                margin-left: 32px;
                margin-right: 32px;
                margin-top: 0px;
                margin-bottom: 36px;
                border-radius: 8px 8px 0px 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-size: 24px;
                color: #2b333b;
                padding-bottom: 12px;
                font-weight: 500;

                .image-search-year-dialog-header-left {
                    margin-bottom: 24px;
                    font-size: 24px;
                    line-height: 48px;
                    font-weight: 500;
                    letter-spacing: 2px;
                }

                .image-search-year-dialog-header-years {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                .image-search-year-dialog-header-yearselect {
                    transform-origin: center;
                }
            }

            .image-search-year-dialog-footer {
                display: flex;
                justify-content: center;
                padding-bottom: 16px;

                .image-search-year-dialog-footer-submit {
                    color: #FFFFFF;
                    font-size: 20px;
                    padding: 8px 36px;
                    border-radius: 8px;
                    background-color: rgb(110, 139, 116);
                    display: inline-block;
                    margin: 0 20px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>