/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-06 18:34:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-10 19:34:48
 */
import { defineStore } from "pinia"
import api from "@/common/api.js"
import Cookies from 'js-cookie'
import { getToken } from '@/request'

const EXPIRE = { expires: 365 }

export default defineStore('user', {
    state: () => ({
        loading: true,
		hasLogin: false,
        userInfo: {} as any,
        categorys: [] as any[],
        showLoginDialog: false,
        imageSearchInfo: {} as any,
        menuSelectIndex: '-1'     // 选中的顶部菜单
    }),
    persist: {
        enabled: false
    },
    actions: {
        getUserInfo() {
            if(getToken()) {
                this.loading = true
                api.userInfo((res: any) => {
                    // 获取用户信息成功
                    if(!res.Age) {
                        res.Age = ''
                    }
                    this.setUserInfo(res)
                }, (e: any) => {
                    // 获取用户下信息失败
                    console.log(e)
                    this.hasLogin = false
                    this.loading = false
                })
            }
        },
        setUserInfo(info: any) {
            Cookies.set('token', info.Token, EXPIRE)
            this.hasLogin = true
            this.userInfo = info
            this.loading = false
        },
        setCategorys(categorys: any[]) {
            Cookies.set('categorys', JSON.stringify(categorys), EXPIRE)
            this.categorys = categorys
        },
        setImageSearchInfo(info: any) {
            this.imageSearchInfo = info
        },
        showLogin() {
            this.showLoginDialog = true
        },
        hideLogin() {
            this.showLoginDialog = false
        },
        logout(callback: Function) {
            Cookies.remove('token')
            this.userInfo = {}
            this.hasLogin = false
            callback()
        },
        setMenuSelectIndex(index: string) {
            this.menuSelectIndex = index
        }
    }
})