<template>
    <div class="c-notice-detail">
        <div class="header">
            <div class="han">系统公告</div>
            <div class="meng mFont">
                <span class="meng-vertical">ᠱᠢᠰᠲᠧᠮ᠎ᠦᠨ</span>
                <span class="meng-vertical">ᠠᠯᠪᠠᠨ</span>
                <span class="meng-vertical">ᠵᠠᠷ</span>
            </div>
        </div>
        <div class="content">
            <div class="main" v-if="state.item">
                <div class="title one-line">
                    {{ state.item.Title }}
                </div>
                <div class="more-box">
                    <div class="more" @click="goNotice">
                        <span>更多</span>
                        <img src="@/assets/images/home/gengduo.png" />
                    </div>
                </div>
                <div class="divider"></div>
                <div class="info" v-html="state.item.FullText"></div>
                <div class="dept" v-if="state.item && state.item.Dept">
                    {{ state.item.Dept }}
                </div>
                <div :class="[state.item.Dept ? 'date' : 'dept']">
                    {{ moment(state.item.ShowFDate).format("YYYY年MM月DD日") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const route = useRoute() as any;
const emits = defineEmits(["handleComplete"]);

const state = reactive({
    id: "",
    item: {} as any,
});

const router = useRouter()
const goNotice = () => {
    routerUtil.openNewWindow({
        path: `/noticelist`,
    });
};

const noticeInfo = () => {
    state.item = {};

    api.noticeInfo(
        { id: state.id },
        (res: any) => {
            state.item = res;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

onMounted(() => {
    state.id = route.query.id;
    noticeInfo();
});
</script>

<style lang="scss" scoped>
.c-notice-detail {
    width: 100%;

    .header {
        width: 100%;
        height: 140px;
        background-color: #f0f8f1;
        display: flex;
        justify-content: center;
        align-items: center;

        .han {
            margin-right: 50px;
            color: #2b333b;
            font-size: 40px;
            text-align: center;
            font-weight: 500;
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                right: -32px;
                top: -8px;
                width: 2px;
                height: 48px;
                border-radius: 2px;
                background-color: #2b333b;
            }
        }

        .meng {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            span {
                line-height: 26px;
                font-size: 26px;
                color: #2b333b;
            }
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        min-height: calc(100vh - 240px);

        .main {
            padding: 40px 0px 172px 0px;

            .title {
                box-sizing: border-box;
                padding-left: 50px;
                padding-right: 50px;
                font-size: 28px;
                line-height: 28px;
                color: #2b333b;
                font-weight: 600;
            }

            .more-box {
                width: 100%;
                box-sizing: border-box;
                padding-right: 24px;
                padding-top: 24px;
                display: flex;
                justify-content: flex-end;

                .more {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 32px;
                    right: 42px;
                    top: 32px;
                    border-radius: 16px;
                    background-color: #f2e6ce;
                    font-size: 16px;
                    color: #000000;
                    cursor: pointer;

                    transition: all 0.2s;
                    transform: scale(1);

                    &:hover {
                        transform: scale(1.2);
                    }

                    img {
                        margin-left: 8px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .divider {
                width: 100%;
                border-bottom: 1px solid #d9dde1;
                margin-top: 24px;
            }

            .info {
                margin-top: 24px;
                margin-left: 50px;
                margin-right: 50px;

                :deep(p) {
                    font-size: 20px;
                    line-height: 40px;
                    text-align: justify;
                    text-indent: 40px;
                    color: #2B333B;
                }
            }

            .dept {
                text-align: right;
                padding-right: 50px;
                font-size: 18px;
                color: #2b333b;
                margin-top: 24px;
                line-height: 56px;
            }

            .date {
                text-align: right;
                padding-right: 50px;
                font-size: 18px;
                color: #2b333b;
                line-height: 56px;
                margin-bottom: 172px;
            }
        }
    }
}
</style>
