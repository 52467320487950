<template>
    <div class="c-magazine-feature">
        <div class="header">
            <div class="han">融合画报栏目</div>
            <div class="meng mFont">
                <span class="meng-vertical">ᠬᠠᠮ</span>
                <span class="meng-vertical">ᠵᠢᠷᠤᠭᠲᠤ</span>
                <span class="meng-vertical">ᠰᠡᠳᠭᠦᠯ</span>
                <span class="meng-vertical">ᠪᠣᠯᠤᠩ</span>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <div class="menu" v-for="(item, index) in state.features" :key="index"
                    :class="{ select: state.menuSelect == index }" @click="menuClick(index)">
                    {{ item.Name }}
                </div>
            </div>
            <div class="right">
                <div class="p1" v-if="state.features.length > 0">
                    <div class="title">
                        {{ state.features[state.menuSelect].Name }}
                    </div>
                    <div class="remark" v-html="state.features[state.menuSelect].Remark"></div>
                </div>
                <div class="p2">
                    <div class="thead">
                        <div class="th th1">卷期</div>
                        <div class="th th2">标题</div>
                    </div>
                    <div class="tbody">
                        <template v-if="state.list.length > 0">
                            <div class="item" v-for="(item, index) in state.list" :key="index">
                                <div class="th th1 one-line">{{ item.Date }}</div>
                                <div class="th th2 one-line" @click="goReadPageById(item.StrId)">{{ item.Title }}</div>
                                <div class="icons">
                                    <template v-for="(iitem, iindex) in item?.RelationData" :key="iindex">
                                        <div v-if="iitem.ObjectType == 5" class="icon" @click.stop.prevent="goReadPageById(iitem.StrId)">
                                            <!-- 视频 -->
                                            <el-tooltip :content="iitem.Title" effect="light">
                                                <img src="@/assets/images/search/shipin.png" />
                                            </el-tooltip>
                                        </div>
                                        <div v-else-if="iitem.ObjectType == 3 && iitem.Language == '蒙文'" class="icon"
                                            @click.stop.prevent="goReadPageById(iitem.StrId)">
                                            <!-- 蒙文 -->
                                            <el-tooltip :content="iitem.Title" effect="light">
                                                <img src="@/assets/images/search/meng.png" />
                                            </el-tooltip>
                                        </div>
                                        <div v-else-if="iitem.ObjectType == 3 && iitem.Language != '蒙文'" class="icon"
                                            @click.stop.prevent="goReadPageById(iitem.StrId)">
                                            <!-- 其他 -->
                                            <el-tooltip :content="iitem.Title" effect="light">
                                                <img src="@/assets/images/search/han.png" />
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="item-empty">暂无数据</div>
                        </template>
                    </div>
                    <div class="pager" v-if="getPageCount() > 1">
                        <div class="btn" :class="{ disabled: state.cp == 1 }" @click="handleLast">
                            上一页
                        </div>
                        <div class="count">{{ state.cp }} / {{ getPageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.cp >= getPageCount() }" @click="handleNext">
                            下一页
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import { storeToRefs } from "pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch } from "vue";
import nations from "@/common/nations.js";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { getToken } from "@/request";
import routerUtil from "@/common/router";

const router = useRouter();
const userStore = store.user();
const { userInfo } = storeToRefs(userStore);

const emits = defineEmits(["handleComplete"]);

const state = reactive({
    menuSelect: 0,
    id: '' as string,
    features: [] as any[],
    cp: 1,
    ps: 10,
    list: [] as any[],
    total: 0,
});

const columnInfoList = () => {
    api.columnInfoList(
        {},
        (res: any) => {
            state.features = res;
            getSelectIndex()
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const columnInfoColumContent = () => {
    api.columnInfoColumContent(
        { columnId: state.features[state.menuSelect].StrId, cp: state.cp, ps: state.ps },
        (res: any) => {
            state.total = res.RecordCount;
            state.list = res.ItemList;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const goReadPageById = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

const getPageCount = () => {
    if (state.total % state.ps == 0) {
        return state.total / state.ps;
    } else {
        return Math.floor(state.total / state.ps) + 1;
    }
};

const handleLast = () => {
    if (state.cp <= 1) {
        state.cp = 1;
        return;
    }

    state.cp -= 1;
    columnInfoColumContent();
};

const handleNext = () => {
    if (state.cp >= getPageCount()) {
        state.cp = getPageCount();
        return;
    }

    state.cp += 1;
    columnInfoColumContent();
};

const menuClick = (index: number) => {
    state.menuSelect = index;
    state.cp = 1;
    columnInfoColumContent();
};

const getSelectIndex = () => {
    for (let i = 0; i < state.features.length; i++) {
        const item = state.features[i];
        if(item.StrId == state.id) {
            menuClick(i)
            return
        }
    }

    menuClick(0)
}

const init = (p: string) => {
    state.id = p
    columnInfoList();
}

defineExpose({
    init
})
</script>

<style lang="scss" scoped>
.c-magazine-feature {
    width: 100%;

    .header {
        width: 100%;
        height: 140px;
        background-color: #f0f8f1;
        display: flex;
        justify-content: center;
        align-items: center;

        .han {
            margin-right: 50px;
            color: #2b333b;
            font-size: 40px;
            text-align: center;
            font-weight: 500;
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                right: -32px;
                top: -8px;
                width: 2px;
                height: 48px;
                border-radius: 2px;
                background-color: #2b333b;
            }
        }

        .meng {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            span {
                line-height: 26px;
                font-size: 26px;
                color: #2b333b;
            }
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .left {
            width: 260px;
            max-height: 780px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
            border-radius: 4px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px; /* 滚动条宽度 */
                background-color: #f9f9f9; /* 滚动条背景色 */
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 4px; /* 滚动条圆角 */
                background-color: #c1c1c1; /* 滚动条颜色 */
                background-clip: padding-box; /* 背景剪切 */
            }
            
            &::-webkit-scrollbar-track {
                background-color: #f9f9f9; /* 滚动条轨道颜色 */
            }

            .menu {
                height: 64px;
                line-height: 64px;
                text-align: center;
                font-size: 20px;
                color: #2b333b;
                cursor: pointer;

                &.select {
                    color: #ffffff;
                    background-color: #6e8b74;
                }
            }
        }

        .right {
            width: 1224px;
            min-height: calc(100vh - 240px);

            .p1 {
                width: 100%;
                box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
                padding: 20px 20px 30px 20px;
                border-radius: 4px;
                margin-bottom: 20px;
                box-sizing: border-box;

                .title {
                    font-size: 24px;
                    color: #2b333b;
                    font-weight: 600;
                    margin-bottom: 20px;
                    text-align: left;
                }

                .remark {
                    :deep(p) {
                        font-size: 20px;
                        line-height: 40px;
                        text-align: justify;
                        text-indent: 40px;
                        color: #2B333B;
                    }
                }
            }

            .p2 {
                width: 100%;
                box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

                .thead {
                    border-bottom: 1px solid #d9dde1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;

                    .th {
                        height: 70px;
                        line-height: 70px;
                        font-size: 20px;
                        color: #596067;

                        &.th1 {
                            width: 170px;
                            margin-left: 40px;
                            margin-right: 20px;
                            text-align: left;
                        }

                        &.th2 {
                            width: 860px;
                            margin-left: 40px;
                            margin-right: 20px;
                            text-align: left;
                            box-sizing: border-box;
                            padding-left: 50px;
                        }
                    }
                }

                .tbody {
                    .item {
                        height: 70px;
                        line-height: 70px;
                        font-size: 20px;
                        color: #596067;
                        border-bottom: 1px solid #d9dde1;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        &:hover {
                            background-color: #EBEBEB;
                        }

                        .th {
                            height: 70px;
                            line-height: 70px;
                            font-size: 20px;
                            color: #596067;

                            &.th1 {
                                width: 170px;
                                margin-left: 40px;
                                margin-right: 20px;
                                text-align: left;
                            }

                            &.th2 {
                                width: 820px;
                                margin-left: 40px;
                                margin-right: 20px;
                                text-align: left;
                                box-sizing: border-box;
                                padding-left: 50px;
                                cursor: pointer;
                            }
                        }

                        .icons {
                            display: flex;
                            justify-content: flex-start;
                            flex-wrap: nowrap;
                            cursor: pointer;

                            .icon {
                                width: 22px;
                                height: 22px;
                                margin-right: 8px;
                                display: flex;
                                align-items: center;

                                img {
                                    width: 22px;
                                    height: 22px;
                                }
                            }
                        }
                    }

                    .item-empty {
                        height: 84px;
                        line-height: 84px;
                        font-size: 20px;
                        color: #2b333b;
                        border-bottom: 1px solid #d9dde1;
                        text-align: center;
                    }
                }

                .pager {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    margin-top: 40px;
                    padding-bottom: 40px;

                    .btn {
                        width: 80px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        color: #2b333b;
                        font-size: 14px;
                        box-sizing: border-box;
                        border: 1px solid #2b333b;
                        border-radius: 4px;
                        cursor: pointer;

                        &.disabled {
                            color: #b7bbbf;
                            border: 1px solid #b7bbbf;
                        }
                    }

                    .count {
                        font-size: 14px;
                        color: #2b333b;
                        margin-left: 40px;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}
</style>
