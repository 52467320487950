<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-06 18:34:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 13:34:51
-->
<template>
    <div class="magazine-feature">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <magazine-feature ref="feature" @handleComplete="handleComplete"></magazine-feature>
        </div>
        <footer-footer
            class="footer"
            :style="getMarginTopStyle(state.boxHeight)"
        ></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import { storeToRefs } from "pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, getCurrentInstance } from "vue";
import nations from "@/common/nations.js";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import MagazineFeature from "@/components/page/magazine/MagazineFeature.vue";
import FooterFooter from "@/components/footer/Footer.vue";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle } from "@/utils/scale";

const router = useRouter();
const userStore = store.user();
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const { userInfo } = storeToRefs(userStore);
const uploadhead = ref(0);

const state = reactive({
    id: '' as string,
    boxHeight: 0,
});

const box = ref(0);
const feature = ref(0);

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height;
    }, 10);
};

emitter.on('handleComplete', handleComplete)

const route = useRoute()
onMounted(() => {
    state.id = route.query.id ? (route.query.id) as string : '';
    (feature.value as any).init(state.id);
});
</script>

<style lang="scss" scoped>
.magazine-feature {
    width: 100%;

    .box {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 80px;
        background-color: #f8f8f8;
    }
}
</style>
