<template>
    <div class="magazine-browse">
        <div class="header">
            <div class="box">
                <div class="han">画报浏览</div>
                <div class="meng mFont">
                    <span class="meng-vertical">ᠰᠡᠳᠭᠦᠯ᠎ᠤᠨ</span>
                    <span class="meng-vertical">ᠬᠥᠲᠦᠴᠢ</span>
                </div>
            </div>
            
            <div class="dropdown">
                <el-select v-model="state.year" class="year-select" value-key="id" placeholder="Select"
                    :suffix-icon="CaretBottom" :teleported="false" @change="yearChange">
                    <el-option v-for="i in 5" :key="i" :label="yearNow - i + 1 + '年'" :value="yearNow - i + 1">
                    </el-option>
                </el-select>
                <div class="total">
                    共{{ state.magazines.length }}期
                </div>
            </div>
        </div>
        <div class="body">
            <template v-if="state.magazines.length > 0">
                <swiper :modules="modules" :loop="false" :slides-per-view="4" :space-between="24" :navigation="navigation"
                    class="box">
                    <div class="swiper-button-prev">
                        <img src="@/assets/images/common/zuojiantou.png" />
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/images/common/youjiantou.png" />
                    </div>
                    <swiper-slide v-for="(item, index) in state.magazines" :key="index" class="slider-item"
                        @click="goMagazine(item)">
                        <img class="item" v-lazy="item.Cover" />
                        <div class="title">{{ item.Year }}年{{ item.Issue }}</div>
                    </swiper-slide>
                </swiper>

                <div class="banner" :class="[
                    state.magazines.length >= 4 ? 'num4' : `num${state.magazines.length}`,
                ]"></div>
            </template>
            <template v-else>
                <div class="box">
                    <div class="empty">暂无数据</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import CaretBottom from "@/components/common/CaretBottom.vue";
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const yearNow = new Date().getFullYear();

const state = reactive({
    year: yearNow,
    magazines: [] as any[],
});

const contentInfoGetJournalByYear = (year: number, flag: boolean) => {
    api.contentInfoGetJournalByYear(
        { year: year },
        (res: any) => {
            if (res.length == 0) {
                if (flag) {
                    state.year--;
                    contentInfoGetJournalByYear(state.year, flag);
                } else {
                    state.magazines = res;
                }
            } else {
                state.magazines = res;
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const router = useRouter();
const goMagazine = (item: any) => {
    routerUtil.openNewWindow({
        path: `/magazinedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const modules = [Navigation];
const navigation = ref({
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
});

const yearChange = () => {
    contentInfoGetJournalByYear(state.year, false);
}

onMounted(() => {
    contentInfoGetJournalByYear(state.year, true);
});
</script>

<style lang="scss" scoped>
.magazine-browse {
    width: 1500px;
    height: 764px;
    background-color: #ffffff;
    border-radius: 4px;

    .header {
        position: relative;
        width: 100%;
        height: 96px;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9dde1;
        display: flex;
        justify-content: space-between;
        
        .box {
            display: inline-block;
            border-bottom: 1px solid #2b333b;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
        }

        .han {
            display: inline-block;
            height: 96px;
            line-height: 96px;
            margin-right: 16px;
            font-size: 28px;
            color: #2b333b;
        }

        .meng {
            box-sizing: border-box;
            padding-top: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #787878;
            font-size: 20px;

            span {
                line-height: 28px;
            }
        }

        .dropdown {
            position: absolute;
            right: 50px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .year-select {
                width: 160px;
                height: 40px;
                margin-right: 16px;
                border-radius: 4px;
                box-shadow: 0px 2px 6px 0px rgba(43, 51, 59, 0.08);

                :deep(.el-select__wrapper) {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 0px 12px 0px 16px;
                    color: #2b333b;
                }

                :deep(.el-select__selected-item) {
                    font-size: 20px;
                }

                :deep(.el-select-dropdown__item) {
                    font-size: 20px;
                    padding: 0;
                    height: 44px;
                    line-height: 44px;
                }
            }

            .total {
                font-size: 20px;
            }

            :deep(.el-select__wrapper) {
                min-height: 38px;
                height: 38px;
                line-height: 38px;
                font-size: 20px;
            }
        }
    }

    .body {
        box-sizing: border-box;
        width: 100%;
        height: 666px;
        padding: 80px 0px;
        position: relative;

        .box {
            position: absolute;
            z-index: 100;
            left: 50px;
            right: 50px;

            .slider-item {
                position: relative;
                height: 506px;
                cursor: pointer;

                .item {
                    width: 332px;
                    height: 442px;
                    object-fit: contain;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .title {
                    position: absolute;
                    width: 332px;
                    left: 0;
                    right: 0;
                    font-size: 24px;
                    color: #2b333b;
                    z-index: 999;
                    bottom: 20px;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                width: 48px;
                height: 48px;

                &::after {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .swiper-button-prev {
                margin-left: -8px;
            }

            .swiper-button-next {
                margin-right: -8px;
            }

            .empty {
                width: 100%;
                height: 506px;
                line-height: 506px;
                font-size: 20px;
                color: #2b333b;
            }
        }

        .banner {
            position: absolute;
            z-index: 99;
            left: 12px;
            right: 12px;
            bottom: 80px;
            height: 64px;
            line-height: 64px;
            background: linear-gradient(#e3d8c9, #f3ebe0, #e3d8c9);
            border-radius: 8px;
            box-shadow: 0px 16px 24px 6px rgba(0, 0, 0, 0.08);

            &.num4 {
                right: 12px;
            }

            &.num3 {
                right: calc(12px + 356px);
            }

            &.num2 {
                right: calc(12px + 356px * 2);
            }

            &.num1 {
                right: calc(12px + 356px * 3);
            }

            &.num0 {
                display: none;
            }
        }
    }
}
</style>
