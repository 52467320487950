<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-24 16:16:26
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 21:37:52
-->
<template>
    <div class="common-ellipsis-view">
        <el-tooltip v-if="state.showToolTip" :content="state._title" effect="light">
            <span class="content two-line" ref="tip" :style="{ opacity: state.opacity }">{{ state._title }}</span>
        </el-tooltip>
        <span v-else class="content" ref="notip" :style="{ opacity: state.opacity }">{{ state._title }}</span>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref, getCurrentInstance } from "vue";

const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const props = defineProps({
    title: {
        type: String,
        default: ''
    }
})

const state = reactive({
    _title: '',
    opacity: 0,
    showToolTip: false,
})

watch(props, (newVal, oldVal) => {
    state._title = newVal.title
    initToolTip()
})


const tip = ref(0);
const notip = ref(0);

const initToolTip = () => {
    setTimeout(() => {
        const nitem = notip.value as any;
        const titem = tip.value as any;

        if (nitem.clientHeight < nitem.scrollHeight) {
            state.showToolTip = true
            state.opacity = 1;
        } else {
            state.showToolTip = false
            state.opacity = 1;
        }
        
        setTimeout(() => {
            emitter.emit("handleComplete")
        }, 100)
    }, 500);
};

onMounted(() => {
    state._title = props.title
    initToolTip()
})
</script>

<style lang="scss" scoped>
.common-ellipsis-view {
    height: 100%;

    .content {
        display: block;
        width: 100%;
        overflow-y: auto;

        &.two-line {
            display: -webkit-box;
            /* Safari */
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            line-clamp: 2;
            /* 设置最大显示行数 */
            -webkit-line-clamp: 2;
            /* Safari and Chrome */
            -webkit-box-orient: vertical;
            /* Safari */
        }
    }
}
</style>