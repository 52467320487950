/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-06 18:34:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-12 17:59:12
 */
import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '@/pages/Home.vue'
import User from '@/pages/User.vue'
import Search from '@/pages/Search.vue'
import Knowledge from '@/pages/Knowledge.vue'
import Magazine from '@/pages/Magazine.vue'
import MagazineDetail from '@/pages/MagazineDetail.vue'
import MagazineFeature from '@/pages/MagazineFeature.vue'
import Book from '@/pages/Book.vue'
import BookDetail from '@/pages/BookDetail.vue'
import Image from '@/pages/Image.vue'
import ImageMore from '@/pages/ImageMore.vue'
import ImageDetail from '@/pages/ImageDetail.vue'
import Video from '@/pages/Video.vue'
import VideoDetail from '@/pages/VideoDetail.vue'
import Audio from '@/pages/Audio.vue'
import AudioDetail from '@/pages/AudioDetail.vue'
import Introduction from '@/pages/Introduction.vue'
import NoticeList from '@/pages/NoticeList.vue'
import NoticeDetail from '@/pages/NoticeDetail.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/search', component: Search },
    { path: '/knowledge', component: Knowledge },
    { path: '/magazine', component: Magazine },
    { path: '/magazinedetail', component: MagazineDetail },
    { path: '/Magazinefeature', component: MagazineFeature },
    { path: '/book', component: Book },
    { path: '/bookdetail', component: BookDetail },
    { path: '/image', component: Image },
    { path: '/imageMore', component: ImageMore },
    { path: '/imagedetail', component: ImageDetail },
    { path: '/video', component: Video },
    { path: '/videodetail', component: VideoDetail },
    { path: '/audio', component: Audio },
    { path: '/audiodetail', component: AudioDetail },
    { path: '/introduction', component: Introduction },
    { path: '/noticelist', component: NoticeList },
    { path: '/noticedetail', component: NoticeDetail },
    { path: '/user', component: User },
]

const router = createRouter({
    routes,
    history: createWebHashHistory()
})

export default router