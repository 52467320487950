<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-19 22:30:16
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-08 10:46:27
-->
<template>
    <div class="home-tupian">
        <div class="item title">
            <div class="han">
                <div>图</div>
                <div>片</div>
            </div>
            <div class="meng mFont meng-vertical">ᠵᠢᠷᠤᠭ ᠰᠡᠭᠦᠳᠡᠷ</div>
            <div class="more" @click="goImages">
                <span>更多</span>
                <img src="@/assets/images/home/gengduo.png" />
            </div>
        </div>

        <div v-for="(item, index) in state.pictures" :key="index" class="item" @click="goImage(item)">
            <img v-lazy="item.Cover" class="image" />
            <div class="shade">
                <div class="han one-line">
                    {{ item.Name }}
                </div>
                <div class="meng">
                    <span
                        class="meng-vertical mFont"
                        v-for="(iitem, iindex) in getMWords(item.MName)"
                        :key="iindex"
                    >
                        {{ iitem }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const state = reactive({
    pictures: [] as any[],
});

const categoryList = () => {
    api.categoryList(
        { type: 4, showOther: false },
        (res: any) => {
            state.pictures = res[0].children;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const router = useRouter()
const goImage = (item: any) => {
    routerUtil.openNewWindow({
        path: `/image`,
        query: {
            id: item.StrId,
        },
    });
};

const goImages = () => {
    routerUtil.openNewWindow({
        path: `/image`,
    });
};

onMounted(() => {
    categoryList();
});
</script>

<style lang="scss" scoped>
.home-tupian {
    width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
        position: relative;
        width: 486px;
        height: 486px;
        margin-bottom: 20px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;

        &.title {
            background-color: #f0f8f1;
            cursor: default;

            &::before {
                display: block;
                content: "";
                position: absolute;
                width: 241px;
                height: 205px;
                z-index: 1;
                bottom: 0px;
                right: 0px;
                background: url("@/assets/images/home/tupian-bg.png");
                background-size: 241px 205px;
            }

            .han {
                box-sizing: border-box;
                margin-top: 80px;

                div {
                    display: block;
                    font-size: 32px;
                    color: #2b333b;
                    text-align: center;
                    width: 164px;
                    height: 44px;
                    line-height: 44px;
                    box-sizing: border-box;
                    font-weight: 600;
                    margin-top: 2px;

                    &:first-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        color: #ffffff;
                        background-color: #6e8b74;
                    }
                }
            }

            .meng {
                position: absolute;
                left: 114px;
                top: 146px;
                font-size: 26px;
                height: 200px;
                text-align: left;
                color: #2b333b;
            }

            .more {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 28px;
                left: 74px;
                top: 324px;
                border-radius: 14px;
                background-color: #f2e6ce;
                font-size: 14px;
                color: #000000;
                cursor: pointer;
                transition: all 0.2s;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }

                img {
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .shade {
            position: absolute;
            width: 486px;
            height: 486px;
            z-index: 1;
            left: 486px;
            top: 0px;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.2s;

            .han {
                height: 32px;
                line-height: 32px;
                margin-top: 188px;
                padding-left: 40px;
                padding-right: 40px;
                font-size: 32px;
                color: #ffffff;
            }

            .meng {
                font-size: 30px;
                color: #ffffff;
                margin-top: 10px;
                margin-left: 8px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
            }
        }

        &:hover {
            .shade {
                left: 0px;
            }
        }

        .image {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
