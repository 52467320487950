<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-07 20:24:25
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-18 10:33:22
-->
<template>
    <div class="c-magazine-detail">
        <div class="bread">
            当前位置：<span class="pointer" @click="goMagazine">融合画报</span>&ensp;>&ensp;<span>融合画报详情</span>
        </div>
        <div class="content">
            <div class="main" v-if="state.item">
                <div class="p1">
                    <div class="l">
                        <img @click="goReadPage" class="video" v-lazy="state.item.Cover" />
                    </div>
                    <div class="r">
                        <el-tooltip :content="state.item.Title" effect="light">
                            <div ref="tip" class="title" :style="{ opacity: state.opacity }">
                                {{ state.item.Title }}
                            </div>
                        </el-tooltip>
                        <div ref="notip" class="title" :style="{ opacity: state.opacity, overflow: state.opacity == 0 ? 'scroll' : '' }">
                            <span>{{ state.item.Title }}</span>
                        </div>
                        <div class="meng mFont">
                            <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                :key="index">{{ item }}</span>
                        </div>
                        <div class="divider"></div>
                        <div class="items" v-if="state.item">
                            <div class="total" v-if="state.total">{{ state.total }}</div>
                            <div class="item one-line">
                                <div class="name">
                                    出版发行单位：{{
                                        state.item.Publisher
                                        ? state.item.Publisher
                                        : "暂无"
                                    }}
                                </div>
                            </div>
                            <div class="item one-line">
                                ISSN：{{ state.item.Issn ? state.item.Issn : "暂无" }}
                            </div>
                            <div class="item one-line" v-if="getPresident().length > 0">
                                <div class="name">社长：</div>
                                <div class="values">
                                    <div class="value one-line" v-for="(item, index) in getPresident()" :key="index">
                                        {{ item.PropertyValue }}
                                    </div>
                                </div>
                            </div>
                            <div class="item one-line" v-if="getEditor().length > 0">
                                <div class="name">本期负责编辑：</div>
                                <div class="values">
                                    <div class="value one-line" v-for="(item, index) in getEditor()" :key="index">
                                        {{ item.PropertyValue }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buttons">
                            <div class="button b1" @click="favoriteClick">
                                <el-tooltip :content="!state.item.IsFavorite ? '收藏' : '取消收藏'" effect="light">
                                    <img v-if="!state.item.IsFavorite" src="@/assets/images/common/shoucang0.png" />
                                    <img v-else src="@/assets/images/common/shoucang.png" />
                                </el-tooltip>
                                <span>{{ state.item.Collect }}</span>
                            </div>
                            
                            <div class="button b2" @click="likeClick">
                                <el-tooltip :content="!state.item.IsLike ? '点赞' : '取消点赞'" effect="light">
                                    <img v-if="!state.item.IsLike" src="@/assets/images/common/dianzan0.png" />
                                    <img v-else src="@/assets/images/common/dianzan.png" />
                                </el-tooltip>
                                <span>{{ state.item.Like }}</span>
                            </div>
                        </div>
                        <div class="mbtn" @click="goReadPage">立即阅读</div>
                    </div>
                </div>
                <div class="p2">
                    <div class="images">
                        <template v-for="(item, index) in state.related" :key="index">
                            <a v-if="index <= 2" @click="goReadPageById(item.StrId)">
                                <img class="image" v-lazy="item.Cover" />
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="content2">
            <div class="menus">
                <div class="menu" :class="{ select: state.menuSelect == 0 }" @click="menuClick(0)">
                    <div class="han">目录</div>
                    <div class="meng mFont">
                        <span>ᠭᠠᠷᠴᠠᠭ</span>
                    </div>
                </div>
                <div class="menu" v-if="!state.ismengEmpty" :class="{ select: state.menuSelect == 1 }" @click="menuClick(1)">
                    <div class="han">蒙文目录</div>
                    <div class="meng mFont">
                        <span>ᠮᠣᠩᠭᠤᠯ</span>
                        <span>ᠭᠠᠷᠴᠠᠭ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.labels1Total > 0" :class="{ select: state.menuSelect == 2 }"
                    @click="menuClick(2)">
                    <div class="han">
                        知识标签<span>({{ state.labels1Total }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠮᠡᠳᠡᠯᠭᠡ᠎ᠶᠢᠨ</span>
                        <span>ᠱᠣᠱᠢᠭ᠎ᠠ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.labels2Total > 0" :class="{ select: state.menuSelect == 3 }"
                    @click="menuClick(3)">
                    <div class="han">
                        资源标签<span>({{ state.labels2Total }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠡᠬᠢ</span>
                        <span>ᠪᠠᠶᠠᠯᠢᠭ᠎ᠤᠨ</span>
                        <span>ᠱᠣᠱᠢᠭ᠎ᠠ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.labels3Total > 0" :class="{ select: state.menuSelect == 4 }"
                    @click="menuClick(4)">
                    <div class="han">
                        网站标签<span>({{ state.labels3Total }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠨᠧᠲ</span>
                        <span>ᠥᠷᠲᠡᠭᠡᠨ</span>
                        <span>ᠱᠣᠱᠢᠭ᠎ᠠ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.markinfoTotal > 0" :class="{ select: state.menuSelect == 5 }"
                    @click="menuClick(5)">
                    <div class="han">
                        我的阅读<span>({{ state.markinfoTotal }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠮᠢᠨᠦ</span>
                        <span>ᠤᠩᠱᠢᠯᠭ᠎ᠠ</span>
                    </div>
                </div>
            </div>
            <div class="table" v-if="state.menuSelect == 0">
                <div class="theader">
                    <div class="th th1">栏目</div>
                    <div class="th th2">标题</div>
                    <div class="th th3">扩展资源</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.chapters" :key="i" @click="goReadPageById(item.StrId)">
                        <div v-if="item.ColumnSize" class="lanmu one-line" :class="[ 'lanmu' + item.ColumnSize ]">{{ item.ColumnName }}</div>
                        <div v-if="!item.ColumnSize || item.ColumnSize > 1" class="lanmu lanmu1 one-line">{{ item.ColumnName }}</div>
                        <div class="title one-line">{{ item.Title }}</div>
                        <div class="icons">
                            <template v-for="(iitem, iindex) in item?.RelationData" :key="iindex">
                                <div v-if="iitem.ObjectType == 5" class="icon" @click.stop.prevent="goReadPageById(iitem.StrId)">
                                    <!-- 视频 -->
                                    <el-tooltip  :content="`视频：${ iitem.Title }`" effect="light">
                                        <img src="@/assets/images/search/shipin.png" />
                                    </el-tooltip>
                                </div>
                                <div v-else-if="iitem.ObjectType == 3 && iitem.Language == '蒙文'" class="icon"
                                    @click.stop.prevent="goReadPageById(iitem.StrId)">
                                    <!-- 蒙文 -->
                                    <el-tooltip  :content="`${ iitem.Language ? iitem.Language : '蒙文' }${ iitem.ObjectTypeName ? iitem.ObjectTypeName : '音频' }：${ iitem.Title }`" effect="light">
                                        <img src="@/assets/images/search/meng.png" />
                                    </el-tooltip>
                                </div>
                                <div v-else-if="iitem.ObjectType == 3 && iitem.Language != '蒙文'" class="icon"
                                    @click.stop.prevent="goReadPageById(iitem.StrId)">
                                    <!-- 其他 -->
                                    <el-tooltip :content="`${ iitem.Language ? iitem.Language : '汉文' }${ iitem.ObjectTypeName ? iitem.ObjectTypeName : '音频' }：${ iitem.Title }`" effect="light">
                                        <img src="@/assets/images/search/han.png" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tablemeng" v-else-if="state.menuSelect == 1">
                <div class="theadmeng">
                    <div class="tcontent" v-for="(item, i) in state.chapters" :key="i"
                            @click="goReadPageById(item.StrId)">

                        <div class="item mFont meng mengh0" v-if="item.ColumnSize >= 1">
                            {{ item.Column ? item.Column.MName : '' }}
                        </div>

                        <div class="item mFont meng mengh1" v-if="item.Column" :class="[ `meng${ item.ColumnIndex }` ]">
                            <template v-if="item.ColumnSize && item.ColumnSize > 1">
                                <span style="cursor: pointer;pointer-events: none;">{{ item.Column ? item.Column.MName : '' }}</span>
                            </template>
                            <template v-else-if="item.ColumnSize && item.ColumnSize == 1">
                                <span style="cursor: pointer;pointer-events: all;">{{ item.Column ? item.Column.MName : '' }}</span>
                            </template>
                        </div>

                        <div class="item mFont meng" v-if="item.MTitle">
                            <span>{{ item.MTitle }}</span>

                            <div class="icons" v-if="item?.RelationData?.length > 0">
                                <template v-for="(iitem, iindex) in item?.RelationData" :key="iindex">
                                    <div v-if="iitem.ObjectType == 5" class="icon" @click.stop.prevent="goReadPageById(iitem.StrId)">
                                        <!-- 视频 -->
                                        <el-tooltip  :content="`视频：${ iitem.MTitle ? iitem.MTitle : iitem.Title }`" effect="light">
                                            <img src="@/assets/images/search/shipin.png" />
                                        </el-tooltip>
                                    </div>
                                    <div v-else-if="iitem.ObjectType == 3 && iitem.Language == '蒙文'" class="icon"
                                        @click.stop.prevent="goReadPageById(iitem.StrId)">
                                        <!-- 蒙文 -->
                                        <el-tooltip  :content="`${ iitem.Language ? iitem.Language : '蒙文' }${ iitem.ObjectTypeName ? iitem.ObjectTypeName : '音频' }：${ iitem.MTitle ? iitem.MTitle : iitem.Title }`" effect="light">
                                            <img src="@/assets/images/search/meng.png" />
                                        </el-tooltip>
                                    </div>
                                    <div v-else-if="iitem.ObjectType == 3 && iitem.Language != '蒙文'" class="icon"
                                        @click.stop.prevent="goReadPageById(iitem.StrId)">
                                        <!-- 其他 -->
                                        <el-tooltip :content="`${ iitem.Language ? iitem.Language : '汉文' }${ iitem.ObjectTypeName ? iitem.ObjectTypeName : '音频' }：${ iitem.MTitle ? iitem.MTitle : iitem.Title }`" effect="light">
                                            <img src="@/assets/images/search/han.png" />
                                        </el-tooltip>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table2" v-if="state.menuSelect == 2">
                <div class="theader">
                    <div class="th th1">标签名称</div>
                    <div class="th th2">类别</div>
                    <div class="th th3">标签介绍</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.labels1" :key="i">
                        <div class="name one-line">{{ item.Name }}</div>
                        <div class="type one-line">{{ item.TypeName }}</div>
                        <div class="des one-line">{{ item.Content }}</div>
                        <div class="icons">
                            <div class="icon btn1" @click="goLabel(item)">详情</div>
                            <div class="icon btn2" @click="tagGetPosition(item)">定位</div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.labels1.length == 0">
                        暂无数据
                    </div>

                    <div class="pager" v-if="getLabels1PageCount() > 1">
                        <div class="btn" :class="{ disabled: state.labels1cp == 1 }" @click="handleLabels1Last">
                            上一页
                        </div>
                        <div class="count">{{ state.labels1cp }} / {{ getLabels1PageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.labels1cp >= getLabels1PageCount() }" @click="handleLabels1Next">
                            下一页
                        </div>
                    </div>
                </div>
            </div>

            <div class="table2" v-if="state.menuSelect == 3">
                <div class="theader">
                    <div class="th th1">标签名称</div>
                    <div class="th th2">类别</div>
                    <div class="th th3">标签介绍</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.labels2" :key="i">
                        <div class="name one-line">{{ item.Name }}</div>
                        <div class="type one-line">{{ item.TypeName }}</div>
                        <div class="des one-line">{{ item.Content }}</div>
                        <div class="icons">
                            <div class="icon btn1" @click="goLabel(item)">详情</div>
                            <div class="icon btn2" @click="tagGetPosition(item)">定位</div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.labels2.length == 0">
                        暂无数据
                    </div>

                    <div class="pager" v-if="getLabels2PageCount() > 1">
                        <div class="btn" :class="{ disabled: state.labels2cp == 1 }" @click="handleLabels2Last">
                            上一页
                        </div>
                        <div class="count">{{ state.labels2cp }} / {{ getLabels2PageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.labels2cp >= getLabels2PageCount() }" @click="handleLabels2Next">
                            下一页
                        </div>
                    </div>
                </div>
            </div>

            <div class="table2" v-if="state.menuSelect == 4">
                <div class="theader">
                    <div class="th th1">标签名称</div>
                    <div class="th th2">类别</div>
                    <div class="th th3">标签介绍</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.labels3" :key="i">
                        <div class="name one-line">{{ item.Name }}</div>
                        <div class="type one-line">{{ item.TypeName }}</div>
                        <div class="des one-line">{{ item.Content }}</div>
                        <div class="icons">
                            <div class="icon btn1" @click="goLabel(item)">详情</div>
                            <div class="icon btn2" @click="tagGetPosition(item)">定位</div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.labels3.length == 0">
                        暂无数据
                    </div>

                    <div class="pager" v-if="getLabels3PageCount() > 1">
                        <div class="btn" :class="{ disabled: state.labels3cp == 1 }" @click="handleLabels3Last">
                            上一页
                        </div>
                        <div class="count">{{ state.labels3cp }} / {{ getLabels3PageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.labels3cp >= getLabels3PageCount() }" @click="handleLabels3Next">
                            下一页
                        </div>
                    </div>
                </div>
            </div>

            <div class="table3" v-if="state.menuSelect == 5">
                <div class="tabs">
                    <div class="tab" :class="{ select: state.tabSelect == 0 }" @click="tabClick(0)">
                        我的笔记
                    </div>
                    <div class="tab" :class="{ select: state.tabSelect == 1 }" @click="tabClick(1)">
                        我的划线
                    </div>
                    <div class="tab" :class="{ select: state.tabSelect == 2 }" @click="tabClick(2)">
                        我的书签
                    </div>
                </div>
                <div class="theader">
                    <div class="th th1" v-if="state.tabSelect == 0">笔记位置</div>
                    <div class="th th1" v-else-if="state.tabSelect == 1">划线位置</div>
                    <div class="th th1" v-else-if="state.tabSelect == 2">书签位置</div>
                    <div class="th th2" v-if="state.tabSelect == 0">笔记上下文</div>
                    <div class="th th2" v-else-if="state.tabSelect == 1">划线上下文</div>
                    <div class="th th2" v-else-if="state.tabSelect == 2">书签上下文</div>
                    <div class="th th3">时间</div>
                </div>
                <div class="tbody">
                    <template v-if="state.tabSelect == 0">
                        <div class="item" v-for="(item, i) in state.noteList" :key="i">
                            <div class="th1 one-line">{{ item.ProgressShow }}</div>
                            <div class="th2 one-line">{{ item.resourceTitle }}</div>
                            <div class="th3 one-line">
                                {{ moment(item.createDate).format("YYYY-MM-DD HH:mm") }}
                            </div>
                            <div class="icons">
                                <div class="icon" @click="goReadPageByRate(item.PageNumber ? item.PageNumber : item.progress)">定位</div>
                                <div class="icon" @click="markInfodeleteIndexMark(item)">删除</div>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.noteList.length == 0">
                            暂无数据
                        </div>
                    </template>
                    <template v-else-if="state.tabSelect == 1">
                        <div class="item" v-for="(item, i) in state.drawlineList" :key="i">
                            <div class="th1 one-line">{{ item.ProgressShow }}</div>
                            <div class="th2 one-line">{{ item.resourceTitle }}</div>
                            <div class="th3 one-line">
                                {{ moment(item.createDate).format("YYYY-MM-DD HH:mm") }}
                            </div>
                            <div class="icons">
                                <div class="icon" @click="goReadPageByRate(item.PageNumber ? item.PageNumber : item.progress)">定位</div>
                                <div class="icon" @click="markInfodeleteIndexMark(item)">删除</div>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.drawlineList.length == 0">
                            暂无数据
                        </div>
                    </template>
                    <template v-else-if="state.tabSelect == 2">
                        <div class="item" v-for="(item, i) in state.bookmarkList" :key="i">
                            <div class="th1 one-line">{{ item.ProgressShow }}</div>
                            <div class="th2 one-line">{{ item.resourceTitle }}</div>
                            <div class="th3 one-line">
                                {{ moment(item.createDate).format("YYYY-MM-DD HH:mm") }}
                            </div>
                            <div class="icons">
                                <div class="icon" @click="goReadPageByRate(item.PageNumber ? item.PageNumber : item.progress)">定位</div>
                                <div class="icon" @click="markInfodeleteIndexMark(item)">删除</div>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.bookmarkList.length == 0">
                            暂无数据
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import { getScale, px2rem } from '@/utils/scale'
import moment from "moment";
import api from "@/common/api";
import { ElMessage } from "element-plus";
import { getToken } from "@/request";

import routerUtil from "@/common/router";

import { storeToRefs } from "pinia";
import store from "@/pinia";
const userStore = store.user();
const user = storeToRefs(userStore) as any;

const route = useRoute() as any;
const router = useRouter() as any;
const emits = defineEmits(["handleComplete"]);

const state = reactive({
    id: "",
    item: null as any,
    related: [] as any[],
    show: false,
    total: '',

    labels1Total: 0,
    labels1: [] as any[],
    labels1cp: 1,
    labels1ps: 10,

    labels2Total: 0,
    labels2: [] as any[],
    labels2cp: 1,
    labels2ps: 10,

    labels3Total: 0,
    labels3: [] as any[],
    labels3cp: 1,
    labels3ps: 10,

    menuSelect: 0,
    tabSelect: 0,

    markinfoTotal: 0,

    noteList: [] as any[],
    drawlineList: [] as any[],
    bookmarkList: [] as any[],

    chapters: [] as any,

    favoriteLoading: false,
    likeLoading: false,
        
    complete: false,
    ellipsis: false,

    opacity: 0,

    ismengEmpty: true,
    columnSizeMax: 0,
});

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const getPresident = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let president = state.item.ExternalData.filter((item: any, index: number) => {
                return item.PropertyName == "president";
            });

            if (president.length > 0) {
                return president;
            }
        }
    }

    return [];
};

const getEditor = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let editor = state.item.ExternalData.filter((item: any, index: number) => {
                return item.PropertyName == "editor";
            });

            if (editor.length > 0) {
                return editor;
            }
        }
    }

    return [];
};

const getTotal = () => {
    if(state.item.ExternalData && state.item.ExternalData.length > 0) {
        for (let i = 0; i < state.item.ExternalData.length; i++) {
            const item = state.item.ExternalData[i];
            if(item.PropertyName == 'totalissue') {
                state.total = item.PropertyValue
            }
        }
    }
}

const contentInfoInfo = () => {
    state.item = {};

    api.contentInfoInfo(
        { id: state.id },
        (res: any) => {
            state.item = res;
            getTotal()
            initToolTip()
            contentInfoRelatedContentList();
            labelContentLabelCount();
            contentInfoGetChapters();

            state.likeLoading = false
            state.favoriteLoading = false
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoRelatedContentList = () => {
    api.contentInfoRelatedContentList(
        { id: state.id, top: 9999, objectType: 4 },
        (res: any) => {
            state.related = res;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoGetChapters = () => {
    api.contentInfoGetChapters(
        { id: state.id },
        (res: any) => {
            state.chapters = res;

            for (let i = 0; i < state.chapters.length; i++) {
                const item = state.chapters[i];
                if(item.MName || item.MTitle) {
                    state.ismengEmpty = false
                    break
                }
            }

            let index = 0
            let columnName = ''
            for (let i = 0; i < state.chapters.length; i++) {
                const item = state.chapters[i];
                if(columnName == '') {
                    columnName = item.ColumnName
                }

                if(i > 0) {
                    if(item.ColumnName != state.chapters[i - 1].ColumnName) {
                        state.chapters[i - 1].ColumnSize = i - index
                        index = i
                    }

                    if(i == state.chapters.length - 1) {
                        if(!state.chapters[i].ColumnSize) {
                            state.chapters[i].ColumnSize = 1
                        }
                    }
                }
            }

            index = 0
            for (let i = 0; i < state.chapters.length; i++) {
                const item = state.chapters[i]

                if(item.ColumnSize) {
                    item.ColumnIndex = index
                    index++
                } else {
                    item.ColumnIndex = index
                }
            }
            state.columnSizeMax = index
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const favoriteSave = () => {
    api.favoriteSave(
        { objectId: state.id },
        (res: any) => {
            state.item.IsFavorite = true;
            state.item.Collect++;
            state.favoriteLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const favoriteDelete = () => {
    api.favoriteDelete(
        { objectId: state.id },
        (res: any) => {
            state.item.IsFavorite = false;
            state.item.Collect--;
            state.favoriteLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const favoriteClick = () => {
    if (state.favoriteLoading) {
        return;
    } else {
        state.favoriteLoading = true;
    }

    if (state.item.IsFavorite) {
        favoriteDelete();
    } else {
        favoriteSave();
    }
};

const likeInfoSave = () => {
    api.likeInfoSave(
        { contentId: state.id },
        (res: any) => {
            state.item.IsLike = true;
            state.item.Like++;
            state.likeLoading = false;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const likeInfoDelete = () => {
    api.likeInfoDelete(
        { contentId: state.id },
        (res: any) => {
            state.item.IsLike = false;
            state.item.Like--;
            state.likeLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const likeClick = () => {
    if (state.likeLoading) {
        return;
    } else {
        state.likeLoading = true;
    }

    if (state.item.IsLike) {
        likeInfoDelete();
    } else {
        likeInfoSave();
    }
};

const goMagazine = () => {
    router.push({
        path: `/magazine`,
    });
};

const goReadPage = () => {
    routerUtil.goReadPage(state.id, getToken());
};

const goReadPageById = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

const goReadPageByNumber = (id: string, page: string) => {
    routerUtil.goReadPageByNumber(id, page, getToken());
}

const labelContentLabelCount = () => {
    getLabels1Data()

    getLabels2Data()

    getLabels3Data()

    api.markInfoGetMarkCount(
        { resourceId: state.id },
        (res: any) => {
            state.markinfoTotal = res;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const tabClick = (index: number) => {
    state.tabSelect = index;
    emits("handleComplete");

    if (index == 0) {
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "note" },
            (res: any) => {
                state.noteList = res;
                emits("handleComplete");
            },
            (err: any) => {
                console.log(err);
            }
        );
    } else if (index == 1) {
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "drawline" },
            (res: any) => {
                state.drawlineList = res;
                emits("handleComplete");
            },
            (err: any) => {
                console.log(err);
            }
        );
    } else if (index == 2) {
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "bookmark" },
            (res: any) => {
                state.bookmarkList = res;
                emits("handleComplete");
            },
            (err: any) => {
                console.log(err);
            }
        );
    }
};

const initMengColumnWidth = () => {
    setTimeout(() => {
        for (let i = 0; i < state.columnSizeMax; i++) {
            const items = $(`.meng${ i }`)
            
            let clientWidth = 0
            let clientHeight = 0
            for (let j = 0; j < items.length; j++) {
                const item = items[j]
                clientWidth += item.parentElement.clientWidth
                clientHeight = item.parentElement.clientHeight

                if(j == items.length - 1) {
                    $(item).css('position', 'absolute')
                    $(item).css('width', clientWidth + (items.length - 3) * 1 + 'px')
                    $(item).css('right', 1 + 'px')

                    if(j > 0) {
                        $(item).css('background-color', '#FFFFFF')
                        $(item).css('pointer-events', 'none')
                        $(item).css('padding-bottom', getScale() * px2rem(40) + 'rem')
                        $(item).css('box-sizing', 'content-box')
                    } else {
                        $(item).css('pointer-events', 'all')
                    }
                }
            }
        }
        
        emits("handleComplete");
    }, 500)
}

const menuClick = (index: number) => {
    state.menuSelect = index;
    emits("handleComplete");

    if (index == 0) {
        console.log("目录");
    } else if (index == 1) {
        console.log("蒙文目录");

        initMengColumnWidth()
    } else if (index == 2) {
        console.log("知识标签");

        state.labels1cp = 1
        getLabels1Data()
    } else if (index == 3) {
        console.log("资源标签");

        state.labels2cp = 1
        getLabels2Data()
    } else if (index == 4) {
        console.log("网站标签");

        state.labels3cp = 1
        getLabels3Data()
    } else if (index == 5) {
        console.log("我的阅读");
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "note" },
            (res: any) => {
                state.noteList = res;
            },
            (err: any) => {
                console.log(err);
            }
        );
    }
};

const goLabel = (item: any) => {
    routerUtil.openNewWindow({
        path: "/knowledge",
        query: {
            id: item.StrId
        }
    });
}

const tagGetPosition = (item: any) => {
    api.tagGetPosition(
        { labelId: item.StrId, resourceId: state.item.StrId },
        (res: any) => {
            if (res.PageNo) {
                routerUtil.goReadPageByNumber(state.item.StrId, res.PageNo, getToken())
            } else {
                routerUtil.goReadPageByRate(state.item.StrId, res.PositionRate, getToken())
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const goReadPageByRate = (rate: string) => {
    routerUtil.goReadPageByRate(state.item.StrId, rate, getToken())
}

const markInfodeleteIndexMark = (item: any) => {
    api.markInfodeleteIndexMark(
        { markId: item.markId, resourceId: state.item.StrId },
        (res: any) => {
            tabClick(state.tabSelect)
            ElMessage.success('删除成功')
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const tip = ref(0);
const notip = ref(0);

const initToolTip = () => {
    setTimeout(() => {
        const titem = tip.value as any;
        const nitem = notip.value as any;

        if (nitem.clientHeight < nitem.scrollHeight) {
            nitem.remove();
            state.opacity = 1;
        } else {
            titem.remove();
            state.opacity = 1;
        }

        emits("handleComplete");
    }, 500);
};

const getLabels1Data = () => {
    api.labelContentLabelPage(
        { contentId: state.id, type: 1, cp: state.labels1cp, ps: state.labels1ps },
        (res: any) => {
            state.labels1 = res.ItemList;
            state.labels1Total = res.RecordCount;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const getLabels1PageCount = () => {
    if (state.labels1Total % state.labels1ps == 0) {
        return state.labels1Total / state.labels1ps;
    } else {
        return Math.floor(state.labels1Total / state.labels1ps) + 1;
    }
};

const handleLabels1Last = () => {
    if (state.labels1cp <= 1) {
        state.labels1cp = 1;
        return;
    }

    state.labels1cp -= 1;
    getLabels1Data();
};

const handleLabels1Next = () => {
    if (state.labels1cp >= getLabels1PageCount()) {
        state.labels1cp = getLabels1PageCount();
        return;
    }

    state.labels1cp += 1;
    getLabels1Data();
};

const getLabels2Data = () => {
    api.labelContentLabelPage(
        { contentId: state.id, type: 2, cp: state.labels2cp, ps: state.labels2ps },
        (res: any) => {
            state.labels2 = res.ItemList;
            state.labels2Total = res.RecordCount;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const getLabels2PageCount = () => {
    if (state.labels2Total % state.labels2ps == 0) {
        return state.labels2Total / state.labels2ps;
    } else {
        return Math.floor(state.labels2Total / state.labels2ps) + 1;
    }
};

const handleLabels2Last = () => {
    if (state.labels2cp <= 1) {
        state.labels2cp = 1;
        return;
    }

    state.labels2cp -= 1;
    getLabels2Data();
};

const handleLabels2Next = () => {
    if (state.labels2cp >= getLabels2PageCount()) {
        state.labels2cp = getLabels2PageCount();
        return;
    }

    state.labels2cp += 1;
    getLabels2Data();
};

const getLabels3Data = () => {
    api.labelContentLabelPage(
        { contentId: state.id, type: 3, cp: state.labels3cp, ps: state.labels3ps },
        (res: any) => {
            state.labels3 = res.ItemList;
            state.labels3Total = res.RecordCount;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const getLabels3PageCount = () => {
    if (state.labels3Total % state.labels3ps == 0) {
        return state.labels3Total / state.labels3ps;
    } else {
        return Math.floor(state.labels3Total / state.labels3ps) + 1;
    }
};

const handleLabels3Last = () => {
    if (state.labels3cp <= 1) {
        state.labels3cp = 1;
        return;
    }

    state.labels3cp -= 1;
    getLabels3Data();
};

const handleLabels3Next = () => {
    if (state.labels3cp >= getLabels3PageCount()) {
        state.labels3cp = getLabels3PageCount();
        return;
    }

    state.labels3cp += 1;
    getLabels3Data();
};

onMounted(() => {
    state.id = route.query.id;
    contentInfoInfo();
});
</script>

<style lang="scss" scoped>
.c-magazine-detail {
    width: 100%;

    .bread {
        width: 1500px;
        margin: 0 auto;
        padding-top: 40px;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 24px;
        color: #2b333b;
        text-align: left;

        .pointer {
            cursor: pointer;
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .main {
            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 40px;
                padding-left: 100px;

                .l {
                    width: 600px;
                    height: 600px;
                    margin-right: 100px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 400px;
                        height: 534px;
                        object-fit: cover;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        line-height: 48px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        width: 600px;
                        max-height: 64rpx;
                        display: -webkit-box;
                        /* Safari */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        line-clamp: 2;
                        /* 设置最大显示行数 */
                        -webkit-line-clamp: 2;
                        /* Safari and Chrome */
                        -webkit-box-orient: vertical;
                        /* Safari */
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .total {
                            margin-top: 32px;
                            font-size: 24px;
                            font-weight: 600;
                            color: #2b333b;
                            margin-bottom: 24px;
                        }

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .buttons {
                        width: 300px;
                        margin-top: 32px;
                        box-sizing: border-box;
                        padding-right: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .button {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            img {
                                margin-right: 18px;
                            }

                            &.b1 {
                                img {
                                    width: 28px;
                                    height: 29px;
                                }
                            }

                            &.b2 {
                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }

                    .mbtn {
                        width: 300px;
                        height: 56px;
                        margin-top: 28px;
                        line-height: 52px;
                        text-align: center;
                        border-radius: 4px;
                        background-color: #6e8b74;
                        color: #ffffff;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 28px;
                    }
                }

                .r {
                    width: 640px;
                }
            }

            .p2 {
                .images {
                    box-sizing: border-box;
                    width: 1420px;
                    margin-left: 40px;
                    margin-right: 40px;
                    margin-top: 12px;
                    border-top: 8px solid transparent;
                    padding-bottom: 20px;
                    opacity: 1;
                    animation: show 2s;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    a {
                        display: inline-block;
                        width: 460px;
                        margin-right: 20px;
                        border-radius: 4px;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            width: 100%;
                            height: 280px;
                            object-fit: cover;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

    .content2 {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .menus {
            height: 138px;
            border-bottom: 2px solid #f2e6ce;
            display: flex;
            justify-content: flex-start;

            .menu {
                width: 250px;
                height: 100%;
                position: relative;
                cursor: pointer;

                .han {
                    padding-top: 24px;
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 8px;
                    letter-spacing: 4px;
                    color: #000000;
                    transition: all 0.2s;

                    span {
                        color: #387db4;
                    }
                }

                .meng {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    height: auto;
                    width: 100%;

                    span {
                        font-size: 22px;
                        line-height: 28px;
                        color: #787878;

                        width: auto;
                        display: inline-block;
                        text-align: center;
                        writing-mode: vertical-lr;
                        /*从上往下 从左往右*/
                        -webkit-writing-mode: vertical-lr;
                        -o-writing-mode: vertical-lr;
                        -ms-writing-mode: vertical-lr;
                        text-orientation: sideways-right;
                        -webkit-text-orientation: sideways-right;
                        /*兼容ios不正常显示*/

                        &:first-child {
                            position: relative;
                        }
                    }
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 33px;
                    right: 0px;
                    width: 2px;
                    height: 80px;
                    border-radius: 1px;
                    background-color: #f5f5f5;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &.select {
                    background-color: #f2e6ce;

                    &::after {
                        display: none;
                    }

                    .han {
                        font-weight: 600;
                    }
                }
            }
        }

        .table {
            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        text-align: left;
                        width: 274px;
                        padding-left: 60px;
                        margin-right: 20px;
                    }

                    &.th2 {
                        width: 922px;
                        text-align: left;
                        margin-right: 36px;
                    }

                    &.th3 {
                        width: 80px;
                        text-align: left;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;
                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    position: relative;
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;
                    cursor: pointer;

                    &:hover {
                        background-color: #EBEBEB;
                    }

                    .lanmu {
                        box-sizing: border-box;
                        text-align: left;
                        width: 274px;
                        padding-left: 60px;
                        margin-right: 20px;
                        font-weight: 600;

                        &.lanmu1 {
                            pointer-events: none;

                            &:hover {
                                cursor: default;
                            }
                        }

                        &.lanmu2 {
                            position: absolute;
                            height: calc(84px * 2);
                            line-height: calc(84px * 2);
                            bottom: 0;
                            background-color: #FFFFFF;
                            pointer-events: none;

                            &:hover {
                                cursor: default;
                            }
                        }

                        &.lanmu3 {
                            position: absolute;
                            height: calc(84px * 3);
                            line-height: calc(84px * 3);
                            bottom: 0;
                            background-color: #FFFFFF;
                            pointer-events: none;

                            &:hover {
                                cursor: default;
                            }
                        }

                        &.lanmu4 {
                            position: absolute;
                            height: calc(84px * 4);
                            line-height: calc(84px * 4);
                            bottom: 0;
                            background-color: #FFFFFF;
                            pointer-events: none;

                            &:hover {
                                cursor: default;
                            }
                        }

                        &.lanmu5 {
                            position: absolute;
                            height: calc(84px * 5);
                            line-height: calc(84px * 5);
                            bottom: 0;
                            background-color: #FFFFFF;
                            pointer-events: none;

                            &:hover {
                                cursor: default;
                            }
                        }
                    }

                    .title {
                        box-sizing: border-box;
                        width: 922px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .icons {
                        padding-left: 16px;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 22px;
                            height: 22px;
                            margin-right: 8px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }
        }

        .tablemeng {
            width: 100%;
            overflow-x: auto;

            .theadmeng {
                display: inline-block;
                margin-top: 32px;
                margin-left: 32px;
                margin-right: 32px;
                display: flex;
                justify-content: flex-start;
                align-items: stretch;

                .tcontent {
                    margin-top: -40px;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    padding-left: 12px;
                    padding-right: 12px;
                    position: relative;
                    border-right: 1px solid #d9dde1;

                    &:first-child {
                        border-left: 1px solid #d9dde1;
                    }

                    .item:first-child {
                        height: 248px;
                    }

                    .item:last-child {
                        max-height: 720px;
                        color: #596067;
                    }

                    .item.mengh0 {
                        box-sizing: content-box;
                        height: 248px;
                        opacity: 0;
                        margin-top: 20px;
                        font-size: 22px;
                        line-height: 40px;
                        cursor: default;
                    }

                    .item.mengh1 {
                        height: 248px;
                        top: 0px;
                        padding-top: 20px;
                        font-size: 22px;
                        line-height: 40px;
                        pointer-events: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;
                    }

                    &:hover {
                        background: #EBEBEB;
                    }
                }

                .item {
                    width: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    writing-mode: vertical-lr;
                    /*从上往下 从左往右*/
                    -webkit-writing-mode: vertical-lr;
                    -o-writing-mode: vertical-lr;
                    -ms-writing-mode: vertical-lr;
                    text-orientation: sideways-right;
                    -webkit-text-orientation: sideways-right;
                    /*兼容ios不正常显示*/
                    font-size: 22px;
                    line-height: 40px;
                    color: #2b333b;
                    cursor: pointer;
                    font-weight: 400;
                    text-align: justify;
                    word-wrap: break-word;
                    white-space: pre-line;

                    &:first-child {
                        font-weight: 600;
                        padding-bottom: 48px;
                    }

                    &:last-child {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        font-size: 20px;
                        letter-spacing: 0px;
                    }

                    .icons {
                        padding-left: 16px;
                        display: flex;
                        flex-direction: row;

                        .icon {
                            width: 22px;
                            height: 22px;
                            margin-right: 8px;
                            margin-bottom: 8px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

        
        }

        .table2 {
            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        box-sizing: border-box;
                        text-align: left;
                        width: 480px;
                        padding-left: 72px;
                        margin-right: 20px;
                    }

                    &.th2 {
                        width: 180px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    &.th3 {
                        width: 580px;
                        text-align: left;
                        margin-right: 20px;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;
                padding-bottom: 58px;

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;
                    transition: all 0.2s;

                    .name {
                        box-sizing: border-box;
                        text-align: left;
                        width: 480px;
                        padding-left: 72px;
                        margin-right: 20px;
                        position: relative;

                        &::before {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            left: 24px;
                            top: 30px;
                            background-image: url("@/assets/images/common/weizhishi.png");
                            background-size: 24px 24px;
                            z-index: 1;
                        }
                    }

                    .type {
                        width: 180px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .des {
                        width: 580px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .icons {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            border-radius: 4px;
                            border: 1px solid #e5e5e5;
                            color: #5365ff;
                            font-size: 16px;

                            &.btn1 {
                                color: #5365ff;
                                border: 1px solid #e5e5e5;
                                margin-right: 8px;
                            }

                            &.btn2 {
                                color: #e66723;
                                border: 1px solid #e5e5e5;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .icons {
                            .icon {
                                &.btn1 {
                                    color: #FFFFFF;
                                    border: 1px solid #5365FF;
                                    background-color: #5365FF;
                                }

                                &.btn2 {
                                    color: #FFFFFF;
                                    border: 1px solid #E66723;
                                    background-color: #E66723;
                                }
                            }
                        }
                    }
                }

                .pager {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    margin-top: 40px;
                    padding-bottom: 24px;

                    .btn {
                        width: 80px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        color: #2b333b;
                        font-size: 14px;
                        box-sizing: border-box;
                        border: 1px solid #2b333b;
                        border-radius: 4px;
                        cursor: pointer;

                        &.disabled {
                            color: #b7bbbf;
                            border: 1px solid #b7bbbf;
                        }
                    }

                    .count {
                        font-size: 14px;
                        color: #2b333b;
                        margin-left: 40px;
                        margin-right: 40px;
                    }
                }
            }
        }

        .table3 {
            .tabs {
                height: 82px;
                line-height: 82px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 40px;
                border-bottom: 2px solid #F2E6CE;

                .tab {
                    width: 106px;
                    height: 32px;
                    line-height: 32px;
                    box-sizing: border-box;
                    border: 1px solid #dadada;
                    text-align: center;
                    font-size: 18px;
                    color: #2b333b;
                    margin-right: 64px;
                    cursor: pointer;

                    &.select {
                        border: 1px solid #6e8b74;
                        background-color: #6e8b74;
                        color: #ffffff;
                    }
                }
            }

            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        box-sizing: border-box;
                        text-align: center;
                        width: 160px;
                        margin-right: 80px;
                    }

                    &.th2 {
                        width: 700px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    &.th3 {
                        width: 353px;
                        text-align: left;
                        margin-right: 20px;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;
                padding-bottom: 58px;

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;
                    transition: all 0.2s;

                    .th1 {
                        box-sizing: border-box;
                        text-align: center;
                        width: 160px;
                        margin-right: 80px;
                    }

                    .th2 {
                        width: 700px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .th3 {
                        width: 353px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .icons {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            border-radius: 4px;
                            border: 1px solid #e5e5e5;
                            color: #5365ff;
                            font-size: 16px;
                            margin-right: 8px;
                            transition: all 0.2s;

                            &:last-child {
                                color: #e00916;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .icons {
                            .icon:first-child {
                                background-color: #5567FF;
                                color: #FFFFFF;
                            }

                            .icon:last-child {
                                background-color: #E00916;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
