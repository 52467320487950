
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol  fill=\"none\" viewBox=\"0 0 12.868 12.868\" id=\"icon-add\"><path d=\"M6.608.19a.654.654 0 0 1 .921 0l3.45 3.43L14.426.19a.654.654 0 0 1 .922 0 .646.646 0 0 1 0 .917l-3.45 3.43 3.45 3.431a.646.646 0 0 1 0 .917.654.654 0 0 1-.922 0l-3.449-3.431-3.449 3.43a.654.654 0 0 1-.921 0 .646.646 0 0 1 0-.916l3.449-3.43-3.45-3.431a.646.646 0 0 1 0-.917Z\" fill-rule=\"evenodd\" transform=\"rotate(45 6.417 0)\" fill=\"#2196F3\" style=\"mix-blend-mode:passthrough\" /></symbol><symbol class=\"icon\" viewBox=\"0 0 1024 1024\"  id=\"icon-down\"><path d=\"M512 938.667 262.4 640h499.2z\" /><path d=\"M426.667 128h170.666v576H426.667z\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}