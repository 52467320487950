<template>
    <img class="dropdown" src="@/assets/images/common/xiajiantou.png" />
</template>
<script lang="ts" setup></script>

<style lang="scss" scoped>
.dropdown {
    width: 12px;
    height: 12px;
}
</style>
