<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-07 20:24:25
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-18 10:05:36
-->
<template>
    <div class="c-book-detail">
        <div class="bread">
            当前位置：<span class="pointer" @click="goBook">书刊</span>&ensp;>&ensp;<span>书刊详情</span>
        </div>
        <div class="content">
            <div class="main" v-if="state.item">
                <div class="p1">
                    <div class="l">
                        <img @click="goReadPage" class="video" v-lazy="state.item.Cover" />
                    </div>
                    <div class="r">
                        <el-tooltip :content="state.item.Title" effect="light">
                            <div ref="tip" class="title" :style="{ opacity: state.opacity }">
                                {{ state.item.Title }}
                            </div>
                        </el-tooltip>
                        <div ref="notip" class="title"
                            :style="{ opacity: state.opacity, overflow: state.opacity == 0 ? 'scroll' : '' }">
                            <span>{{ state.item.Title }}</span>
                        </div>
                        <div class="meng mFont">
                            <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                :key="index">{{ item }}</span>
                        </div>
                        <div class="divider"></div>
                        <div class="items" v-if="state.item">
                            <div class="item one-line">
                                作&emsp;&emsp;者：{{ state.item.Author ? state.item.Author : "暂无" }}
                            </div>
                            <div class="item one-line">
                                出&ensp;版&ensp;社：{{ state.item.Publisher ? state.item.Publisher : "暂无" }}
                            </div>
                            <div class="item one-line">
                                出版时间：{{ state.item.SPublishDate ? state.item.SPublishDate : "暂无" }}
                            </div>
                            <div class="item one-line" v-if="getEditor().length > 0">
                                <div class="name">主&emsp;&emsp;编：</div>
                                <div class="values">
                                    <div class="value one-line" v-for="(item, index) in getEditor()" :key="index">
                                        {{ item.PropertyValue }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="state.item.Isbn" class="item one-line">
                                I&ensp;S&ensp;B&ensp;N：{{ state.item.Isbn ? state.item.Isbn : "暂无" }}
                            </div>
                            <div v-else-if="state.item.Issn" class="item one-line">
                                I&ensp;S&ensp;S&ensp;N：{{ state.item.Issn ? state.item.Issn : "暂无" }}
                            </div>
                            <div v-else class="item one-line">
                                I&ensp;S&ensp;B&ensp;N：暂无
                            </div>
                        </div>
                        <div class="buttons">
                            <div class="button b1" @click="favoriteClick">
                                <el-tooltip :content="!state.item.IsFavorite ? '收藏' : '取消收藏'" effect="light">
                                    <img v-if="!state.item.IsFavorite" src="@/assets/images/common/shoucang0.png" />
                                    <img v-else src="@/assets/images/common/shoucang.png" />
                                </el-tooltip>
                                <span>{{ state.item.Collect }}</span>
                            </div>

                            <div class="button b2" @click="likeClick">
                                <el-tooltip :content="!state.item.IsLike ? '点赞' : '取消点赞'" effect="light">
                                    <img v-if="!state.item.IsLike" src="@/assets/images/common/dianzan0.png" />
                                    <img v-else src="@/assets/images/common/dianzan.png" />
                                </el-tooltip>
                                <span>{{ state.item.Like }}</span>
                            </div>
                        </div>
                        <div class="mbtn" @click="goReadPage">立即阅读</div>
                    </div>
                </div>
                <div class="p2">
                    <div class="p21">
                        <div class="title one-line">
                            <span>“</span>
                            内容简介
                        </div>
                        <div class="meng mFont">
                            <span class="meng-vertical">ᠠᠭᠤᠯᠭ᠎ᠠ᠎ᠶᠢᠨ</span>
                            <span class="meng-vertical">ᠲᠣᠪᠴᠢ</span>
                            <span class="meng-vertical">ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ</span>
                        </div>
                    </div>
                    <div ref="p22" class="p22" :class="[!state.show ? 'hide' : '', state.complete ? 'complete' : '']">
                        {{ state.item.Abstract ? state.item.Abstract : "暂无" }}
                    </div>
                    <div class="p23" v-if="state.item.Abstract && state.ellipsis">
                        <div class="btn" v-if="!state.show" @click="showOrHide(true)">
                            展开全部&ensp;>
                        </div>
                        <div class="btn" v-if="state.show" @click="showOrHide(false)">
                            收起&ensp;>
                        </div>
                    </div>
                </div>
                <div class="p3">
                    <div class="images" id="images">
                        <template v-for="(item, index) in state.related" :key="index">
                            <a @click="goImage(item)">
                                <img class="image" v-lazy="item.Cover" />
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="content2">
            <div class="menus">
                <div class="menu" :class="{ select: state.menuSelect == 0 }" @click="menuClick(0)">
                    <div class="han">目录</div>
                    <div class="meng mFont">
                        <span>ᠭᠠᠷᠴᠠᠭ</span>
                    </div>
                </div>
                <div class="menu" v-if="!state.ismengEmpty" :class="{ select: state.menuSelect == 1 }"
                    @click="menuClick(1)">
                    <div class="han">蒙文目录</div>
                    <div class="meng mFont">
                        <span>ᠮᠣᠩᠭᠤᠯ</span>
                        <span>ᠭᠠᠷᠴᠠᠭ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.labels1Total > 0" :class="{ select: state.menuSelect == 2 }"
                    @click="menuClick(2)">
                    <div class="han">
                        知识标签<span>({{ state.labels1Total }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠮᠡᠳᠡᠯᠭᠡ᠎ᠶᠢᠨ</span>
                        <span>ᠱᠣᠱᠢᠭ᠎ᠠ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.labels2Total > 0" :class="{ select: state.menuSelect == 3 }"
                    @click="menuClick(3)">
                    <div class="han">
                        资源标签<span>({{ state.labels2Total }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠡᠬᠢ</span>
                        <span>ᠪᠠᠶᠠᠯᠢᠭ᠎ᠤᠨ</span>
                        <span>ᠱᠣᠱᠢᠭ᠎ᠠ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.labels3Total > 0" :class="{ select: state.menuSelect == 4 }"
                    @click="menuClick(4)">
                    <div class="han">
                        网站标签<span>({{ state.labels3Total }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠨᠧᠲ</span>
                        <span>ᠥᠷᠲᠡᠭᠡᠨ</span>
                        <span>ᠱᠣᠱᠢᠭ᠎ᠠ</span>
                    </div>
                </div>
                <div class="menu" v-if="state.markinfoTotal > 0" :class="{ select: state.menuSelect == 5 }"
                    @click="menuClick(5)">
                    <div class="han">
                        我的阅读<span>({{ state.markinfoTotal }})</span>
                    </div>
                    <div class="meng mFont">
                        <span>ᠮᠢᠨᠦ</span>
                        <span>ᠤᠩᠱᠢᠯᠭ᠎ᠠ</span>
                    </div>
                </div>
            </div>
            <div class="table" v-if="state.menuSelect == 0">
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.chapters" :key="i"
                        @click="goReadPageByNumber(item.PageNumber)">
                        <div class="title one-line parent">{{ item.Title }}</div>
                        <div class="item" v-for="(iitem, iindex) in item.Children" :key="iindex"
                            @click="goReadPageByNumber(iitem.PageNumber)">
                            <div class="title one-line child1">{{ iitem.Title }}</div>
                            <div class="item" v-for="(iiitem, iiindex) in iitem.Children" :key="iiindex"
                                @click="goReadPageByNumber(iiitem.PageNumber)">
                                <div class="title one-line child2">
                                    {{ iiitem.Title }}
                                </div>

                                <div class="item" v-for="(iiiitem, iiiindex) in iiitem.Children" :key="iiiindex"
                                    @click="goReadPageByNumber(iiiitem.PageNumber)">
                                    <div class="title one-line child3">
                                        {{ iiiitem.Title }}
                                    </div>
                                    <div class="item" v-for="(iiiiitem, iiiiindex) in iiiitem.Children" :key="iiiiindex"
                                        @click="goReadPageByNumber(iiiiitem.PageNumber)">
                                        <div class="title one-line child4">
                                            {{ iiiiitem.Title }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.chapters.length == 0">
                        暂无数据
                    </div>
                </div>
            </div>

            <div class="tablemeng" v-else-if="state.menuSelect == 1">
                <div class="tbodymeng" :class="{ empty: state.chapters.length == 0 }">
                    <template v-for="(item, i) in state.chapters" :key="i">
                        <div v-if="item.MTitle" class="item mFont parent" @click="goReadPageByNumber(item.PageNumber)">
                            <span class="meng" v-for="(iitem, iindex) in getMWords(item.MTitle)" :key="iindex">
                                {{ iitem }}
                            </span>
                        </div>
                        <template v-for="(iitem, iindex) in item.Children" :key="iindex">
                            <div v-if="iitem.MTitle" class="item mFont child1"
                                @click="goReadPageByNumber(iitem.PageNumber)">
                                <span class="meng" v-for="(iiitem, iiindex) in getMWords(iitem.MTitle)" :key="iiindex">
                                    {{ iiitem }}
                                </span>
                            </div>
                            <template v-for="(iiitem, iiindex) in iitem.Children" :key="iiindex">
                                <div v-if="iiitem.MTitle" class="item mFont child2"
                                    @click="goReadPageByNumber(iiitem.PageNumber)">
                                    <span class="meng" v-for="(iiiitem, iiiindex) in getMWords(
                                        iiitem.MTitle
                                    )" :key="iiiindex">
                                        {{ iiiitem }}
                                    </span>
                                </div>
                                <template v-for="(iiiitem, iiiindex) in iiitem.Children" :key="iiiindex">
                                    <div v-if="iiiitem.MTitle" class="item mFont child3"
                                        @click="goReadPageByNumber(iiiitem.PageNumber)">
                                        <span class="meng" v-for="(iiiiitem, iiiiindex) in getMWords(
                                            iiiitem.MTitle
                                        )" :key="iiiiindex">
                                            {{ iiiiitem }}
                                        </span>
                                    </div>
                                    <template v-for="(iiiiitem, iiiiindex) in iiiitem.Children" :key="iiiiindex">
                                        <div v-if="iiiiitem.MTitle" class="item mFont child4"
                                            @click="goReadPageByNumber(iiiiitem.PageNumber)">
                                            <span class="meng" v-for="(
                                                    iiiiiitem, iiiiiindex
                                                ) in getMWords(iiiiitem.MTitle)" :key="iiiiiindex">
                                                {{ iiiiiitem }}
                                            </span>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                    <div class="item-empty" v-if="state.chapters.length == 0">
                        暂无数据
                    </div>
                </div>
            </div>

            <div class="table2" v-if="state.menuSelect == 2">
                <div class="theader">
                    <div class="th th1">标签名称</div>
                    <div class="th th2">类别</div>
                    <div class="th th3">标签介绍</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.labels1" :key="i">
                        <div class="name one-line">{{ item.Name }}</div>
                        <div class="type one-line">{{ item.TypeName }}</div>
                        <div class="des one-line">{{ item.Content }}</div>
                        <div class="icons">
                            <div class="icon btn1" @click="goLabel(item)">详情</div>
                            <div class="icon btn2" @click="tagGetPosition(item)">定位</div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.labels1.length == 0">
                        暂无数据
                    </div>
                    
                    <div class="pager" v-if="getLabels1PageCount() > 1">
                        <div class="btn" :class="{ disabled: state.labels1cp == 1 }" @click="handleLabels1Last">
                            上一页
                        </div>
                        <div class="count">{{ state.labels1cp }} / {{ getLabels1PageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.labels1cp >= getLabels1PageCount() }" @click="handleLabels1Next">
                            下一页
                        </div>
                    </div>
                </div>
            </div>

            <div class="table2" v-if="state.menuSelect == 3">
                <div class="theader">
                    <div class="th th1">标签名称</div>
                    <div class="th th2">类别</div>
                    <div class="th th3">标签介绍</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.labels2" :key="i">
                        <div class="name one-line">{{ item.Name }}</div>
                        <div class="type one-line">{{ item.TypeName }}</div>
                        <div class="des one-line">{{ item.Content }}</div>
                        <div class="icons">
                            <div class="icon btn1" @click="goLabel(item)">详情</div>
                            <div class="icon btn2" @click="tagGetPosition(item)">定位</div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.labels2.length == 0">
                        暂无数据
                    </div>
                    
                    <div class="pager" v-if="getLabels2PageCount() > 1">
                        <div class="btn" :class="{ disabled: state.labels2cp == 1 }" @click="handleLabels2Last">
                            上一页
                        </div>
                        <div class="count">{{ state.labels2cp }} / {{ getLabels2PageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.labels2cp >= getLabels2PageCount() }" @click="handleLabels2Next">
                            下一页
                        </div>
                    </div>
                </div>
            </div>

            <div class="table2" v-if="state.menuSelect == 4">
                <div class="theader">
                    <div class="th th1">标签名称</div>
                    <div class="th th2">类别</div>
                    <div class="th th3">标签介绍</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.labels3" :key="i">
                        <div class="name one-line">{{ item.Name }}</div>
                        <div class="type one-line">{{ item.TypeName }}</div>
                        <div class="des one-line">{{ item.Content }}</div>
                        <div class="icons">
                            <div class="icon btn1" @click="goLabel(item)">详情</div>
                            <div class="icon btn2" @click="tagGetPosition(item)">定位</div>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.labels3.length == 0">
                        暂无数据
                    </div>
                    
                    <div class="pager" v-if="getLabels3PageCount() > 1">
                        <div class="btn" :class="{ disabled: state.labels3cp == 1 }" @click="handleLabels3Last">
                            上一页
                        </div>
                        <div class="count">{{ state.labels3cp }} / {{ getLabels3PageCount() }}</div>
                        <div class="btn" :class="{ disabled: state.labels3cp >= getLabels3PageCount() }" @click="handleLabels3Next">
                            下一页
                        </div>
                    </div>
                </div>
            </div>

            <div class="table3" v-if="state.menuSelect == 5">
                <div class="tabs">
                    <div class="tab" :class="{ select: state.tabSelect == 0 }" @click="tabClick(0)">
                        我的笔记
                    </div>
                    <div class="tab" :class="{ select: state.tabSelect == 1 }" @click="tabClick(1)">
                        我的划线
                    </div>
                    <div class="tab" :class="{ select: state.tabSelect == 2 }" @click="tabClick(2)">
                        我的书签
                    </div>
                </div>
                <div class="theader">
                    <div class="th th1" v-if="state.tabSelect == 0">笔记位置</div>
                    <div class="th th1" v-else-if="state.tabSelect == 1">划线位置</div>
                    <div class="th th1" v-else-if="state.tabSelect == 2">书签位置</div>
                    <div class="th th2" v-if="state.tabSelect == 0">笔记上下文</div>
                    <div class="th th2" v-else-if="state.tabSelect == 1">划线上下文</div>
                    <div class="th th2" v-else-if="state.tabSelect == 2">书签上下文</div>
                    <div class="th th3">时间</div>
                </div>
                <div class="tbody">
                    <template v-if="state.tabSelect == 0">
                        <div class="item" v-for="(item, i) in state.noteList" :key="i">
                            <div class="th1 one-line">{{ item.ProgressShow }}</div>
                            <div class="th2 one-line">{{ item.resourceTitle }}</div>
                            <div class="th3 one-line">
                                {{ moment(item.createDate).format("YYYY-MM-DD HH:mm") }}
                            </div>
                            <div class="icons">
                                <div class="icon" @click="goReadPageByRate(item.PageNumber ? item.PageNumber : item.progress)">定位</div>
                                <div class="icon" @click="markInfodeleteIndexMark(item)">删除</div>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.noteList.length == 0">
                            暂无数据
                        </div>
                    </template>
                    <template v-else-if="state.tabSelect == 1">
                        <div class="item" v-for="(item, i) in state.drawlineList" :key="i">
                            <div class="th1 one-line">{{ item.ProgressShow }}</div>
                            <div class="th2 one-line">{{ item.resourceTitle }}</div>
                            <div class="th3 one-line">
                                {{ moment(item.createDate).format("YYYY-MM-DD HH:mm") }}
                            </div>
                            <div class="icons">
                                <div class="icon" @click="goReadPageByRate(item.PageNumber ? item.PageNumber : item.progress)">定位</div>
                                <div class="icon" @click="markInfodeleteIndexMark(item)">删除</div>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.drawlineList.length == 0">
                            暂无数据
                        </div>
                    </template>
                    <template v-else-if="state.tabSelect == 2">
                        <div class="item" v-for="(item, i) in state.bookmarkList" :key="i">
                            <div class="th1 one-line">{{ item.ProgressShow }}</div>
                            <div class="th2 one-line">{{ item.resourceTitle }}</div>
                            <div class="th3 one-line">
                                {{ moment(item.createDate).format("YYYY-MM-DD HH:mm") }}
                            </div>
                            <div class="icons">
                                <div class="icon" @click="goReadPageByRate(item.PageNumber ? item.PageNumber : item.progress)">定位</div>
                                <div class="icon" @click="markInfodeleteIndexMark(item)">删除</div>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.bookmarkList.length == 0">
                            暂无数据
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import moment from "moment";
import api from "@/common/api";
import { ElMessage } from "element-plus";
import { getToken } from "@/request";

import routerUtil from "@/common/router";

import { storeToRefs } from "pinia";
import store from "@/pinia";
const userStore = store.user();
const user = storeToRefs(userStore) as any;

const route = useRoute() as any;
const router = useRouter() as any;
const emits = defineEmits(["handleComplete"]);

const option = {
    rowHeight: 280,
    maxRowHeight: 280,
    maxRowsCount: 1,
    margins: 20,
    lastRow: "justify",
};

const state = reactive({
    id: "",
    item: null as any,
    related: [] as any[],
    show: false,
    total: '',

    labels1Total: 0,
    labels1: [] as any[],
    labels1cp: 1,
    labels1ps: 10,

    labels2Total: 0,
    labels2: [] as any[],
    labels2cp: 1,
    labels2ps: 10,

    labels3Total: 0,
    labels3: [] as any[],
    labels3cp: 1,
    labels3ps: 10,

    menuSelect: 0,
    tabSelect: 0,

    markinfoTotal: 0,

    noteList: [] as any[],
    drawlineList: [] as any[],
    bookmarkList: [] as any[],

    chapters: [] as any,

    favoriteLoading: false,
    likeLoading: false,

    complete: false,
    ellipsis: false,

    opacity: 0,

    ismengEmpty: true
});

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const getEditor = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let editor = state.item.ExternalData.filter((item: any, index: number) => {
                return item.PropertyName == "editor";
            });

            if (editor.length > 0) {
                return editor;
            }
        }
    }

    return [];
};

const contentInfoInfo = () => {
    state.item = {};

    api.contentInfoInfo(
        { id: state.id },
        (res: any) => {
            state.item = res;
            isEllipsis()
            initToolTip()

            contentInfoPageList();
            contentInfoRelatedContentList();
            labelContentLabelCount();
            contentInfoGetChapters();

            state.likeLoading = false
            state.favoriteLoading = false
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoPageList = () => {
    api.contentInfoPageList(
        { objectType: 1 },
        (res: any) => {
            state.total = res.RecordCount;
            setTimeout(() => {
                $("#images")
                    .justifiedGallery(option)
                    .on("jg.complete", (e: any) => {
                        emits("handleComplete");
                    });
            }, 200);
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoRelatedContentList = () => {
    api.contentInfoRelatedContentList(
        { id: state.id, top: 9999, objectType: 4 },
        (res: any) => {
            state.related = res;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoGetChapters = () => {
    state.chapters = state.item.CtalogueInfos;

    for (let i = 0; i < state.chapters.length; i++) {
        const item = state.chapters[i];
        if (item.MTitle) {
            state.ismengEmpty = false
            break
        }
    }

    emits("handleComplete");
};

const favoriteSave = () => {
    api.favoriteSave(
        { objectId: state.id },
        (res: any) => {
            state.item.IsFavorite = true;
            state.item.Collect++;
            state.favoriteLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const favoriteDelete = () => {
    api.favoriteDelete(
        { objectId: state.id },
        (res: any) => {
            state.item.IsFavorite = false;
            state.item.Collect--;
            state.favoriteLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const favoriteClick = () => {
    if (state.favoriteLoading) {
        return;
    } else {
        state.favoriteLoading = true;
    }

    if (state.item.IsFavorite) {
        favoriteDelete();
    } else {
        favoriteSave();
    }
};

const likeInfoSave = () => {
    api.likeInfoSave(
        { contentId: state.id },
        (res: any) => {
            state.item.IsLike = true;
            state.item.Like++;
            state.likeLoading = false;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const likeInfoDelete = () => {
    api.likeInfoDelete(
        { contentId: state.id },
        (res: any) => {
            state.item.IsLike = false;
            state.item.Like--;
            state.likeLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const likeClick = () => {
    if (state.likeLoading) {
        return;
    } else {
        state.likeLoading = true;
    }

    if (state.item.IsLike) {
        likeInfoDelete();
    } else {
        likeInfoSave();
    }
};

const showOrHide = (show: boolean) => {
    state.show = show;
    emits("handleComplete");
};

const goBook = () => {
    router.push({
        path: `/book`,
    });
};

const goImage = (item: any) => {
    routerUtil.openNewWindow({
        path: `/imagedetail`,
        query: {
            id: item.StrId,
        },
    });
};

const goReadPage = () => {
    routerUtil.goReadPage(state.id, getToken());
};

const goReadPageById = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

const goReadPageByNumber = (number: string) => {
    //routerUtil.goReadPageByNumber(state.id, number, getToken());
};

const labelContentLabelCount = () => {
    getLabels1Data()

    getLabels2Data()

    getLabels3Data()

    api.markInfoGetMarkCount(
        { resourceId: state.id },
        (res: any) => {
            state.markinfoTotal = res;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const tabClick = (index: number) => {
    state.tabSelect = index;
    emits("handleComplete");

    if (index == 0) {
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "note" },
            (res: any) => {
                state.noteList = res;
                emits("handleComplete");
            },
            (err: any) => {
                console.log(err);
            }
        );
    } else if (index == 1) {
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "drawline" },
            (res: any) => {
                state.drawlineList = res;
                emits("handleComplete");
            },
            (err: any) => {
                console.log(err);
            }
        );
    } else if (index == 2) {
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "bookmark" },
            (res: any) => {
                state.bookmarkList = res;
                emits("handleComplete");
            },
            (err: any) => {
                console.log(err);
            }
        );
    }
};

const menuClick = (index: number) => {
    state.menuSelect = index;
    emits("handleComplete");

    if (index == 0) {
        console.log("目录");
    } else if (index == 1) {
        console.log("蒙文目录");
    } else if (index == 2) {
        console.log("知识标签");

        state.labels1cp = 1
        getLabels1Data()
    } else if (index == 3) {
        console.log("资源标签");

        state.labels2cp = 1
        getLabels2Data()
    } else if (index == 4) {
        console.log("网站标签");

        state.labels3cp = 1
        getLabels3Data()
    } else if (index == 5) {
        console.log("我的阅读");
        api.markInfoGetIndexMarkByOther(
            { resourceId: state.id, type: "note" },
            (res: any) => {
                state.noteList = res;
            },
            (err: any) => {
                console.log(err);
            }
        );
    }
};

const goLabel = (item: any) => {
    routerUtil.openNewWindow({
        path: "/knowledge",
        query: {
            id: item.StrId
        }
    });
}

const tagGetPosition = (item: any) => {
    api.tagGetPosition(
        { labelId: item.StrId, resourceId: state.item.StrId },
        (res: any) => {
            if (res.PageNo) {
                routerUtil.goReadPageByNumber(state.item.StrId, res.PageNo, getToken())
            } else {
                routerUtil.goReadPageByRate(state.item.StrId, res.PositionRate, getToken())
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const goReadPageByRate = (rate: string) => {
    routerUtil.goReadPageByRate(state.item.StrId, rate, getToken())
}

const markInfodeleteIndexMark = (item: any) => {
    api.markInfodeleteIndexMark(
        { markId: item.markId, resourceId: state.item.StrId },
        (res: any) => {
            tabClick(state.tabSelect)
            ElMessage.success('删除成功')
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const p22 = ref(0)
const isEllipsis = () => {
    setTimeout(() => {
        if ((p22.value as any).clientHeight < (p22.value as any).scrollHeight) {
            state.ellipsis = true;
            state.complete = true;
            state.show = false;

            emits("handleComplete");
        } else {
            state.ellipsis = false;
            state.complete = true;
            state.show = false;

            emits("handleComplete");
        }
    }, 1000);
}

const tip = ref(0);
const notip = ref(0);

const initToolTip = () => {
    setTimeout(() => {
        const titem = tip.value as any;
        const nitem = notip.value as any;

        if (nitem.clientHeight < nitem.scrollHeight) {
            nitem.remove();
            state.opacity = 1;
        } else {
            titem.remove();
            state.opacity = 1;
        }

        emits("handleComplete");
    }, 500);
};

const getLabels1Data = () => {
    api.labelContentLabelPage(
        { contentId: state.id, type: 1, cp: state.labels1cp, ps: state.labels1ps },
        (res: any) => {
            state.labels1 = res.ItemList;
            state.labels1Total = res.RecordCount;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const getLabels1PageCount = () => {
    if (state.labels1Total % state.labels1ps == 0) {
        return state.labels1Total / state.labels1ps;
    } else {
        return Math.floor(state.labels1Total / state.labels1ps) + 1;
    }
};

const handleLabels1Last = () => {
    if (state.labels1cp <= 1) {
        state.labels1cp = 1;
        return;
    }

    state.labels1cp -= 1;
    getLabels1Data();
};

const handleLabels1Next = () => {
    if (state.labels1cp >= getLabels1PageCount()) {
        state.labels1cp = getLabels1PageCount();
        return;
    }

    state.labels1cp += 1;
    getLabels1Data();
};

const getLabels2Data = () => {
    api.labelContentLabelPage(
        { contentId: state.id, type: 2, cp: state.labels2cp, ps: state.labels2ps },
        (res: any) => {
            state.labels2 = res.ItemList;
            state.labels2Total = res.RecordCount;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const getLabels2PageCount = () => {
    if (state.labels2Total % state.labels2ps == 0) {
        return state.labels2Total / state.labels2ps;
    } else {
        return Math.floor(state.labels2Total / state.labels2ps) + 1;
    }
};

const handleLabels2Last = () => {
    if (state.labels2cp <= 1) {
        state.labels2cp = 1;
        return;
    }

    state.labels2cp -= 1;
    getLabels2Data();
};

const handleLabels2Next = () => {
    if (state.labels2cp >= getLabels2PageCount()) {
        state.labels2cp = getLabels2PageCount();
        return;
    }

    state.labels2cp += 1;
    getLabels2Data();
};

const getLabels3Data = () => {
    api.labelContentLabelPage(
        { contentId: state.id, type: 3, cp: state.labels3cp, ps: state.labels3ps },
        (res: any) => {
            state.labels3 = res.ItemList;
            state.labels3Total = res.RecordCount;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const getLabels3PageCount = () => {
    if (state.labels3Total % state.labels3ps == 0) {
        return state.labels3Total / state.labels3ps;
    } else {
        return Math.floor(state.labels3Total / state.labels3ps) + 1;
    }
};

const handleLabels3Last = () => {
    if (state.labels3cp <= 1) {
        state.labels3cp = 1;
        return;
    }

    state.labels3cp -= 1;
    getLabels3Data();
};

const handleLabels3Next = () => {
    if (state.labels3cp >= getLabels3PageCount()) {
        state.labels3cp = getLabels3PageCount();
        return;
    }

    state.labels3cp += 1;
    getLabels3Data();
};

onMounted(() => {
    state.id = route.query.id;
    contentInfoInfo();
});
</script>

<style lang="scss" scoped>
.c-book-detail {
    width: 100%;

    .bread {
        width: 1500px;
        margin: 0 auto;
        padding-top: 40px;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 24px;
        color: #2b333b;
        text-align: left;

        .pointer {
            cursor: pointer;
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .main {
            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 40px;
                padding-left: 100px;

                .l {
                    width: 600px;
                    height: 600px;
                    margin-right: 100px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 400px;
                        height: 534px;
                        object-fit: cover;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        line-height: 48px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        width: 600px;
                        max-height: 64rpx;
                        display: -webkit-box;
                        /* Safari */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        line-clamp: 2;
                        /* 设置最大显示行数 */
                        -webkit-line-clamp: 2;
                        /* Safari and Chrome */
                        -webkit-box-orient: vertical;
                        /* Safari */
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .total {
                            margin-top: 32px;
                            font-size: 24px;
                            font-weight: 600;
                            color: #2b333b;
                            margin-bottom: 24px;
                        }

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .buttons {
                        width: 300px;
                        margin-top: 32px;
                        box-sizing: border-box;
                        padding-right: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .button {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            img {
                                margin-right: 18px;
                            }

                            &.b1 {
                                img {
                                    width: 28px;
                                    height: 29px;
                                }
                            }

                            &.b2 {
                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }

                    .mbtn {
                        width: 300px;
                        height: 56px;
                        margin-top: 28px;
                        line-height: 52px;
                        text-align: center;
                        border-radius: 4px;
                        background-color: #6e8b74;
                        color: #ffffff;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 28px;
                    }
                }
            }

            .p2 {
                margin-top: 40px;
                margin-left: 40px;
                margin-right: 50px;
                padding-bottom: 40px;
                text-align: left;

                .p21 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .title {
                        display: inline-block;
                        font-size: 32px;
                        height: 68px;
                        line-height: 68px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;

                        span {
                            font-size: 48px;
                            line-height: 68px;
                            color: #6e8b74;
                            font-family: "PingFangSC-Semibold", "Georgia", "宋体";
                        }
                    }

                    .meng {
                        padding-top: 18px;
                        margin-left: 16px;
                        box-sizing: border-box;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #787878;
                        font-size: 20px;

                        span {
                            line-height: 28px;
                        }
                    }
                }

                .p22 {
                    margin-top: 18px;
                    font-size: 20px;
                    line-height: 48px;
                    color: #2b333b;
                    text-indent: 40px;
                    padding-left: 10px;
                    text-align: justify;
                    letter-spacing: 1px;
                    overflow: auto;
                    opacity: 0;
                    height: 232px;

                    &.hide {
                        display: -webkit-box;
                        /* Safari */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        line-clamp: 4;
                        /* 设置最大显示行数 */
                        -webkit-line-clamp: 4;
                        /* Safari and Chrome */
                        -webkit-box-orient: vertical;
                        /* Safari */
                        max-height: 192px;
                    }

                    &.complete {
                        overflow: hidden;
                        opacity: 1;
                    }
                }

                .p23 {
                    margin-top: 16px;

                    .btn {
                        width: 120px;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 4px;
                        border: 1px solid #e9e9e9;
                        color: #5297ff;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }

            .p3 {
                .images {
                    width: 1460px;
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-top: 12px;
                    border-top: 8px solid transparent;
                    padding-bottom: 20px;
                    opacity: 1;
                    animation: show 2s;

                    a {
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .content2 {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .menus {
            height: 138px;
            border-bottom: 2px solid #f2e6ce;
            display: flex;
            justify-content: flex-start;

            .menu {
                width: 250px;
                height: 100%;
                position: relative;
                cursor: pointer;

                .han {
                    padding-top: 24px;
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 8px;
                    letter-spacing: 4px;
                    color: #000000;
                    transition: all 0.2s;

                    span {
                        color: #387db4;
                    }
                }

                .meng {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    height: auto;
                    width: 100%;

                    span {
                        font-size: 22px;
                        line-height: 28px;
                        color: #787878;

                        width: auto;
                        display: inline-block;
                        text-align: center;
                        writing-mode: vertical-lr;
                        /*从上往下 从左往右*/
                        -webkit-writing-mode: vertical-lr;
                        -o-writing-mode: vertical-lr;
                        -ms-writing-mode: vertical-lr;
                        text-orientation: sideways-right;
                        -webkit-text-orientation: sideways-right;
                        /*兼容ios不正常显示*/

                        &:first-child {
                            position: relative;
                        }
                    }
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 33px;
                    right: 0px;
                    width: 2px;
                    height: 80px;
                    border-radius: 1px;
                    background-color: #f5f5f5;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &.select {
                    background-color: #f2e6ce;

                    &::after {
                        display: none;
                    }

                    .han {
                        font-weight: 600;
                    }
                }
            }
        }

        .table {
            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        text-align: left;
                        width: 274px;
                        padding-left: 60px;
                        margin-right: 20px;
                    }

                    &.th2 {
                        width: 922px;
                        text-align: left;
                        margin-right: 20px;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    font-size: 20px;
                    color: #2b333b;

                    .title {
                        box-sizing: border-box;
                        width: 100%;
                        text-align: left;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-size: 20px;
                        color: #2b333b;

                        &:hover {
                            background-color: #EBEBEB;
                        }

                        &.parent {
                            padding-left: 56px;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                display: block;
                                z-index: 999;
                                left: 36px;
                                top: 26px;
                                width: 4px;
                                height: 20px;
                                border-radius: 2px;
                                background-color: #6e8b74;
                            }
                        }

                        &.child1 {
                            padding-left: 48px;
                        }

                        &.child2 {
                            padding-left: 76px;
                        }

                        &.child3 {
                            padding-left: 104px;
                        }

                        &.child4 {
                            padding-left: 132px;
                        }
                    }
                }
            }
        }

        .tablemeng {
            width: 100%;
            min-height: 144px;
            overflow-x: auto;

            .tbodymeng {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;

                &.empty {
                    margin: 0;
                    padding: 0;
                }

                .item-empty {
                    width: 100%;
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    text-align: center;
                }

                .item {
                    padding-top: 32px;
                    padding-bottom: 40px;
                    width: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    writing-mode: vertical-lr;
                    /*从上往下 从左往右*/
                    -webkit-writing-mode: vertical-lr;
                    -o-writing-mode: vertical-lr;
                    -ms-writing-mode: vertical-lr;
                    text-orientation: sideways-right;
                    -webkit-text-orientation: sideways-right;
                    /*兼容ios不正常显示*/
                    font-size: 20px;
                    width: 72px;
                    line-height: 72px;
                    color: #787878;
                    cursor: pointer;

                    &:hover {
                        background-color: #EBEBEB;
                    }

                    &.parent {
                        padding-top: 48px;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            display: block;
                            z-index: 999;
                            left: 28px;
                            top: 32px;
                            width: 20px;
                            height: 4px;
                            border-radius: 2px;
                            background-color: #6e8b74;
                        }
                    }

                    &.child1 {
                        padding-top: 60px;
                    }

                    &.child2 {
                        padding-top: 88px;
                    }

                    &.child3 {
                        padding-top: 116px;
                    }

                    &.child4 {
                        padding-top: 144px;
                    }
                }
            }
        }

        .table2 {
            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        box-sizing: border-box;
                        text-align: left;
                        width: 280px;
                        padding-left: 72px;
                        margin-right: 20px;
                    }

                    &.th2 {
                        width: 180px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    &.th3 {
                        width: 780px;
                        text-align: left;
                        margin-right: 20px;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;
                padding-bottom: 58px;

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;
                    transition: all 0.2s;

                    .name {
                        box-sizing: border-box;
                        text-align: left;
                        width: 280px;
                        padding-left: 72px;
                        margin-right: 20px;
                        position: relative;

                        &::before {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            left: 24px;
                            top: 30px;
                            background-image: url("@/assets/images/common/weizhishi.png");
                            background-size: 24px 24px;
                            z-index: 1;
                        }
                    }

                    .type {
                        width: 180px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .des {
                        width: 780px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .icons {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            border-radius: 4px;
                            border: 1px solid #e5e5e5;
                            color: #5365ff;
                            font-size: 16px;

                            &.btn1 {
                                color: #5365ff;
                                border: 1px solid #e5e5e5;
                                margin-right: 8px;
                            }

                            &.btn2 {
                                color: #e66723;
                                border: 1px solid #e5e5e5;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .icons {
                            .icon {
                                &.btn1 {
                                    color: #FFFFFF;
                                    border: 1px solid #5365FF;
                                    background-color: #5365FF;
                                }

                                &.btn2 {
                                    color: #FFFFFF;
                                    border: 1px solid #E66723;
                                    background-color: #E66723;
                                }
                            }
                        }
                    }
                }
            }
        }

        .table3 {
            .tabs {
                height: 82px;
                line-height: 82px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 40px;
                border-bottom: 2px solid #F2E6CE;

                .tab {
                    width: 106px;
                    height: 32px;
                    line-height: 32px;
                    box-sizing: border-box;
                    border: 1px solid #dadada;
                    text-align: center;
                    font-size: 18px;
                    color: #2b333b;
                    margin-right: 64px;
                    cursor: pointer;

                    &.select {
                        border: 1px solid #6e8b74;
                        background-color: #6e8b74;
                        color: #ffffff;
                    }
                }
            }

            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        box-sizing: border-box;
                        text-align: center;
                        width: 160px;
                        margin-right: 80px;
                    }

                    &.th2 {
                        width: 700px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    &.th3 {
                        width: 353px;
                        text-align: left;
                        margin-right: 20px;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;
                padding-bottom: 58px;

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;
                    transition: all 0.2s;

                    .th1 {
                        box-sizing: border-box;
                        text-align: center;
                        width: 160px;
                        margin-right: 80px;
                    }

                    .th2 {
                        width: 700px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .th3 {
                        width: 353px;
                        text-align: left;
                        margin-right: 20px;
                    }

                    .icons {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            border-radius: 4px;
                            border: 1px solid #e5e5e5;
                            color: #5365ff;
                            font-size: 16px;
                            margin-right: 8px;
                            transition: all 0.2s;

                            &:last-child {
                                color: #e00916;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .icons {
                            .icon:first-child {
                                background-color: #5567FF;
                                color: #FFFFFF;
                            }

                            .icon:last-child {
                                background-color: #E00916;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }
}</style>
