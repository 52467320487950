<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-03 10:51:18
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 13:39:21
-->
<template>
    <div class="video">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <video-search class="search"></video-search>
            <video-category
                class="category"
                @handleComplete="handleComplete"
            ></video-category>
            <div class="labels">
                <video-labels></video-labels>
            </div>
        </div>
        <footer-footer
            class="footer"
            :style="getMarginTopStyle(state.boxHeight)"
        ></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import VideoSearch from "@/components/page/video/VideoSearch.vue";
import VideoCategory from "@/components/page/video/VideoCategory.vue";
import VideoLabels from "@/components/page/video/VideoLabels.vue";

import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle } from "@/utils/scale";
import FooterFooter from "@/components/footer/Footer.vue";

const router = useRouter();
const userStore = store.user() as any;
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const state = reactive({
    keyword: "",
    boxHeight: 0,
});

const box = ref(0);

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height;
    }, 10);
};

emitter.on('handleComplete', handleComplete)

onMounted(() => {});
</script>

<style lang="scss" scoped>
.video {
    width: 100%;
    .box {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 80px;
        background-color: #f8f8f8;

        .search {
            margin-bottom: 40px;
        }

        .category {
            margin-bottom: 40px;
        }

        .labels {
            width: 1500px;
            margin: 0 auto;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }
    }

    .footer {
        transition: all 0.05s ease-out;
    }
}
</style>
