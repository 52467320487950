<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 20:01:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-12 13:42:18
-->
<template>
    <div class="knowledge-labels">
        <div ref="labels" class="labels">

        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    onMounted,
    reactive,
    watch,
    ref,
    defineExpose,
    defineEmits,
} from "vue";
import moment from "moment";
import api from "@/common/api";
import mpx2vw from "@/utils/px2vw";
import echartsUtil from '@/utils/echarts'

const emit = defineEmits(["handleLabelsComplete", "handleLabelsEmpty", "handleLabelSelect"]);

const state = reactive({
    //labelCount: 20,
    labelCount: 80,
    labels: [] as any[],
    selectLabel: {} as any,
    labelPositions: [] as any[],
    showLabels: false,
});

const labelSearch = (keyword: string, labelId: string) => {
    state.labels = [];
    state.selectLabel = {};

    api.labelSearch(
        { top: state.labelCount, keyword: keyword },
        (res: any) => {
            state.labels = res;
            
            if(labelId == "" || labelId == null) {
                if (state.labels.length > 0) {
                    state.selectLabel = state.labels[0];
                } else {
                    state.selectLabel = null;
                    emit("handleLabelsEmpty");
                    emit("handleLabelsComplete", state.labels);
                    echartsUtil.getChart()?.clear()
                    return
                }
            }

            state.labels.sort((a: any, b: any) => {
                return b.Cites - a.Cites;
            });

            echartsUtil.initEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
                let series = option.series[0]

                handleLabelSelect(series.data[params.dataIndex].item)
                echartsUtil.updateEcharts(mecharts)
            }, labelId)

            emit("handleLabelsComplete", state.labels);
            if(labelId == "" || labelId == null) {
                handleLabelSelect(state.selectLabel)
            } else {
                emit("handleLabelSelect", null);
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const labels = ref(0)

const handleLabelSelect = (item: any) => {
    state.selectLabel = item;

    let option = echartsUtil.getOption()
    let series = option.series[0]
    let has = false

    for (let i = 0; i < series.data.length; i++) {
        const element = series.data[i]

        if(element.item.StrId == item.StrId) {
            has = true
        }
    }
    
    if(!has) {
        state.labels.push(item)
        
        echartsUtil.initEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
            let series = option.series[0]

            handleLabelSelect(series.data[params.dataIndex].item)
            echartsUtil.updateEcharts(mecharts)
        }, state.selectLabel.StrId)

        for (let i = 0; i < series.data.length; i++) {
            const element = series.data[i]

            if(element.item.StrId == item.StrId) {
                has = true
            }
        }
    }

    echartsUtil.updateEcharts(echartsUtil.getChart())
    echartsUtil.setSelect(state.selectLabel)
    emit("handleLabelSelect", state.selectLabel);
};

defineExpose({
    labelSearch,
    handleLabelSelect
});
</script>

<style lang="scss" scoped>
.knowledge-labels {
    width: 100%;
    height: 100%;
    background-color: #f0f8f1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 8px;
        background-image: url("@/assets/images/home/stars.png");
        background-position: 50% 50%;
        background-size: 100% 100%;
    }

    @keyframes anim {
        0% {
            opacity: 0;
        }
        90% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .labels {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 560px;
        z-index: 99;

        //animation: anim 2s;
    }
}
</style>
