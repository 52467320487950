/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-24 10:03:48
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-24 10:16:02
 */
import mitt from 'mitt'


export default mitt()