<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-29 20:02:08
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 16:04:34
-->
<template>
    <div class="magazine">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <magazine-search></magazine-search>
            <magazine-latest ref="latest" class="latest"></magazine-latest>
            <magazine-browse ref="browse" class="browse"></magazine-browse>
            <magazine-characteristic
                ref="characteristic"
                class="characteristic"
            ></magazine-characteristic>
            <magazine-covers ref="covers" class="covers"></magazine-covers>
            <magazine-years ref="years" class="years"></magazine-years>
            <div class="labels">
                <magazine-labels ref="labels"></magazine-labels>
            </div>
        </div>
        <footer-footer
            class="footer"
            :style="getMarginTopStyle(state.boxHeight)"
        ></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import MagazineSearch from "@/components/page/magazine/MagazineSearch.vue";
import MagazineLatest from "@/components/page/magazine/MagazineLatest.vue";
import MagazineBrowse from "@/components/page/magazine/MagazineBrowse.vue";
import MagazineCharacteristic from "@/components/page/magazine/MagazineCharacteristic.vue";
import MagazineCovers from "@/components/page/magazine/MagazineCovers.vue";
import MagazineYears from "@/components/page/magazine/MagazineYears.vue";
import MagazineLabels from "@/components/page/magazine/MagazineLabels.vue";

import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle } from "@/utils/scale";
import FooterFooter from "@/components/footer/Footer.vue";

const router = useRouter();
const userStore = store.user() as any;
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const state = reactive({
    keyword: "",
    boxHeight: 0,

    latest: {} as any,
    opacitys: [] as any[],
    labels: [] as any,
    related: [] as any[],
});

const box = ref(0);
const latest = ref(0);

const contentInfoNewData = () => {
    api.contentInfoNewData(
        { objectType: 1 },
        (res: any) => {
            state.latest = res;
            state.latest.CtalogueInfos.forEach((item: any) => {
                state.opacitys.push(item);
            });

            labelHotList();
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoRelatedContentList = () => {
    api.contentInfoRelatedContentList(
        { id: state.latest.StrId, top: 3, objectType: 4 },
        (res: any) => {
            state.related = res;
            (latest.value as any).initLatest(
                state.latest,
                state.opacitys,
                state.labels,
                state.related
            );
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const labelHotList = () => {
    api.labelHotList(
        { contentId: state.latest.StrId },
        (res: any) => {
            state.labels = res;
            contentInfoRelatedContentList();
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoNewList = () => {
    api.contentInfoNewList(
        { top: 1, objectType: 1, removeFirst: false },
        (res: any) => {
            state.latest = res[0];
            (latest.value as any).initLatest(state.latest, state.opacitys);
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const handleSearch = () => {};

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height;
    }, 10);
};

emitter.on('handleComplete', handleComplete)

onMounted(() => {
    contentInfoNewData();
    handleSearch();
    handleComplete();
});
</script>

<style lang="scss" scoped>
.magazine {
    width: 100%;
    .box {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 80px;
        background-color: #f0f0f0;

        .latest {
            margin: 0 auto;
            margin-top: 40px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }

        .browse {
            margin: 0 auto;
            margin-top: 40px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }

        .characteristic {
            margin: 0 auto;
            margin-top: 40px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }

        .covers {
            margin: 0 auto;
            margin-top: 40px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }

        .years {
            margin: 0 auto;
            margin-top: 40px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }

        .labels {
            width: 1500px;
            margin: 0 auto;
            margin-top: 90px;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }
    }

    .footer {
        transition: all 0.05s ease-out;
    }
}
</style>
