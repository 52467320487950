<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-15 19:33:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-24 10:56:37
-->
<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-06 18:34:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-01-20 10:46:09
-->
<template>
    <div class="home">
        <home-banner :notice="state.notice" class="banner" :style="getScaleBodyStyle(160)"></home-banner>
        <home-search class="search" :style="getScaleStyle(450)"></home-search>
        <home-labels class="labels" :style="getScaleStyle(64)"></home-labels>
        <home-hua-bao class="huabao" :style="getScaleStyle(443)"></home-hua-bao>
        <home-shu-kan class="shukan" :style="getScaleStyle(1172)"></home-shu-kan>
        <home-tu-pian class="tupian" :style="getScaleStyle(686)"></home-tu-pian>
        <home-shi-pin class="shipin" :style="getScaleStyle(1500)"></home-shi-pin>
        <div :style="getMarginBottomStyle(516)">
            <home-yin-pin class="yinpin" :style="getScaleStyle(996)"></home-yin-pin>
        </div>
        <home-float-buttons class="float-buttons" @noticeComplete="noticeComplete"></home-float-buttons>
        <footer-footer></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import { storeToRefs } from "pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch } from "vue";
import HomeBanner from "@/components/page/home/HomeBanner.vue";
import HomeSearch from "@/components/page/home/HomeSearch.vue";
import HomeLabels from "@/components/page/home/HomeLabels.vue";
import HomeHuaBao from "@/components/page/home/HomeHuaBao.vue";
import HomeShuKan from "@/components/page/home/HomeShuKan.vue";
import HomeTuPian from "@/components/page/home/HomeTuPian.vue";
import HomeShiPin from "@/components/page/home/HomeShiPin.vue";
import HomeYinPin from "@/components/page/home/HomeYinPin.vue";
import HomeFloatButtons from "@/components/page/home/HomeFloatButtons.vue";
import FooterFooter from "@/components/footer/Footer.vue";
import { useRoute, useRouter } from "vue-router";
import {
    getScaleStyle,
    getScaleXStyle,
    getScaleYStyle,
    getMarginBottomStyle,
    getMarginTopStyle,
    getScaleBodyStyle
} from "@/utils/scale";

const router = useRouter();
const userStore = store.user() as any;
const mbanner = ref(0)

const state = reactive({
    notice: {} as any,
    hasLogin: computed(() => {
        return userStore.hasLogin;
    }),
    showLoginDialog: computed(() => {
        return userStore.showLoginDialog;
    })
});

const noticeComplete = (notice: any) => {
    state.notice = notice
}

onMounted(() => {
    
});
</script>

<style lang="scss" scoped>
.home {
    width: 100%;

    .banner {
        width: 100%;
        padding-bottom: 44px;
        margin-bottom: 12px;
    }

    .search {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 32px;
        border-top: 1px solid transparent;
    }

    .labels {
        width: 1600px;
        height: 443px;
        margin: 0 auto;
        margin-bottom: 120px;
    }

    .huabao {
        width: 1500px;
        height: 1172px;
        margin: 0 auto;
        margin-bottom: 120px;
    }

    .shukan {
        width: 100%;
        height: 686px;
        background-color: #f0f8f1;
        margin-bottom: 120px;
    }

    .tupian {
        width: 1500px;
        margin-bottom: 100px;
    }

    .shipin {
        width: 100%;
        height: 996px;
        background-color: #f0f8f1;
        margin-bottom: 144px;
    }

    .yinpin {
        width: 100%;
        margin-bottom: 120px;
    }

    .float-buttons {
        position: fixed;
        z-index: 99;
        top: 226px;
        right: 24px;
    }

    .box {
        width: 1200px;
        margin: 0 auto;
    }
}
</style>
