<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 20:01:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 11:11:56
-->
<template>
    <div class="home-labels">
        <div ref="labels" class="labels">

        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, ref } from 'vue';
import moment from 'moment';
import api from '@/common/api';
import mpx2vw from '@/utils/px2vw';
import echartsUtil from '@/utils/echarts'
import routerUtil from "@/common/router";

const state = reactive({
    //labelCount: 20,
    labelCount: 80,
    labels: [] as any[],
    labelPositions: [] as any[],
    showLabels: false
});

const labels = ref(0)

const labelHotList = () => {
    api.labelHotList({ top: state.labelCount }, (res: any) => {
        state.labels = res

        state.labels.sort((a: any, b: any) => {
            return b.Cites - a.Cites;
        })

        echartsUtil.initEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
            let series = option.series[0]

            goLabel(series.data[params.dataIndex].item)
        })
    }, (err: any) => {
        console.log(err)
    })
}



const router = useRouter()
const goLabel = (item: any) => {
    routerUtil.openNewWindow({
        path: "/knowledge",
        query: {
            id: item.StrId
        }
    });
}

onMounted(() => {
    labelHotList()
})
</script>

<style lang="scss" scoped>
.home-labels {
    width: 100%;
    height: 100%;
    background-color: #F0F8F1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -1px;
        bottom: 0;
        border-radius: 8px;
        background-image: url('@/assets/images/home/stars.png');
        background-position: 50% 50%;
        background-size: 100% 100%;
    }

    .labels {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 443px;
        z-index: 99;
    }
}
</style>