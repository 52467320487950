<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-20 10:37:25
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-25 18:02:04
-->
<template>
    <div class="home-float-buttons">
        <div class="item qrsmall">
            <img src="@/assets/images/home/qrsmall.png" />
            <div class="tooltip">
                <img src="@/assets/images/home/qrlarge.png" />
                <div>扫一扫，打开小程序</div>
            </div>
        </div>

        <!-- <div class="item gonggao" @click="goNotice">
            <img src="@/assets/images/home/gonggao.png" />
            <div class="tooltip">
                <div>{{ state.notice.Title }}</div>
            </div>
        </div> -->
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";

const emits = defineEmits(["noticeComplete"]);

const state = reactive({
    notice: {} as any,
});

const noticeNewDataList = () => {
    api.noticeNewDataList(
        { top: 1 },
        (res: any) => {
            state.notice = res[0];
            emits("noticeComplete", state.notice)
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const router = useRouter()
const goNotice = () => {
    router.push({
        path: `/noticedetail`,
        query: {
            id: state.notice.StrId,
        },
    }), true;
};

onMounted(() => {
    noticeNewDataList();
});
</script>

<style lang="scss" scoped>
.home-float-buttons {
    .item {
        width: 48px;
        height: 48px;
        background-color: #ffffff;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 2px;
        overflow: visible;

        img {
            width: 28px;
            height: 28px;
            opacity: 0.5;
            transition: all 0.2s;
        }

        &:hover {
            .tooltip {
                opacity: 1 !important;
            }

            img {
                opacity: 1;
            }
        }

        .tooltip {
            position: absolute;
            right: 56px;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 140px;
            height: 160px;
            background-color: #ffffff;
            border-radius: 4px;
            opacity: 0;
            transition: all 0.2s;
            overflow: visible;
            white-space: nowrap;

            &:after {
                display: inline-block;
                content: "";
                position: absolute;
                top: 70px;
                right: -16px;
                border-left: 10px solid #ffffff;
                border-top: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                z-index: 999;
                width: 0;
                height: 0;
            }

            img {
                width: 116px;
                height: 116px;
                margin-top: 10px;
            }

            div {
                color: #2b333b;
                font-size: 12px;
            }
        }

        &.qrsmall {
            margin-top: 0;
        }

        &.gonggao {
            cursor: pointer;
            margin-top: 20px;

            .tooltip {
                background: #2e3968;
                color: #ffffff;
                width: auto;
                height: 32px;
                line-height: 32px;
                padding-left: 8px;
                padding-right: 8px;
                padding-bottom: 2px;

                &::after {
                    top: 6px;
                    right: -14px;
                    border-left: 10px solid #2e3968;
                }

                div {
                    color: #ffffff;
                    font-size: 18px;
                    text-wrap: nowrap;
                }
            }
        }
    }
}
</style>
