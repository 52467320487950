<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-03 11:05:02
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-13 11:48:11
-->
<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-03 11:05:02
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-03 11:31:56
-->
<template>
    <div class="image-more-images">
        <div class="header">
            <div class="left-box">
                <div class="l">
                    筛选条件
                    <!-- <img src="@/assets/images/common/xiajiantou.png" /> -->

                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;
                    <span>共 {{ state.total }} 张图片</span>
                </div>
                <!-- <el-scrollbar class="scroll-bar">
                    <div class="scroll-labels">
                        <div class="scroll-label" v-for="(item, index) in state.selectLabels" :key="index"
                            @click="closeLabel(item, true)">
                            {{ item.name }}
                            <img src="@/assets/images/common/close2.png">
                        </div>
                    </div>
                </el-scrollbar> -->
            </div>

            <div class="r">
                <div class="dropdown">
                    <el-select v-model="state.sort" class="data-select d1" value-key="id" placeholder="Select"
                        :suffix-icon="CaretBottom12" :teleported="false" fit-input-width
                        popper-class="image-more-drop-d1" @change="sortChange">
                        <el-option v-for="(item, index) in sorts" :key="index" :label="item.name" :value="item.key">
                        </el-option>
                    </el-select>
                </div>

                <div class="dropdown">
                    <el-select v-model="state.ps" class="data-select d2" value-key="id" placeholder="Select"
                        :suffix-icon="CaretBottom12" :teleported="false" fit-input-width
                        popper-class="image-more-drop-d2" @change="sizeChange">
                        <el-option v-for="(item, index) in sizes" :key="index" :label="item.name" :value="item.key">
                        </el-option>
                    </el-select>
                </div>

                <div class="pager" v-if="getPageCount() > 1">
                    <div class="btn" :class="{ disabled: state.cp == 1 }" @click="handleLast">
                        <el-icon>
                            <ArrowLeft />
                        </el-icon>
                    </div>
                    <div class="count">
                        <el-input v-model="state.cp" class="number" placeholder="1" @change="countChange"></el-input>
                        / {{ getPageCount() }}
                    </div>
                    <div class="btn" :class="{ disabled: state.cp >= getPageCount() }" @click="handleNext">
                        <el-icon>
                            <ArrowRight />
                        </el-icon>
                    </div>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="left" :class="{ 'show-filter': state.showFilter }">
                <template v-if="state.showFilterContent">
                    <div class="left-header">
                        <div class="left-title">
                            图片分类
                        </div>
                        <!-- <div class="left-btn" @click="closeAllParams">
                            清除全部条件
                        </div> -->
                    </div>
                    <div class="left-category">
                        <!-- <div class="el-checkbox-group">
                            <el-checkbox v-for="(item, index) in state.categorys" :key="index" :label="item.Name"
                                v-model="state.categorysIds[index]" @change="checkChange($event, index)" />
                        </div>
                        <template v-for="(item, index) in state.categorysWithChildren" :key="index">
                            <div class="el-checkbox-group">
                                <el-checkbox v-model="state.categorysWithChildrenIds[index]" :label="item.Name"
                                    @change="checkAllChange($event, index)" />
                            </div>
                            <div class="left-category left-category-children">
                                <div class="el-checkbox-group">
                                    <el-checkbox v-for="(iitem, iindex) in item.children" :key="iindex" :label="iitem.Name"
                                        v-model="state.categorysChildrenIds[index][iindex]"
                                        @change="checkAllChildrenChange($event, index, iindex)" />
                                </div>
                            </div>
                        </template> -->
                        <template v-for="(item, index) in state._filterTypes" :key="index">
                            <div class="child" :class="{ select: item.select }" @click="handleSelectItem(item)">
                                <template v-if="item.children && item.children.length > 0">
                                    <el-icon v-if="!item.expand"
                                        @click.stop.prevent="item.expand = !item.expand; emits('handleComplete')">
                                        <CaretRight />
                                    </el-icon>
                                    <el-icon v-if="item.expand"
                                        @click.stop.prevent="item.expand = !item.expand; emits('handleComplete')">
                                        <CaretRight class="xiajiantou" />
                                    </el-icon>
                                </template>
                                <div class="r-out">
                                    <div class="r-inner"></div>
                                </div>
                                <span class="name one-line">{{ item.Name }}</span>
                            </div>
                            <div v-if="item.expand" class="child-l2">
                                <template v-for="(iitem, iindex) in item.children" :key="iindex">
                                    <div class="child" :class="{ select: iitem.select }"
                                        @click="handleSelectChildItem(iitem, item)">
                                        <template v-if="iitem.children && iitem.children.length > 0">
                                            <el-icon v-if="!iitem.expand"
                                                @click.stop.prevent="iitem.expand = !iitem.expand; emits('handleComplete')">
                                                <CaretRight />
                                            </el-icon>
                                            <el-icon v-if="iitem.expand"
                                                @click.stop.prevent="iitem.expand = !iitem.expand; emits('handleComplete')">
                                                <CaretRight class="xiajiantou" />
                                            </el-icon>
                                        </template>
                                        <div class="r-out">
                                            <div class="r-inner"></div>
                                        </div>
                                        <span class="name one-line">{{ iitem.Name }}</span>
                                    </div>
                                    <div v-if="iitem.expand" class="child-l3">
                                        <template v-for="(iiitem, iiindex) in iitem.children" :key="iiindex">
                                            <div class="child" :class="{ select: iiitem.select }"
                                                @click="handleSelectChildItem(iiitem, iitem, item)">
                                                <template v-if="iiitem.children && iiitem.children.length > 0">
                                                    <el-icon v-if="!iiitem.expand"
                                                        @click.stop.prevent="iiitem.expand = !iiitem.expand; emits('handleComplete')">
                                                        <CaretRight />
                                                    </el-icon>
                                                    <el-icon v-if="iiitem.expand"
                                                        @click.stop.prevent="iiitem.expand = !iiitem.expand; emits('handleComplete')">
                                                        <CaretRight class="xiajiantou" />
                                                    </el-icon>
                                                </template>
                                                <div class="r-out">
                                                    <div class="r-inner"></div>
                                                </div>
                                                <span class="name one-line">{{ iiitem.Name }}</span>
                                            </div>
                                            <div v-if="iiitem.expand" class="child-l4">
                                                <template v-for="(iiiitem, iiiindex) in iiitem.children"
                                                    :key="iiiindex">
                                                    <div class="child" :class="{ select: iiiitem.select }"
                                                        @click="handleSelectChildItem(iiiitem, iiitem, iitem, item)">
                                                        <template
                                                            v-if="iiiitem.children && iiiitem.children.length > 0">
                                                            <el-icon v-if="!iiiitem.expand"
                                                                @click.stop.prevent="iiiitem.expand = !iiiitem.expand; emits('handleComplete')">
                                                                <CaretRight />
                                                            </el-icon>
                                                            <el-icon v-if="iiiitem.expand"
                                                                @click.stop.prevent="iiiitem.expand = !iiiitem.expand; emits('handleComplete')">
                                                                <CaretRight class="xiajiantou" />
                                                            </el-icon>
                                                        </template>
                                                        <div class="r-out">
                                                            <div class="r-inner"></div>
                                                        </div>
                                                        <span class="name one-line">{{ iiiitem.Name }}</span>
                                                    </div>
                                                    <div v-if="iiiitem.expand" class="child-l5">
                                                        <template v-for="(iiiiitem, iiiiindex) in iiiitem.children"
                                                            :key="iiiiindex">
                                                            <div class="child" :class="{ select: iiiiitem.select }"
                                                                @click="handleSelectChildItem(iiiiitem, iiiitem, iiitem, iitem, item)">
                                                                <template
                                                                    v-if="iiiiitem.children && iiiiitem.children.length > 0">
                                                                    <el-icon v-if="!iiiiitem.expand"
                                                                        @click.stop.prevent="iiiiitem.expand = !iiiiitem.expand; emits('handleComplete')">
                                                                        <CaretRight />
                                                                    </el-icon>
                                                                    <el-icon v-if="iiiiitem.expand"
                                                                        @click.stop.prevent="iiiiitem.expand = !iiiiitem.expand; emits('handleComplete')">
                                                                        <CaretRight class="xiajiantou" />
                                                                    </el-icon>
                                                                </template>
                                                                <div class="r-out">
                                                                    <div class="r-inner"></div>
                                                                </div>
                                                                <span class="name one-line">{{ iiiiitem.Name }}</span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div class="divider"></div>
                    </div>
                    <!-- <div class="left-header">
                        <div class="left-title">
                            热门标签
                        </div>
                    </div> -->
                    <!-- <div class="labels">
                        <div ref="labels" class="labels-content">

                        </div>

                        <div class="divider"></div>
                        <div class="btns">
                            <div class="btn" @click="closeAllLabel">
                                清除全部条件
                            </div>
                        </div>
                    </div> -->
                    <div class="left-header">
                        <div class="left-title">
                            时间范围
                        </div>
                    </div>
                    <div class="left-category">
                        <div class="el-checkbox-group">
                            <el-checkbox-group v-model="state.filterYearSelect" @change="reload">
                                <el-checkbox v-for="(item, index) in state._filterYears" :key="index" :label="`${ item }年`" />
                            </el-checkbox-group>
                        </div>
                        <div class="divider"></div>
                    </div>
                    <div class="left-header">
                        <div class="left-title">
                            地区选择
                        </div>
                    </div>
                    <div class="left-category" style="padding-bottom: 20px;">
                        <div class="select-area" @click="showArea">
                            选择地区
                        </div>
                    </div>
                </template>
            </div>
            <div class="right" :class="{ 'show-filter': state.showFilter }">
                <div class="images" id="images">
                    <template v-for="(item, index) in state.dataList" :key="index">
                        <a @click="goImage(item)">
                            <img class="image" :src="item.Cover" />
                            <div class="jg-caption one-line">{{ item.Title }}</div>
                        </a>
                    </template>
                    <div class="item-empty" v-if="state.dataList.length == 0">
                        <img src="@/assets/images/image/warn.png" /> 抱歉，未找到相关图片
                    </div>
                </div>
                <div class="next-page" v-if="getPageCount() > 0">
                    <div class="next-btn" @click="handleLast" v-if="state.cp > 1">
                        上一页
                    </div>
                    <div class="next-btn" @click="handleNext" v-if="state.cp < getPageCount()">
                        下一页
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            v-model="state.showAreaDialog"
            append-to-body
            class="filter-search-area-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :style="getScaleStyle()"
        >
            <div class="filter-search-area-dialog-content" @wheel.stop="return">
                <img
                    class="filter-search-area-dialog-close"
                    src="@/assets/images/common/close.png"
                    @click="areaCancel"
                />

                <div class="filter-search-area-dialog-header">
                    <div class="filter-search-area-dialog-header-left">
                        请选择地址：
                    </div>
                    <div class="filter-search-area-dialog-header-right">
                        {{ state.areaSelectStringList.join('、') }}
                    </div>
                </div>
                <div class="filter-search-area-dialog-body">
                    <div class="filter-search-area-dialog-body-scroll">
                        <div class="filter-search-area-dialog-body-area l2">
                            <div class="filter-search-area-dialog-body-area-item" v-for="(item, index) in state._filterAreas" :key="index" @click="areaClick(item)" :class="{ select: item.select }">
                                {{ item.Name }}
                            </div>
                        </div>
                        <template v-for="(item, index) in state._filterAreas" :key="index">
                            <template v-if="item.children.length > 0">
                                <div class="filter-search-area-dialog-body-area l3" v-for="(iitem, iindex) in item.children" :key="iindex">
                                    <div class="filter-search-area-dialog-body-area-title" @click="areaClick(iitem)" :class="{ select: iitem.select }">
                                        <span>{{ iitem.Name }}</span>
                                    </div>
                                    <div class="filter-search-area-dialog-body-area-content">
                                        <div class="filter-search-area-dialog-body-area-item" v-for="(iiitem, iiindex) in iitem.children" :key="iiindex" @click="areaClick(iiitem)" :class="{ select: iiitem.select }">
                                            {{ iiitem.Name }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="filter-search-area-dialog-footer">
                    <div class="filter-search-area-dialog-footer-submit" @click="areaSubmit">
                        确定
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";
import CaretBottom12 from "@/components/common/CaretBottom12.vue";
import { getScaleStyle } from "@/utils/scale";
import mpx2vw from "@/utils/px2vw";
import echartsUtil from '@/utils/echarts'
import routerUtil from "@/common/router";
import _ from 'loadsh'

let zoom = window.innerWidth / 1920

const labels = ref(0)
const option = {
    rowHeight: 267 * zoom,
    maxRowHeight: 267 * zoom,
    margins: 16 * zoom,
    lastRow: "nojustify",
    captions: true
};

const sorts = [
    {
        key: 1,
        name: "最新发布",
    },
    {
        key: 0,
        name: "最佳匹配",
    },
];

const sizes = [
    {
        key: 50,
        name: "每页50条",
    },
    {
        key: 100,
        name: "每页100条",
    },
];

const emits = defineEmits(["handleComplete", "handleLoad", "handleCategory"]);

const props = defineProps({
    filterTypes: [],
    filterAreas: [],
    filterYears: []
})

watch(() => props.filterTypes, (newVal: any, oldVal: any) => {
    state._filterTypes = newVal
})

watch(() => props.filterAreas, (newVal: any, oldVal: any) => {
    state._filterAreas = newVal
    initAreaInfoList(state._filterAreas, null);
})

watch(() => props.filterYears, (newVal: any, oldVal: any) => {
    state._filterYears = newVal
})

const state = reactive({
    id: '',
    keyword: '',
    categorys: [] as any[],
    categorysWithChildren: [] as any[],
    categorysIds: [] as any[],
    categorysWithChildrenIds: [] as any[],
    categorysChildrenIds: [] as any[],
    dataList: [] as any[],
    total: 0,
    sort: 1,
    cp: 1,
    ps: 50,

    showFilter: true,
    showFilterContent: true,
    labels: [] as any[],
    opacitys: [] as any[],
    clickLabelsIds: [] as any[],
    selectLabels: [] as any[],

    styleList: [] as any[],

    labelSelect: null as any,

    showAreaDialog: false,
    areaListOld: [] as any[],
    areaSelectListOld: [] as any,
    areaSelectList: [] as any,
    areaSelectStringListOld: [] as any[],
    areaSelectStringList: [] as any[],

    _categoryIds: '',
    _areaIds: '',
    _sYear: '',
    _eYear: '',
    _filterTypes: [],
    _filterAreas: [],
    _filterYears: [],
    filterYearSelect: [] as any[]
});

watch(() => state.showFilter, (newVal, oldVal) => {
    if (newVal) {
        setTimeout(() => {
            state.showFilterContent = true
        }, 100)
    } else {
        state.showFilterContent = false
    }
})

watch(() => state.showFilterContent, (newVal, oldVal) => {
    if (newVal && !oldVal) {
        setTimeout(() => {
            echartsUtil.initSmallEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
                let series = option.series[0]

                selectHotLabel(series.data[params.dataIndex].item)
            })
        }, 500)
    }
})

const closeAllParams = () => {
    initAreaInfoList(state._filterTypes, null)

    state.areaSelectList = []
    initAreaInfoList(state._filterAreas, null)
    state.filterYearSelect = []

    reload()
}

const getPageCount = () => {
    if (state.total % state.ps == 0) {
        return state.total / state.ps;
    } else {
        return Math.floor(state.total / state.ps) + 1;
    }
};

const scrollToTop = () => {
    window.scrollTo({ top: 0 });
}

const handleLast = () => {
    if (state.cp <= 1) {
        state.cp = 1;
        return;
    }

    state.cp -= 1;
    initImages();
    scrollToTop();
};

const handleNext = () => {
    if (state.cp >= getPageCount()) {
        state.cp = getPageCount();
        return;
    }

    state.cp += 1;
    initImages();
    scrollToTop();
};

const countChange = () => {
    if (state.cp >= getPageCount()) {
        state.cp = getPageCount();
    }

    initImages();
};

const sizeChange = () => {
    state.cp = 1;
    initImages();
};

const sortChange = () => {
    state.cp = 1;
    initImages();
};

const reload = (flag = false) => {
    state.cp = 1;
    initImages(flag);
}

const initCategoryIds = (arr: any[], categoryIds: any[]) => {
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i] as any;
        if (item.select) {
            categoryIds.push(item.StrId);
        }

        if (item.children && item.children.length > 0) {
            initCategoryIds(item.children, categoryIds);
        }
    }
};

const getTypeSelect = (list: any[], typeIds: any[]) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if(item.select) {
            typeIds.push(item.StrId)
        } else {
            getTypeSelect(item.children, typeIds)
        }
    }
}

const contentInfoPageList = (flag = false) => {
    let typeIds = [] as any[]
    getTypeSelect(state._filterTypes, typeIds)

    let areaIds = state.areaSelectList.map((item: any) => {
        return item.StrId
    })

    let year = state.filterYearSelect.map((item: any) => {
        return item.replaceAll('年', '')
    })

    let params = {
        keyword: state.keyword,
        objectType: 4,
        categoryIds: state._categoryIds,
        areaIds: state._areaIds,
        sYear: state._sYear,
        eYear: state._eYear,
        year: year.join(','),
        filterCategoryIds: typeIds.join(','),
        filterAreaIds: areaIds.join(','),
        sort: state.sort,
        cp: state.cp,
        ps: state.ps
    } as any

    state.dataList = []

    return new Promise<void>((resolve, reject) => {
        api.contentInfoSearch(
            params,
            async (res: any) => {
                state.total = res.RecordCount;
                state.dataList = res.ItemList;

                resolve();
            },
            (err: any) => {
                console.log(err);
            }
        );
    });
};

const initGallery = () => {
    setTimeout(() => {
        $("#images")
            .justifiedGallery(option)
            .on("jg.complete", (e: any) => {
                emits("handleComplete");
            });
    }, 200);
}

const initImages = async (flag = false) => {
    emits("handleLoad");
    await contentInfoPageList(flag);
    initGallery()
};

const categoryList = () => {
    api.categoryList(
        { type: 4, showOther: true },
        async (res: any) => {
            let categorys = res[0].children;
            emits('handleCategory', categorys)

            //initImages();
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const initCategory = async () => {
    categoryList()
}

const labelHotList = () => {
    api.labelHotList(
        { contentType: 4, top: 10 },
        (res: any) => {
            state.labels = res;

            state.labels.sort((a: any, b: any) => {
                return b.Cites - a.Cites;
            });

            if (!echartsUtil.getChart()) {
                echartsUtil.initSmallEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
                    let series = option.series[0]

                    selectHotLabel(series.data[params.dataIndex].item)
                })
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const initLabels = async () => {
    labelHotList()
}

const router = useRouter();

const goImage = (item: any) => {
    routerUtil.openNewWindow({
        path: `/imagedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const goMore = (item: any) => {
    routerUtil.openNewWindow({
        path: `/imagedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const showOrHideFilter = () => {
    state.showFilter = !state.showFilter
    setTimeout(() => {
        emits("handleComplete");
    }, 200);
}

const checkChange = (event: any, index: number) => {
    let item = state.categorys[index]

    if (event) {
        addSelectLabel(item)
    } else {
        removeSelectLabel(item)
    }

    reload()
}

const checkAllChange = (event: any, index: number) => {
    if (event) {
        for (let i = 0; i < state.categorysChildrenIds[index].length; i++) {
            state.categorysChildrenIds[index][i] = true
            addSelectLabel(state.categorysWithChildren[index].children[i])
        }
    } else {
        for (let i = 0; i < state.categorysChildrenIds[index].length; i++) {
            state.categorysChildrenIds[index][i] = false
            removeSelectLabel(state.categorysWithChildren[index].children[i])
        }
    }

    reload()
}

const checkAllChildrenChange = (event: any, index: number, iindex: number) => {
    if (event) {
        addSelectLabel(state.categorysWithChildren[index].children[iindex])
        let flag = true
        for (let i = 0; i < state.categorysChildrenIds[index].length; i++) {
            const item = state.categorysChildrenIds[index][i];
            if (!item) {
                flag = item
                break
            }
        }

        if (flag) {
            state.categorysWithChildrenIds[index] = true
        } else {
            state.categorysWithChildrenIds[index] = false
        }
    } else {
        removeSelectLabel(state.categorysWithChildren[index].children[iindex])
        state.categorysWithChildrenIds[index] = false
    }

    reload()
}

const addSelectLabel = (item: any, type = 'label') => {
    let has = false
    for (let i = 0; i < state.selectLabels.length; i++) {
        const element = state.selectLabels[i];
        if (item.StrId == element.id) {
            has = true
            break
        }
    }

    if (!has) {
        state.selectLabels.push({
            id: item.StrId,
            name: item.Name,
            type
        })
    }
}

const removeSelectLabel = (item: any) => {
    state.selectLabels = state.selectLabels.filter((iitem) => {
        return item.StrId != iitem.id
    })
}

const addClickLabel = (item: any) => {
    addSelectLabel(item)
    reload()
}

const closeAllLabel = () => {
    for (let i = state.selectLabels.length - 1; i >= 0; i--) {
        const item = state.selectLabels[i];
        closeLabel(item)
    }

    state.labelSelect = null
    reload()

    echartsUtil.getChart().clear()
    echartsUtil.initSmallEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
        let series = option.series[0]

        selectHotLabel(series.data[params.dataIndex].item)
    })
}

const closeLabel = (item: any, flag = false) => {
    state.selectLabels = state.selectLabels.filter((iitem, iindex) => {
        return item.id != iitem.id
    })

    for (let i = 0; i < state.categorys.length; i++) {
        const iitem = state.categorys[i];
        if (item.id == iitem.StrId) {
            state.categorysIds[i] = false
            checkChange(false, i)
        }
    }

    for (let i = 0; i < state.categorysWithChildren.length; i++) {
        const iitem = state.categorysWithChildren[i];
        if (item.id == iitem.StrId) {
            state.categorysWithChildrenIds[i] = false
            checkAllChange(false, i)
        }
    }

    for (let i = 0; i < state.categorysWithChildren.length; i++) {
        const iitem = state.categorysWithChildren[i];

        for (let j = 0; j < iitem.children.length; j++) {
            if (item.id == iitem.children[j].StrId) {
                state.categorysWithChildrenIds[i] = false
                state.categorysChildrenIds[i][j] = false
                checkAllChildrenChange(false, i, j)
            }
        }
    }

    if (flag) {
        reload()
    }
}

const selectHotLabel = (item: any) => {
    addSelectLabel(item, 'hot')
    state.labelSelect = item
    reload()
}

const computeStyle = (index: number) => {
    let style = JSON.parse(JSON.stringify(state.styleList[index]))
    if (state.labelSelect && state.labelSelect.StrId == state.labels[index].StrId) {
        style.color = style['background-color']
        style['background-color'] = ['#FFFFFF']
    }

    console.log(style)
    return style
}

const getStyle = (index: number) => {
    const colors = [
        '#FE5621',
        '#129CBA',
        '#954A8E',
        '#409EFF',
        '#67C23A',
        '#909399'
    ]

    const random = Math.floor(Math.random() * colors.length);
    const width = 80 + Math.floor(Math.random() * 20)
    const right = Math.floor(Math.random() * 10);
    const left = Math.floor(Math.random() * 10);
    const top = Math.floor(Math.random() * -10);
    const bottom = Math.floor(20 + Math.random() * 20);

    return {
        color: "#FFFFFF",
        "background-color": colors[random],
        "box-shadow": `0px 0px 8px 0px ${colors[random]}`,
        "margin-left": `${mpx2vw(left)}`,
        "margin-right": `${mpx2vw(right)}`,
        "margin-top": `${mpx2vw(top)}`,
        "margin-bottom": `${mpx2vw(bottom)}`,
        "width": `${mpx2vw(width)}`,
    };
};

const initStyles = () => {
    state.styleList = [] as any[]
    for (let i = 0; i < state.labels.length; i++) {
        state.styleList.push(getStyle(i))
    }
}


const initAreaInfoList = (list: any[], parent: any) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        item.select = false
        item.parent = parent
        if(item.children && item.children.length > 0) {
            initAreaInfoList(item.children, item)
        }
    }
}

const showArea = () => {
    state.showAreaDialog = true
    state.areaListOld = _.cloneDeep(state._filterAreas)
    state.areaSelectListOld = _.cloneDeep(state.areaSelectList)
    state.areaSelectStringListOld = _.cloneDeep(state.areaSelectStringList)
}

const getAreaSelect = (list: any[]) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if(item.select) {
            state.areaSelectList.push(item)
            state.areaSelectStringList.push(item.Name)
        }

        getAreaSelect(item.children)
    }
}

const getAreaSelectList = () => {
    state.areaSelectList = []
    state.areaSelectStringList = []
    getAreaSelect(state._filterAreas)
}

const areaChildrenCheck = (item: any, flag: boolean) => {
    if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
            const element = item.children[i];
            element.select = flag
            areaChildrenCheck(element, flag)
        }
    }
}

const areaParentCheck = (item: any) => {
    if(item.parent && item.parent.children && item.parent.children.length > 0) {
        let flag = true
        for (let i = 0; i < item.parent.children.length; i++) {
            const element = item.parent.children[i];

            if(!element.select) {
                flag = false
                break
            }
        }

        item.parent.select = flag
        areaParentCheck(item.parent)
    }
}

const areaClick = (item: any) => {
    item.select = !item.select

    getAreaSelectList()
}

const areaCancel = () => {
    state.showAreaDialog = false
    state._filterAreas = state.areaListOld
    state.areaSelectList = state.areaSelectListOld
    state.areaSelectStringList = state.areaSelectStringListOld
}

const clearArea = () => {
    for (let i = 0; i < state._filterAreas.length; i++) {
        const item = state._filterAreas[i] as any;
        item.select = false
        areaChildrenCheck(item, false)
    }

    getAreaSelectList()
}

const areaSubmit = () => {
    state.showAreaDialog = false
    reload()
}

const selectAllRadio = (trees: any[], flag: boolean) => {
    trees.forEach((item) => {
        item.select = flag;

        if (item.children.length > 0) {
            selectAllRadio(item.children, flag);
        }
    });
};

const handleSelectItem = (item: any) => {
    state.cp = 1;

    item.select = !item.select;
    selectAllRadio(item.children, item.select);

    reload();
};

const handleSelectChildItem = (item: any, parent: any, pparent = null as any, ppparent = null as any, pppparent = null as any) => {
    state.cp = 1;

    item.select = !item.select;
    selectAllRadio(item.children, item.select);

    let allSelect = true;
    for (let i = 0; i < parent.children.length; i++) {
        const iitem = parent.children[i];
        if (!iitem.select) {
            allSelect = false;
            break;
        }
    }

    if (allSelect) {
        parent.select = true;
    } else {
        parent.select = false;
    }

    if(pparent) {
        allSelect = true;
        for (let i = 0; i < pparent.children.length; i++) {
            const iitem = pparent.children[i];
            if (!iitem.select) {
                allSelect = false;
                break;
            }
        }

        if (allSelect) {
            pparent.select = true;
        } else {
            pparent.select = false;
        }
    }

    if(ppparent) {
        allSelect = true;
        for (let i = 0; i < ppparent.children.length; i++) {
            const iitem = ppparent.children[i];
            if (!iitem.select) {
                allSelect = false;
                break;
            }
        }

        if (allSelect) {
            ppparent.select = true;
        } else {
            ppparent.select = false;
        }
    }
    
    if(pppparent) {
        allSelect = true;
        for (let i = 0; i < pppparent.children.length; i++) {
            const iitem = pppparent.children[i];
            if (!iitem.select) {
                allSelect = false;
                break;
            }
        }

        if (allSelect) {
            pppparent.select = true;
        } else {
            pppparent.select = false;
        }
    }

    reload();
    emits("handleComplete")
};

const handleSearch = (keyword: string, categoryIds: string, areaIds: string, sYear: string, eYear: string) => {
    state.keyword = keyword
    state._categoryIds = categoryIds
    state._areaIds = areaIds
    state._sYear = sYear
    state._eYear = eYear

    state._filterTypes = []
    state._filterAreas = []
    state.areaSelectList = []
    state.areaSelectStringList = []
    state.filterYearSelect = []

    reload(true)
}

defineExpose({
    handleSearch
})

const route = useRoute()
onMounted(() => {
    state.id = route.query.id ? route.query.id : ''
    state.keyword = route.query.keyword ? route.query.keyword : ''

    if (state.id) {
        state.showFilter = true
    }

    initCategory();
});
</script>

<style lang="scss" scoped>
.image-more-images {
    width: 1500px;
    min-height: 320px;

    .title {
        box-sizing: border-box;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 2px solid #f2e6ce;
        border-bottom: 2px solid #f2e6ce;
        background-color: #ffffff;

        img {
            margin-left: 30px;
            margin-right: 8px;
            width: 38px;
            height: 28px;
        }

        .tname {
            font-size: 28px;
            color: #2b333b;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .header {
        height: 64px;
        line-height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        box-sizing: border-box;
        padding-left: 24px;
        padding-right: 24px;

        .left-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .scroll-bar {
                width: 860px;
                margin-left: 16px;
            }

            .scroll-labels {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                flex-shrink: 0;

                .scroll-label {
                    margin-left: 8px;
                    height: 32px;
                    line-height: 32px;
                    border: 1px solid #DADADA;
                    font-size: 18px;
                    color: #787878;
                    padding: 0px 8px;
                    white-space: nowrap;

                    &:first-child {
                        margin-left: 0px;
                    }

                    img {
                        width: 12px;
                        height: 12px;
                        margin-left: 4px;
                        cursor: pointer;
                    }
                }
            }

            .l {
                height: 100%;
                font-size: 20px;
                color: #2b333b;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 8px;
                }

                span {
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }

        .r {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .dropdown {
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .data-select {
                    box-sizing: border-box;
                    width: 160px;
                    height: 34px;
                    margin-left: 16px;
                    border-radius: 0px;
                    border: 1px solid #dadada;

                    :deep(.el-popper) {
                        left: 0 !important;
                    }

                    :deep(.el-select__wrapper) {
                        box-sizing: border-box;
                        width: 100%;
                        height: 30px;
                        padding: 0px 9px 0px 9px;
                        color: #787878;
                        border: #dadada;
                        box-shadow: none;
                        font-size: 18px;
                        height: 30px;
                        min-height: 30px;
                        line-height: 100%;

                        &.is-focused {
                            box-shadow: none;
                        }
                    }

                    &.d1 {
                        width: 128px;
                    }

                    &.d2 {
                        width: 128px;

                        :deep(.el-select__wrapper) {
                            padding: 0px 7px 0px 7px;
                        }
                    }

                    :deep(.el-select-dropdown__list) {
                        background-color: #ffffff;
                        padding: 10px 4px;
                    }

                    :deep(.el-select__wrapper) {
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        padding: 0px 9px 0px 9px;
                        color: #787878;
                        text-align: center;
                    }

                    :deep(.el-select-dropdown__item) {
                        font-size: 18px;
                        padding: 0;
                        height: 44px;
                        line-height: 44px;

                        &.is-selected {
                            background-color: #5a8ade;
                            color: #ffffff;
                        }
                    }

                    :deep(.el-select__selected-item) {
                        font-size: 18px;
                        background-color: #ffffff;

                        &.is-hovering {
                            background-color: #ffffff;
                        }
                    }
                }

                .image-more-drop-d1 {
                    left: 0;
                }

                .image-more-drop-d2 {
                    left: 0;
                }
            }

            .pager {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 24px;

                .btn {
                    width: 24px;
                    height: 32px;
                    line-height: 36px;
                    color: #b7bbbf;
                    font-size: 14px;
                    box-sizing: border-box;
                    border: 1px solid #b7bbbf !important;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.disabled {
                        color: #b7bbbf;
                        border: 1px solid #b7bbbf;
                    }

                    :deep(.el-icon) {
                        font-size: 18px;
                        font-weight: 800;
                    }
                }

                .count {
                    font-size: 16px;
                    color: #2b333b;
                    margin-left: 16px;
                    margin-right: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .number {
                        display: inline-flex;
                        width: 32px;
                        height: 32px;
                        border-radius: 4px;
                        font-size: 14px;
                        text-align: center;
                        margin-right: 8px;

                        :deep(.el-input__wrapper) {
                            padding: 0;
                        }

                        :deep(.el-input__wrapper.is-focus) {
                            box-shadow: 0 0 0 1px #dadada inset !important;
                        }

                        :deep(.el-input__inner) {
                            text-align: center;
                            width: 32px;
                            height: 32px;
                            line-height: 32px;
                            font-size: 16px;
                            color: #2b333b;
                            box-sizing: border-box;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }

    .content {
        width: 1500px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left {
            width: 0;
            overflow: hidden;
            height: auto;
            background-color: #EEEEEE;
            transition: all 0.2s;
            box-sizing: border-box;
            padding: 0;

            &.show-filter {
                width: 240px;
                opacity: 1;
                padding: 22px 16px 16px 16px;
            }

            .left-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left-title {
                    color: #2B333B;
                    font-size: 16px;
                    font-weight: 600;
                }

                .left-btn {
                    cursor: pointer;
                    width: 95px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    border-radius: 2px;
                    background-color: #F2E6CE;
                }
            }

            .labels {
                position: relative;
                width: 100%;
                margin-top: 24px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .labels-content {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    height: 380px;
                    z-index: 99;
                }

                // .litem {
                //     height: 24px;
                //     line-height: 24px;
                //     padding-left: 16px;
                //     padding-right: 16px;
                // }

                // .label {
                //     box-sizing: border-box;
                //     width: 90px;
                //     height: 24px;
                //     line-height: 24px;
                //     border-bottom-left-radius: 25px;
                //     border-top-right-radius: 25px;
                //     border-bottom-right-radius: 25px;
                //     cursor: pointer;
                //     transition: all 0.2s;
                //     transform: scale(1);

                //     &:hover {
                //         transform: scale(1.04);
                //     }

                //     span {
                //         display: inline-block;
                //         width: 100%;
                //         height: 24px;
                //         line-height: 24px;
                //         padding: 0 10px;
                //         box-sizing: border-box;
                //         font-size: 14px;
                //     }
                // }

                .divider {
                    width: 100%;
                    border-bottom: 1px solid #D8D8D8;
                    margin-top: 400px;
                    margin-bottom: 16px;
                }

                .btns {

                    .btn {
                        height: 28px;
                        line-height: 28px;
                        border-radius: 14px;
                        background-color: #F2E6CE;
                        color: #2B333B;
                        font-size: 14px;
                        padding-left: 8px;
                        padding-right: 8px;
                        cursor: pointer;
                    }
                }
            }

            .left-category {
                :deep(.el-checkbox-group) {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    padding-left: 0px;
                }

                :deep(.el-checkbox) {
                    margin-right: 0px;
                    margin-top: 16px;
                    height: 16px;
                    line-height: 16px;
                    width: 96px;
                    box-sizing: border-box;
                }

                :deep(.el-checkbox:nth-child(2n)) {
                    margin-right: 0px;
                    text-align: right;
                }

                :deep(.el-checkbox__inner) {
                    width: 18px;
                    height: 18px;
                    border: 1px solid #B4B4B4;
                    border-radius: 4px !important;
                }

                :deep(.el-checkbox__label) {
                    font-size: 16px;
                    color: #3C3C3C;
                }

                :deep(.el-checkbox__input .el-checkbox__inner) {
                    border-color: #B4B4B4;
                }

                :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
                    background-color: #6E8B74;
                }

                :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
                    left: 6px;
                    top: 2px;
                    border-color: #FFFFFF;
                    font-size: 16px;
                    width: 4px;
                    height: 8px;
                }

                .divider {
                    width: 100%;
                    border-bottom: 1px solid #D8D8D8;
                    margin-top: 16px;
                    margin-bottom: 16px;
                }

                .child {
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                    color: #2b333b;
                    padding-left: 22px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;

                    .xiajiantou {
                        transform: rotate(90deg);
                        transform-origin: 50% 50%;
                    }

                    &.all {
                        padding-left: 16px;
                    }

                    .r-out {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                        border: 2px solid #B4B4B4;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #FFFFFF;

                        .r-inner {
                            width: 6px;
                            height: 6px;
                            border-radius: 2px;
                        }
                    }

                    &.select {
                        .r-out {
                            border: 2px solid rgb(110, 139, 116);

                            .r-inner {
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    .el-icon {
                        color: #2b333b;
                        margin-left: -24px;
                        margin-right: 8px;
                    }
                }

                .child-l2 {
                    .child {
                        padding-left: 42px;

                        .name {
                            max-width: 202px;
                        }
                    }
                }

                .child-l3 {
                    .child {
                        padding-left: 82px;

                        .name {
                            max-width: 182px;
                        }
                    }
                }

                .child-l4 {
                    .child {
                        padding-left: 122px;

                        .name {
                            max-width: 162px;
                        }
                    }
                }

                .child-l5 {
                    .child {
                        padding-left: 162px;

                        .name {
                            max-width: 142px;
                        }
                    }
                }

                .select-area {
                    font-size: 16px;
                    color: rgb(215, 186, 125);
                    border: 1px solid rgb(215, 186, 125);
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    line-height: 32px;
                    width: 140px;
                    margin-top: 20px;
                    margin-left: 16px;
                    cursor: pointer;
                }
            }

            .left-category-children {
                :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
                    background-color: #FFFFFF;
                }

                :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
                    left: 3px;
                    top: 3px;
                    border-color: #FFFFFF;
                    border: none;
                    font-size: 16px;
                    width: 8px;
                    height: 8px;
                    background-color: #6E8B74;
                    transform: rotate(0deg);
                }
            }
        }

        .right {
            width: 1500px;
            min-height: calc(100vh - 240px);

            &.show-filter {
                width: 1250px;
            }

            .images {
                width: calc(100% + 28px);
                margin-left: -14px;
                margin-top: -16px;
                border-top: 8px solid transparent;
                margin-bottom: 4px;
                opacity: 1;
                animation: show 2s;

                .jg-caption {
                    font-size: 20px;
                    padding-left: 40px;
                    padding-right: 40px;
                }

                .item-empty {
                    width: 100% !important;
                    height: 267px;
                    line-height: 267px;
                    font-size: 20px;
                    color: #2b333b;
                    text-align: center;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 24px !important;
                        height: 24px !important;
                        margin-right: 8px;
                        margin-top: 0 !important;
                        margin-left: 0 !important;
                        position: relative !important;
                        top: 0 !important;
                        left: 0 !important;
                    }
                }

                a {
                    border-radius: 8px;
                    cursor: pointer;

                    img {
                        object-fit: cover;
                    }

                    &.more {
                        &::after {
                            content: "查看更多 >";
                            display: flex;
                            position: absolute;
                            z-index: 99;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            background-color: rgba(0, 0, 0, 0.5);
                            color: #ffffff;
                            font-size: 32px;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .next-page {
            display: flex;
            justify-content: center;
            margin-top: 32px;

            .next-btn {
                width: 120px;
                height: 40px;
                line-height: 40px;
                border-radius: 20px;
                background-color: #F2E6CE;
                color: #2B333B;
                font-size: 20px;
                cursor: pointer;
                margin-left: 24px;
                margin-right: 24px;
            }
        }
    }
}
</style>

<style>
.filter-search-area-dialog {
    width: 1404px;
    transform-origin: center !important;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 10rem;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 100%;
        padding: 0;

        .filter-search-area-dialog-content {
            width: 100%;
            padding-top: 72px;
            box-sizing: border-box;
            position: relative;

            .filter-search-area-dialog-close {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 32px;
                top: 24px;
                cursor: pointer;
            }

            .filter-search-area-dialog-header {
                margin-left: 32px;
                margin-right: 32px;
                border-radius: 8px 8px 0px 0px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 24px;
                color: #2b333b;
                border-bottom: 1px solid #d9dde1;
                padding-bottom: 12px;
                font-weight: 500;

                .filter-search-area-dialog-header-left {
                    font-size: 24px;
                    line-height: 48px;
                    font-weight: 500;
                    letter-spacing: 2px;
                    width: 160px;
                }

                .filter-search-area-dialog-header-right {
                    font-size: 22px;
                    line-height: 48px;
                    max-height: 96px;
                    overflow-y: auto;
                    width: calc(100% - 160px);

                    &::-webkit-scrollbar {
                        width: 4px; /* 滚动条宽度 */
                        background-color: #f9f9f9; /* 滚动条背景色 */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px; /* 滚动条圆角 */
                        background-color: #c1c1c1; /* 滚动条颜色 */
                        background-clip: padding-box; /* 背景剪切 */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background-color: #f9f9f9; /* 滚动条轨道颜色 */
                    }
                }
            }

            .filter-search-area-dialog-body {
                font-size: 20px;
                line-height: 48px;
                color: #2b333b;
                text-align: left;
                letter-spacing: 1px;
                margin-top: 0px;
                margin-bottom: 40px;
                margin-left: 32px;
                margin-right: 32px;

                .filter-search-area-dialog-body-scroll {
                    max-height: calc(60vh - 160px);
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 4px; /* 滚动条宽度 */
                        background-color: #f9f9f9; /* 滚动条背景色 */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 4px; /* 滚动条圆角 */
                        background-color: #c1c1c1; /* 滚动条颜色 */
                        background-clip: padding-box; /* 背景剪切 */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background-color: #f9f9f9; /* 滚动条轨道颜色 */
                    }
                }

                .filter-search-area-dialog-body-area {
                    padding-left: 18px;

                    .filter-search-area-dialog-body-area-title {
                        margin-right: 32px;
                        cursor: pointer;
                        display: block;

                        span {
                            height: 34px;
                            line-height: 34px;
                            padding: 0 8px 0px 8px;
                            border-radius: 4px;
                        } 

                        &.select {
                            span {
                                color: #FFFFFF;
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    .filter-search-area-dialog-body-area-content {
                        margin-left: 12px;
                        line-height: 32px;
                        padding-bottom: 0px;
                    }

                    .filter-search-area-dialog-body-area-item {
                        margin-right: 32px;
                        cursor: pointer;
                        display: inline-block;
                        height: 32px;
                        line-height: 32px;
                        padding: 0 8px 0px 8px;
                        border-radius: 4px;

                        &.select {
                            color: #FFFFFF;
                            background-color: rgb(110, 139, 116);
                        }
                    }

                    &.l1 {
                        font-size: 24px;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        
                        span {
                            cursor: pointer;
                            display: inline-block;
                            height: 36px;
                            line-height: 36px;
                            padding: 0 8px 2px 8px;
                            border-radius: 4px;

                            &.select {
                                color: #FFFFFF;
                                background-color: rgb(110, 139, 116);
                            }
                        }
                    }

                    &.l2 {
                        font-size: 22px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }

                    
                    &.l3 {
                        font-size: 20px;
                        display: block;
                        line-height: 72px;
                        border-bottom: 1px solid #d9dde1;
                        font-weight: 500;

                        .filter-search-area-dialog-body-area-title {
                            font-size: 20px;
                        }

                        .filter-search-area-dialog-body-area-item {
                            font-size: 18px;
                            line-height: 32px;
                            margin-bottom: 12px;
                        }
                    }
                }
            }

            .filter-search-area-dialog-footer {
                display: flex;
                justify-content: center;
                padding-bottom: 16px;

                .filter-search-area-dialog-footer-submit {
                    color: #FFFFFF;
                    font-size: 20px;
                    padding: 8px 36px;
                    border-radius: 8px;
                    background-color: rgb(110, 139, 116);
                    display: inline-block;
                    margin: 0 auto;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>