<template>
    <div class="audio-category">
        <div class="item" v-for="(item, index) in state.categoryFirst" :key="index" :id="`item-${ state.category[0].StrId }`">
            <div class="header">
                <div class="box">
                    <div class="han">{{ item.Name }}</div>
                    <div class="meng mFont">
                        <span
                            class="meng-vertical"
                            v-for="(iitem, iindex) in getMWords(item.MName)"
                            :key="iindex"
                            >{{ iitem }}</span
                        >
                    </div>
                </div>
                

                <div class="more" @click="goMore(state.category[0])">
                    <span>更多</span>
                    <img src="@/assets/images/home/gengduo.png" />
                </div>
            </div>
            <div class="body">
                <swiper
                    :modules="modules"
                    :loop="false"
                    :slides-per-view="4"
                    :space-between="0"
                    :navigation="navigation"
                    class="box"
                >
                    <div class="swiper-button-prev">
                        <img src="@/assets/images/common/zuojiantou.png" />
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/images/common/youjiantou.png" />
                    </div>
                    <swiper-slide
                        v-for="(iitem, iindex) in state.dataListFirst[index]"
                        :key="iindex"
                        class="slider-item"
                        @click="goDetail(iitem)"
                    >
                        <img v-if="iitem.Cover" class="item" v-lazy="iitem.Cover" />
                        <img v-else class="item" src="@/assets/images/audio/yinpin.png" />
                        <div class="title one-line">{{ iitem.Title }}</div>
                        <div
                            class="title t2 one-line"
                            v-if="iitem.BelongDataName"
                        >
                            《{{ iitem.BelongDataName }}》
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <div class="item2" v-if="state.categoryCenter.length > 0" :id="`item-${ state.category[1].StrId }`">
            <div class="header">
                <div class="box">
                    <div class="han">{{ state.category[1].Name }}</div>
                    <div class="meng mFont">
                        <span
                            class="meng-vertical"
                            v-for="(item, index) in getMWords(state.category[1].MName)"
                            :key="index"
                            >{{ item }}</span
                        >
                    </div>
                </div>

                <div class="more" @click="goMore(state.category[1])">
                    <span>更多</span>
                    <img src="@/assets/images/home/gengduo.png" />
                </div>
            </div>
            <div class="body">
                <div
                    class="outer"
                    v-for="(item, index) in state.categoryCenter"
                    :key="index"
                >
                    <div class="ctype" @click="goMore(item)">
                        <img v-if="item.Cover" class="cover" v-lazy="item.Cover" />
                        <img v-else class="cover" src="@/assets/images/audio/yinpin.png" />
                        <img class="back" src="@/assets/images/audio/guangdie.png" />
                        <div class="title one-line">
                            <span>{{ item.Name }}</span>
                        </div>
                    </div>
                    <div class="swiper-box">
                        <swiper
                            :modules="modules"
                            :loop="false"
                            :slides-per-view="3"
                            :space-between="36"
                            :navigation="navigation"
                            class="box"
                        >
                            <div class="swiper-button-prev">
                                <img src="@/assets/images/common/zuojiantou.png" />
                            </div>
                            <div class="swiper-button-next">
                                <img src="@/assets/images/common/youjiantou.png" />
                            </div>
                            <swiper-slide
                                v-for="(iitem, iindex) in state.dataListCenter[index]"
                                :key="iindex"
                                class="slider-item"
                                @click="goDetail(iitem)"
                            >
                                <img v-if="iitem.Cover" class="item" v-lazy="iitem.Cover" />
                                <img v-else class="item" src="@/assets/images/audio/yinpin.png" />
                                <div class="title one-line">
                                    <span>{{ iitem.Title }}</span>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>

        <div class="item3" v-for="(item, index) in state.categoryOther" :key="index" :id="`item-${ state.category[2].StrId }`">
            <div class="header">
                <div class="box">
                    <div class="han">{{ item.Name }}</div>
                    <div class="meng mFont">
                        <span
                            class="meng-vertical"
                            v-for="(iitem, iindex) in getMWords(item.MName)"
                            :key="iindex"
                            >{{ iitem }}</span
                        >
                    </div>
                </div>

                <div class="more" @click="goMore(state.category[2])">
                    <span>更多</span>
                    <img src="@/assets/images/home/gengduo.png" />
                </div>
            </div>
            <div class="body">
                <swiper
                    :modules="modules"
                    :loop="false"
                    :slides-per-view="4"
                    :space-between="24"
                    :navigation="navigation"
                    class="box"
                >
                    <div class="swiper-button-prev">
                        <img src="@/assets/images/common/zuojiantou.png" />
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/images/common/youjiantou.png" />
                    </div>
                    <swiper-slide
                        v-for="(iitem, iindex) in state.dataListOther[index]"
                        :key="iindex"
                        class="slider-item"
                        @click="goDetail(iitem)"
                    >
                        <div class="outer-box">
                            <img v-if="iitem.Cover" class="item" v-lazy="iitem.Cover" />
                            <img v-else class="item" src="@/assets/images/audio/yinpin.png" />
                        </div>
                        <div class="title one-line">{{ iitem.Title }}</div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const route = useRoute()
const emits = defineEmits(["handleComplete"]);

const state = reactive({
    category: [] as any[],
    categoryFirst: [] as any[],
    categoryCenter: [] as any[],
    categoryOther: [] as any[],
    dataListFirst: [] as any[],
    dataListCenter: [] as any[],
    dataListOther: [] as any[],
    id: ''
});

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const categoryList = () => {
    api.categoryList(
        { type: 3 },
        async (res: any) => {
            state.dataListFirst = [];
            state.dataListCenter = [];
            state.dataListOther = [];

            state.category = res[0].children;
            state.categoryFirst = [state.category[0]];
            state.categoryCenter = state.category[1].children;
            state.categoryOther = [state.category[2]];

            for (let i = 0; i < state.categoryFirst.length; i++) {
                const item = state.categoryFirst[i] as any;
                state.dataListFirst.push(await contentInfoNewList(item.StrId as string));
            }

            for (let i = 0; i < state.categoryCenter.length; i++) {
                const item = state.categoryCenter[i] as any;
                state.dataListCenter.push(await contentInfoNewList(item.StrId as string));
            }

            for (let i = 0; i < state.categoryOther.length; i++) {
                const item = state.categoryOther[i] as any;
                state.dataListOther.push(await contentInfoNewList(item.StrId as string));
            }

            emits("handleComplete");

            if(state.id) {
                setTimeout(() => {
                    document.getElementById(`item-${ state.id }`)?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                }, 100)
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoNewList = (categoryId: string) => {
    return new Promise((resolve, reject) => {
        api.contentInfoNewList(
            { objectType: 3, categoryId, getBelong: true },
            (res: any) => {
                resolve(res);
            },
            (err: any) => {
                console.log(err);
            }
        );
    });
};

const modules = [Navigation];
const navigation = ref({
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
});

const router = useRouter();
const goDetail = (item: any) => {
    routerUtil.openNewWindow({
        path: `/audiodetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const goMore = (item: any) => {
    routerUtil.openNewWindow({
        path: `/search`,
        query: {
            id: item.StrId,
            index: 5
        },
    });
};

onMounted(() => {
    state.id = route.query.id
    categoryList();
});
</script>

<style lang="scss" scoped>
.audio-category {
    margin: 0 auto;
    width: 1500px;
    border-radius: 4px;

    .item {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);

        .header {
            position: relative;
            width: 100%;
            height: 96px;
            line-height: 96px;
            padding-left: 32px;
            box-sizing: border-box;
            border-bottom: 1px solid #d9dde1;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .box {
                display: inline-block;
                border-bottom: 1px solid #2b333b;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
            }

            .han {
                display: inline-block;
                height: 96px;
                line-height: 96px;
                margin-right: 16px;
                font-size: 28px;
                color: #2b333b;
            }

            .meng {
                box-sizing: border-box;
                padding-top: 8px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                color: #787878;
                font-size: 20px;

                span {
                    line-height: 28px;
                }
            }

            .more {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 32px;
                right: 42px;
                top: 32px;
                border-radius: 16px;
                background-color: #f2e6ce;
                font-size: 16px;
                color: #000000;
                cursor: pointer;

                transition: all 0.2s;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }

                img {
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .body {
            box-sizing: border-box;
            width: 100%;
            height: 636px;
            position: relative;

            .outer {
                width: 100%;
                padding-top: 40px;
            }

            .box {
                position: absolute;
                height: 436px;
                z-index: 100;
                left: 38px;
                right: 38px;
                top: 68px;

                .slider-item {
                    width: 356px !important;
                    height: 378px;
                    margin-right: 0px !important;
                    padding: 12px;
                    box-sizing: border-box;
                    position: relative;
                    cursor: pointer;

                    .item {
                        width: 332px;
                        height: 332px;
                        border-radius: 64px;
                        margin-bottom: 0;
                        object-fit: cover;

                        img {
                            object-fit: cover;
                            width: 332px;
                            height: 332px;
                        }
                    }

                    .title {
                        margin-top: 12px;
                        width: 332px;
                        height: 40px;
                        line-height: 40px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        font-size: 22px;
                        color: #2b333b;
                        z-index: 999;
                        bottom: 0px;
                        text-align: center;

                        &.t2 {
                            margin-top: 0;
                        }
                    }
                }

                .swiper-button-prev,
                .swiper-button-next {
                    width: 48px;
                    height: 48px;
                    top: 174px;

                    &::after {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .swiper-button-prev {
                    margin-left: -8px;
                }

                .swiper-button-next {
                    margin-right: -8px;
                }
            }

            .banner {
                position: absolute;
                z-index: 99;
                left: 12px;
                right: 12px;
                bottom: 80px;
                height: 64px;
                line-height: 64px;
                background: linear-gradient(#e3d8c9, #f3ebe0, #e3d8c9);
                border-radius: 8px;
                box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

                &.num4 {
                    right: 12px;
                }

                &.num3 {
                    right: calc(12px + 356px);
                }

                &.num2 {
                    right: calc(12px + 356px * 2);
                }

                &.num1 {
                    right: calc(12px + 356px * 3);
                }

                &.num0 {
                    display: none;
                }
            }
        }
    }

    .item2 {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

        .header {
            position: relative;
            width: 100%;
            height: 96px;
            line-height: 96px;
            padding-left: 32px;
            box-sizing: border-box;
            border-bottom: 1px solid #d9dde1;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .box {
                display: inline-block;
                border-bottom: 1px solid #2b333b;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
            }

            .han {
                display: inline-block;
                height: 96px;
                line-height: 96px;
                margin-right: 16px;
                font-size: 28px;
                color: #2b333b;
            }

            .meng {
                box-sizing: border-box;
                padding-top: 8px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                color: #787878;
                font-size: 20px;

                span {
                    line-height: 28px;
                }
            }

            .more {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 32px;
                right: 42px;
                top: 32px;
                border-radius: 16px;
                background-color: #f2e6ce;
                font-size: 16px;
                color: #000000;
                cursor: pointer;

                transition: all 0.2s;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }

                img {
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .body {
            box-sizing: border-box;
            width: 100%;
            position: relative;
            padding-top: 40px;
            padding-bottom: 16px;

            .outer {
                width: 100%;
                background-color: #f4faff;
                margin-bottom: 24px;
                display: flex;
                align-items: center;

                .ctype {
                    display: inline-block;
                    width: 448px;
                    height: 414px;
                    margin-right: 16px;
                    position: relative;
                    cursor: pointer;
                    object-fit: cover;

                    .cover {
                        position: absolute;
                        left: 50px;
                        top: 60px;
                        width: 290px;
                        height: 290px;
                        z-index: 9;
                        border-radius: 48px;
                        background: #000000;
                        object-fit: cover;
                    }

                    .back {
                        position: absolute;
                        left: 268px;
                        top: 79px;
                        width: 180px;
                        height: 254px;
                        z-index: 8;
                    }

                    .title {
                        position: absolute;
                        left: 50px;
                        top: 311px;
                        width: 290px;
                        height: 24px;
                        line-height: 24px;
                        z-index: 10;
                        text-align: center;

                        span {
                            display: inline-block;
                            height: 24px;
                            line-height: 24px;
                            padding: 0 36px;
                            background-color: #6e8b74;
                            border-radius: 12px;
                            color: #ffffff;
                        }
                    }
                }

                .swiper-box {
                    box-sizing: border-box;
                    width: 986px;
                    height: 364px;
                    display: inline-block;
                    background: #e7f2fb;
                    border-radius: 20px 8px 8px 20px;
                    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.24);

                    .box {
                        display: block;
                        position: relative;
                        height: 364px;
                        z-index: 100;
                        margin-left: 40px;
                        margin-right: 34px;

                        .slider-item {
                            width: 280px !important;
                            padding-top: 20px;
                            margin-right: 36px !important;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            cursor: pointer;

                            .item {
                                width: 280px;
                                height: 280px;
                                border-radius: 40px;
                                margin-bottom: 0;
                                object-fit: cover;
                                box-shadow: none;

                                img {
                                    object-fit: cover;
                                    width: 280px;
                                    height: 280px;
                                }
                            }

                            .title {
                                margin-top: 24px;
                                width: 280px;
                                height: 20px;
                                line-height: 20px;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                font-size: 20px;
                                color: #2b333b;
                                z-index: 999;
                                bottom: 0px;
                                text-align: center;

                                &.t2 {
                                    margin-top: 0;
                                }
                            }
                        }

                        .swiper-button-prev,
                        .swiper-button-next {
                            width: 40px;
                            height: 40px;
                            top: 160px;

                            &::after {
                                display: none;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .swiper-button-prev {
                            margin-left: -8px;
                        }

                        .swiper-button-next {
                            margin-right: -8px;
                        }
                    }
                }

                .banner {
                    position: absolute;
                    z-index: 99;
                    left: 12px;
                    right: 12px;
                    bottom: 80px;
                    height: 64px;
                    line-height: 64px;
                    background: linear-gradient(#e3d8c9, #f3ebe0, #e3d8c9);
                    border-radius: 8px;
                    box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

                    &.num4 {
                        right: 12px;
                    }

                    &.num3 {
                        right: calc(12px + 356px);
                    }

                    &.num2 {
                        right: calc(12px + 356px * 2);
                    }

                    &.num1 {
                        right: calc(12px + 356px * 3);
                    }

                    &.num0 {
                        display: none;
                    }
                }
            }
        }
    }

    .item3 {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

        .header {
            position: relative;
            width: 100%;
            height: 96px;
            line-height: 96px;
            padding-left: 32px;
            box-sizing: border-box;
            border-bottom: 1px solid #d9dde1;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .box {
                display: inline-block;
                border-bottom: 1px solid #2b333b;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
            }

            .han {
                display: inline-block;
                height: 96px;
                line-height: 96px;
                margin-right: 16px;
                font-size: 28px;
                color: #2b333b;
            }

            .meng {
                box-sizing: border-box;
                padding-top: 8px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                color: #787878;
                font-size: 20px;

                span {
                    line-height: 28px;
                }
            }

            .more {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 32px;
                right: 42px;
                top: 32px;
                border-radius: 16px;
                background-color: #f2e6ce;
                font-size: 16px;
                color: #000000;
                cursor: pointer;

                transition: all 0.2s;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }

                img {
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .body {
            box-sizing: border-box;
            width: 100%;
            height: 546px;
            position: relative;

            .outer {
                width: 100%;
                padding-top: 40px;
            }

            .box {
                position: absolute;
                height: 436px;
                z-index: 100;
                left: 50px;
                right: 50px;
                top: 80px;

                .slider-item {
                    width: 332px !important;
                    height: 378px;
                    margin-right: 24px !important;
                    position: relative;
                    cursor: pointer;

                    .outer-box {
                        width: 332px;
                        height: 332px;
                        border-radius: 64px;
                        background-image: url("@/assets/images/audio/qita.png");
                        background-size: 100% 100%;
                        position: relative;

                        .item {
                            position: absolute;
                            z-index: 9;
                            left: 66px;
                            top: 66px;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            object-fit: cover;

                            img {
                                object-fit: cover;
                                width: 200px;
                                height: 200px;
                            }
                        }
                    }

                    .title {
                        margin-top: 24px;
                        width: 332px;
                        height: 40px;
                        line-height: 40px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        font-size: 22px;
                        color: #2b333b;
                        z-index: 999;
                        bottom: 0px;
                        text-align: center;

                        &.t2 {
                            margin-top: 0;
                        }
                    }
                }

                .swiper-button-prev,
                .swiper-button-next {
                    width: 48px;
                    height: 48px;
                    top: 174px;

                    &::after {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .swiper-button-prev {
                    margin-left: -8px;
                }

                .swiper-button-next {
                    margin-right: -8px;
                }
            }

            .banner {
                position: absolute;
                z-index: 99;
                left: 12px;
                right: 12px;
                bottom: 80px;
                height: 64px;
                line-height: 64px;
                background: linear-gradient(#e3d8c9, #f3ebe0, #e3d8c9);
                border-radius: 8px;
                box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

                &.num4 {
                    right: 12px;
                }

                &.num3 {
                    right: calc(12px + 356px);
                }

                &.num2 {
                    right: calc(12px + 356px * 2);
                }

                &.num1 {
                    right: calc(12px + 356px * 3);
                }

                &.num0 {
                    display: none;
                }
            }
        }
    }
}
</style>
