<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-07 20:24:25
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-07 20:22:35
-->
<template>
    <div class="c-audio-detail">
        <div class="bread">
            当前位置：<span class="pointer" @click="goImage">图片</span>&ensp;>&ensp;<span
                >图片详情</span
            >
        </div>
        <div class="content">
            <div class="main" v-if="state.item">
                <div class="p1">
                    <div class="l">
                        <img class="video" v-lazy="state.item.HighCover" @click="goReadPage" />
                    </div>
                    <div class="r">
                        <el-tooltip :content="state.item.Title" effect="light">
                            <div ref="tip" class="title" :style="{ opacity: state.opacity }">
                                {{ state.item.Title }}
                            </div>
                        </el-tooltip>
                        <div ref="notip" class="title" :style="{ opacity: state.opacity, overflow: state.opacity == 0 ? 'scroll' : '' }">
                            <span>{{ state.item.Title }}</span>
                        </div>
                        <div class="meng mFont">
                            <span
                                class="meng-vertical"
                                v-for="(item, index) in getMWords(state.item.MTitle)"
                                :key="index"
                                >{{ item }}</span
                            >
                        </div>
                        <div class="divider"></div>
                        <div class="items" v-if="state.item">
                            <div class="item one-line">
                                <div class="name">
                                    作&emsp;&emsp;者：{{
                                        state.item.Author ? state.item.Author : "暂无"
                                    }}
                                </div>
                            </div>
                            <div class="item one-line">
                                拍摄时间：{{
                                    getPhotodate()
                                }}
                            </div>
                            <div class="item one-line">
                                所属分类：{{
                                    state.item.CategoryName
                                        ? state.item.CategoryName
                                        : "暂无"
                                }}
                            </div>
                            <div class="item one-line" v-if="getBelongData().length > 0">
                                <div class="name">所属资源：</div>
                                <div class="values">
                                    <div
                                        class="value one-line"
                                        v-for="(item, index) in getBelongData()"
                                        :key="index"
                                    >
                                        {{ item.Title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buttons">
                            <div class="button b1" @click="favoriteClick">
                                <el-tooltip :content="!state.item.IsFavorite ? '收藏' : '取消收藏'" effect="light">
                                    <img v-if="!state.item.IsFavorite" src="@/assets/images/common/shoucang0.png" />
                                    <img v-else src="@/assets/images/common/shoucang.png" />
                                </el-tooltip>
                                <span>{{ state.item.Collect }}</span>
                            </div>

                            <div class="button b2" @click="likeClick">
                                <el-tooltip :content="!state.item.IsLike ? '点赞' : '取消点赞'" effect="light">
                                    <img v-if="!state.item.IsLike" src="@/assets/images/common/dianzan0.png" />
                                    <img v-else src="@/assets/images/common/dianzan.png" />
                                </el-tooltip>
                                <span>{{ state.item.Like }}</span>
                            </div>
                        </div>
                        <div class="mbtn" @click="goReadPage">立即欣赏</div>
                    </div>
                </div>
                <div class="p2">
                    <div class="p21">
                        <div class="title one-line">
                            <span>“</span>
                            图片简介
                        </div>
                        <div class="meng mFont">
                            <span class="meng-vertical">ᠵᠢᠷᠤᠭ</span>
                            <span class="meng-vertical">ᠰᠡᠭᠦᠳᠡᠷ᠎ᠦᠨ</span>
                            <span class="meng-vertical">ᠲᠣᠪᠴᠢ</span>
                            <span class="meng-vertical">ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ</span>
                        </div>
                    </div>
                    <div ref="p22" class="p22" :class="[!state.show ? 'hide' : '', state.complete ? 'complete' : '']">
                        {{ state.item.Abstract ? state.item.Abstract : "暂无" }}
                    </div>
                    <div class="p23" v-if="state.item.Abstract && state.ellipsis">
                        <div class="btn" v-if="!state.show" @click="showOrHide(true)">
                            展开全部&ensp;>
                        </div>
                        <div class="btn" v-if="state.show" @click="showOrHide(false)">
                            收起&ensp;>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import moment from "moment";
import api from "@/common/api";
import { ElMessage } from "element-plus";
import { getToken } from "@/request";
import routerUtil from "@/common/router";

const route = useRoute() as any;
const router = useRouter() as any;
const emits = defineEmits(["handleComplete"]);

const state = reactive({
    id: "",
    item: null as any,
    show: true,
    favoriteLoading: false,
    likeLoading: false,
    
    complete: false,
    ellipsis: false,

    opacity: 0
});

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const getPhotographer = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let photographer = state.item.ExternalData.filter(
                (item: any, index: number) => {
                    return item.PropertyName == "photographer";
                }
            );

            if (photographer.length > 0) {
                return photographer;
            }
        }
    }

    return [];
};

const getPhotodate = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let photodate = state.item.ExternalData.filter(
                (item: any, index: number) => {
                    return item.PropertyName == "photodate";
                }
            );

            if (photodate.length > 0) {
                return photodate[0].PropertyValue;
            }
        }
    }

    return "";
};

const getBelongData = () => {
    if (state.item && state.item.BelongData) {
        if (state.item.BelongData.length == 0) {
            return "";
        } else {
            let photographer = state.item.BelongData;

            if (photographer.length > 0) {
                return photographer;
            }
        }
    }

    return [];
};

const contentInfoInfo = () => {
    state.item = {};

    api.contentInfoInfo(
        { id: state.id },
        (res: any) => {
            state.item = res;
            isEllipsis()
            initToolTip()
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const favoriteSave = () => {
    api.favoriteSave(
        { objectId: state.id },
        (res: any) => {
            state.item.IsFavorite = true;
            state.item.Collect++;
            state.favoriteLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const favoriteDelete = () => {
    api.favoriteDelete(
        { objectId: state.id },
        (res: any) => {
            state.item.IsFavorite = false;
            state.item.Collect--;
            state.favoriteLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const favoriteClick = () => {
    if (state.favoriteLoading) {
        return;
    } else {
        state.favoriteLoading = true;
    }

    if (state.item.IsFavorite) {
        favoriteDelete();
    } else {
        favoriteSave();
    }
};

const likeInfoSave = () => {
    api.likeInfoSave(
        { contentId: state.id },
        (res: any) => {
            state.item.IsLike = true;
            state.item.Like++;
            state.likeLoading = false;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const likeInfoDelete = () => {
    api.likeInfoDelete(
        { contentId: state.id },
        (res: any) => {
            state.item.IsLike = false;
            state.item.Like--;
            state.likeLoading = false;
        },
        (err: any) => {
            ElMessage.error(err.Description);
            console.log(err);
        }
    );
};

const likeClick = () => {
    if (state.likeLoading) {
        return;
    } else {
        state.likeLoading = true;
    }

    if (state.item.IsLike) {
        likeInfoDelete();
    } else {
        likeInfoSave();
    }
};

const showOrHide = (show: boolean) => {
    state.show = show;
    emits("handleComplete");
};

const goImage = () => {
    router.push({
        path: `/image`,
    });
};

const goReadPage = () => {
    routerUtil.goReadPage(state.id, getToken());
};

const handlePlay = () => {};

const p22 = ref(0)
const isEllipsis = () => {
    setTimeout(() => {
        if ((p22.value as any).clientHeight  < (p22.value as any).scrollHeight) {
            state.ellipsis = true;
            state.complete = true;
            state.show = false;

            emits("handleComplete");
        } else {
            state.ellipsis = false;
            state.complete = true;
            state.show = false;

            emits("handleComplete");
        }
    }, 1000);
}

const tip = ref(0);
const notip = ref(0);

const initToolTip = () => {
    setTimeout(() => {
        const titem = tip.value as any;
        const nitem = notip.value as any;

        if (nitem.clientHeight  < nitem.scrollHeight) {
            nitem.remove();
            state.opacity = 1;
        } else {
            titem.remove();
            state.opacity = 1;
        }
    }, 500);
};

onMounted(() => {
    state.id = route.query.id;
    contentInfoInfo();
});
</script>

<style lang="scss" scoped>
.c-audio-detail {
    width: 100%;

    .bread {
        width: 1500px;
        margin: 0 auto;
        padding-top: 40px;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 24px;
        color: #2b333b;
        text-align: left;

        .pointer {
            cursor: pointer;
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .main {
            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 40px;
                padding-left: 100px;

                .l {
                    width: 600px;
                    height: 600px;
                    margin-right: 100px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;
                    .title {
                        font-size: 32px;
                        line-height: 48px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        width: 600px;
                        max-height: 64rpx;
                        display: -webkit-box;
                        /* Safari */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        line-clamp: 2;
                        /* 设置最大显示行数 */
                        -webkit-line-clamp: 2;
                        /* Safari and Chrome */
                        -webkit-box-orient: vertical;
                        /* Safari */
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .buttons {
                        width: 300px;
                        margin-top: 32px;
                        box-sizing: border-box;
                        padding-right: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .button {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            img {
                                margin-right: 18px;
                            }

                            &.b1 {
                                img {
                                    width: 28px;
                                    height: 29px;
                                }
                            }
                            
                            &.b2 {
                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }

                    .mbtn {
                        width: 300px;
                        height: 56px;
                        margin-top: 28px;
                        line-height: 52px;
                        text-align: center;
                        border-radius: 4px;
                        background-color: #6e8b74;
                        color: #ffffff;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 28px;
                    }
                }
            }

            .p2 {
                margin-top: 40px;
                margin-left: 40px;
                margin-right: 50px;
                padding-bottom: 40px;
                text-align: left;

                .p21 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .title {
                        display: inline-block;
                        font-size: 32px;
                        height: 68px;
                        line-height: 68px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;

                        span {
                            font-size: 48px;
                            line-height: 68px;
                            color: #6e8b74;
                            font-family: "PingFangSC-Semibold", "Georgia", "宋体";
                        }
                    }

                    .meng {
                        padding-top: 18px;
                        margin-left: 16px;
                        box-sizing: border-box;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #787878;
                        font-size: 20px;

                        span {
                            line-height: 28px;
                        }
                    }
                }

                .p22 {
                    margin-top: 18px;
                    font-size: 20px;
                    line-height: 48px;
                    color: #2b333b;
                    text-indent: 40px;
                    padding-left: 10px;
                    text-align: justify;
                    letter-spacing: 1px;
                    overflow: auto;
                    opacity: 0;
                    height: 232px;

                    &.hide {
                        display: -webkit-box;
                        /* Safari */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        line-clamp: 4;
                        /* 设置最大显示行数 */
                        -webkit-line-clamp: 4;
                        /* Safari and Chrome */
                        -webkit-box-orient: vertical;
                        /* Safari */
                        max-height: 192px;
                    }

                    &.complete {
                        overflow: hidden;
                        opacity: 1;
                    }
                }

                .p23 {
                    margin-top: 16px;
                    .btn {
                        width: 120px;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 4px;
                        border: 1px solid #e9e9e9;
                        color: #5297ff;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
