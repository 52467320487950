/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-06 18:34:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-01-16 13:15:32
 */
import axios from 'axios'
import Cookies from 'js-cookie'

const getToken = () => {
    let token = Cookies.get('token')
    if (!token) {
        return ''
    }
    return token
}

// 创建一个 axios 实例
const serviceFB = axios.create({
	baseURL: import.meta.env.VITE_BASE_FB_URL, // 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: false,
})

const serviceBY = axios.create({
	baseURL: import.meta.env.VITE_BASE_BY_URL, // 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: false,
})

// 添加请求拦截器
serviceFB.interceptors.request.use(
	function (config: any) {
		if (config.method === 'get') {
            config.data = true
        }
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.headers['token'] = getToken()
		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
serviceFB.interceptors.response.use(
	function (response) {
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		// 这个状态码是和后端约定的
		const code = dataAxios.reset
		return dataAxios
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加请求拦截器
serviceBY.interceptors.request.use(
	function (config: any) {
		if (config.method === 'get') {
            config.data = true
        }
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.headers['token'] = getToken()
		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
serviceBY.interceptors.response.use(
	function (response) {
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		// 这个状态码是和后端约定的
		const code = dataAxios.reset
		return dataAxios
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		console.log(error)
		return Promise.reject(error)
	}
)

export { getToken }
export default {
    axiosFB: serviceFB,
    axiosBY: serviceBY
}

