/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-07 08:23:59
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-11 18:53:05
 */
import Cookies from 'js-cookie'
import axios from '@/request'
import { getToken } from '@/request'
import { ElMessage } from 'element-plus'
import store from "@/pinia"
import router from '@/router'

const urls = {
    authLogin: 'Auth/Login',
    userInfo: 'User/Info',

    // 发布端
    bannerInfoList: 'BannerInfo/List',
    contentInfoNewData: 'ContentInfo/NewData',
    contentInfoRelatedContentList: 'ContentInfo/RelatedContentList',
    contentInfoNewList: 'ContentInfo/NewList',
    contentInfoInfo: 'ContentInfo/Info',
    contentInfoPageList: 'ContentInfo/PageList',

    categoryList: 'Category/List',
    noticeNewDataList: 'Notice/NewDataList',
    noticePageList: 'Notice/PageList',
    noticeInfo: 'Notice/Info',
    contentInfoSearchCount: 'ContentInfo/SearchCount',
    contentInfoSearch: 'ContentInfo/Search',
    contentInfoGetJournalByYear: 'ContentInfo/GetJournalByYear',
    contentInfoGetChapters: 'ContentInfo/GetChapters',
    contentInfoCategoryData: 'ContentInfo/CategoryData',
    contentInfoYearData: 'ContentInfo/YearData',
    contentInfoAreaData: 'ContentInfo/AreaData',
    columnInfoList: "ColumnInfo/List",
    columnInfoEnableList: 'ColumnInfo/EnableList',
    columnInfoColumContent: 'ColumnInfo/ColumContent',
    contentSettingList: 'ContentSetting/List',
    favoriteSave: 'Favorite/Save',
    favoriteDelete: 'Favorite/Delete',
    likeInfoSave: 'LikeInfo/Save',
    likeInfoDelete: 'LikeInfo/Delete',
    userSelfUpdate: 'User/SelfUpdate',
    userUpdatePassword: 'User/UpdatePassword',
    processMyList: 'Process/MyList',
    favoriteMyList: 'Favorite/MyList',

    markInfoMyList: 'MarkInfo/MyList',
    markInfoGetIndexMarkByOther: 'MarkInfo/getIndexMarkByOther',
    markInfoGetMarkCount: "MarkInfo/getMarkCount",
    markInfodeleteIndexMark: 'MarkInfo/deleteIndexMark',
    smsCodeSend: 'SmsCode/Send',
    authRegister: 'Auth/Register',
    userForgetPassword: 'User/ForgetPassword',
    areaInfoList: 'AreaInfo/List',

    // 标引端
    labelHotList: 'Label/HotList',
    labelSearch: 'Label/Search',
    labelContentLabel: 'Label/ContentLabel',
    labelContentLabelPage: 'Label/ContentLabelPage',
    labelInfo: 'Label/Info',
    labelContentLabelCount: 'Label/ContentLabelCount',
    tagGetPosition: 'Tag/GetPosition',
}

const methods = {
    GET: 'get',
    POST: 'post',
}

/**
 * @param {Object} url
 * @param {Object} method
 * @param {Object} data
 * 返回值 type 0代表服务器返回的状态码错误，1代表网络错误
 */
const request = (axios: any, url: string, method: string, data: any, hasFile=false, file=null) => {
    let params = null as any
    let requestUrl = url
    let headers = {}

    if(method == methods.POST) {
        if(!hasFile) {
            params = new URLSearchParams()
            headers = {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': getToken()
            }
        } else {
            params = new FormData()
            params.append('file', file)
            headers = {
                'Content-Type': 'multipart/form-data',
                'token': getToken()
            }
        }
        for (const key in data) {
            params.append(key, data[key])
        }
    } else if(method == methods.GET) {
        params = '?'
        for (const key in data) {
            params += `${key}=${data[key]}&`
        }

        requestUrl = `${ requestUrl }${ params }`
        headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
		    'token': getToken()
        }
    }

    let funVal = method == methods.GET ? axios.get : axios.post

    return new Promise((resolve, reject) => {
        funVal(requestUrl, params, {
            headers: headers
        }).then((res: any) => {
            console.log(res)
            if (res.Code == 200) {
                resolve(res.Data)
            } else if(res.Code == 11) {
                Cookies.remove('token')
                store.user().logout(() => {
                    store.user().showLogin()
                })
            } else {
                reject({
                    type: '0',
                    description: res.Description
                })
            }
        }).catch((err: any) => {
            reject({
                type: '1',
                description: err
            })
        })
    })
}

export default {
    authLogin: async (acc: string, pd: string, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.authLogin, methods.POST, {
                acc,
                pd,
                source: 0
            })

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    userInfo: async (callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.userInfo, methods.POST, {})

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.warning('网络错误！')
            }
        }
    },

    bannerInfoList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.bannerInfoList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    labelHotList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosBY, urls.labelHotList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoNewData: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoNewData, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoRelatedContentList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoRelatedContentList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoNewList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoNewList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoInfo: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoInfo, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoPageList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoPageList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    categoryList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.categoryList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    noticeNewDataList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.noticeNewDataList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    noticePageList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.noticePageList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    noticeInfo: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.noticeInfo, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoSearchCount: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoSearchCount, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoSearch: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoSearch, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    labelSearch: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosBY, urls.labelSearch, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    labelContentLabel: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosBY, urls.labelContentLabel, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    labelContentLabelPage: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosBY, urls.labelContentLabelPage, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoGetJournalByYear: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoGetJournalByYear, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoGetChapters: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoGetChapters, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoCategoryData: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoCategoryData, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoYearData: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoYearData, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentInfoAreaData: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentInfoAreaData, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    columnInfoEnableList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.columnInfoEnableList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    columnInfoList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.columnInfoList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    columnInfoColumContent: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.columnInfoColumContent, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    contentSettingList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.contentSettingList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    favoriteSave: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.favoriteSave, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    favoriteDelete: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.favoriteDelete, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    likeInfoSave: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.likeInfoSave, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    likeInfoDelete: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.likeInfoDelete, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    userSelfUpdate: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.userSelfUpdate, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    userUpdatePassword: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.userUpdatePassword, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    processMyList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.processMyList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    favoriteMyList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.favoriteMyList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    markInfoGetIndexMarkByOther: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.markInfoGetIndexMarkByOther, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    markInfoGetMarkCount: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.markInfoGetMarkCount, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    markInfodeleteIndexMark: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.markInfodeleteIndexMark, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    smsCodeSend: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.smsCodeSend, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    authRegister: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.authRegister, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    userForgetPassword: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.userForgetPassword, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    areaInfoList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.areaInfoList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    markInfoMyList: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.markInfoMyList, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    labelInfo: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosBY, urls.labelInfo, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    labelContentLabelCount: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosBY, urls.labelContentLabelCount, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },

    tagGetPosition: async (params: any, callback: Function, errback: Function) => {
        try {
            let res = await request(axios.axiosFB, urls.tagGetPosition, methods.POST, params)

            callback(res)
        } catch (e: any) {
            if (e.type == '0') {
                errback(e.description)
            } else if (e.type == '1') {
                ElMessage.error('网络错误！')
            }
        }
    },
}