import * as echarts from "echarts"

/**
* 取字符串长度
* @param {} str
* @returns {}
*/
const getStrLength = (str: string) => {
    let cArr = str.match(/[^\x00-\xff]/ig)
    return Math.round((str.length + (cArr == null ? 0 : cArr.length)) / 2)
}

/**
 * 是否都是单字节字符
 * @param {} str
 * @returns {}
 */
const strIsSingleByte = (str: string) => {
    let cArr = str.match(/[^\x00-\xff]/ig)
    if (cArr == null || cArr.length == 0) {
        return true
    }
    return false
}

//判断是否包含蒙文
const stringHaveMeng = (str: string) => {
    if (str == null || str.length == 0)
        return false
    let mengReg = new RegExp("[\u1800-\u18AF]")
    let flag = mengReg.test(str)
    //console.log("是否包含蒙文-->", flag)
    return flag
}

const getScale = () => {
    if (window.innerWidth > 1920) {
        return 1920 / window.innerWidth;
    }

    return 1;
};

let option = {} as any
let myChart = null as any

let zoom = window.innerWidth / 1920

const getOption = () => {
    return option
}

const getChart = () => {
    return myChart
}

const initEcharts = (domRef: any, labels: any[], clickCallBack: Function, selectId = '') => {
    myChart = echarts.init(domRef)
    let nodeDatas = []
    let links = []

    let maxCites = 0

    for (let i = 0; i < labels.length; i++) {
        const item = labels[i];
        if(item.Cites > maxCites) {
            maxCites = item.Cites
        }
    }

    // 选中的标签 Cites最大
    for (let i = 0; i < labels.length; i++) {
        const item = labels[i];
        if(selectId == labels[i].StrId) {
            labels[i].Cites = maxCites + 1
        }
    }

    labels.sort((a: any, b: any) => {
        return b.Cites - a.Cites;
    });

    for (let i = 0; i < labels.length; i++) {
        const random = (Math.random() * 0.2)
        const randomPic = labels[i].Type

        let widthOffset = 0
        let heightOffset = 0
        let sizeOffset = 0

        if(labels[i].Cites == maxCites) {
            widthOffset = Math.floor((labels[i].Cites / maxCites) * 60 * zoom)
            heightOffset = Math.floor((labels[i].Cites / maxCites) * 12 * zoom)
            sizeOffset = Math.floor((labels[i].Cites / maxCites) * 12 * zoom)
        } else {
            if(labels[i].Cites / maxCites + random >= 1) {
                widthOffset = Math.floor((labels[i].Cites / maxCites) * 40 * zoom)
                heightOffset = Math.floor((labels[i].Cites / maxCites) * 12 * zoom)
                sizeOffset = Math.floor((labels[i].Cites / maxCites) * 12 * zoom)
            } else {
                widthOffset = Math.floor((labels[i].Cites / maxCites + random) * 40 * zoom)
                heightOffset = Math.floor((labels[i].Cites / maxCites + random) * 12 * zoom)
                sizeOffset = Math.floor((labels[i].Cites / maxCites + random) * 12 * zoom)
            }
        }

        if(labels[i].Name.length >= 5 && labels[i].Cites != maxCites) {
            widthOffset += 20 * zoom
        } else if(labels[i].Name.length <= 4 && labels[i].Cites != maxCites) {
            widthOffset -= 20 * zoom
        }

        if(!widthOffset) {
            widthOffset = 0
        }

        if(!heightOffset) {
            heightOffset = 0
        }

        let node = {} as any
        node.id = labels[i].StrId
        node.name = labels[i].StrId
        node.category = 0
        node.title = labels[i].Name
        node.draggable = true                   //支持拖拽
        node.symbol = `image://public/image/bg${ randomPic }.png`          //标签形状
        node.symbolOld = `image://public/image/bg${ randomPic }.png`
        node.symbolSize = [160 * 0.9 * zoom + widthOffset, 50 * 0.9 * zoom + heightOffset]             //标签大小
        node.itemStyle = {
            color: "#FFFFFF"
        }
        node.label = {
            borderType: "none",
            borderColor: "inherit",
            shadowColor: "transparent",
            textBorderColor: "inherit",
            fontSize: 18 * 0.90 * zoom + sizeOffset
        }                                       //标签样式
        node.value = labels[i].Cites
        node.item = labels[i]

        if(i == 0 && labels.length > 10) {
            node.x = 1500 / 2 / getScale()
            node.y = 560 / 2 / getScale()
            node.fixed = true
        }

        nodeDatas.push(node);

        if(i != 0 && i <= 8 && labels.length > 10) {
            links.push({
                source: i,
                target: 0,
                value: labels[0].Cites - labels[i].Cites,
                lineStyle: {
                    opacity: 0
                }
            })
        }

        console.log(links)
    }

    // 指定图表的配置项和数据
    option = {
        title: {
            text: ''
        },
        tooltip: {
            show: true,                         // 提示框的配置
            confine: true,
            enterable: true,
            formatter: (param: any) => {
                return param.data.title;
            }
        },
        series: [
            {
                type: "graph",                  // 系列类型:关系图
                right: '7%',
                roam: 'move',
                force: {
                    gravity: 0.24 / zoom,
                    repulsion: 1000 * zoom,
                    edgeLength: [200, 400],
                    layoutAnimation: true
                },
                layout: 'force',
                symbol: 'circle',
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [0, 7],
                label: {
                    // 关系对象上的标签
                    normal: {
                        show: true,
                        position: "inside",
                        textStyle: {
                            fontSize: 16 * zoom
                        },
                        formatter: (param: any) => {
                            let result = ''
                            let length = param.data.title.length

                            let flag = stringHaveMeng(param.data.title)
                            if (flag) {
                                //蒙文最多显示15个字符
                                let mMaxLength = 15
                                let r1Data = ""     //第一行数据
                                let r2Data = ""     //第二行数据
                                if (length <= mMaxLength) {
                                    return param.data.title
                                } else {
                                    let list = param.data.title.split(' ')
                                    for (let i = 0; i < list.length; i++) {
                                        if (r1Data.length < mMaxLength) {
                                            if (r1Data.length == 0) {
                                                r1Data = list[i]
                                            } else if (r1Data.length + list[i].length + 1 > mMaxLength) {
                                                //第一行超过了，从第二行开始
                                                r2Data = list[i]
                                            } else {
                                                r1Data = r1Data + " " + list[i]
                                            }
                                        } else {
                                            if (r2Data.length == 0) {
                                                r2Data = list[i]
                                            }
                                            else if (r2Data.length + list[i].length > mMaxLength - 3) {
                                                r2Data = r2Data + " .."
                                            } else {
                                                r2Data = r2Data + " " + list[i]
                                            }
                                        }

                                    }
                                }

                                if (r2Data.length > 0) {
                                    result = r1Data + "\r\n" + r2Data
                                } else {
                                    result = r1Data
                                }

                                return result
                            }

                            let lineLabelCnt = 4
                            let maxLength = 12

                            let sByte = strIsSingleByte(param.data.title)

                            if (sByte) {
                                lineLabelCnt = 9
                                maxLength = 9
                            } else {
                                lineLabelCnt = 5
                                maxLength = 5
                            }

                            if(length > lineLabelCnt) {
                                result = param.data.title.substring(0, lineLabelCnt) + "..."
                            } else {
                                result = param.data.title
                            }

                            return result
                        }
                    }
                },
                edgeLabel: {
                    normal: {
                        show: true,
                        textStyle: {
                            fontSize: 16 * zoom
                        },
                        formatter: function (param: any) {
                            // 标签内容
                            return param.data.category
                        }
                    }
                },
                data: nodeDatas,
                categories: [{
                    name: "分类1"
                }],
                selectedMode: "single",
                select: {
                    itemStyle: {
                        shadowColor: 'rgba(256, 256, 256, 0.3)',
                        shadowBlur: 40 * zoom
                    },
                    label: {
                        fontWeight: "bolder",
                        color: "#2b333b"
                    }
                },
                links: links
            }
        ],

        animation: true,
        animationEasingUpdate: "quinticInOut",
        animationDurationUpdate: 0
    }

    myChart.clear()
    myChart.setOption(option as any, true)
    myChart.off('click')
    myChart.on('click', (params: any) => {
        console.log(params)
        console.log(option.series[0].data)

        clickCallBack(myChart, option, params)
    })

    // 设置默认选中
    if(selectId) {
        setTimeout(() => {
            myChart.dispatchAction({
                type: 'select',
                name: selectId
            })
        }, 500)
    }
}

const initSmallEcharts = (domRef: any, labels: any[], clickCallBack: Function) => {
    myChart = echarts.init(domRef)
    let nodeDatas = []
    let links = []

    let maxCites = 0

    for (let i = 0; i < labels.length; i++) {
        const item = labels[i];
        if(item.Cites > maxCites) {
            maxCites = item.Cites
        }
    }

    for (let i = 0; i < labels.length; i++) {
        const random = (Math.random() * 0.2)
        const randomPic = labels[i].Type

        let widthOffset = 0
        let heightOffset = 0
        let sizeOffset = 0

        if(labels[i].Cites == maxCites) {
            widthOffset = Math.floor((labels[i].Cites / maxCites) * 20 * zoom)
            heightOffset = Math.floor((labels[i].Cites / maxCites) * 6 * zoom)
            sizeOffset = Math.floor((labels[i].Cites / maxCites) * 6 * zoom)
        } else {
            if(labels[i].Cites / maxCites + random >= 1) {
                widthOffset = Math.floor((labels[i].Cites / maxCites) * 20 * zoom)
                heightOffset = Math.floor((labels[i].Cites / maxCites) * 6 * zoom)
                sizeOffset = Math.floor((labels[i].Cites / maxCites) * 6 * zoom)
            } else {
                widthOffset = Math.floor((labels[i].Cites / maxCites + random) * 20 * zoom)
                heightOffset = Math.floor((labels[i].Cites / maxCites + random) * 6 * zoom)
                sizeOffset = Math.floor((labels[i].Cites / maxCites + random) * 6 * zoom)
            }
        }

        if(!widthOffset) {
            widthOffset = 0
        }

        if(!heightOffset) {
            heightOffset = 0
        }

        if(!sizeOffset) {
            sizeOffset = 0
        }

        let node = {} as any
        node.id = labels[i].StrId
        node.name = labels[i].StrId
        node.category = 0
        node.title = labels[i].Name
        node.draggable = true                   //支持拖拽
        node.symbol = `image://public/image/bg${ randomPic }.png`          //标签形状
        node.symbolOld = `image://public/image/bg${ randomPic }.png`
        node.symbolSize = [90 * zoom + widthOffset, 24 * zoom + heightOffset]             //标签大小
        node.itemStyle = {
            color: "#FFFFFF"
        }
        node.label = {
            borderType: "none",
            borderColor: "inherit",
            shadowColor: "transparent",
            textBorderColor: "inherit",
            fontSize: 14 * zoom + sizeOffset
        }                                       //标签样式
        node.value = labels[i].Cites
        node.item = labels[i]
        nodeDatas.push(node);

        if(i != 0) {
            links.push({
                source: i,
                target: 0,
                value: (labels[0].Cites - labels[i].Cites),
                lineStyle: {
                    opacity: 0
                }
            })
        }
    }

    // 指定图表的配置项和数据
    option = {
        title: {
            text: ''
        },
        tooltip: {
            show: true,                         // 提示框的配置
            confine: true,
            enterable: true,
            formatter: (param: any) => {
                return param.data.title;
            }
        },
        series: [
            {
                type: "graph",                  // 系列类型:关系图
                right: '7%',
                roam: 'move',
                force: {
                    gravity: 0.5,
                    repulsion: 1000,
                    edgeLength: [150, 100],
                    layoutAnimation: true
                },
                layout: 'force',
                symbol: 'circle',
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [0, 7],
                label: {
                    // 关系对象上的标签
                    normal: {
                        show: true,
                        position: "inside",
                        textStyle: {
                            fontSize: 16 * zoom
                        },
                        formatter: (param: any) => {
                            let result = ''
                            let length = param.data.title.length

                            let flag = stringHaveMeng(param.data.title)
                            if (flag) {
                                //蒙文最多显示15个字符
                                let mMaxLength = 15
                                let r1Data = ""     //第一行数据
                                let r2Data = ""     //第二行数据
                                if (length <= mMaxLength) {
                                    return param.data.title
                                } else {
                                    let list = param.data.title.split(' ')
                                    for (let i = 0; i < list.length; i++) {
                                        if (r1Data.length < mMaxLength) {
                                            if (r1Data.length == 0) {
                                                r1Data = list[i]
                                            } else if (r1Data.length + list[i].length + 1 > mMaxLength) {
                                                //第一行超过了，从第二行开始
                                                r2Data = list[i]
                                            } else {
                                                r1Data = r1Data + " " + list[i]
                                            }
                                        } else {
                                            if (r2Data.length == 0) {
                                                r2Data = list[i]
                                            }
                                            else if (r2Data.length + list[i].length > mMaxLength - 3) {
                                                r2Data = r2Data + " .."
                                            } else {
                                                r2Data = r2Data + " " + list[i]
                                            }
                                        }

                                    }
                                }

                                if (r2Data.length > 0) {
                                    result = r1Data + "\r\n" + r2Data
                                } else {
                                    result = r1Data
                                }

                                return result
                            }

                            let lineLabelCnt = 4
                            let maxLength = 12

                            let sByte = strIsSingleByte(param.data.title)

                            if (sByte) {
                                lineLabelCnt = 9
                                maxLength = 9
                            } else {
                                lineLabelCnt = 5
                                maxLength = 5
                            }

                            if(length > lineLabelCnt) {
                                result = param.data.title.substring(0, lineLabelCnt) + "..."
                            } else {
                                result = param.data.title
                            }

                            return result
                        }
                    }
                },
                edgeLabel: {
                    normal: {
                        show: true,
                        textStyle: {
                            fontSize: 16 * zoom
                        },
                        formatter: function (param: any) {
                            // 标签内容
                            return param.data.category
                        }
                    }
                },
                data: nodeDatas,
                categories: [{
                    name: "分类1"
                }],
                selectedMode: "single",
                select: {
                    itemStyle: {
                        shadowColor: 'rgba(256, 256, 256, 0.3)',
                        shadowBlur: 40 * zoom
                    },
                    label: {
                        fontWeight: "bolder",
                        color: "#2b333b"
                    }
                }
                // links: links
            }
        ],

        animation: true,
        animationEasingUpdate: "quinticInOut",
        animationDurationUpdate: 0
    }

    myChart.clear()
    myChart.setOption(option as any, true)
    myChart.off('click')
    //点击事件
    myChart.on('click', (params: any) => {
        clickCallBack(myChart, option, params)
    })
}

const updateEcharts = (echartsInstance: any) => {
    echartsInstance.setOption(option as any, false)
}

const setSelect = (item: any) => {
    setTimeout(() => {
        myChart.dispatchAction({
            type: 'select',
            name: item.StrId
        })
    }, 500)
}

export default { initEcharts, initSmallEcharts, updateEcharts, setSelect, getOption, getChart }