<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-17 20:29:48
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-08 10:47:59
-->
<template>
    <div class="home-yinpin">
        <div class="box">
            <div class="content">
                <div class="l">
                    <div class="han">
                        <div>音</div>
                        <div>频</div>
                    </div>
                    <div class="meng mFont meng-vertical">ᠲᠠᠭᠤ ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ</div>
                    <div class="more" @click="goAudios">
                        <span>更多</span>
                        <img src="@/assets/images/home/gengduo.png" />
                    </div>
                </div>
                <div class="r">
                    <template v-for="(item, index) in state.sounds" :key="index">
                        <div class="item" @click="goAudio(item)">
                            <img class="cover" v-lazy="item.Cover" />
                            <div class="name">
                                <div class="han VerticalHan">
                                    {{ item.Name }}
                                </div>
                                <div class="meng">
                                    <span class="meng-vertical mFont">
                                        {{ item.MName }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const state = reactive({
    sounds: [] as any[],
});

const categoryList = () => {
    api.categoryList(
        { type: 3 },
        (res: any) => {
            state.sounds = res[0].children;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const router = useRouter()
const goAudio = (item: any) => {
    routerUtil.openNewWindow({
        path: `/audio`,
        query: {
            id: item.StrId,
        },
    });
};

const goAudios = () => {
    routerUtil.openNewWindow({
        path: `/audio`,
    });
};

onMounted(() => {
    categoryList();
});
</script>

<style lang="scss" scoped>
.home-yinpin {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .box {
        width: 100%;
        position: relative;
        background-size: 100% 100%;

        .content {
            width: 1500px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;

            .l {
                width: 264px;
                margin-top: 96px;
                margin-right: 30px;
                position: relative;

                .han {
                    div {
                        display: block;
                        font-size: 32px;
                        color: #2b333b;
                        text-align: left;
                        width: 164px;
                        height: 44px;
                        line-height: 44px;
                        box-sizing: border-box;
                        font-weight: 600;
                        margin-top: 2px;
                        padding-left: 44px;

                        &:first-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            color: #ffffff;
                            background-color: #6e8b74;
                        }
                    }
                }

                .meng {
                    position: absolute;
                    left: 92px;
                    top: 64px;
                    font-size: 26px;
                    height: 200px;
                    text-align: left;
                    color: #2b333b;
                }

                .more {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 90px;
                    height: 28px;
                    left: 74px;
                    top: 244px;
                    border-radius: 14px;
                    background-color: #f2e6ce;
                    font-size: 14px;
                    color: #000000;
                    cursor: pointer;

                    transition: all 0.2s;
                    transform: scale(1);

                    &:hover {
                        transform: scale(1.2);
                    }

                    img {
                        margin-left: 2px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .r {
                width: 1206px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;

                .item {
                    position: relative;
                    cursor: pointer;
                    transition: all 0.2s;
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
                    overflow: hidden;
                    border-radius: 8px;
                    transform: translateY(0);

                    &::after {
                        display: block;
                        position: absolute;
                        content: "";
                        width: 800px;
                        height: 800px;
                        border-radius: 50%;
                        left: -207px;
                        top: -142px;
                        background-color: transparent;
                        z-index: 1;
                        transition: all 0.2s;
                    }

                    &:hover {
                        transform: translateY(-24px);

                        &::after {
                            background-color: rgba(0, 0, 0, 0.5);
                        }

                        .name {
                            .han {
                                color: #ffffff !important;
                            }

                            .meng {
                                color: #ffffff !important;
                            }
                        }
                    }

                    .cover {
                        width: 386px;
                        height: 516px;
                        border-radius: 0px;
                        border-radius: 8px;
                        cursor: pointer;
                    }

                    .name {
                        position: absolute;
                        right: 32px;
                        top: 32px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        z-index: 9;

                        .han {
                            max-height: 100%;
                            white-space: nowrap;
                            font-size: 32px;
                            font-weight: 600;
                            line-height: 40px;
                            letter-spacing: 8px;
                            color: #2b333b;
                            transition: all 0.2s;
                        }

                        .meng {
                            font-size: 26px;
                            color: #2b333b;
                            margin-left: 8px;
                            display: flex;
                            align-items: flex-start;
                            transition: all 0.2s;
                        }
                    }
                }
            }
        }
    }
}
</style>
