<template>
    <div class="search-all">
        <div class="items">
            <div class="item" :class="{ select: state.selectIndex == 0 }" @click="selectType(0, false)">
                <div class="han">
                    <span class="box">
                        全部资源&ensp;<span :class="{ empty: state.totalCount == 0 }">({{ state.totalCount }})</span>
                    </span>
                    <div class="meng mFont">
                        <span class="meng-vertical"> ᠪᠥᠬᠦ </span>
                        <span class="meng-vertical"> ᠡᠬᠢ </span>
                        <span class="meng-vertical"> ᠪᠠᠶᠠᠯᠢᠭ </span>
                    </div>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 1 }" @click="selectType(1, false)">
                <div class="han">
                    <span class="box">
                        {{ state.hbName }}&ensp;<span :class="{ empty: state.hbCount == 0 }">({{ state.hbCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠰᠡᠳᠭᠦᠯ᠎ᠳᠦ </span>
                            <span class="meng-vertical"> ᠨᠡᠢᠲᠡᠯᠡᠭᠳᠡᠭᠰᠡᠨ </span>
                            <span class="meng-vertical"> ᠵᠣᠬᠢᠶᠠᠯ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 2 }" @click="selectType(2, false)">
                <div class="han">
                    <span class="box">
                        {{ state.skName }}&ensp;<span :class="{ empty: state.skCount == 0 }">({{ state.skCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠨᠣᠮ </span>
                            <span class="meng-vertical"> ᠰᠡᠳᠭᠦᠯ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 3 }" @click="selectType(3, false)">
                <div class="han">
                    <span class="box">
                        {{ state.tpName }}&ensp;<span :class="{ empty: state.tpCount == 0 }">({{ state.tpCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠵᠢᠷᠤᠭ </span>
                            <span class="meng-vertical"> ᠰᠡᠭᠦᠳᠡᠷ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 4 }" @click="selectType(4, false)">
                <div class="han">
                    <span class="box">
                        {{ state.spName }}&ensp;<span :class="{ empty: state.spCount == 0 }">({{ state.spCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠳᠦᠷᠰᠦ </span>
                            <span class="meng-vertical"> ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 5 }" @click="selectType(5, false)">
                <div class="han">
                    <span class="box">
                        {{ state.ypName }}&ensp;<span :class="{ empty: state.ypCount == 0 }">({{ state.ypCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠲᠠᠭᠤ </span>
                            <span class="meng-vertical"> ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ </span>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";

const state = reactive({
    selectIndex: 0,
    types: [] as any[],
    allType: [] as any[],
    totalName: "",
    totalCount: 0,
    hbName: "",
    hbCount: 0,
    skName: "",
    skCount: 0,
    tpName: "",
    tpCount: 0,
    spName: "",
    spCount: 0,
    ypName: "",
    ypCount: 0,

    firstInit: true,
});

const emit = defineEmits(["handleChange"]);

const selectType = (index: number, flag: boolean, type = 'select') => {
    state.selectIndex = index;

    switch (state.selectIndex) {
        case 0: {
            emit("handleChange", 0, [2, 3, 4, 5, 6], flag, type);
            break; 
        }
        case 1: {
            emit("handleChange", 1, [6], flag, type);
            break;
        }
        case 2: {
            emit("handleChange", 2, [2], flag, type);
            break;
        }
        case 3: {
            emit("handleChange", 3, [4], flag, type);
            break;
        }
        case 4: {
            emit("handleChange", 4, [5], flag, type);
            break;
        }
        case 5: {
            emit("handleChange", 5, [3], flag, type);
            break;
        }
    }
};

const getTreeData = (allType: string, keyword: string, dates: any[], flag: boolean) => {
    let param = { keyword: keyword, type: 1 } as any
    if (dates?.length > 0) {
        param.sDate = moment(dates[0]).format('YYYY-MM-DD')
        param.eDate = moment(dates[1]).format('YYYY-MM-DD')
    }

    api.contentInfoSearchCount(
        param,
        (res1: any) => {
            state.types = res1;

            state.totalCount = 0;
            for (let i = 0; i < state.types.length; i++) {
                const item = state.types[i];

                if (item.X == 6) {
                    state.hbName = item.Y;
                    state.hbCount = item.Z;
                } else if (item.X == 2) {
                    state.skName = item.Y;
                    state.skCount = item.Z;
                } else if (item.X == 4) {
                    state.tpName = item.Y;
                    state.tpCount = item.Z;
                } else if (item.X == 5) {
                    state.spName = item.Y;
                    state.spCount = item.Z;
                } else if (item.X == 3) {
                    state.ypName = item.Y;
                    state.ypCount = item.Z;
                } else if (item.X == 0) {
                    state.totalName = item.Y;
                    state.totalCount = item.Z;
                }
            }

            selectType(state.selectIndex, flag, 'search')
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const initData = (selectIndex: number, allType: string, keyword: string, dates: any[], flag: boolean) => {
    state.selectIndex = selectIndex
    getTreeData(allType, keyword, dates, flag); 
};

defineExpose({
    initData,
});
</script>

<style lang="scss" scoped>
.search-all {
    width: 1562px;
    height: 160px;
    margin: 0 auto;

    .items {
        margin-top: -62px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        background: #ffffff;
        border-radius: 8px;
        overflow: hidden;

        .item {
            width: 267px;
            height: 160px;
            cursor: pointer;

            &.select {
                background-color: #f2e6ce;
                font-weight: 600;

                .han {
                    font-weight: 600 !important;

                    span {
                        color: #2b333b !important;
                    }
                }
            }

            &:first-child {
                .meng {
                    margin-top: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 30px;
                }
            }

            .han {
                font-size: 24px;
                color: #2b333b;
                margin-top: 36px;
                height: 24px;
                line-height: 24px;
                font-weight: 500;
                transition: all 0.2s;

                .box {
                    position: relative;

                    span {
                        color: #387db4;

                        &.empty {
                            color: #787878 !important;
                        }
                    }

                    .meng {
                        position: absolute;
                        left: 0px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-top: 8px;

                        span {
                            font-size: 22px;
                            line-height: 26px;
                            color: #2b333b;
                        }
                    }
                }
            }
        }
    }
}
</style>
