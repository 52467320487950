<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-07 09:23:12
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-11 15:05:07
-->
<template>
    <div class="header" :class="{ homepage: state.homePage }" :style="getScaleStyle(0)">
        <div class="box">
            <div class="l" @click="goHome">
                <img src="@/assets/images/header/logo.png" />
            </div>
            <div class="r">
                <div class="menus">
                    <div
                        class="menu-item"
                        :class="{ select: state.menuSelectIndex == '0' }"
                        @click="menuClick('0')"
                    >
                        <div class="han">资源检索</div>
                        <div class="meng mFont">
                            <span> ᠡᠬᠢ </span>
                            <span> ᠪᠠᠶᠠᠯᠢᠭ᠎ᠤᠨ </span>
                            <span> ᠬᠠᠢᠯᠲᠠ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item"
                        :class="{ select: state.menuSelectIndex == '1' }"
                        @click="menuClick('1')"
                    >
                        <div class="han">知识导航</div>
                        <div class="meng mFont">
                            <span> ᠮᠡᠳᠡᠯᠭᠡ᠎ᠶᠢᠨ </span>
                            <span> ᠵᠠᠮᠴᠢ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item"
                        :class="{ select: state.menuSelectIndex == '2' }"
                        @click="menuClick('2')"
                    >
                        <div class="han">融合画报</div>
                        <div class="meng mFont">
                            <span> ᠬᠠᠮ </span>
                            <span> ᠵᠢᠷᠤᠭᠲᠤ </span>
                            <span> ᠰᠡᠳᠭᠦᠯ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item small"
                        :class="{ select: state.menuSelectIndex == '3' }"
                        @click="menuClick('3')"
                    >
                        <div class="han">书刊</div>
                        <div class="meng mFont">
                            <span> ᠨᠣᠮ </span>
                            <span> ᠰᠡᠳᠭᠦᠯ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item small"
                        :class="{ select: state.menuSelectIndex == '4' }"
                        @click="menuClick('4')"
                    >
                        <div class="han">图片</div>
                        <div class="meng mFont">
                            <span> ᠵᠢᠷᠤᠭ </span>
                            <span> ᠰᠡᠭᠦᠳᠡᠷ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item small"
                        :class="{ select: state.menuSelectIndex == '5' }"
                        @click="menuClick('5')"
                    >
                        <div class="han">视频</div>
                        <div class="meng mFont">
                            <span> ᠳᠦᠷᠰᠦ </span>
                            <span> ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item small"
                        :class="{ select: state.menuSelectIndex == '6' }"
                        @click="menuClick('6')"
                    >
                        <div class="han">音频</div>
                        <div class="meng mFont">
                            <span> ᠲᠠᠭᠤ </span>
                            <span> ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ </span>
                        </div>
                    </div>
                    <div
                        class="menu-item"
                        :class="{ select: state.menuSelectIndex == '7' }"
                        @click="menuClick('7')"
                    >
                        <div class="han">画报介绍</div>
                        <div class="meng mFont">
                            <span> ᠵᠢᠷᠤᠭᠲᠤ </span>
                            <span> ᠰᠡᠳᠭᠦᠯ᠎ᠦᠨ </span>
                            <span> ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ </span>
                        </div>
                    </div>
                </div>

                <div>
                    <template v-if="state.hasLogin">
                        <el-dropdown
                            :teleported="true"
                            trigger="click"
                            @command="handleCommand"
                            placement="bottom"
                            popper-class="header-dropdown"
                            @visible-change="handleVisible"
                            ref="dropdown"
                        >
                            <div class="mhead">
                                <div class="left">
                                    <img
                                        class="face"
                                        src="@/assets/images/header/icon.png"
                                    />

                                    <span><span class="username one-line">{{ state.userInfo.UserName }}</span>，您好!</span>
                                </div>

                                <img
                                    class="drap"
                                    src="@/assets/images/header/arrow_down.png"
                                />
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item command="0"
                                        >个人中心</el-dropdown-item
                                    >
                                    <el-dropdown-item command="1" divided
                                        ><span class="logout"
                                            >退出登录</span
                                        ></el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                    <template v-else>
                        <div class="mhead" @click="showLogin">
                            <div class="left margin">
                                <img
                                    class="face nomargin"
                                    src="@/assets/images/header/icon.png"
                                />

                                <span>登录</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <Login :showLoginDialog="state.showLoginDialog" />
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch } from "vue";
import Login from "./Login.vue";
import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getScale } from "@/utils/scale";
import { ElMessage, ElMessageBox } from "element-plus";

const router = useRouter();
const userStore = store.user() as any;

const state = reactive({
    homePage: true,
    showLoginDialog: computed(() => {
        return userStore.showLoginDialog;
    }),
    userInfo: computed(() => {
        return userStore.userInfo;
    }),
    hasLogin: computed(() => {
        return userStore.hasLogin;
    }),

    menuSelectIndex: computed(() => {
        return userStore.menuSelectIndex;
    }),
});

const goHome = () => {
    router.push({
        path: `/`,
    } as any);
};

let timer = null as any
const handleVisible = (e: any) => {
    if(e) {
        timer = setInterval(() => {
            $('.header-dropdown')?.css('transform', `scale(${ getScale() })`)
        }, 10)
    } else [
        clearInterval(timer)
    ]
}

const handleCommand = (command: any) => {
    if (command == "0") {
        router.push({
            path: `/user/`,
        } as any);
    } else if (command == "1") {
        ElMessageBox.confirm("确定要退出登录吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                store.user().logout(() => {
                    console.log("退出登录");

                    router.push({
                        path: `/`,
                    } as any);
                });
            })
            .catch(() => {
                console.log("取消");
            });
    }
};

const checkCurrentRoute = (current: any) => {
    if (!getToken()) {
        router.push({
            path: `/`,
        } as any);
    } else {
        if ("/" == current) {
            state.homePage = true;
        } else {
            state.homePage = false;
        }
    }
};

watch(
    () => router.currentRoute.value.path,
    (toPath) => {
        // 滚动到顶部
        window.scrollTo({ top: 0 });
        
        if(toPath.startsWith('/search')) {
            userStore.setMenuSelectIndex('0')
        } else if(toPath.startsWith('/knowledge')) {
            userStore.setMenuSelectIndex('1')
        } else if(toPath.startsWith('/magazine')) {
            userStore.setMenuSelectIndex('2')
        } else if(toPath.startsWith('/book')) {
            userStore.setMenuSelectIndex('3')
        } else if(toPath.startsWith('/image')) {
            userStore.setMenuSelectIndex('4')
        } else if(toPath.startsWith('/video')) {
            userStore.setMenuSelectIndex('5')
        } else if(toPath.startsWith('/audio')) {
            userStore.setMenuSelectIndex('6')
        } else if(toPath.startsWith('/introduction')) {
            userStore.setMenuSelectIndex('7')
        } else {
            userStore.setMenuSelectIndex('-1')
        }
    },
    { immediate: true, deep: true }
);

onMounted(() => {
    
});

const showLogin = () => {
    userStore.showLogin();
};

const menuClick = (index: string) => {
    userStore.setMenuSelectIndex(index);

    switch (index) {
        case "0": {
            router.push({
                path: "/search",
            });
            break;
        }
        case "1": {
            router.push({
                path: "/knowledge",
            });
            break;
        }
        case "2": {
            router.push({
                path: "/magazine",
            });
            break;
        }
        case "3": {
            router.push({
                path: "/book",
            });
            break;
        }
        case "4": {
            router.push({
                path: "/image",
            });
            break;
        }
        case "5": {
            router.push({
                path: "/video",
            });
            break;
        }
        case "6": {
            router.push({
                path: "/audio",
            });
            break;
        }
        case "7": {
            router.push({
                path: "/introduction",
            });
            break;
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    box-sizing: border-box;
    width: 100%;
    height: 160px;
    padding: 0px 40px;
    border-bottom: 2px solid transparent;
    background-image: url("@/assets/images/header/background.png");
    background-size: 100% 160px;

    .box {
        width: 100%;
        height: 160px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .l {
            font-size: 22px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            margin-right: 36px;

            img {
                width: 304px;
                height: 128px;
            }
        }

        .r {
            width: calc(100vw - 406px);
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .menus {
                display: flex;
                height: 100%;

                .menu-item {
                    width: 172px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;

                    &.small {
                        width: 126px;
                    }

                    .han {
                        padding-top: 34px;
                        font-size: 24px;
                        line-height: 24px;
                        margin-bottom: 12px;
                        letter-spacing: 4px;
                        color: #ffffff;
                    }

                    .meng {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        height: auto;
                        width: 112px;

                        span {
                            font-size: 20px;
                            line-height: 28px;
                            color: #ffffff;

                            width: auto;
                            display: inline-block;
                            text-align: center;
                            writing-mode: vertical-lr; /*从上往下 从左往右*/
                            -webkit-writing-mode: vertical-lr;
                            -o-writing-mode: vertical-lr;
                            -ms-writing-mode: vertical-lr;
                            text-orientation: sideways-right;
                            -webkit-text-orientation: sideways-right; /*兼容ios不正常显示*/

                            &:first-child {
                                position: relative;
                            }
                        }
                    }

                    &.select {
                        background-color: #f2e6ce;
                        .han {
                            color: #000000;
                        }

                        .meng {
                            span {
                                color: #000000;
                            }
                        }
                    }
                }
            }

            .el-dropdown {
                padding-left: 0px;
            }

            .back-home {
                color: #0c3680;
                font-size: 16px;
                padding: 0px 8px;
                height: 40px;
                line-height: 40px;
                border-radius: 8px;
                box-sizing: border-box;
                margin-right: 20px;
                width: 80px !important;
                max-width: 80px !important;
                cursor: pointer;
            }

            .login {
                background-color: #0c3680;
                color: #ffffff;
                font-size: 16px;
                padding: 8px 24px;
                height: 40px;
                line-height: 40px;
                border-radius: 8px;
            }

            .mhead {
                display: flex;
                align-items: center;
                cursor: pointer;

                .left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &.margin {
                        margin-right: 16px;
                    }

                    span {
                        font-size: 24px;
                        line-height: 24px;
                        display: inline-block;
                        margin-top: 8px;
                        color: #ffffff;

                        display: block;
                        text-align: center;
                        box-sizing: border-box;
                        padding-left: 0px;

                        display: flex;
                        align-items: center;
                    }

                    .username {
                        display: inline-block;
                        max-width: 120px;
                        margin-top: 0;
                    }
                }
            }

            .face {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                margin-right: 8px;

                &.nomargin {
                    margin-right: 0;
                }
            }

            .drap {
                margin-left: 8px;
                margin-top: -32px;
            }
        }
    }
}

.logout {
    color: rgb(173, 173, 173) !important;
}

:deep(.el-dropdown-menu__item:focus) {
    color: #0c3680;
    background-color: rgb(245, 247, 250) !important;
}
</style>

<style lang="scss">
.header-dropdown {
    margin-left: -10px;
    transform-origin: 50% 0%;

    .el-dropdown-menu__item {
        font-size: 20px;
        line-height: 40px;
        padding: 0 32px;
    }
}
</style>
