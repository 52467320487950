<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 11:23:05
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-25 18:01:39
-->
<template>
    <div class="home-banner">
        <el-carousel class="carousel" arrow="never">
            <template v-for="(item, index) in state.banners" :key="index">
                <el-carousel-item v-if="index < 6">
                    <img class="img" v-lazy="item.Url" />
                </el-carousel-item>
            </template>
        </el-carousel>
        <marquee v-if="state._notice" direction="left" behavior="scroll" class="marquee" @click="goNotice">
            {{ state._notice.Title }}
        </marquee>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const props = defineProps({
    notice: {
        default: null
    }
})

watch(props, (newVal, oldVal) => {
    state._notice = newVal.notice
})

const state = reactive({
    _notice: {} as any,
    banners: [] as any[],
});

const router = useRouter()
const goNotice = () => {
    routerUtil.openNewWindow({
        path: `/noticedetail`,
        query: {
            id: state._notice.StrId,
        },
    }, true);
};

const bannerInfoList = () => {
    api.bannerInfoList(
        {},
        (res: any) => {
            state.banners = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

onMounted(() => {
    state._notice = props.notice
    bannerInfoList();
});
</script>

<style lang="scss" scoped>
.home-banner {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    .carousel {
        width: 100%;
        height: 450px;
    }

    :deep(.el-carousel__item) {
        width: 100%;
        height: 450px;
    }

    .img {
        width: 100%;
        height: 450px;
        object-fit: cover;
    }

    $indicator-size: 16px;
    $indicator-active-size: 24px;

    :deep(.el-carousel__indicators--horizontal) {
        bottom: 0px;
    }

    :deep(.el-carousel__indicator--horizontal) {
        button {
            position: relative;
            box-sizing: border-box;
            width: $indicator-size;
            height: $indicator-size;
            border-radius: 50%;
            background-color: #d9dbe7;
            opacity: 1;
            margin-left: 2px;
            margin-right: 2px;
            margin-bottom: 12px;
            transition: all 0.2s;
        }

        button:after {
            content: "";
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            left: -4px;
            top: -4px;
            width: $indicator-active-size;
            height: $indicator-active-size;
            border-radius: 50%;
            border: 1px solid transparent;
            transition: all 0.2s;
        }
    }

    :deep(.el-carousel__indicator--horizontal.is-active) {
        button {
            background-color: #ccb16e;
        }

        button:after {
            border: 1px solid #ccb16e;
        }
    }

    :deep(.el-carousel__button:focus) {
        outline: none;
    }

    .marquee {
        position: absolute;
        bottom: 64px;
        right: 0px;
        height: 32px;
        line-height: 32px;
        font-size: 18px;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.4);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        width: 840px;
        cursor: pointer;
    }
}
</style>
