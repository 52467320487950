<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-27 12:19:03
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-12 20:57:31
-->
<template>
    <div class="search-body">
        <div class="l">
            <div class="child all" :class="{ select: state.selectAll }" @click="handleSelectAll">
                <div class="r-out">
                    <div class="r-inner"></div>
                </div>
                <span class="name one-line">全部资源</span>
                <span class="number" v-if="state.totalCount > 0">&ensp;({{ state.totalCount }})</span>
            </div>
            <template v-for="(item, index) in state.trees" :key="index">
                <div class="child" :class="{ select: item.select }" @click="handleSelectItem(item)">
                    <template v-if="item.children && item.children.length > 0">
                        <el-icon v-if="!item.expand" @click.stop.prevent="item.expand = !item.expand;emit('handleComplete')">
                            <CaretRight />
                        </el-icon>
                        <el-icon v-if="item.expand" @click.stop.prevent="item.expand = !item.expand;emit('handleComplete')">
                            <CaretRight class="xiajiantou" />
                        </el-icon>
                    </template>
                    <div class="r-out">
                        <div class="r-inner"></div>
                    </div>
                    <span class="name one-line">{{ item.Name }}</span>
                    <span class="number" v-if="item.Count > 0">&ensp;({{ item.Count }})</span>
                </div>
                <div v-if="item.expand" class="child-l2">
                    <template v-for="(iitem, iindex) in item.children" :key="iindex">
                        <div class="child" :class="{ select: iitem.select }" @click="handleSelectChildItem(iitem, item)">
                            <template v-if="iitem.children && iitem.children.length > 0 && state.selectIndex != 0">
                                <el-icon v-if="!iitem.expand" @click.stop.prevent="iitem.expand = !iitem.expand;emit('handleComplete')">
                                    <CaretRight />
                                </el-icon>
                                <el-icon v-if="iitem.expand" @click.stop.prevent="iitem.expand = !iitem.expand;emit('handleComplete')">
                                    <CaretRight class="xiajiantou" />
                                </el-icon>
                            </template>
                            <div class="r-out">
                                <div class="r-inner"></div>
                            </div>
                            <span class="name one-line">{{ iitem.Name }}</span>
                            <span class="number" v-if="iitem.Count > 0">&ensp;({{ iitem.Count }})</span>
                        </div>
                        <div v-if="iitem.expand" class="child-l3">
                            <template v-for="(iiitem, iiindex) in iitem.children" :key="iiindex" v-if="state.selectIndex != 0">
                                <div class="child" :class="{ select: iiitem.select }"
                                    @click="handleSelectChildItem(iiitem, iitem)">
                                    <template v-if="iiitem.children && iiitem.children.length > 0">
                                        <el-icon v-if="!iiitem.expand" @click.stop.prevent="iiitem.expand = !iiitem.expand;emit('handleComplete')">
                                            <CaretRight />
                                        </el-icon>
                                        <el-icon v-if="iiitem.expand" @click.stop.prevent="iiitem.expand = !iiitem.expand;emit('handleComplete')">
                                            <CaretRight class="xiajiantou" />
                                        </el-icon>
                                    </template>
                                    <div class="r-out">
                                        <div class="r-inner"></div>
                                    </div>
                                    <span class="name one-line">{{ iiitem.Name }}</span>
                                    <span class="number" v-if="iiitem.Count > 0">&ensp;({{ iiitem.Count }})</span>
                                </div>
                                <div v-if="iiitem.expand" class="child-l4">
                                    <template v-for="(iiiitem, iiiindex) in iiitem.children" :key="iiiindex">
                                        <div class="child" :class="{ select: iiiitem.select }"
                                            @click="handleSelectChildItem(iiiitem, iiitem)">
                                            <template v-if="iiiitem.children && iiiitem.children.length > 0">
                                                <el-icon v-if="!iiiitem.expand" @click.stop.prevent="iiiitem.expand = !iiiitem.expand;emit('handleComplete')">
                                                    <CaretRight />
                                                </el-icon>
                                                <el-icon v-if="iiiitem.expand" @click.stop.prevent="iiiitem.expand = !iiiitem.expand;emit('handleComplete')">
                                                    <CaretRight class="xiajiantou" />
                                                </el-icon>
                                            </template>
                                            <div class="r-out">
                                                <div class="r-inner"></div>
                                            </div>
                                            <span class="name one-line">{{ iiiitem.Name }}</span>
                                            <span class="number" v-if="iiiitem.Count > 0">&ensp;({{ iiiitem.Count }})</span>
                                        </div>
                                        <div v-if="iiiitem.expand" class="child-l5">
                                            <template v-for="(iiiiitem, iiiiindex) in iiiitem.children" :key="iiiiindex">
                                                <div class="child" :class="{ select: iiiiitem.select }"
                                                    @click="handleSelectChildItem(iiiiitem, iiiitem)">
                                                    <template v-if="iiiiitem.children && iiiiitem.children.length > 0">
                                                        <el-icon v-if="!iiiiitem.expand" @click.stop.prevent="iiiiitem.expand = !iiiiitem.expand;emit('handleComplete')">
                                                            <CaretRight />
                                                        </el-icon>
                                                        <el-icon v-if="iiiiitem.expand" @click.stop.prevent="iiiiitem.expand = !iiiiitem.expand;emit('handleComplete')">
                                                            <CaretRight class="xiajiantou" />
                                                        </el-icon>
                                                    </template>
                                                    <div class="r-out">
                                                        <div class="r-inner"></div>
                                                    </div>
                                                    <span class="name one-line">{{ iiiiitem.Name }}</span>
                                                    <span class="number" v-if="iiiiitem.Count > 0">&ensp;({{ iiiiitem.Count }})</span>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <div class="r">
            <div class="t">
                <div class="px">排序</div>
                <div class="item" :class="{ select: state.sortSelectIndex == 0, sort1: state.sortType1 == 1 }"
                    @click="selectType(0)">
                    发布时间
                    <svg-icon class="icon" iconClass="down" :color="state.sortSelectIndex == 0 ? '#FFFFFF' : '#2B333B'" />
                </div>
                <div class="item" :class="{ select: state.sortSelectIndex == 1, sort1: state.sortType2 == 1 }"
                    @click="selectType(1)">
                    阅读量
                    <svg-icon class="icon" iconClass="down" :color="state.sortSelectIndex == 1 ? '#FFFFFF' : '#2B333B'" />
                </div>

                <div class="qiehuan">
                    <img class="qiehuan1" @click="showTypeClick('2')" src="@/assets/images/search/more.png" />
                    <img class="qiehuan2" @click="showTypeClick('1')" src="@/assets/images/search/xiangqing.png" />
                </div>
            </div>
            <div class="b">
                <template v-if="state.showType == '1'">
                    <div class="theader">
                        <div class="th th1" :class="['select' + state.selectIndex]">标题</div>
                        <div class="th th2" :class="['select' + state.selectIndex]"
                            v-if="state.selectIndex == 0 || state.selectIndex == 1">所属栏目</div>
                        <div class="th th2" :class="['select' + state.selectIndex]" v-else-if="state.selectIndex == 2">出版社
                        </div>
                        <div class="th th2" :class="['select' + state.selectIndex]" v-else>文件类型</div>
                        <div class="th th3" :class="['select' + state.selectIndex]">时间</div>
                        <div class="th th4" :class="['select' + state.selectIndex]" v-if="state.selectIndex == 0">类型</div>
                        <div class="th th5" :class="['select' + state.selectIndex]">阅读量</div>
                    </div>
                    <div class="tbody">
                        <div class="item" v-for="(item, i) in state.list" :key="i">
                            <div class="index one-line">
                                {{ (state.cp - 1) * state.ps + i + 1 }}
                            </div>
                            <div class="title one-line" :class="['select' + state.selectIndex]" @click="goDetailPage(item)">
                                {{ item.Title }}</div>
                            <div class="lanmu one-line" :class="['select' + state.selectIndex]"
                                v-if="state.selectIndex == 0 || state.selectIndex == 1">{{ item.ColumnName }}</div>
                            <div class="lanmu one-line" :class="['select' + state.selectIndex]"
                                v-else-if="state.selectIndex == 2">{{ item.Publisher }}</div>
                            <div class="lanmu one-line" :class="['select' + state.selectIndex]" v-else>{{ item.FileType }}
                            </div>
                            <div class="time one-line" :class="{ cursor: item.ObjectType == 6 }"
                                @click.stop.prevent="goParentMagazine(item)">{{ item.Date }}</div>
                            <div v-if="state.selectIndex == 0" class="type one-line"
                                :class="['select' + state.selectIndex]">{{ item.ObjectTypeName }}</div>
                            <div class="read one-line" :class="['select' + state.selectIndex]">{{ item.Hits }}</div>
                            <div class="icons">
                                <template v-for="(iitem, index) in item?.RelationData" :key="index">
                                    <div v-if="iitem.ObjectType == 5" class="icon"
                                        @click.stop.prevent="goReadPageById(iitem.StrId)">
                                        <!-- 视频 -->
                                        <el-tooltip :content="iitem.Title" effect="light">
                                            <img src="@/assets/images/search/shipin.png" />
                                        </el-tooltip>
                                    </div>
                                    <div v-else-if="iitem.ObjectType == 3 && iitem.Language == '蒙文'" class="icon"
                                        @click.stop.prevent="goReadPageById(iitem.StrId)">
                                        <!-- 蒙文 -->
                                        <el-tooltip :content="iitem.Title" effect="light">
                                            <img src="@/assets/images/search/meng.png" />
                                        </el-tooltip>
                                    </div>
                                    <div v-else-if="iitem.ObjectType == 3 && iitem.Language != '蒙文'" class="icon"
                                        @click.stop.prevent="goReadPageById(iitem.StrId)">
                                        <!-- 其他 -->
                                        <el-tooltip :content="iitem.Title" effect="light">
                                            <img src="@/assets/images/search/han.png" />
                                        </el-tooltip>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="item-empty" v-if="state.list.length == 0">
                            暂无数据
                        </div>
                    </div>
                </template>
                <template v-else-if="state.showType == '2'">
                    <div class="nine-box">
                        <div class="nine-item" v-for="(item, index) in state.list" :key="index">
                            <div class="nine-l">
                                <template v-if="item.ObjectType == 6">
                                    <img src="@/assets/images/search/wenzhang.png" @click="goDetailPage(item)"/>
                                </template>
                                <template v-else-if="item.ObjectType == 3 && !item.Cover">
                                    <img src="@/assets/images/search/yinpin.png" @click="goDetailPage(item)"/>
                                </template>
                                <template v-else>
                                    <img v-lazy="item.Cover" @click="goDetailPage(item)"/>
                                </template>

                                <template v-if="item.ObjectType == 6">
                                    <img class="icon" src="@/assets/images/search/hb.png" />
                                </template>
                                <template v-else-if="item.ObjectType == 2">
                                    <img class="icon" src="@/assets/images/search/sk.png" />
                                </template>
                                <template v-else-if="item.ObjectType == 4">
                                    <img class="icon" src="@/assets/images/search/tp.png" />
                                </template>
                                <template v-else-if="item.ObjectType == 5">
                                    <img class="icon" src="@/assets/images/search/sp.png" />
                                </template>
                                <template v-else-if="item.ObjectType == 3">
                                    <img class="icon" src="@/assets/images/search/yp.png" />
                                </template>
                            </div>
                            <div class="nine-r">
                                <div class="nine-title">
                                    <ellipsis-view-mult :title="item.Title"
                                        @click="goDetailPage(item)"></ellipsis-view-mult>
                                </div>
                                <div class="nine-type-item one-line" v-if="item.ObjectType == 6">
                                    所属栏目：{{ item.ColumnName ? item.ColumnName : '暂无' }}
                                </div>
                                <div class="nine-type-item one-line" :class="{ cursor: item.ObjectType == 6 }"
                                    @click.stop.prevent="goParentMagazine(item)">
                                    时&emsp;&emsp;间：{{ item.Date ? item.Date : '暂无' }}
                                </div>
                                <div class="nine-type-item one-line">
                                    类&emsp;&emsp;型：{{ item.ObjectTypeName ? item.ObjectTypeName : '暂无' }}
                                </div>

                                <div class="nine-type-item one-line">
                                    阅&ensp;读&ensp;量：{{ item.Hits }}
                                </div>

                                <div class="nine-icons">
                                    <template v-for="(iitem, iindex) in item?.RelationData" :key="iindex">
                                        <div v-if="iitem.ObjectType == 5" class="icon"
                                            @click.stop.prevent="goReadPageById(iitem.StrId)">
                                            <!-- 视频 -->
                                            <el-tooltip :content="iitem.Title" effect="light">
                                                <img src="@/assets/images/search/shipin.png" />
                                            </el-tooltip>
                                        </div>
                                        <div v-else-if="iitem.ObjectType == 3 && iitem.Language == '蒙文'" class="icon"
                                            @click.stop.prevent="goReadPageById(iitem.StrId)">
                                            <!-- 蒙文 -->
                                            <el-tooltip :content="iitem.Title" effect="light">
                                                <img src="@/assets/images/search/meng.png" />
                                            </el-tooltip>
                                        </div>
                                        <div v-else-if="iitem.ObjectType == 3 && iitem.Language != '蒙文'" class="icon"
                                            @click.stop.prevent="goReadPageById(iitem.StrId)">
                                            <!-- 其他 -->
                                            <el-tooltip :content="iitem.Title" effect="light">
                                                <img src="@/assets/images/search/han.png" />
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="nine-item-empty" v-if="state.list.length == 0">
                            暂无数据
                        </div>
                    </div>
                </template>

                <div class="pager" v-if="getPageCount() > 1">
                    <div class="btn" :class="{ disabled: state.cp == 1 }" @click="handleLast">
                        上一页
                    </div>
                    <div class="count">{{ state.cp }} / {{ getPageCount() }}</div>
                    <div class="btn" :class="{ disabled: state.cp >= getPageCount() }" @click="handleNext">
                        下一页
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineExpose } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import loading from "@/utils/loading";
import routerUtil from "@/common/router";
import { getToken } from "@/request";
import EllipsisViewMult from "@/components/common/EllipsisViewMult.vue";
import { resolve } from "path";

const state = reactive({
    showLeft: false,
    selectIndex: 0,
    sortSelectIndex: 0,
    totalCount: 0,
    selectAll: false,
    keyword: "",
    dates: [] as any[],
    types: [] as any[],
    typesOld: [] as any[],
    trees: [] as any[],
    treesOld: [] as any[],
    categoryIds: [] as any[],

    id: 0,
    ps: 10,
    cp: 1,
    list: [] as any[],
    listCount: 0,
    sortType1: 2,
    sortType2: 2,

    showType: '1',      // 1列表模式 2九宫格模式
    firstInit: true,
});

const emit = defineEmits(["handleComplete"]);

const getPageCount = () => {
    if (state.listCount % state.ps == 0) {
        return state.listCount / state.ps;
    } else {
        return Math.floor(state.listCount / state.ps) + 1;
    }
};

const selectType = (index: number) => {
    if (state.sortSelectIndex == index) {
        state.sortSelectIndex = index;
        state.cp = 1;

        if (index == 0) {
            if (state.sortType1 == 1) {
                state.sortType1 = 2
            } else {
                state.sortType1 = 1
            }
        } else {
            if (state.sortType2 == 1) {
                state.sortType2 = 2
            } else {
                state.sortType2 = 1
            }
        }

        searchData();
    } else {
        state.sortSelectIndex = index;
        state.cp = 1;

        searchData();
    }
};

const scrollToTop = () => {
    window.scrollTo({ top: 0 });
}

const handleLast = () => {
    if (state.cp <= 1) {
        state.cp = 1;
        return;
    }

    state.cp -= 1;
    searchData();
    scrollToTop();
};

const handleNext = () => {
    if (state.cp >= getPageCount()) {
        state.cp = getPageCount();
        return;
    }

    state.cp += 1;
    searchData();
    scrollToTop();
};

const getCategorysData = (keyword: string, dates: any[]) => {
    return new Promise((resolve, reject) => {
        api.categoryList(
            {
                type: '2,3,4,5,6',
            },
            (res: any) => {
                state.treesOld = res;
                resolve()
            },
            (err: any) => {
                console.log(err);
            }
        );
    })
}

const getCountsData = (keyword: string, dates: any[]) => {
    return new Promise((resolve, reject) => {
        let param = {
            keyword: keyword,
            type: 2
        } as any

        if (dates.length > 0) {
            param.sDate = moment(dates[0]).format('YYYY-MM-DD')
            param.eDate = moment(dates[1]).format('YYYY-MM-DD')
        }

        api.contentInfoSearchCount(
            param,
            (res: any) => {
                state.types = res;
                resolve()
            },
            (err: any) => {
                console.log(err);
            }
        );
    })
}

const getCountById = (id: string) => {
    for (let i = 0; i < state.types.length; i++) {
        const item = state.types[i];
        if (item.X == id) {
            return item.Z;
        }
    }

    return null;
};

const getTotolCount = () => {
    if (state.selectIndex == 0) {
        return getCountById("0");
    } else {
        return getCountById(`0-${getObjectType()}`);
    }
};

const initTreeCount = (trees: any[], type: string) => {
    state.totalCount = Number(getTotolCount());

    for (let i = 0; i < trees.length; i++) {
        const item = trees[i];
        item.Count = getCountById(item.StrId);

        if (type == 'search') {
            if (state.firstInit && state.id != -1) {
                if (item.StrId == state.id) {
                    item.select = true;
                    selectAllRadio(item.children, true)
                }
            } else if (state.firstInit) {
                item.select = true
                selectAllRadio(item.children, true)
            }
        } else {
            item.select = true
            selectAllRadio(item.children, true)
        }

        checkSelectAll()

        if (!('expand' in item)) {
            item.expand = false;
        }

        if (item.children) {
            initTreeCount(item.children, type);
        }
    }
};

const initData = (selectIndex: number, keyword: string, dates: any[], id: number, flag: boolean) => {
    state.showLeft = false;

    if (!flag) {
        state.selectAll = true;
    } else {
        checkSelectAll()
    }

    state.keyword = keyword;
    state.dates = dates;
    state.selectIndex = selectIndex;
    state.cp = 1;
    state.id = id;
};

const initCountData = async (keyword: string, dates: any[], flag: boolean, type: string) => {
    if (type == 'search') {
        if (state.treesOld.length == 0) {
            await getCategorysData(keyword, dates);
        }
        await getCountsData(keyword, dates);

        if (state.selectIndex == 0) {
            state.trees = state.treesOld
        } else {
            let menuType = getObjectType()
            for (let i = 0; i < state.treesOld.length; i++) {
                const item = state.treesOld[i];
                if (item.Type == menuType) {
                    state.trees = item.children
                    break
                }
            }
        }

        initTreeCount(state.trees, type);
        searchData();
    } else {
        if (state.selectIndex == 0) {
            state.trees = state.treesOld
        } else {
            let menuType = getObjectType()
            for (let i = 0; i < state.treesOld.length; i++) {
                const item = state.treesOld[i];
                if (item.Type == menuType) {
                    state.trees = item.children
                    break
                }
            }
        }

        initTreeCount(state.trees, type);
        searchData();
    }

    state.firstInit = false;
};


const selectAllRadio = (trees: any[], flag: boolean) => {
    trees.forEach((item) => {
        item.select = flag;

        if (item.children.length > 0) {
            selectAllRadio(item.children, flag);
        }
    });
};

const handleSelectAll = () => {
    state.selectAll = !state.selectAll;
    selectAllRadio(state.trees, state.selectAll);

    state.cp = 1;
    searchData();
};

const checkSelectAll = () => {
    let allSelect = true;
    for (let i = 0; i < state.trees.length; i++) {
        const iitem = state.trees[i];
        if (!iitem.select) {
            allSelect = false;
            break;
        }
    }

    if (allSelect) {
        state.selectAll = true;
    } else {
        state.selectAll = false;
    }
}

const handleSelectItem = (item: any) => {
    state.cp = 1;

    item.select = !item.select;
    selectAllRadio(item.children, item.select);

    checkSelectAll()

    searchData();
};

const handleSelectChildItem = (item: any, parent: any) => {
    state.cp = 1;

    item.select = !item.select;
    selectAllRadio(item.children, item.select);

    let allSelect = true;
    for (let i = 0; i < parent.children.length; i++) {
        const iitem = parent.children[i];
        if (!iitem.select) {
            allSelect = false;
            break;
        }
    }

    if (allSelect) {
        parent.select = true;
    } else {
        parent.select = false;
    }

    allSelect = true;
    for (let i = 0; i < state.trees.length; i++) {
        const iitem = state.trees[i];
        if (!iitem.select) {
            allSelect = false;
            break;
        }
    }

    if (allSelect) {
        state.selectAll = true;
    } else {
        state.selectAll = false;
    }

    searchData();
    emit("handleComplete")
};

const getObjectType = () => {
    if (state.selectIndex == 0) {
        return "2,3,4,5,6";
    } else if (state.selectIndex == 1) {
        return "6";
    } else if (state.selectIndex == 2) {
        return "2";
    } else if (state.selectIndex == 3) {
        return "4";
    } else if (state.selectIndex == 4) {
        return "5";
    } else if (state.selectIndex == 5) {
        return "3";
    }
};

const searchData = () => {
    let loadingIns = loading.showLoading();
    state.categoryIds = [];
    initCategoryIds(state.trees);

    if (state.categoryIds.length == 0) {
        state.categoryIds.push(0)
    }

    // 全选的话不传 categoryIds
    if (state.selectAll) {
        state.categoryIds = []
    }

    let param = {
        keyword: state.keyword,
        objectType: getObjectType(),
        sortType: state.sortSelectIndex == 0 ? state.sortType1 : state.sortType2,
        categoryIds: state.categoryIds.join(","),
        sort: state.sortSelectIndex == 0 ? "3" : "2",
        ps: state.ps,
        cp: state.cp,
    } as any

    if (state.dates.length > 0) {
        param.sDate = moment(state.dates[0]).format('YYYY-MM-DD')
        param.eDate = moment(state.dates[1]).format('YYYY-MM-DD')
    }

    api.contentInfoSearch(
        param,
        (res: any) => {
            state.list = res.ItemList;
            state.listCount = res.RecordCount;
            loading.hideLoading(loadingIns);
            emit("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const showTypeClick = (p: string) => {
    state.showType = p
    emit("handleComplete");
}

const router = useRouter()

const goParentMagazine = (item: any) => {
    let path = ''
    let id = item.StrId

    switch (item.ObjectType) {
        case 6: {
            id = item.Parent.StrId
            path = '/magazinedetail'
            break;
        }
        case 2: {
            path = '/bookdetail'
            break;
        }
        case 3: {
            path = '/audiodetail'
            break;
        }
        case 4: {
            path = '/imagedetail'
            break;
        }
        case 5: {
            path = '/videodetail'
            break;
        }
    }

    routerUtil.openNewWindow({
        path: path,
        query: {
            id
        }
    }, true)

    router.resolve
};

const goDetailPage = (item: any) => {
    let path = ''
    switch (item.ObjectType) {
        case 6: {
            goReadPageById(item.StrId)
            break;
        }
        case 2: {
            path = '/bookdetail'
            break;
        }
        case 3: {
            path = '/audiodetail'
            break;
        }
        case 4: {
            path = '/imagedetail'
            break;
        }
        case 5: {
            path = '/videodetail'
            break;
        }
    }

    routerUtil.openNewWindow({
        path: path,
        query: {
            id: item.StrId
        }
    }, true)
}

const initCategoryIds = (arr: any[]) => {
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i] as any;
        if (item.select) {
            state.categoryIds.push(item.StrId);
        }

        if (item.children && item.children.length > 0) {
            initCategoryIds(item.children);
        }
    }
};


const goReadPageById = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

defineExpose({
    initData,
    initCountData
});
</script>

<style lang="scss" scoped>
.search-body {
    width: 1562px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .l {
        min-height: calc(100vh - 240px);
        width: 322px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .child {
            height: 64px;
            line-height: 64px;
            font-size: 20px;
            color: #2b333b;
            padding-left: 36px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .xiajiantou {
                transform: rotate(90deg);
                transform-origin: 50% 50%;
            }

            &.all {
                padding-left: 16px;
            }

            .r-out {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                border: 2px solid rgb(206, 206, 206);
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;

                .r-inner {
                    width: 10px;
                    height: 10px;
                    //background-color: rgb(206, 206, 206);
                    border-radius: 2px;
                }
            }

            &.select {
                .r-out {
                    border: 2px solid rgb(110, 139, 116);

                    .r-inner {
                        background-color: rgb(110, 139, 116);
                    }
                }
            }

            .el-icon {
                color: #2b333b;
                margin-left: -27px;
                margin-right: 8px;
            }
        }

        .child-l2 {
            .child {
                padding-left: 56px;

                .name {
                    max-width: 202px;
                }
            }
        }

        .child-l3 {
            .child {
                padding-left: 96px;

                .name {
                    max-width: 182px;
                }
            }
        }

        .child-l4 {
            .child {
                padding-left: 136px;

                .name {
                    max-width: 162px;
                }
            }
        }

        .child-l5 {
            .child {
                padding-left: 176px;

                .name {
                    max-width: 142px;
                }
            }
        }
    }

    .r {
        width: 1224px;

        .t {
            width: 100%;
            height: 64px;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            .px {
                margin-left: 24px;
                margin-right: 16px;
                color: #b7bbbf;
                font-size: 18px;
            }

            .item {
                color: #2b333b;
                width: auto;
                text-align: center;
                height: 32px;
                line-height: 32px;
                font-size: 18px;
                cursor: pointer;
                box-sizing: border-box;
                border: 1px solid #dadada;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 12px;
                padding-right: 12px;

                &.select {
                    color: #ffffff;
                    background-color: #6e8b74;
                    border: 1px solid #6e8b74;
                }

                &.sort1 {
                    .icon {
                        transform: rotate(180deg);
                    }
                }

                &:last-child {
                    width: auto;
                }
            }

            .qiehuan {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 24px;

                .qiehuan1 {
                    margin-right: 16px;
                    width: 28px;
                    height: 28px;
                    cursor: pointer;
                }

                .qiehuan2 {
                    width: 28px;
                    height: 28px;
                    cursor: pointer;
                }
            }
        }

        .b {
            margin-top: 16px;
            background-color: #ffffff;
            min-height: calc(100vh - 240px);

            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        text-align: left;
                        width: 524px;
                        padding-left: 154px;

                        &.select1 {
                            width: 644px;
                        }

                        &.select2 {
                            width: 584px;
                        }

                        &.select3 {
                            width: 584px;
                        }

                        &.select4 {
                            width: 584px;
                        }

                        &.select5 {
                            width: 584px;
                        }
                    }

                    &.th2 {
                        width: 160px;
                        text-align: center;

                        &.select2 {
                            width: 220px;
                        }

                        &.select3 {
                            width: 220px;
                        }

                        &.select4 {
                            width: 220px;
                        }

                        &.select5 {
                            width: 220px;
                        }
                    }

                    &.th3 {
                        width: 170px;
                        text-align: center;
                    }

                    &.th4 {
                        width: 120px;
                        text-align: center;
                    }

                    &.th5 {
                        width: 120px;
                        text-align: center;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;

                .item {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;

                    &:hover {
                        background-color: #EBEBEB;
                    }

                    .index {
                        width: 60px;
                    }

                    .title {
                        width: 464px;
                        text-align: left;
                        cursor: pointer;

                        &.select1 {
                            width: 584px;
                        }

                        &.select2 {
                            width: 524px;
                        }

                        &.select3 {
                            width: 524px;
                        }

                        &.select4 {
                            width: 524px;
                        }

                        &.select5 {
                            width: 524px;
                        }
                    }

                    .lanmu {
                        width: 160px;

                        &.select2 {
                            width: 220px;
                        }

                        &.select3 {
                            width: 220px;
                        }

                        &.select4 {
                            width: 220px;
                        }

                        &.select5 {
                            width: 220px;
                        }
                    }

                    .time {
                        width: 170px;

                        &.cursor {
                            cursor: pointer;
                        }
                    }

                    .type {
                        width: 120px;
                    }

                    .read {
                        width: 120px;
                    }

                    .icons {
                        padding-left: 16px;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 22px;
                            height: 22px;
                            margin-right: 8px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }
            }

            .nine-box {
                width: 100%;
                box-sizing: border-box;
                padding: 24px;
                padding-bottom: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .nine-item-empty {
                    width: 100%;
                    text-align: center;
                    height: 400px;
                    line-height: 400px;
                }

                .nine-item {
                    width: 576px;
                    background-color: #F7F7F7;
                    border-radius: 4px;
                    box-sizing: border-box;
                    padding: 24px;
                    margin-bottom: 20px;
                    display: flex;

                    .nine-l {
                        width: 160px;
                        height: 212px;
                        margin-right: 16px;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            background-color: #E9E9E9;
                            border-radius: 4px;
                            object-fit: cover;
                            cursor: pointer;
                        }

                        .icon {
                            width: 32px;
                            height: 32px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                    }

                    .nine-r {
                        .nine-title {
                            cursor: pointer;
                            margin-top: 4px;
                            font-size: 16px;
                            line-height: 24px;
                            color: #2B333B;
                            font-weight: 600;
                            max-height: 52px;
                            width: 360px;
                            margin-bottom: 10px;

                            :deep(.common-ellipsis-view) {
                                line-height: 24px;
                                max-height: 52px;
                            }

                            :deep(.common-ellipsis-view .content) {
                                text-align: justify;
                                line-height: 24px;
                                max-height: 52px;
                            }

                            :deep(.common-ellipsis-view .content.two-line) {
                                line-height: 24px;
                                height: 52px;
                                overflow-y: hidden;
                            }
                        }

                        .nine-type-item {
                            width: 360px;
                            text-align: left;
                            font-size: 16px;
                            color: #2B333B;
                            margin-bottom: 12px;

                            &.cursor {
                                cursor: pointer;
                            }
                        }

                        .nine-icons {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .icon {
                                width: 22px;
                                height: 22px;
                                margin-right: 8px;
                                cursor: pointer;
                            }

                            .icon img {
                                width: 22px;
                                height: 22px;
                                border-radius: 4px;
                            }
                        }
                    }
                }

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }
            }

            .pager {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                margin-top: 40px;
                padding-bottom: 24px;

                .btn {
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    color: #2b333b;
                    font-size: 14px;
                    box-sizing: border-box;
                    border: 1px solid #2b333b;
                    border-radius: 4px;
                    cursor: pointer;

                    &.disabled {
                        color: #b7bbbf;
                        border: 1px solid #b7bbbf;
                    }
                }

                .count {
                    font-size: 14px;
                    color: #2b333b;
                    margin-left: 40px;
                    margin-right: 40px;
                }
            }
        }
    }
}
</style>
