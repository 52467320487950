<template>
    <div class="video-labels">
        <div class="header">
            <div class="box">
                <div class="han">融合阅读</div>
                <div class="meng mFont">
                    <span class="meng-vertical">ᠬᠠᠮ</span>
                    <span class="meng-vertical">ᠤᠩᠱᠢᠭᠤ</span>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="menus">
                <div
                    class="menu menu0"
                    :class="{ select: state.selectIndex == 0 }"
                    @click="menuClick(0)"
                >
                    <img
                        v-if="state.selectIndex == 0"
                        src="@/assets/images/common/zhishi.png"
                    />
                    <img v-else src="@/assets/images/common/weizhishi.png" />
                    <span>知识标签</span>
                </div>
                <div
                    class="menu menu1"
                    :class="{ select: state.selectIndex == 1 }"
                    @click="menuClick(1)"
                >
                    <img
                        v-if="state.selectIndex == 1"
                        src="@/assets/images/common/ziyuan.png"
                    />
                    <img v-else src="@/assets/images/common/weiziyuan.png" />
                    <span>资源标签</span>
                </div>
                <div
                    class="menu menu2"
                    :class="{ select: state.selectIndex == 2 }"
                    @click="menuClick(2)"
                >
                    <img
                        v-if="state.selectIndex == 2"
                        src="@/assets/images/common/wangzhan.png"
                    />
                    <img v-else src="@/assets/images/common/weiwangzhan.png" />
                    <span>网站标签</span>
                </div>
            </div>
            <div class="box-labels">
                <div ref="labels" class="labels">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import CaretBottom from "@/components/common/CaretBottom.vue";
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import mpx2vw from "@/utils/px2vw";
import echartsUtil from '@/utils/echarts'
import routerUtil from "@/common/router";

const state = reactive({
    selectIndex: 0,
    //labelCount: 20,
    labelCount: 80,
    labels: [] as any[],
    labelPositions: [] as any[],
    showLabels: false
});

const labels = ref(0)

const menuClick = (index: number) => {
    state.selectIndex = index;
    state.labels = [];
    echartsUtil.getChart().clear()
    labelHotList();
};

const labelHotList = () => {
    api.labelHotList(
        { contentType: 5, type: state.selectIndex + 1, top: state.labelCount },
        (res: any) => {
            state.labels = res;

            state.labels.sort((a: any, b: any) => {
                return b.Cites - a.Cites;
            });

            echartsUtil.initEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
                let series = option.series[0]

                goLabel(series.data[params.dataIndex].item)
            })
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const router = useRouter()
const goLabel = (item: any) => {
    routerUtil.openNewWindow({
        path: "/knowledge",
        query: {
            id: item.StrId
        }
    });
}

onMounted(() => {
    labelHotList();
});
</script>

<style lang="scss" scoped>
.video-labels {
    width: 1500px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;

    .header {
        position: relative;
        width: 100%;
        height: 96px;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9dde1;
        text-align: left;

        .box {
            display: inline-block;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
        }

        .han {
            display: inline-block;
            height: 96px;
            line-height: 96px;
            margin-right: 16px;
            font-size: 28px;
            color: #2b333b;
        }

        .meng {
            box-sizing: border-box;
            padding-top: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #787878;
            font-size: 20px;

            span {
                line-height: 28px;
            }
        }
    }

    .body {
        box-sizing: border-box;
        width: 100%;
        padding: 0px 0px;
        position: relative;

        .menus {
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .menu {
                width: 500px;
                height: 80px;
                line-height: 80px;
                text-align: center;
                font-size: 26px;
                color: #2b333b;

                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                img {
                    margin-right: 20px;
                }

                &.select {
                    color: #ffffff;
                }

                &.menu0 {
                    &.select {
                        background: rgb(254, 86, 33);
                    }

                    img {
                        width: 40px;
                        height: 34px;
                    }
                }

                &.menu1 {
                    &.select {
                        background: #38bf49;
                    }

                    img {
                        width: 40px;
                        height: 32px;
                    }
                }

                &.menu2 {
                    &.select {
                        background: #3d8bff;
                    }

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }

        .box-labels {
            width: 100%;
            height: 560px;
            background-color: #f0f8f1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: -1px;
                bottom: 0;
                background-image: url("@/assets/images/home/stars.png");
                background-position: 50% 50%;
                background-size: 100% 100%;
            }

            .labels {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 560px;
                z-index: 99;
            }
        }
    }
}
</style>
