<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-03 11:05:02
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-13 11:07:43
-->
<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-03 11:05:02
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-03 11:31:56
-->
<template>
    <div class="image-images" v-if="state.category && state.category.length > 0">
        <div class="menus">
            <div class="menu" v-for="(item, index) in state.category" :key="index"
                :class="{ select: state.selectIndex == index }" @click="menuClick(index)">
                <div class="han">
                    {{ item.Name }}
                </div>
                <div class="meng mFont">
                    <span class="meng-vertical" v-for="(iitem, iindex) in getMWords(item.MName)" :key="iindex">{{ iitem
                    }}</span>
                </div>
            </div>
        </div>

        <template v-if="state.category[state.selectIndex].children &&
            state.category[state.selectIndex].children.length == 0
            ">
            <div class="images" id="images" :class="{ empty: state.dataList.length == 0 }">
                <template v-for="(item, index) in state.dataList" :key="index">
                    <a @click="goImage(item)">
                        <img class="image" :src="item.Cover" />
                        <div class="jg-caption one-line">{{ item.Title }}</div>
                    </a>
                </template>
                <a v-if="state.dataList.length > 0" class="more" @click="goMore(state.category[state.selectIndex])">
                    <img class="image" src="@/assets/images/image/more.png" />
                </a>
            </div>
        </template>
        <div v-if="state.dataList.length == 0" class="images-empty">
            <img src="@/assets/images/image/warn.png" /> 抱歉，未找到相关图片
        </div>
        <template v-else-if="state.category[state.selectIndex].children &&
            state.category[state.selectIndex].children.length > 0
            ">
            <template v-for="(item, index) in state.category[state.selectIndex].children" :key="index">
                <div class="title">
                    <img src="@/assets/images/common/biaoti.png" />
                    <div class="tname">
                        {{ item.Name }}
                    </div>
                </div>
                <template v-if="state.dataList &&
                    state.dataList[index] &&
                    state.dataList[index].length > 0
                    ">
                    <div class="images" :id="`images${index}`">
                        <template v-for="(iitem, iindex) in state.dataList[index]" :key="iindex">
                            <a @click="goImage(iitem)">
                                <img class="image" :src="iitem.Cover" />
                                <div class="jg-caption one-line">
                                    {{ iitem.Title }}
                                </div>
                            </a>
                        </template>
                        <a v-if="state.dataList[index].length > 0" class="more"
                            @click="goMore(state.category[state.selectIndex].children[index])">
                            <img class="image" src="@/assets/images/image/more.png" />
                        </a>
                    </div>
                </template>
                <div v-if="state.dataList &&
                    state.dataList[index] &&
                    state.dataList[index].length == 0" class="images-empty">
                    <img src="@/assets/images/image/warn.png" /> 抱歉，未找到相关图片
                </div>
            </template>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";
import store from "@/pinia";
import _ from 'loadsh'

const userStore = store.user();

let zoom = window.innerWidth / 1920

const option = {
    rowHeight: 267 * zoom,
    maxRowHeight: 267 * zoom,
    margins: 16 * zoom,
    lastRow: "nojustify",
    captions: true
};

const emits = defineEmits(["handleComplete", "handleLoad", "handleCategory"]);

const props = defineProps({
    keyword: {
        type: String,
        default: ''
    }
})

watch(props, (newVal) => {
    state._keyword = newVal.keyword
})

const state = reactive({
    _keyword: '',
    id: '',
    selectIndex: 0,
    category: [] as any[],
    dataList: [] as any[],

    typeList: [] as any[],
    typeSelectList: [] as any[],
    typeSelectStringList: [] as any[],
});

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const categoryList = () => {
    api.categoryList(
        { type: 4, showOther: true },
        async (res: any) => {
            let category = res[0].children

            state.typeList = _.cloneDeep(res[0].children)
            state.category = res[0].children.filter((item: any) => {
                return item.Name != '其他'
            })
            
            emits('handleCategory', category)
            
            if (state.id) {
                for (let i = 0; i < state.category.length; i++) {
                    const item = state.category[i];
                    if (item.StrId == state.id) {
                        state.selectIndex = i;
                        break
                    }
                }
            } else {
                state.selectIndex = 0;
            }

            state.dataList = [];

            initImages();
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoNewList = (categoryId: string) => {
    return new Promise<any[]>((resolve, reject) => {
        api.contentInfoNewList(
            { top: 20, objectType: 4, categoryId },
            async (res: any) => {
                let dataList = res;

                console.log(dataList);
                resolve(dataList as any[]);
            },
            (err: any) => {
                console.log(err);
            }
        );
    });
};

const initImages = async () => {
    emits("handleLoad");

    if (state.category[state.selectIndex].children.length == 0) {
        state.dataList = await contentInfoNewList(
            state.category[state.selectIndex].StrId
        );

        setTimeout(() => {
            $("#images")
                .justifiedGallery(option)
                .on("jg.complete", (e: any) => {
                    let items = e.target.children
                    let yarr = []

                    if(items.length >= 2) {
                        for (let i = 0; i < items.length; i++) {
                            const element = items[i];
                            let y = element.children[0].y

                            if(y > yarr[yarr.length - 1]) {
                                yarr = []
                                yarr.push(y)
                            } else {
                                yarr.push(y)
                            }
                        }

                        if(yarr.length == 1) {
                            items[items.length - 1].style.top = items[items.length - 2].style.top
                            items[items.length - 1].style.width = items[items.length - 2].style.width
                            items[items.length - 2].remove()
                            $("#images").justifiedGallery(option)
                        }
                    }

                    emits("handleComplete");
                });
        }, 200);
    } else {
        for (let i = 0; i < state.category[state.selectIndex].children.length; i++) {
            const item = state.category[state.selectIndex].children[i];
            state.dataList.push(await contentInfoNewList(item.StrId));
        }

        setTimeout(() => {
            for (let i = 0; i < state.category[state.selectIndex].children.length; i++) {
                $(`#images${i}`)
                    .justifiedGallery(option)
                    .on("jg.complete", (e: any) => {
                        let items = e.target.children
                        let yarr = []

                        if(items.length >= 2) {
                            for (let i = 0; i < items.length; i++) {
                                const element = items[i];
                                let y = element.children[0].y

                                if(y > yarr[yarr.length - 1]) {
                                    yarr = []
                                    yarr.push(y)
                                } else {
                                    yarr.push(y)
                                }
                            }

                            if(yarr.length == 1) {
                                items[items.length - 1].style.top = items[items.length - 2].style.top
                                items[items.length - 1].style.width = items[items.length - 2].style.width
                                items[items.length - 2].remove()
                                $(`#images${i}`).justifiedGallery(option)
                            }
                        }

                        emits("handleComplete");
                    });
            }
        }, 200);
    }
};

const menuClick = async (index: number) => {
    state.selectIndex = index;
    state.dataList = [];

    initImages();
};

const router = useRouter();
const goImage = (item: any) => {
    routerUtil.openNewWindow({
        path: `/imagedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const initTypeList = (list: any[], parent: any) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        item.select = false
        item.parent = parent
        if(item.children && item.children.length > 0) {
            initTypeList(item.children, item)
        }
    }
}

const getTypeSelect = (list: any[]) => {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if(item.select) {
            state.typeSelectList.push(item)

            if(item.parent && !item.parent.parent) {
                state.typeSelectStringList.push(item.Name)
            } else if(item.parent && item.parent.parent) {
                state.typeSelectStringList.push(item.Name)
            } else if(!item.parent) {
                state.typeSelectStringList.push(item.Name)
            }
        } else {
            getTypeSelect(item.children)
        }
    }
}

const getTypeSelectList = (typeList: any[]) => {
    state.typeSelectList = []
    state.typeSelectStringList = []
    getTypeSelect(typeList)
}

const typeChildrenCheck = (item: any, flag: boolean) => {
    if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
            const element = item.children[i];
            element.select = flag
            typeChildrenCheck(element, flag)
        }
    }
}

const typeParentCheck = (item: any) => {
    if(item.parent && item.parent.children && item.parent.children.length > 0) {
        let flag = true
        for (let i = 0; i < item.parent.children.length; i++) {
            const element = item.parent.children[i];

            if(!element.select) {
                flag = false
                break
            }
        }

        item.parent.select = flag
        typeParentCheck(item.parent)
    }
}

const typeClickFun = (typeList: any[], item: any) => {
    item.select = true

    typeChildrenCheck(item, item.select)
    typeParentCheck(item)

    getTypeSelectList(typeList)
}

const getRealItem: any = (typeList: any[], item: any) => {
    for (let i = 0; i < typeList.length; i++) {
        const element = typeList[i];
        
        if(item.StrId == element.StrId) {
            return element
        }

        if(item.children) {
            let res = getRealItem(element.children, item)
            if(res) {
                return res
            }
        }
    }

    return null
}

const goMore = (item: any) => {
    let typeList = _.cloneDeep(state.typeList)
    initTypeList(typeList, null)

    let realItem = getRealItem(typeList, item)
    typeClickFun(typeList, realItem)

    userStore.setImageSearchInfo({
        areaList: [],
        areaSelectList: [],
        areaSelectStringList: [],
        allAreaSelect: false,

        typeFirstInit: false,
        typeList: typeList,
        typeSelectList: state.typeSelectList,
        typeSelectStringList: state.typeSelectStringList,
        allTypeSelect: false,

        yearStart: '',
        yearEnd: '',
        yearString: ''
    })

    routerUtil.openNewWindow({
        path: `/imagemore`,
        query: {
            keyword: state._keyword,
            id: item.StrId
        },
    });
};

const route = useRoute();

onMounted(() => {
    state.id = route.query.id
    state._keyword = props.keyword
    categoryList();
});
</script>

<style lang="scss" scoped>
.image-images {
    width: 1500px;
    min-height: 320px;

    .menus {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        border-radius: 4px;
        background-color: #ffffff;
        overflow: hidden;
        margin-bottom: 0px;

        .menu {
            width: 187px;
            height: 138px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                right: 0px;
                top: 29px;
                width: 2px;
                height: 80px;
                border-radius: 2px;
                background-color: #f5f5f5;
            }

            &:last-child {
                width: 192px;

                &::after {
                    display: none;
                }
            }

            &.select {
                background-color: #f2e6ce;

                &::after {
                    display: none;
                }
            }

            .han {
                margin-top: 24px;
                margin-bottom: 8px;
                color: #2b333b;
                font-size: 22px;
                text-align: center;
                font-weight: 600;
            }

            .meng {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                span {
                    line-height: 22px;
                    font-size: 22px;
                    color: #2b333b;
                }
            }
        }
    }

    .title {
        box-sizing: border-box;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 2px solid #f2e6ce;
        border-bottom: 2px solid #f2e6ce;
        background-color: #ffffff;

        img {
            margin-left: 30px;
            margin-right: 8px;
            width: 38px;
            height: 28px;
        }

        .tname {
            font-size: 28px;
            color: #2b333b;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .images-empty {
        height: 267px;
        line-height: 267px;
        text-align: center;
        font-size: 20px;
        color: #2b333b;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 24px !important;
            height: 24px !important;
            margin-right: 8px;
            margin-top: 0 !important;
            margin-left: 0 !important;
            position: relative !important;
            top: 0 !important;
        }
    }

    .images {
        width: 1528px;
        margin-left: -14px;
        margin-top: -16px;
        border-top: 8px solid transparent;
        margin-bottom: 4px;
        opacity: 1;
        animation: show 2s;

        &.empty {
            display: none;
        }

        a {
            border-radius: 8px;
            cursor: pointer;

            &.more {
                &::after {
                    content: "查看更多 >";
                    display: flex;
                    position: absolute;
                    z-index: 99;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    color: #ffffff;
                    font-size: 32px;
                    justify-content: center;
                    align-items: center;
                }
            }

            .jg-caption {
                font-size: 20px;
                padding-left: 40px;
                padding-right: 40px;

                .buttons {
                    width: 300px;
                    margin-top: 32px;
                    box-sizing: border-box;
                    padding-right: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .button {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        img {
                            margin-right: 18px;
                        }

                        &.b1 {
                            img {
                                width: 28px;
                                height: 29px;
                            }
                        }

                        &.b2 {
                            img {
                                width: 30px;
                                height: 32x;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
