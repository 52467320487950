<template>
    <div class="c-user-detail">
        <div class="header">
            <div class="han">个人中心</div>
            <div class="meng mFont">
                <span class="meng-vertical">ᠬᠤᠪᠢ</span>
                <span class="meng-vertical">ᠬᠥᠮᠦᠨ᠎ᠦ</span>
                <span class="meng-vertical">ᠲᠥᠪ</span>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <div class="menu" @click="menuSelect(0)" :class="{ select: state.selectIndex == 0 }">
                    <img v-if="state.selectIndex == 0" src="@/assets/images/user/0_1.png" />
                    <img v-else src="@/assets/images/user/0_0.png" />
                    <span> 个人信息 </span>
                </div>
                <div class="menu" @click="menuSelect(1)" :class="{ select: state.selectIndex == 1 }">
                    <img v-if="state.selectIndex == 1" src="@/assets/images/user/1_1.png" />
                    <img v-else src="@/assets/images/user/1_0.png" />
                    <span> 修改密码 </span>
                </div>
                <div class="menu" @click="menuSelect(2)" :class="{ select: state.selectIndex == 2 }">
                    <img v-if="state.selectIndex == 2" src="@/assets/images/user/2_1.png" />
                    <img v-else src="@/assets/images/user/2_0.png" />
                    <span> 浏览历史 </span>
                </div>
                <div class="menu" @click="menuSelect(3)" :class="{ select: state.selectIndex == 3 }">
                    <img v-if="state.selectIndex == 3" src="@/assets/images/user/3_1.png" />
                    <img v-else src="@/assets/images/user/3_0.png" />
                    <span> 我的收藏 </span>
                </div>
                <div class="menu" @click="menuSelect(4)" :class="{ select: state.selectIndex == 4 }">
                    <img v-if="state.selectIndex == 4" src="@/assets/images/user/4_1.png" />
                    <img v-else src="@/assets/images/user/4_0.png" />
                    <span> 我的书签 </span>
                </div>
                <div class="menu" @click="menuSelect(5)" :class="{ select: state.selectIndex == 5 }">
                    <img v-if="state.selectIndex == 5" src="@/assets/images/user/5_1.png" />
                    <img v-else src="@/assets/images/user/5_0.png" />
                    <span> 我的划线 </span>
                </div>
                <div class="menu" @click="menuSelect(6)" :class="{ select: state.selectIndex == 6 }">
                    <img v-if="state.selectIndex == 6" src="@/assets/images/user/6_1.png" />
                    <img v-else src="@/assets/images/user/6_0.png" />
                    <span> 我的笔记 </span>
                </div>
            </div>
            <div v-if="state.selectIndex == 0" class="right0">
                <div class="ftitle">个人信息</div>
                <div class="fitem">
                    <div class="fl">用户名</div>
                    <div class="fr">
                        <el-input class="input" v-model="userInfo.UserName" placeholder="" />
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">手机号</div>
                    <div class="fr">
                        <el-input class="input" v-model="userInfo.MobileNumber" placeholder="" />
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">性别</div>
                    <div class="fr">
                        <el-select v-model="userInfo.Sex" :clearable="false" placeholder="" fit-input-width
                            :teleported="false">
                            <el-option v-for="(item, index) in sexs" :label="item" :key="index" :value="item">{{ item
                            }}</el-option>
                        </el-select>
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">年龄</div>
                    <div class="fr">
                        <el-input class="input" v-model="userInfo.Age" placeholder="" />
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">民族</div>
                    <div class="fr">
                        <el-select v-model="userInfo.Nation" :clearable="false" placeholder="" fit-input-width
                            :teleported="false">
                            <el-option v-for="(item, index) in state.nations" :label="item" :key="index" :value="item">{{
                                item }}</el-option>
                        </el-select>
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">学历</div>
                    <div class="fr">
                        <el-select v-model="userInfo.Education" :clearable="false" placeholder="" fit-input-width
                            :teleported="false">
                            <el-option v-for="(item, index) in state.educations" :label="item" :key="index" :value="item">{{
                                item }}</el-option>
                        </el-select>
                    </div>
                </div>
                <div class="fitem">
                    <div class="fr">
                        <div class="submit" @click="submitEdit">保存</div>
                    </div>
                </div>
            </div>
            <div v-if="state.selectIndex == 1" class="right1">
                <div class="ftitle">修改密码</div>
                <div class="fitem">
                    <div class="fl">原密码</div>
                    <div class="fr">
                        <el-input class="input" type="password" v-model="state.pwForm.oldPassword" placeholder=""
                            :class="{ err: state.pwForm.errTextOld }" @input="state.pwForm.errTextOld = ''" />
                        <div v-if="state.pwForm.errTextOld" class="err-text">
                            {{ state.pwForm.errTextOld }}
                        </div>
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">新密码</div>
                    <div class="fr">
                        <el-input class="input" type="password" v-model="state.pwForm.password" placeholder=""
                            :class="{ err: state.pwForm.errText }" @input="state.pwForm.errText = ''" />
                        <div v-if="state.pwForm.errText" class="err-text">
                            {{ state.pwForm.errText }}
                        </div>
                    </div>
                </div>
                <div class="fitem">
                    <div class="fl">确认密码</div>
                    <div class="fr">
                        <el-input class="input" type="password" v-model="state.pwForm.passwordRepeat" placeholder="" />
                        <div v-if="state.pwForm.errTextRepeat" class="err-text">
                            {{ state.pwForm.errTextRepeat }}
                        </div>
                    </div>
                </div>
                <div class="fitem">
                    <div class="fr">
                        <div class="submit" @click="submitPassword">修改密码</div>
                    </div>
                </div>
            </div>
            <div v-if="state.selectIndex == 2" class="right2">
                <div class="ftitle">浏览历史</div>
                <div class="fthead">
                    <div class="th th1">资源名称</div>
                    <div class="th th2">位置</div>
                    <div class="th th3">类型</div>
                    <div class="th th4">时间</div>
                </div>
                <div class="ftbody">
                    <div class="item" v-for="(item, index) in state.history" :key="index">
                        <div class="th th0 one-line">{{ index + 1 }}</div>
                        <div class="th th1 one-line">{{ item.ContentTitle }}</div>
                        <div class="th th2 one-line">{{ item.SProcess }}</div>
                        <div class="th th3 one-line">{{ item.ObjectTypeName }}</div>
                        <div class="th th4 one-line">
                            {{ moment(item.ReadTime).format("YYYY年MM月DD日") }}
                        </div>
                        <div class="operate">
                            <div class="btn btn1" @click="goDetailPage(item)">详情</div>
                            <div class="btn btn2" @click="goReadPage(item)">阅读</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="state.selectIndex == 3" class="right3">
                <div class="ftitle">我的收藏</div>
                <div class="fthead">
                    <div class="th th1">资源名称</div>
                    <div class="th th2">类型</div>
                    <div class="th th3">时间</div>
                </div>
                <div class="ftbody">
                    <div class="item" v-for="(item, index) in state.favoriteList" :key="index">
                        <div class="th th0 one-line">{{ index + 1 }}</div>
                        <div class="th th1 one-line">{{ item.ObjectTitle }}</div>
                        <div class="th th2 one-line">{{ item.TypeName }}</div>
                        <div class="th th3 one-line">
                            {{ moment(item.FavoriteDate).format("YYYY年MM月DD日") }}
                        </div>
                        <div class="operate">
                            <div class="btn btn1" @click="goFavoriteDetailPage(item)">详情</div>
                            <div class="btn btn2" @click="deleteFavorite(item)">取消收藏</div>
                        </div>
                    </div>
                </div>
                <div class="pager" v-if="getFavoritePageCount() > 1">
                    <div class="btn" :class="{ disabled: state.favoriteCp == 1 }" @click="handleLast">
                        上一页
                    </div>
                    <div class="count">
                        {{ state.favoriteCp }} / {{ getFavoritePageCount() }}
                    </div>
                    <div class="btn" :class="{ disabled: state.favoriteCp == getFavoritePageCount() }" @click="handleNext">
                        下一页
                    </div>
                </div>
            </div>
            <div v-if="state.selectIndex == 4" class="right4">
                <div class="ftitle">我的书签</div>
                <div class="fthead">
                    <div class="th th1">资源名称</div>
                    <div class="th th2">位置</div>
                    <div class="th th3">书签上下文</div>
                    <div class="th th4">时间</div>
                </div>
                <div class="ftbody">
                    <div class="item" v-for="(item, index) in state.bookmarkList" :key="index">
                        <div class="th th1 one-line">{{ item.resourceTitle }}</div>
                        <div class="th th2 one-line">{{ item.ProgressShow }}</div>
                        <div class="th th3 one-line">{{ item.text }}</div>
                        <div class="th th4 one-line">
                            {{ moment(item.createDate).format("YYYY年MM月DD日") }}
                        </div>
                        <div class="operate">
                            <div class="btn btn1" @click="tagGetPosition(item)">定位</div>
                            <div class="btn btn2" @click="markInfodeleteIndexMark(item)">删除</div>
                        </div>
                    </div>
                </div>
                <div class="pager" v-if="getBookMarkPageCount() > 1">
                    <div class="btn" :class="{ disabled: state.bookmarkCp == 1 }" @click="handleLastBookmark">
                        上一页
                    </div>
                    <div class="count">
                        {{ state.bookmarkCp }} / {{ getBookMarkPageCount() }}
                    </div>
                    <div class="btn" :class="{ disabled: state.bookmarkCp == getBookMarkPageCount() }"
                        @click="handleNextBookmark">
                        下一页
                    </div>
                </div>
            </div>
            <div v-if="state.selectIndex == 5" class="right4">
                <div class="ftitle">我的划线</div>
                <div class="fthead">
                    <div class="th th1">资源名称</div>
                    <div class="th th2">位置</div>
                    <div class="th th3">划线上下文</div>
                    <div class="th th4">时间</div>
                </div>
                <div class="ftbody">
                    <div class="item" v-for="(item, index) in state.drawlineList" :key="index">
                        <div class="th th1 one-line">{{ item.resourceTitle }}</div>
                        <div class="th th2 one-line">{{ item.ProgressShow }}</div>
                        <div class="th th3 one-line">{{ item.text }}</div>
                        <div class="th th4 one-line">
                            {{ moment(item.createDate).format("YYYY年MM月DD日") }}
                        </div>
                        <div class="operate">
                            <div class="btn btn1" @click="tagGetPosition(item)">定位</div>
                            <div class="btn btn2" @click="markInfodeleteIndexMark(item)">删除</div>
                        </div>
                    </div>
                </div>
                <div class="pager" v-if="getDrawlinePageCount() > 1">
                    <div class="btn" :class="{ disabled: state.drawlineCp == 1 }" @click="handleLastDrawline">
                        上一页
                    </div>
                    <div class="count">
                        {{ state.drawlineCp }} / {{ getDrawlinePageCount() }}
                    </div>
                    <div class="btn" :class="{ disabled: state.drawlineCp == getDrawlinePageCount() }"
                        @click="handleNextDrawline">
                        下一页
                    </div>
                </div>
            </div>
            <div v-if="state.selectIndex == 6" class="right4">
                <div class="ftitle">我的笔记</div>
                <div class="fthead">
                    <div class="th th1">资源名称</div>
                    <div class="th th2">位置</div>
                    <div class="th th3">笔记上下文</div>
                    <div class="th th4">时间</div>
                </div>
                <div class="ftbody">
                    <div class="item" v-for="(item, index) in state.noteList" :key="index">
                        <div class="th th1 one-line">{{ item.resourceTitle }}</div>
                        <div class="th th2 one-line">{{ item.ProgressShow }}</div>
                        <div class="th th3 one-line">{{ item.text }}</div>
                        <div class="th th4 one-line">
                            {{ moment(item.createDate).format("YYYY年MM月DD日") }}
                        </div>
                        <div class="operate">
                            <div class="btn btn1" @click="tagGetPosition(item)">定位</div>
                            <div class="btn btn2" @click="markInfodeleteIndexMark(item)">删除</div>
                        </div>
                    </div>
                </div>
                <div class="pager" v-if="getNotePageCount() > 1">
                    <div class="btn" :class="{ disabled: state.noteCp == 1 }" @click="handleLastNote">
                        上一页
                    </div>
                    <div class="count">{{ state.noteCp }} / {{ getNotePageCount() }}</div>
                    <div class="btn" :class="{ disabled: state.noteCp == getNotePageCount() }" @click="handleNextNote">
                        下一页
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import { storeToRefs } from "pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive } from "vue";
import nations from "@/common/nations.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { getToken } from "@/request";
import routerUtil from "@/common/router";

const sexs = ["男", "女"];

const router = useRouter();
const userStore = store.user();
const { userInfo } = storeToRefs(userStore);
const uploadhead = ref(0);

const emits = defineEmits(["handleComplete"]);

const state = reactive({
    nations: nations,
    educations: ["小学", "初中", "中专/高中", "专科", "本科", "硕士研究生", "博士研究生"],
    selectIndex: 0,
    userInfo: computed(() => {
        return userStore.userInfo;
    }),

    pwForm: {
        oldPassword: "",
        errTextOld: "",
        password: "",
        errText: "",
        passwordRepeat: "",
        errTextRepeat: "",
    },

    history: [] as any[],
    favoriteList: [] as any[],
    favoriteTotal: 0,
    favoritePs: 10,
    favoriteCp: 1,

    drawlineList: [] as any[],
    drawlineTotal: 0,
    drawlinePs: 10,
    drawlineCp: 1,

    noteList: [] as any[],
    noteTotal: 0,
    notePs: 10,
    noteCp: 1,

    bookmarkList: [] as any[],
    bookmarkTotal: 0,
    bookmarkPs: 10,
    bookmarkCp: 1,
});

const menuSelect = (index: number) => {
    state.selectIndex = index;
    window.scrollTo({ top: 0 });

    if (state.selectIndex == 2) {
        processMyList();
    } else if (state.selectIndex == 3) {
        state.favoriteCp = 1;
        favoriteMyList();
    } else if (state.selectIndex == 4) {
        state.bookmarkCp = 1;
        markInfoMyListBookmark();
    } else if (state.selectIndex == 5) {
        state.drawlineCp = 1;
        markInfoMyListDrawline();
    } else if (state.selectIndex == 6) {
        state.noteCp = 1;
        markInfoMyListNote();
    } else {
        emits("handleComplete");
    }
};

const submitEdit = () => {
    if (!state.userInfo.UserName.trim()) {
        ElMessage.error("请输入昵称！");
        return;
    }

    if (!state.userInfo.MobileNumber.trim()) {
        ElMessage.error("请输入手机号！");
        return;
    }

    if (state.userInfo.UserName.trim().length > 50) {
        ElMessage.error("昵称长度不超过50！");
        return;
    }

    /*
    if (!`${state.userInfo.Age}`.trim()) {
        ElMessage.error("请输入年龄！");
        return;
    }

    if (!state.userInfo.Sex) {
        ElMessage.error("请选择性别！");
        return;
    }

    if (!state.userInfo.Nation) {
        ElMessage.error("请选择民族！");
        return;
    }

    if (!state.userInfo.Education) {
        ElMessage.error("请选择教育程度！");
        return;
    }
    */

    api.userSelfUpdate(
        {
            userName: state.userInfo.UserName ? state.userInfo.UserName : '',
            mobileNumber: state.userInfo.MobileNumber ? state.userInfo.MobileNumber : '',
            sex: state.userInfo.Sex ? state.userInfo.Sex : '',
            age: state.userInfo.Age ? state.userInfo.Age : '',
            nation: state.userInfo.Nation ? state.userInfo.Nation : '',
            education: state.userInfo.Education ? state.userInfo.Education : '',
        },
        (res: any) => {
            ElMessage.success("保存成功！");
            userStore.getUserInfo();
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const submitPassword = () => {
    if (!state.pwForm.oldPassword.trim()) {
        state.pwForm.errTextOld = "请输入原密码！";
        return;
    }

    if (!state.pwForm.password.trim()) {
        state.pwForm.errText = "请输入新密码！";
        return;
    }

    if (!state.pwForm.passwordRepeat.trim()) {
        state.pwForm.errTextRepeat = "请确认新密码！";
        return;
    }

    if (state.pwForm.password.trim() != state.pwForm.passwordRepeat.trim()) {
        state.pwForm.errTextRepeat = "两次输入的密码不一致！";
        return;
    }

    state.pwForm.errTextOld = "";
    state.pwForm.errText = "";
    state.pwForm.errTextRepeat = "";

    api.userUpdatePassword(
        {
            password: state.pwForm.password,
            oldPassword: state.pwForm.oldPassword,
        },
        (res: any) => {
            ElMessage.success("保存成功！");
            state.pwForm.oldPassword = "";
            state.pwForm.password = "";
            state.pwForm.passwordRepeat = "";
        },
        (err: any) => {
            state.pwForm.errTextOld = err;
            console.log(err);
        }
    );
};

const processMyList = () => {
    api.processMyList(
        {},
        (res: any) => {
            state.history = res;
            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const getFavoritePageCount = () => {
    if (state.favoriteTotal % state.favoritePs == 0) {
        return state.favoriteTotal / state.favoritePs;
    } else {
        return Math.floor(state.favoriteTotal / state.favoritePs) + 1;
    }
};

const getDrawlinePageCount = () => {
    if (state.drawlineTotal % state.drawlinePs == 0) {
        return state.drawlineTotal / state.drawlinePs;
    } else {
        return Math.floor(state.drawlineTotal / state.drawlinePs) + 1;
    }
};

const getNotePageCount = () => {
    if (state.noteTotal % state.notePs == 0) {
        return state.noteTotal / state.notePs;
    } else {
        return Math.floor(state.noteTotal / state.notePs) + 1;
    }
};

const getBookMarkPageCount = () => {
    if (state.bookmarkTotal % state.bookmarkPs == 0) {
        return state.bookmarkTotal / state.bookmarkPs;
    } else {
        return Math.floor(state.bookmarkTotal / state.bookmarkPs) + 1;
    }
};

const goDetailPage = (item: any) => {
    let path = ''
    switch (item.ObjectType) {
        case 1: {
            path = '/magazinedetail'
            break;
        }
        case 2: {
            path = '/bookdetail'
            break;
        }
        case 3: {
            path = '/audiodetail'
            break;
        }
        case 4: {
            path = '/imagedetail'
            break;
        }
        case 5: {
            path = '/videodetail'
            break;
        }
        case 6: {
            routerUtil.goReadPageByRate(item.SContentId, item.Process ? item.Process : item.PageNo, getToken());
            return
        }
    }

    routerUtil.openNewWindow({
        path: path,
        query: {
            id: item.SContentId
        }
    }, true)
}

const goReadPage = (item: any) => {
    routerUtil.goReadPageByRate(item.SContentId, item.SProcess, getToken());
};


const goFavoriteDetailPage = (item: any) => {
    let path = ''

    if(item.Type == 2) {
        switch (item.ContentType) {
            case 1: {
                path = '/magazinedetail'
                break;
            }
            case 2: {
                path = '/bookdetail'
                break;
            }
            case 3: {
                path = '/audiodetail'
                break;
            }
            case 4: {
                path = '/imagedetail'
                break;
            }
            case 5: {
                path = '/videodetail'
                break;
            }
            case 6: {
                routerUtil.goReadPageByRate(item.SObjectId, item.Process ? item.Process : item.PageNo, getToken());
                return
            }
        }
    } else {
        path = '/knowledge'
    }

    routerUtil.openNewWindow({
        path: path,
        query: {
            id: item.SObjectId
        }
    }, true)
}

const goFavoriteReadPage = (item: any) => {
    routerUtil.goReadPage(item.SObjectId, getToken());
};

const deleteFavorite = (item: any) => {
    ElMessageBox.confirm("确定要取消收藏吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(() => {
            api.favoriteDelete(
                { objectId: item.SObjectId },
                (res: any) => {
                    menuSelect(state.selectIndex)
                },
                (err: any) => {
                    ElMessage.error(err.Description);
                    console.log(err);
                }
            );
        })
        .catch(() => {
            console.log("取消");
        });
}

const tagGetPosition = (item: any) => {
    routerUtil.goReadPageByRate(item.resourceData.StrId, item.PageNumber ? item.PageNumber : item.progress, getToken())
}

const markInfodeleteIndexMark = (item: any) => {
    ElMessageBox.confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
    })
        .then(() => {
            api.markInfodeleteIndexMark(
                { markId: item.markId, resourceId: item.resourceData.StrId },
                (res: any) => {
                    ElMessage.success('删除成功')
                    menuSelect(state.selectIndex)
                },
                (err: any) => {
                    console.log(err);
                }
            );
        })
        .catch(() => {
            console.log("取消");
        });
}

const handleLast = () => {
    if (state.favoriteCp <= 1) {
        state.favoriteCp = 1;
        return;
    }

    state.favoriteCp -= 1;
    favoriteMyList();
};

const handleNext = () => {
    if (state.favoriteCp >= getFavoritePageCount()) {
        state.favoriteCp = getFavoritePageCount();
        return;
    }

    state.favoriteCp += 1;
    favoriteMyList();
};

const favoriteMyList = () => {
    api.favoriteMyList(
        { cp: state.favoriteCp, ps: state.favoritePs },
        (res: any) => {
            state.favoriteTotal = res.RecordCount;
            state.favoriteList = res.ItemList;

            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const markInfoMyListDrawline = () => {
    api.markInfoMyList(
        { type: "drawline", cp: state.drawlineCp, ps: state.drawlinePs },
        (res: any) => {
            state.drawlineTotal = res.RecordCount;
            state.drawlineList = res.ItemList;

            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const handleLastDrawline = () => {
    if (state.drawlineCp <= 1) {
        state.drawlineCp = 1;
        return;
    }

    state.drawlineCp -= 1;
    markInfoMyListDrawline();
};

const handleNextDrawline = () => {
    if (state.drawlineCp >= getDrawlinePageCount()) {
        state.drawlineCp = getDrawlinePageCount();
        return;
    }

    state.drawlineCp += 1;
    markInfoMyListDrawline();
};

const markInfoMyListNote = () => {
    api.markInfoMyList(
        { type: "note", cp: state.noteCp, ps: state.notePs },
        (res: any) => {
            state.noteTotal = res.RecordCount;
            state.noteList = res.ItemList;

            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const handleLastNote = () => {
    if (state.noteCp <= 1) {
        state.noteCp = 1;
        return;
    }

    state.noteCp -= 1;
    markInfoMyListNote();
};

const handleNextNote = () => {
    if (state.noteCp >= getNotePageCount()) {
        state.noteCp = getNotePageCount();
        return;
    }

    state.noteCp += 1;
    markInfoMyListNote();
};

const markInfoMyListBookmark = () => {
    api.markInfoMyList(
        { type: "bookmark", cp: state.bookmarkCp, ps: state.bookmarkPs },
        (res: any) => {
            state.bookmarkTotal = res.RecordCount;
            state.bookmarkList = res.ItemList;

            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const handleLastBookmark = () => {
    if (state.bookmarkCp <= 1) {
        state.bookmarkCp = 1;
        return;
    }

    state.bookmarkCp -= 1;
    markInfoMyListBookmark();
};

const handleNextBookmark = () => {
    if (state.bookmarkCp >= getBookMarkPageCount()) {
        state.bookmarkCp = getBookMarkPageCount();
        return;
    }

    state.bookmarkCp += 1;
    markInfoMyListBookmark();
};

onMounted(() => {
    emits("handleComplete");
});
</script>

<style lang="scss" scoped>
.c-user-detail {
    width: 100%;

    .header {
        width: 100%;
        height: 140px;
        background-color: #f0f8f1;
        display: flex;
        justify-content: center;
        align-items: center;

        .han {
            margin-right: 50px;
            color: #2b333b;
            font-size: 40px;
            text-align: center;
            font-weight: 500;
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                right: -32px;
                top: -8px;
                width: 2px;
                height: 48px;
                border-radius: 2px;
                background-color: #2b333b;
            }
        }

        .meng {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            span {
                line-height: 26px;
                font-size: 26px;
                color: #2b333b;
            }
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .left {
            width: 260px;
            height: 560px;
            background-color: #ffffff;
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
            padding-top: 0.5px;

            .menu {
                width: 100%;
                height: 64px;
                line-height: 64px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                background-color: #ffffff;
                font-size: 20px;

                &:nth-child(1) {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;
                        margin-right: 16px;
                    }
                }

                &:nth-child(2) {
                    img {
                        width: 20px;
                        height: 24px;
                        margin-left: 22px;
                        margin-right: 18px;
                    }
                }

                &:nth-child(3) {
                    img {
                        width: 19px;
                        height: 24px;
                        margin-left: 22px;
                        margin-right: 18px;
                    }
                }

                &:nth-child(4) {
                    img {
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;
                        margin-right: 16px;
                    }
                }

                &:nth-child(5) {
                    img {
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;
                        margin-right: 16px;
                    }
                }

                &:nth-child(6) {
                    img {
                        width: 22px;
                        height: 24px;
                        margin-left: 21px;
                        margin-right: 17px;
                    }
                }

                &:nth-child(7) {
                    img {
                        width: 21px;
                        height: 24px;
                        margin-left: 22px;
                        margin-right: 17px;
                    }
                }

                &.select {
                    background-color: #6e8b74;
                    color: #ffffff;
                }
            }
        }

        .right0 {
            width: 1224px;
            min-height: calc(100vh - 240px);
            border-radius: 4px;
            background-color: #ffffff;
            padding-bottom: 48px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

            .ftitle {
                color: #2b333b;
                font-weight: 600;
                font-size: 24px;
                box-sizing: border-box;
                text-align: left;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 52px;
            }

            .fitem {
                padding-left: 40px;
                margin-bottom: 36px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .fl {
                    font-size: 18px;
                    color: #787878;
                    margin-right: 40px;
                    margin-bottom: 8px;
                }

                .fr {
                    .input {
                        width: 480px;
                    }

                    :deep(.el-input) {
                        height: 48px;
                        line-height: 48px;
                    }

                    :deep(.el-select) {
                        width: 480px;
                        height: 48px;
                    }

                    :deep(.el-input__wrapper) {
                        padding-left: 20px;
                        border-radius: 8px;
                        height: 100%;
                        line-height: 100%;
                        box-sizing: border-box;
                        color: #2b333b;
                        font-size: 20px;
                    }

                    :deep(.el-input__inner) {
                        font-weight: 600;
                    }

                    :deep(.el-select__wrapper) {
                        padding-left: 20px;
                        border-radius: 8px;
                        width: 100%;
                        height: 100%;
                        line-height: 100%;
                        box-sizing: border-box;
                        color: #2b333b;
                        font-size: 20px;
                        font-weight: 600;
                    }

                    :deep(.el-select-dropdown__item) {
                        height: 48px;
                        line-height: 48px;
                        font-size: 20px;
                        text-align: left;
                    }

                    :deep(.el-input__wrapper:hover) {
                        box-shadow: 0 0 0 1px #4567fc inset;
                    }

                    :deep(.el-select-dropdown) {
                        width: 480px !important;
                    }

                    :deep(.el-select__wrapper:hover) {
                        box-shadow: 0 0 0 1px #4567fc inset;
                    }

                    :deep(.el-radio__input.is-checked + .el-radio__label) {
                        color: #0c3680;
                    }

                    :deep(.el-radio__input.is-checked .el-radio__inner) {
                        background-color: #0c3680;
                        color: #0c3680;
                    }

                    .submit {
                        margin-top: 4px;
                        width: 104px;
                        height: 48px;
                        line-height: 48px;
                        font-size: 20px;
                        background-color: #6e8b74;
                        color: #ffffff;
                        border-radius: 4px;
                        cursor: pointer;
                        font-weight: 600;
                    }
                }
            }
        }

        .right1 {
            width: 1224px;
            min-height: calc(100vh - 240px);
            border-radius: 4px;
            background-color: #ffffff;
            padding-bottom: 48px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

            .ftitle {
                color: #2b333b;
                font-weight: 600;
                font-size: 24px;
                box-sizing: border-box;
                text-align: left;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 52px;
            }

            .fitem {
                padding-left: 40px;
                margin-bottom: 24px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .fl {
                    font-size: 18px;
                    color: #787878;
                    margin-right: 40px;
                    margin-bottom: 8px;
                }

                .fr {
                    .input {
                        width: 480px;
                    }

                    :deep(.el-input) {
                        height: 48px;
                        line-height: 48px;
                    }

                    :deep(.el-input__wrapper) {
                        padding-left: 20px;
                        border-radius: 8px;
                        height: 100%;
                        line-height: 100%;
                        box-sizing: border-box;
                        color: #2b333b;
                        font-size: 20px;
                    }

                    :deep(.el-input__inner) {
                        font-weight: 600;
                    }

                    :deep(.el-input__wrapper:hover) {
                        box-shadow: 0 0 0 1px #4567fc inset;
                    }

                    .input.err {
                        :deep(.el-input__wrapper) {
                            box-shadow: 0 0 0 1px #e00916 inset;
                        }
                    }

                    .err-text {
                        font-size: 18px;
                        margin-top: 8px;
                        color: #e00916;
                        text-align: left;
                    }

                    .submit {
                        margin-top: 4px;
                        width: 124px;
                        height: 48px;
                        line-height: 48px;
                        font-size: 20px;
                        background-color: #6e8b74;
                        color: #ffffff;
                        border-radius: 4px;
                        cursor: pointer;
                        font-weight: 600;
                    }
                }
            }
        }

        .right2 {
            width: 1224px;
            min-height: calc(100vh - 240px);
            border-radius: 4px;
            background-color: #ffffff;
            padding-bottom: 48px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

            .ftitle {
                color: #2b333b;
                font-weight: 600;
                font-size: 24px;
                box-sizing: border-box;
                text-align: left;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 52px;
            }

            .fthead {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 1px solid #d9dde1;

                .th {
                    height: 44px;
                    font-size: 20px;
                    color: #596067;

                    &.th1 {
                        margin-left: 60px;
                        width: 357px;
                        margin-right: 20px;
                        text-align: left;
                    }

                    &.th2 {
                        width: 110px;
                        margin-right: 20px;
                        text-align: center;
                    }

                    &.th3 {
                        width: 270px;
                        margin-right: 20px;
                        text-align: center;
                    }

                    &.th4 {
                        width: 172px;
                        margin-right: 20px;
                        text-align: center;
                    }
                }
            }

            .ftbody {
                .item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #596067;
                    border-bottom: 1px solid #d9dde1;
                    position: relative;
                    background-color: #FFFFFF;
                    transition: all 0.2s;

                    .th {
                        height: 84px;
                        line-height: 84px;
                        font-size: 20px;
                        color: #2b333b;

                        &.th0 {
                            width: 60px;
                            box-sizing: border-box;
                            text-align: left;
                            padding-left: 24px;
                        }

                        &.th1 {
                            width: 357px;
                            margin-right: 20px;
                            text-align: left;
                        }

                        &.th2 {
                            width: 110px;
                            margin-right: 20px;
                            text-align: center;
                        }

                        &.th3 {
                            width: 270px;
                            margin-right: 20px;
                            text-align: center;
                        }

                        &.th4 {
                            width: 172px;
                            margin-right: 20px;
                            text-align: center;
                        }
                    }

                    .operate {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        height: 84px;
                        line-height: 84px;
                        float: right;
                        width: 120px;
                        position: absolute;
                        right: 32px;

                        .btn {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            font-size: 16px;
                            border-radius: 4px;
                            cursor: pointer;
                            transition: all 0.2s;

                            &.btn1 {
                                color: #5365ff;
                                border: 1px solid #e5e5e5;
                                margin-right: 8px;
                            }

                            &.btn2 {
                                color: #e66723;
                                border: 1px solid #e5e5e5;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .operate {
                            .btn {
                                &.btn1 {
                                    color: #FFFFFF;
                                    border: 1px solid #5365FF;
                                    background-color: #5365FF;
                                }

                                &.btn2 {
                                    color: #FFFFFF;
                                    border: 1px solid #E66723;
                                    background-color: #E66723;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right3 {
            width: 1224px;
            min-height: calc(100vh - 240px);
            border-radius: 4px;
            background-color: #ffffff;
            padding-bottom: 40px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

            .ftitle {
                color: #2b333b;
                font-weight: 600;
                font-size: 24px;
                box-sizing: border-box;
                text-align: left;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 52px;
            }

            .fthead {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 1px solid #d9dde1;

                .th {
                    height: 44px;
                    font-size: 20px;
                    color: #596067;

                    &.th1 {
                        margin-left: 60px;
                        width: 420px;
                        margin-right: 20px;
                        text-align: left;
                    }

                    &.th2 {
                        width: 180px;
                        margin-right: 20px;
                        text-align: center;
                    }

                    &.th3 {
                        width: 252px;
                        margin-right: 20px;
                        text-align: center;
                    }
                }
            }

            .ftbody {
                .item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #596067;
                    border-bottom: 1px solid #d9dde1;
                    position: relative;
                    transition: all 0.2s;

                    .th {
                        height: 84px;
                        line-height: 84px;
                        font-size: 20px;
                        color: #2b333b;

                        &.th0 {
                            width: 60px;
                            box-sizing: border-box;
                            text-align: left;
                            padding-left: 24px;
                        }

                        &.th1 {
                            width: 420px;
                            margin-right: 20px;
                            text-align: left;
                        }

                        &.th2 {
                            width: 180px;
                            margin-right: 20px;
                            text-align: center;
                        }

                        &.th3 {
                            width: 252px;
                            margin-right: 20px;
                            text-align: center;
                        }
                    }

                    .operate {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        height: 84px;
                        line-height: 84px;
                        float: right;
                        width: 144px;
                        position: absolute;
                        right: 32px;
                        transition: all 0.2s;

                        .btn {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            font-size: 16px;
                            border-radius: 4px;
                            cursor: pointer;
                            transition: all 0.2s;

                            &.btn1 {
                                color: #5365ff;
                                border: 1px solid #e5e5e5;
                                margin-right: 8px;
                            }

                            &.btn2 {
                                width: 80px;
                                color: #e66723;
                                border: 1px solid #e5e5e5;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .operate {
                            .btn {
                                &.btn1 {
                                    color: #FFFFFF;
                                    border: 1px solid #5365FF;
                                    background-color: #5365FF;
                                }

                                &.btn2 {
                                    color: #FFFFFF;
                                    border: 1px solid #E66723;
                                    background-color: #E66723;
                                }
                            }
                        }
                    }
                }
            }

            .pager {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                margin-top: 30px;

                .btn {
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    color: #2b333b;
                    font-size: 14px;
                    box-sizing: border-box;
                    border: 1px solid #2b333b;
                    border-radius: 4px;
                    cursor: pointer;

                    &.disabled {
                        color: #b7bbbf;
                        border: 1px solid #b7bbbf;
                    }
                }

                .count {
                    font-size: 14px;
                    color: #2b333b;
                    margin-left: 40px;
                    margin-right: 40px;
                }
            }
        }

        .right4 {
            width: 1224px;
            min-height: calc(100vh - 240px);
            border-radius: 4px;
            background-color: #ffffff;
            padding-bottom: 40px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

            .ftitle {
                color: #2b333b;
                font-weight: 600;
                font-size: 24px;
                box-sizing: border-box;
                text-align: left;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 52px;
            }

            .fthead {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 1px solid #d9dde1;

                .th {
                    height: 44px;
                    font-size: 20px;
                    color: #596067;

                    &.th1 {
                        margin-left: 24px;
                        width: 286px;
                        margin-right: 20px;
                        text-align: left;
                    }

                    &.th2 {
                        width: 98px;
                        margin-right: 20px;
                        text-align: left;
                    }

                    &.th3 {
                        width: 407px;
                        margin-right: 20px;
                        text-align: left;
                    }

                    &.th4 {
                        width: 185px;
                        margin-right: 20px;
                        text-align: left;
                    }
                }
            }

            .ftbody {
                .item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #596067;
                    border-bottom: 1px solid #d9dde1;
                    position: relative;
                    transition: all 0.2s;

                    .th {
                        height: 84px;
                        line-height: 84px;
                        font-size: 20px;
                        color: #2b333b;

                        &.th1 {
                            margin-left: 24px;
                            width: 286px;
                            margin-right: 20px;
                            text-align: left;
                        }

                        &.th2 {
                            width: 98px;
                            margin-right: 20px;
                            text-align: left;
                        }

                        &.th3 {
                            width: 407px;
                            margin-right: 20px;
                            text-align: left;
                        }

                        &.th4 {
                            width: 185px;
                            margin-right: 20px;
                            text-align: left;
                        }
                    }

                    .operate {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        height: 84px;
                        line-height: 84px;
                        float: right;
                        width: 120px;
                        position: absolute;
                        right: 32px;

                        .btn {
                            width: 56px;
                            height: 32px;
                            line-height: 32px;
                            font-size: 16px;
                            border-radius: 4px;
                            cursor: pointer;
                            transition: all 0.2s;

                            &.btn1 {
                                color: #5365ff;
                                border: 1px solid #e5e5e5;
                                margin-right: 8px;
                            }

                            &.btn2 {
                                width: 56px;
                                color: #e66723;
                                border: 1px solid #e5e5e5;
                            }
                        }
                    }

                    &:hover {
                        background-color: #EBEBEB;

                        .operate {
                            .btn {
                                &.btn1 {
                                    color: #FFFFFF;
                                    border: 1px solid #5567FF;
                                    background-color: #5567FF;
                                }

                                &.btn2 {
                                    color: #FFFFFF;
                                    border: 1px solid #E00916;
                                    background-color: #E00916;
                                }
                            }
                        }
                    }
                }
            }

            .pager {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                margin-top: 30px;

                .btn {
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    color: #2b333b;
                    font-size: 14px;
                    box-sizing: border-box;
                    border: 1px solid #2b333b;
                    border-radius: 4px;
                    cursor: pointer;

                    &.disabled {
                        color: #b7bbbf;
                        border: 1px solid #b7bbbf;
                    }
                }

                .count {
                    font-size: 14px;
                    color: #2b333b;
                    margin-left: 40px;
                    margin-right: 40px;
                }
            }
        }
    }
}
</style>
