<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-26 20:08:20
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-12 13:44:09
-->

<template>
    <div class="knowledge">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <knowledge-search v-model:keyword="state.keyword" @search="doSearch"></knowledge-search>
            <knowledge-labels ref="labels" class="mlabels" @handleLabelsComplete="handleLabelsComplete"
                @handleLabelSelect="handleLabelSelect" @handleLabelsEmpty="handleLabelsEmpty"></knowledge-labels>
            <div v-show="!state.isEmpty">
                <knowledge-label v-if="state.selectLabel" ref="label" class="mlabel"
                    @handleLabelComplete="handleLabelComplete"> </knowledge-label>
                <knowledge-relation v-if="state.selectLabel" ref="knowledge" class="mknowledge"
                    @handleComplete="handleComplete"></knowledge-relation>
            </div>
            <div class="knowlede-label-empty" v-show="state.isEmpty">
                <img src="@/assets/images/image/warn.png" /> 抱歉，未找到相关知识点
            </div>
        </div>

        <footer-footer class="footer" :style="getMarginTopStyle(state.boxHeight)"></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import KnowledgeSearch from "@/components/page/knowledge/KnowledgeSearch.vue";
import KnowledgeLabels from "@/components/page/knowledge/KnowledgeLabels.vue";
import KnowledgeLabel from "@/components/page/knowledge/KnowledgeLabel.vue";
import KnowledgeRelation from "@/components/page/knowledge/KnowledgeRelation.vue";
import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle, getScale } from "@/utils/scale";
import FooterFooter from "@/components/footer/Footer.vue";

const router = useRouter();
const userStore = store.user() as any;
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const state = reactive({
    keyword: "",
    labelId: "",
    boxHeight: 0,

    labels: [] as any[],
    selectLabel: {} as any,
    isEmpty: false
})

const box = ref(0)
const labels = ref(0)
const label = ref(0)
const knowledge = ref(0)

const doSearch = () => {
    state.labelId = "";
    (labels.value as any).labelSearch(state.keyword, state.labelId)
}

const handleSearch = () => {
    (labels.value as any).labelSearch(state.keyword, state.labelId)
}

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height
    }, 10)
}

emitter.on('handleComplete', handleComplete)

const handleLabelsComplete = (labels: any[]) => {
    state.labels = labels
    if (state.labels.length > 0) {
        state.isEmpty = false
    } else {
        state.isEmpty = true
    }
};

const handleLabelsEmpty = () => {
    state.labels = []
    state.isEmpty = true
    handleComplete()
}

const handleLabelComplete = (mlabel: any) => {
    (labels.value as any).handleLabelSelect(mlabel)
    (knowledge.value as any).initLabelById(mlabel)
}

const handleLabelSelect = (p: any) => {
    if (p == null) {
        (label.value as any).initLabelById(state.labelId);
    } else {
        state.selectLabel = p;
        if (state.selectLabel) {
            (label.value as any).initLabel(state.selectLabel);
            (knowledge.value as any).initLabel(state.selectLabel, state.keyword)
        } else {
            handleComplete()
        }
    }
}

const scrollToTargetAdjusted = () =>{ 
    var element = document.getElementById('knowlede-label') as any
    var headerOffset = getScale() * 260
    var elementPosition = element.getBoundingClientRect().top
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset
   
    window.scrollTo({ 
         top: offsetPosition, 
         behavior: "smooth" 
    }); 
} 

const route = useRoute()
onMounted(() => {
    state.labelId = (route.query as any).id ? (route.query as any).id : '';

    if (state.labelId) {
        setTimeout(() => {
            scrollToTargetAdjusted()
        }, 100)
    }

    handleSearch()
})
</script>

<style lang="scss" scoped>
.knowledge {
    width: 100%;

    .box {
        width: 100%;
        margin: 0 auto;
        background: #f0f0f0;
        padding-bottom: 80px;

        .knowlede-label-empty {
            height: 160px;
            line-height: 160px;
            padding-bottom: 400px;
            width: 100%;

            font-size: 20px;
            color: #2b333b;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 24px !important;
                height: 24px !important;
                margin-right: 8px;
            }
        }

        .mlabels {
            width: 1500px;
            height: 560px;
            margin: 0 auto;
            margin-bottom: 60px;
            margin-top: -62px;
            border-radius: 8px;
            box-shadow: 0px 0px 32px 0px rgba(156, 139, 116, 0.24);
        }

        .mlabel {
            width: 1500px;
            margin: 0 auto;
            margin-bottom: 40px;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }

        .mknowledge {
            width: 1500px;
            margin: 0 auto;
            border-radius: 4px;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        }
    }

    .footer {
        transition: all 0.05s ease-out;
    }
}
</style>
