<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-26 20:13:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-19 14:17:50
-->
<template>
    <div class="knowledge-search">
        <el-input class="input" v-model="state._keyword" placeholder="搜索更多标签" @keyup.enter="handleSearch">
            <template #append>
                <el-button @click="handleSearch">
                    <img src="@/assets/images/home/sousuo.png" />
                </el-button>
            </template>
        </el-input>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";

const state = reactive({
    _keyword: "",
    _date: "",
});

const props = defineProps({
    keyword: {
        type: String,
        default: "",
    },
    date: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["update:keyword", "update:date", "search"]);

const handleSearch = () => {
    emit("update:keyword", state._keyword);
    emit("search", state._keyword);
};

watch(props, (newVal, oldVal) => {
    state._keyword = newVal.keyword
})

onMounted(() => {
    state._keyword = props.keyword;
    state._date = props.date;
});
</script>

<style lang="scss" scoped>
.knowledge-search {
    width: 100%;
    height: 320px;
    background-color: #6e8b74;

    .input {
        position: relative;
        box-sizing: border-box;
        width: 800px;
        height: 64px;
        margin: 0 auto;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.04);
        margin-top: 110px;

        :deep(.el-input__wrapper) {
            box-sizing: border-box;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: none;
            box-shadow: none !important;
            font-size: 20px;
            padding-left: 18px;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
        }

        $place-holder-color: #a5a5a5;
        $place-holder-size: 20px;

        :deep(.el-input__inner) {
            font-size: $place-holder-size;
            height: 100%;
        }

        :deep(.el-input__inner::placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-webkit-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-moz-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-ms-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
            margin-left: 22px;
        }

        :deep(.el-input-group__append) {
            position: absolute;
            z-index: 9;
            display: flex;
            justify-content: flex-end;
            top: 0px;
            right: 0px;
            height: 64px;
            padding: 0;
            box-sizing: border-box;
            border: none;
            border-radius: 0;
            box-shadow: none !important;
            background-color: transparent;

            .el-button {
                width: 106px;
                height: 67px;
                line-height: 67px;
                border-radius: 0px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-top: 0px;
                margin-right: -1px;
                background-color: #6e8b74;
                padding: 0;
                border: 1px solid #6e8b74;

                img {
                    width: 28px;
                    height: 28px;
                }
                .el-icon {
                    color: #ffffff;
                    font-size: 28px;
                }
            }

            .el-date-editor {
                width: 320px;
                margin-top: 0px;
                height: 62px;
                line-height: 62px;
                padding-left: 0;
                padding-right: 0;
                position: absolute;
                right: 126px;
            }

            .el-range__icon {
                display: none;
            }

            .el-range-input {
                font-size: 22px;
            }

            .el-range-separator {
                width: 20px;
            }

            .el-range-input {
                width: 140px;
                font-size: 22px;
            }

            :deep(.el-range-input::placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
            }

            :deep(.el-range-input::-webkit-input-placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
            }

            :deep(.el-range-input::-moz-placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
            }

            :deep(.el-range-input::-ms-input-placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
                margin-left: 22px;
            }
        }

        .mrange {
            height: 32px;
            line-height: 32px;
            width: 98px;
            font-size: 16px;
            color: #787878;
            position: absolute;
            right: 122px;
            border-left: 2px solid #f5f5f5;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #787878;
            cursor: pointer;

            span {
                display: inline-block;
                margin-right: 8px;
            }
        }
    }

    :deep(.el-button > span) {
        width: 100%;
        height: 100%;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
