<template>
    <div class="c-notice-list">
        <div class="header">
            <div class="han">系统公告</div>
            <div class="meng mFont">
                <span class="meng-vertical">ᠱᠢᠰᠲᠧᠮ᠎ᠦᠨ</span>
                <span class="meng-vertical">ᠠᠯᠪᠠᠨ</span>
                <span class="meng-vertical">ᠵᠠᠷ</span>
            </div>
        </div>
        <div class="content">
            <div class="list">
                <div
                    class="item"
                    v-for="(item, index) in state.list"
                    :key="index"
                    @click="goDetail(item)"
                >
                    <div class="title one-line">【公告】 {{ item.Title }}</div>
                    <div class="date">
                        {{ moment(item.ShowFDate).format("YYYY-MM-DD") }}
                    </div>

                    <div class="divider" v-if="index < state.list.length - 1">

                    </div>
                </div>
            </div>

            <div class="pager" v-if="getPageCount() > 1">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    v-model:page-size="state.ps"
                    v-model:current-page="state.cp"
                    :total="state.total"
                    @current-change="currentChange"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import moment from "moment";
import api from "@/common/api";
import router from "@/router";
import routerUtil from "@/common/router";

const emits = defineEmits(["handleComplete"]);

const state = reactive({
    cp: 1,
    ps: 10,
    list: [] as any,
    total: 0,
});

const getPageCount = () => {
    if (state.total % state.ps == 0) {
        return state.total / state.ps;
    } else {
        return Math.floor(state.total / state.ps) + 1;
    }
};

const noticePageList = () => {
    state.list = [];

    api.noticePageList(
        { cp: state.cp, ps: state.ps },
        (res: any) => {
            state.total = res.RecordCount;
            state.list = res.ItemList;

            emits("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const currentChange = () => {
    noticePageList();
};

const goDetail = (item: any) => {
    routerUtil.openNewWindow({
        path: `/noticedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

onMounted(() => {
    noticePageList();
});
</script>

<style lang="scss" scoped>
.c-notice-list {
    width: 100%;

    .header {
        width: 100%;
        height: 140px;
        background-color: #f0f8f1;
        display: flex;
        justify-content: center;
        align-items: center;

        .han {
            margin-right: 50px;
            color: #2b333b;
            font-size: 40px;
            text-align: center;
            font-weight: 500;
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                right: -32px;
                top: -8px;
                width: 2px;
                height: 48px;
                border-radius: 2px;
                background-color: #2b333b;
            }
        }

        .meng {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            span {
                line-height: 26px;
                font-size: 26px;
                color: #2b333b;
            }
        }
    }

    .content {
        width: 1500px;
        margin: 0 auto;
        margin-top: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

        .pager {
            display: flex;
            justify-content: center;
            padding-bottom: 40px;

            :deep(.el-pagination button) {
                padding: 0;
                width: 48px;
                height: 48px;
                line-height: 48px;
                font-size: 16px;
            }

            :deep(.el-pagination .btn-next .el-icon) {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                padding: 0;
                width: 48px;
                height: 48px;
                line-height: 48px;
            }

            :deep(.el-pagination .btn-prev .el-icon) {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                padding: 0;
                width: 48px;
                height: 48px;
                line-height: 48px;
            }

            :deep(.el-pagination li) {
                display: inline-block;
                font-size: 20px;
                padding: 0;
                width: 48px;
                height: 48px;
                line-height: 48px;
                margin: 0 8px;
            }

            :deep(.el-pagination li.is-active) {
                background-color: #6e8b74;
            }
        }

        .list {
            padding-top: 32px;
            padding-bottom: 20px;
            box-sizing: border-box;
            min-height: calc(100vh - 160px);

            .item {
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 24px;
                width: 100%;
                box-sizing: border-box;
                text-align: left;
                cursor: pointer;

                &:hover {
                    background-color: #EBEBEB;
                }

                &:last-child {
                    border-bottom: none;
                }

                .title {
                    font-size: 24px;
                    line-height: 48px;
                    margin-bottom: 16px;
                    color: #2b333b;
                }

                .date {
                    font-size: 28px;
                    line-height: 28px;
                    padding-bottom: 24px;
                    color: #787878;
                }

                .divider {
                    margin-left: 0px;
                    margin-right: 0px;
                    border-bottom: 1px solid #d9dde1;
                }
            }
        }
    }
}
</style>
