<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-29 20:47:54
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-08 16:02:24
-->
<template>
    <div class="magazine-latest">
        <div class="header">
            <div class="box">
                <div class="han">最新画报</div>
                <div class="meng mFont">
                    <span class="meng-vertical">ᠬᠠᠮᠤᠭ᠎ᠤᠨ</span>
                    <span class="meng-vertical">ᠱᠢᠨ᠎ᠡ</span>
                    <span class="meng-vertical">ᠵᠢᠷᠤᠭᠲᠤ</span>
                    <span class="meng-vertical">ᠰᠡᠳᠭᠦᠯ</span>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="l" @click="goMagazine(state.latest)">
                <img v-if="state.latest" v-lazy="state.latest.Cover" />
            </div>
            <div v-if="state.latest" class="r">
                <div class="name one-line">
                    {{ state.latest.Title }}
                </div>
                <div class="date">
                    
                </div>
                <div class="infos">
                    <template v-for="(item, index) in state.list" :key="index">
                        <div v-if="index < 3" class="item" @click="goReadPage(item.StrChapterId)">
                            <el-tooltip :content="item.Title" effect="light">
                                <span class="one-line" ref="tips" :style="{ opacity: state.opacitys[index] }"
                                    :data-index="index">{{ item.Title }}</span>
                            </el-tooltip>
                            <span class="one-line" ref="notips" :style="{ opacity: state.opacitys[index] }"
                                :data-index="index">{{ item.Title }}</span>
                        </div>
                    </template>
                </div>
                <div class="labels">
                    <template v-for="(item, index) in state.labels" :key="index">
                        <ellipsis-view :title="item.Name" class="label one-line" :class="[ `type${ item.Type }` ]" @click="goLabelSearch(item)">
                            {{ item.Name }}
                        </ellipsis-view>
                    </template>
                </div>
                <div class="relateds">
                    <template v-for="(item, index) in state.related" :key="index">
                        <img class="related" v-lazy="item.Cover"  @click="goReadPage(item.StrId)" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps } from "vue";
import { Search } from "@element-plus/icons-vue";
import EllipsisView from "@/components/common/EllipsisView.vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";
import { getToken } from "@/request";

const state = reactive({
    latest: null as any,
    opacitys: [] as any[],
    list: [] as any[],
    labels: [] as any[],
    related: [] as any[],
});

const initLatest = (latest: any, opacitys: any[], labels: any[], related: any[]) => {
    state.latest = latest;
    state.list = opacitys;
    state.labels = labels;
    state.related = related;

    state.opacitys = []
    state.list.forEach(() => {
        state.opacitys.push(0);
    });
    initToolTip();
};

const goReadPage = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

const tips = ref([]);
const notips = ref([]);

const initToolTip = () => {
    setTimeout(() => {
        console.log(notips.value);
        for (let i = 0; i < notips.value.length; i++) {
            const nitem = notips.value[i] as any;
            const titem = tips.value[i] as any;

            if (nitem.clientWidth < nitem.scrollWidth) {
                const dIndex = Number(nitem.dataset.index);
                nitem.remove();
                state.opacitys[dIndex] = 1;
            } else {
                const dIndex = Number(nitem.dataset.index);
                titem.remove();
                state.opacitys[dIndex] = 1;
            }
        }
    }, 500);
};

const router = useRouter();
const goMagazine = (item: any) => {
    routerUtil.openNewWindow({
        path: `/magazinedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

const goLabelSearch = (item: any) => {
    routerUtil.openNewWindow({
        path: `/knowledge`,
        query: {
            id: item.StrId
        }
    } as any);
}

defineExpose({
    initLatest,
});
</script>

<style lang="scss" scoped>
.magazine-latest {
    width: 1500px;
    height: 728px;
    background-color: #ffffff;
    border-radius: 4px;

    .header {
        height: 96px;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9dde1;
        text-align: left;

        .box {
            display: inline-block;
            border-bottom: 1px solid #2b333b;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
        }

        .han {
            display: inline-block;
            height: 96px;
            line-height: 96px;
            margin-right: 16px;
            font-size: 28px;
            color: #2b333b;
        }

        .meng {
            box-sizing: border-box;
            padding-top: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #787878;
            font-size: 20px;

            span {
                line-height: 28px;
            }
        }
    }

    .body {
        width: 100%;
        height: 630px;
        padding-left: 130px;
        padding-top: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .l {
            width: 400px;
            height: 534px;
            margin-right: 48px;

            img {
                width: 400px;
                height: 534px;
                border-radius: 8px;
                box-shadow: 0px 14px 40px 0px rgba(0, 0, 0, 0.16);
                cursor: pointer;
            }
        }

        .r {
            width: 784px;

            .name {
                width: 100%;
                height: 56px;
                line-height: 56px;
                font-size: 32px;
                font-weight: 600;
                color: #2b333b;
                text-align: right;
            }

            .date {
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 32px;
                font-weight: 600;
                color: #2b333b;
                text-align: right;
            }

            .infos {
                width: 786px;

                .item {
                    width: 100%;
                    text-align: left;
                    font-size: 26px;
                    height: 54px;
                    line-height: 54px;
                    color: #2b333b;
                    cursor: pointer;

                    span {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .labels {
                height: 28px;
                margin-top: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                overflow: hidden;

                .label {
                    display: inline-block;
                    min-width: 98px;
                    width: 80px;
                    height: 28px;
                    box-sizing: border-box;
                    border: 1px solid #003166;
                    border-radius: 4px;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    padding-left: 8px;
                    padding-right: 8px;

                    :deep(.content) {
                        line-height: 24px;
                    }

                    &.type1 {
                        color: #ffffff;
                        border-color: rgb(254, 86, 33);
                        background-color: rgb(254, 86, 33);
                    }

                    &.type2 {
                        color: #ffffff;
                        border-color: #38bf49;
                        background-color: #38bf49;
                    }

                    &.type3 {
                        color: #ffffff;
                        border-color: #3d8bff;
                        background-color: #3d8bff;
                    }
                }
            }

            .relateds {
                margin-top: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .related {
                    cursor: pointer;
                    width: 240px;
                    height: 178px;
                    border-radius: 8px;
                    margin-right: 32px;

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}
</style>
