<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 11:23:05
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-19 20:30:17
-->
<template>
    <div class="introduction-banner">
        <img class="img" src="@/assets/images/introduction/banner.png" />
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";

const state = reactive({
    banners: [] as any[],
});

const bannerInfoList = () => {
    api.bannerInfoList(
        {},
        (res: any) => {
            state.banners = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

onMounted(() => {
    bannerInfoList();
});
</script>

<style lang="scss" scoped>
.introduction-banner {
    width: 100%;
    height: 400px;

    .img {
        width: 100%;
        height: 400px;
    }
}
</style>
