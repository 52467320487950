/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-07 08:23:59
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-10 09:38:24
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-07 08:23:59
 * @LastEditors: QianDuan
 * @LastEditTime: 2023-12-16 03:47:19
 */
import router from '@/router'

export default {
	goReadPage: (id: any, token: any) => {
		let url = `https://readv2.golden-prairie.com/?resourceId=${ id }&token=${ encodeURIComponent(token) }`
		
        window.open(url, '_blank')
	},

    goReadPageByNumber: (id: any, page: any, token: any) => {
		let url = `https://readv2.golden-prairie.com/?resourceId=${ id }&token=${ encodeURIComponent(token) }&location=${ page ? page : '' }`
		
		window.open(url, '_blank')
	},

    goReadPageByRate: (id: any, rate: any, token: any) => {
		let url = `https://readv2.golden-prairie.com/?resourceId=${ id }&token=${ encodeURIComponent(token) }&location=${ rate ? rate : '' }`
		
		window.open(url, '_blank')
	},

    openNewWindow: (params: any, newWindow = false) => {
        if(newWindow) {
            const { href } = router.resolve(params)

            window.open(href, '_blank')
        } else {
            router.push(params)
        }
    }
}