<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-17 20:29:48
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-08 10:45:52
-->
<template>
    <div class="home-shukan">
        <div class="box">
            <div class="content">
                <div class="l">
                    <template v-for="(item, index) in state.category" :key="index">
                        <div class="item" @click="goBook(item)">
                            <img class="cover" v-lazy="item.Cover" />
                            <div class="name">
                                <div class="han">
                                    {{ item.Name }}
                                </div>
                                <div class="meng">
                                    <span
                                        class="meng-vertical mFont"
                                        v-for="(iitem, iindex) in getMWords(item.MName)"
                                        :key="iindex"
                                    >
                                        {{ iitem }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="r">
                    <div class="han">
                        <div>书</div>
                        <div>刊</div>
                    </div>
                    <div class="meng mFont meng-vertical">ᠨᠣᠮ ᠰᠡᠳᠭᠦᠯ</div>
                    <div class="more" @click="goBooks">
                        <span>更多</span>
                        <img src="@/assets/images/home/gengduo.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";
import { getScaleStyle } from "@/utils/scale";
import routerUtil from "@/common/router";

const state = reactive({
    category: [] as any[],
});

const categoryList = () => {
    api.categoryList(
        { type: 2, showOther: false },
        (res: any) => {
            state.category = res[0].children;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const router = useRouter()
const goBook = (item: any) => {
    routerUtil.openNewWindow({
        path: `/book`,
        query: {
            id: item.StrId,
        },
    });
};

const goBooks = (item: any) => {
    routerUtil.openNewWindow({
        path: `/book`,
    });
};

onMounted(() => {
    categoryList();
});
</script>

<style lang="scss" scoped>
.home-shukan {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    align-items: flex-start;

    .box {
        width: 100%;
        height: 670px;
        position: relative;
        background: url("@/assets/images/home/shukan.png");
        background-size: 100% 100%;

        .content {
            width: 1500px;
            margin: 0 auto;
            margin-top: 72px;
            display: flex;
            justify-content: flex-start;

            .l {
                width: 1230px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;

                .item {
                    cursor: pointer;
                    transition: all 0.2s;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    transform: translateY(0);
                    background-color: #ffffff;

                    &:hover {
                        transform: translateY(-24px);
                    }

                    .cover {
                        width: 394px;
                        height: 446px;
                        border-radius: 0px;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        background-color: #ffffff;
                        cursor: pointer;
                    }

                    .name {
                        width: 394px;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #ffffff;

                        .han {
                            font-size: 24px;
                            color: #000000;
                        }

                        .meng {
                            font-size: 26px;
                            color: #000000;
                            margin-left: 8px;
                            display: flex;
                            align-items: flex-start;
                        }
                    }
                }
            }

            .r {
                margin-left: 40px;
                margin-top: 100px;
                padding-left: 66px;
                position: relative;

                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 184px;
                    height: 184px;
                    z-index: 1;
                    top: 60px;
                    left: -60px;
                    background: url("@/assets/images/home/shukan-bg.png");
                    background-size: 184px 184px;
                }

                .han {
                    div {
                        display: block;
                        font-size: 32px;
                        color: #2b333b;
                        text-align: left;
                        width: 164px;
                        height: 44px;
                        line-height: 44px;
                        box-sizing: border-box;
                        text-align: center;
                        font-weight: 600;
                        margin-top: 2px;

                        &:first-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            color: #ffffff;
                            background-color: #6e8b74;
                        }
                    }
                }

                .meng {
                    position: absolute;
                    right: 34px;
                    top: 64px;
                    font-size: 26px;
                    height: 200px;
                    text-align: left;
                    color: #2b333b;
                }

                .more {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 90px;
                    height: 28px;
                    left: 66px;
                    top: 244px;
                    border-radius: 14px;
                    background-color: #f2e6ce;
                    font-size: 14px;
                    color: #000000;
                    cursor: pointer;

                    transition: all 0.2s;
                    transform: scale(1);

                    &:hover {
                        transform: scale(1.2);
                    }

                    img {
                        margin-left: 2px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}
</style>
