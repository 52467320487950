<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-02-03 10:51:18
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-05-24 13:29:00
-->
<template>
    <div class="image-more">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <image-search
                class="search"
                v-model:keyword="state.keyword"
                @search="handleSearch"
                :category="state.category"
                @filterTypesChange="filterTypesChange"
                @filterYearsChange="filterYearsChange"
                @filterAreasChange="filterAreasChange"
                @paramsChange="paramsChange"></image-search>
            <image-images
                ref="images"
                class="images"
                :id="state.id"
                :keyword="state.keyword"
                :filterTypes="state.filterTypes"
                :filterAreas="state.filterAreas"
                :filterYears="state.filterYears"
                @handleComplete="handleComplete"
                @handleLoad="handleLoad"
                @handleCategory="handleCategory"
            ></image-images>
        </div>
        <footer-footer
            class="footer"
            v-if="state.showFooter"
            :style="getMarginTopStyle(state.boxHeight)"
        ></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import ImageSearch from "@/components/page/imageMore/ImageSearch.vue";
import ImageImages from "@/components/page/imageMore/ImageImages.vue";

import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle } from "@/utils/scale";
import FooterFooter from "@/components/footer/Footer.vue";

const router = useRouter();
const userStore = store.user() as any;
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const state = reactive({
    id: '' as string,
    keyword: "" as string,
    boxHeight: 0,
    showFooter: false,
    category: [] as any[],

    filterTypes: [] as any[],
    filterAreas: [] as any[],
    filterYears: [] as any[],

    typeSelectList: [] as any[],
    areaSelectList: [] as any[],
    yearStart: '',
    yearEnd: ''
});

const box = ref(0);
const images = ref(0)

const handleLoad = () => {
    state.showFooter = true;
};

const handleCategory = (category: any[]) => {
    state.category = category
}

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height;
    }, 10);

    setTimeout(() => {
        state.showFooter = true;
    }, 20);
};

emitter.on('handleComplete', handleComplete)

const handleSearch = () => {
    let categoryIds = state.typeSelectList.map((item: any) => {
        return item.StrId
    }).join(',');

    let areaIds = state.areaSelectList.map((item: any) => {
        return item.StrId
    }).join(',');
    (images.value as any).handleSearch(state.keyword, categoryIds, areaIds, state.yearStart, state.yearEnd)
}

const filterTypesChange = (params: any[]) => {
    state.filterTypes = params
}

const filterYearsChange = (params: any[]) => {
    state.filterYears = params
}

const filterAreasChange = (params: any[]) => {
    state.filterAreas = params
}

const paramsChange = (p1: any[], p2: any[], p3: string, p4: string) => {
    state.typeSelectList = p1
    state.areaSelectList = p2
    state.yearStart = p3
    state.yearEnd = p4
}

const route = useRoute()
onMounted(() => {
    state.id = route.query.id ? route.query.id : ''
    state.keyword = route.query.keyword ? route.query.keyword : ''
});
</script>

<style lang="scss" scoped>
.image-more {
    width: 100%;
    .box {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 80px;
        background-color: #f8f8f8;

        .search {
            width: 1200px;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 40px;
            background-color: #f8f8f8;
        }

        .images {
            width: 1500px;
            margin: 0 auto;
            margin-bottom: 24px;
        }
    }

    .footer {
        transition: all 0.05s ease-out;
    }
}
</style>
