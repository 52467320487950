<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-28 17:00:05
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-15 10:11:35
-->
<template>
    <div class="knowledge-relation">
        <div class="header">
            <div class="han">相关资源</div>
            <div class="meng mFont">
                <span class="meng-vertical"> ᠬᠣᠯᠪᠤᠭᠳᠠᠯ </span>
                <span class="meng-vertical"> ᠪᠦᠬᠦᠢ </span>
                <span class="meng-vertical"> ᠡᠬᠢ </span>
                <span class="meng-vertical"> ᠪᠠᠶᠠᠯᠢᠭ </span>
            </div>
        </div>
        <div class="items">
            <div class="item" :class="{ select: state.selectIndex == 0 }" @click="selectType(0)">
                <div class="han">
                    <span class="box">
                        全部资源&ensp;<span>({{ state.totalCount }})</span>
                    </span>
                    <div class="meng mFont">
                        <span class="meng-vertical"> ᠪᠥᠬᠦ </span>
                        <span class="meng-vertical"> ᠡᠬᠢ </span>
                        <span class="meng-vertical"> ᠪᠠᠶᠠᠯᠢᠭ </span>
                    </div>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 1 }" @click="selectType(1)" v-if="state.rhhbCount > 0">
                <div class="han">
                    <span class="box">
                        {{ state.rhhbName }}&ensp;<span v-if="state.rhhbCount > 0">({{ state.rhhbCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠠᠭᠤᠰᠤᠯ </span>
                            <span class="meng-vertical"> ᠵᠢᠷᠤᠭᠲᠤ </span>
                            <span class="meng-vertical"> ᠰᠡᠳᠭᠦᠯ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 2 }" @click="selectType(2)" v-if="state.hbCount > 0">
                <div class="han">
                    <span class="box">
                        {{ state.hbName }}&ensp;<span v-if="state.hbCount > 0">({{ state.hbCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠰᠡᠳᠭᠦᠯ᠎ᠳᠦ </span>
                            <span class="meng-vertical"> ᠨᠡᠢᠲᠡᠯᠡᠭᠳᠡᠭᠰᠡᠨ </span>
                            <span class="meng-vertical"> ᠵᠣᠬᠢᠶᠠᠯ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 3 }" @click="selectType(3)" v-if="state.skCount > 0">
                <div class="han">
                    <span class="box">
                        {{ state.skName }}&ensp;<span v-if="state.skCount > 0">({{ state.skCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠨᠣᠮ </span>
                            <span class="meng-vertical"> ᠰᠡᠳᠭᠦᠯ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 4 }" @click="selectType(4)" v-if="state.tpCount > 0">
                <div class="han">
                    <span class="box">
                        {{ state.tpName }}&ensp;<span v-if="state.tpCount > 0">({{ state.tpCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠵᠢᠷᠤᠭ </span>
                            <span class="meng-vertical"> ᠰᠡᠭᠦᠳᠡᠷ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 5 }" @click="selectType(5)" v-if="state.spCount > 0">
                <div class="han">
                    <span class="box">
                        {{ state.spName }}&ensp;<span v-if="state.spCount > 0">({{ state.spCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠳᠦᠷᠰᠦ </span>
                            <span class="meng-vertical"> ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ </span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="item" :class="{ select: state.selectIndex == 6 }" @click="selectType(6)" v-if="state.ypCount > 0">
                <div class="han">
                    <span class="box">
                        {{ state.ypName }}&ensp;<span v-if="state.ypCount > 0">({{ state.ypCount }})</span>
                        <div class="meng mFont">
                            <span class="meng-vertical"> ᠲᠠᠭᠤ </span>
                            <span class="meng-vertical"> ᠱᠢᠩᠭᠡᠭᠡᠯᠲᠡ </span>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="r">
            <div class="t">
                <div class="px">排序</div>
                <div class="item" :class="{ select: state.sortSelectIndex == 0, sort1: state.sortType1 == 1 }" @click="selectSort(0)">
                    发布时间
                    <svg-icon class="icon" iconClass="down" :color="state.sortSelectIndex == 0 ? '#FFFFFF' : '#2B333B'" />
                </div>
                <div class="item" :class="{ select: state.sortSelectIndex == 1, sort1: state.sortType2 == 1 }" @click="selectSort(1)">
                    阅读量
                    <svg-icon class="icon" iconClass="down" :color="state.sortSelectIndex == 1 ? '#FFFFFF' : '#2B333B'" />
                </div>
            </div>
            <div class="b">
                <div class="theader">
                    <div class="th th1">标题</div>
                    <div class="th th2">所属栏目</div>
                    <div class="th th3">时间</div>
                    <div class="th th4">类型</div>
                    <div class="th th5">阅读量</div>
                </div>
                <div class="tbody">
                    <div class="item" v-for="(item, i) in state.list" :key="i">
                        <div class="index one-line">
                            {{ (state.cp - 1) * state.ps + i + 1 }}
                        </div>
                        <div class="title one-line" @click.stop.prevent="goReadPageById(item.StrId)">{{ item.Title }}</div>
                        <div class="lanmu one-line">{{ item.ColumnName }}</div>
                        <div class="time one-line">{{ item.Date }}</div>
                        <div class="type one-line">{{ item.ObjectTypeName }}</div>
                        <div class="read one-line">{{ item.Hits }}</div>
                        <div class="icons">
                            <template v-for="(iitem, iindex) in item.RelationData" :key="iindex">
                                <div v-if="iitem.ObjectType == 5" class="icon" @click.stop.prevent="goReadPageById(iitem.StrId)">
                                    <!-- 视频 -->
                                    <el-tooltip :content="iitem.Title" effect="light">
                                        <img src="@/assets/images/search/shipin.png" />
                                    </el-tooltip>
                                </div>
                                <div v-else-if="iitem.ObjectType == 3 && iitem.Language == '蒙文'" class="icon"
                                    @click.stop.prevent="goReadPageById(iitem.StrId)">
                                    <!-- 视频 -->
                                    <el-tooltip :content="iitem.Title" effect="light">
                                        <img src="@/assets/images/search/meng.png" />
                                    </el-tooltip>
                                </div>
                                <div v-else-if="iitem.ObjectType == 3 && iitem.Language != '蒙文'" class="icon"
                                    @click.stop.prevent="goReadPageById(iitem.StrId)">
                                    <!-- 视频 -->
                                    <el-tooltip :content="iitem.Title" effect="light">
                                        <img src="@/assets/images/search/han.png" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="item-empty" v-if="state.list.length == 0">
                        暂无数据
                    </div>
                </div>

                <div class="pager" v-if="getPageCount() > 1">
                    <div class="btn" :class="{ disabled: state.cp == 1 }" @click="handleLast">
                        上一页
                    </div>
                    <div class="count">{{ state.cp }} / {{ getPageCount() }}</div>
                    <div class="btn" :class="{ disabled: state.cp >= getPageCount() }" @click="handleNext">
                        下一页
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    onMounted,
    reactive,
    watch,
    ref,
    defineExpose,
    defineEmits,
} from "vue";
import moment from "moment";
import api from "@/common/api";
import mpx2vw from "@/utils/px2vw";
import loading from "@/utils/loading";
import routerUtil from "@/common/router";
import { getToken } from "@/request";

const emit = defineEmits(["handleComplete"]);

const state = reactive({
    selectIndex: 0,
    label: {} as any,

    trees: [] as any[],
    types: [] as any[],
    allType: [1, 2, 3, 4, 5, 6],
    keyword: "",

    list: [] as any[],
    listCount: 0,
    sortType1: 2,
    sortType2: 2,
    sortSelectIndex: 0,

    ps: 10,
    cp: 1,

    totalName: "",
    totalCount: 0,
    rhhbName: "",
    rhhbCount: 0,
    hbName: "",
    hbCount: 0,
    skName: "",
    skCount: 0,
    tpName: "",
    tpCount: 0,
    spName: "",
    spCount: 0,
    ypName: "",
    ypCount: 0,
});


const selectSort = (index: number) => {
    if(state.sortSelectIndex == index) {
        state.sortSelectIndex = index;
        state.cp = 1;

        if(index == 0) {
            if(state.sortType1 == 1) {
                state.sortType1 = 2
            } else {
                state.sortType1 = 1
            }
        } else {
            if(state.sortType2 == 1) {
                state.sortType2 = 2
            } else {
                state.sortType2 = 1
            }
        }

        searchData();
    } else {
        state.sortSelectIndex = index;
        state.cp = 1;

        searchData();
    }
};

const initLabel = (label: any, keyword: string) => {
    state.selectIndex = 0;
    state.label = label;
    state.keyword = keyword;
    state.cp = 1;

    getMenuData();
    searchData();
};

const initLabelById = (label: string) => {
    state.selectIndex = 0;
    state.label = label
    state.keyword = '';
    state.cp = 1;

    getMenuData();
    searchData();
};

const getMenuData = () => {
    api.contentInfoSearchCount(
        {
            type: 1,
            labelId: state.label.StrId,
            searchHb: true
        },
        (res1: any) => {
            state.types = res1;

            state.totalCount = 0;
            for (let i = 0; i < state.types.length; i++) {
                const item = state.types[i];

                if (item.X == 6) {
                    state.hbName = item.Y;
                    state.hbCount = item.Z;
                } else if (item.X == 1) {
                    state.rhhbName = item.Y;
                    state.rhhbCount = item.Z;
                } else if (item.X == 2) {
                    state.skName = item.Y;
                    state.skCount = item.Z;
                } else if (item.X == 4) {
                    state.tpName = item.Y;
                    state.tpCount = item.Z;
                } else if (item.X == 5) {
                    state.spName = item.Y;
                    state.spCount = item.Z;
                } else if (item.X == 3) {
                    state.ypName = item.Y;
                    state.ypCount = item.Z;
                } else if (item.X == 0) {
                    state.totalName = item.Y;
                    state.totalCount = item.Z;
                }
            }
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const getObjectType = () => {
    if (state.selectIndex == 0) {
        return "";
    } else if (state.selectIndex == 1) {
        return "1";
    } else if (state.selectIndex == 2) {
        return "6";
    } else if (state.selectIndex == 3) {
        return "2";
    } else if (state.selectIndex == 4) {
        return "4";
    } else if (state.selectIndex == 5) {
        return "5";
    } else if (state.selectIndex == 6) {
        return "3";
    }
};

const searchData = () => {
    let loadingIns = loading.showLoading();

    api.contentInfoSearch(
        {
            objectType: getObjectType(),
            sortType: state.sortSelectIndex == 0 ? state.sortType1 : state.sortType2,
            sort: state.sortSelectIndex == 0 ? "3" : "2",
            ps: state.ps,
            cp: state.cp,
            labelId: state.label.StrId,
            searchHb: true
        },
        (res: any) => {
            state.list = res.ItemList;
            state.listCount = res.RecordCount;
            loading.hideLoading(loadingIns);
            emit("handleComplete");
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const selectType = (index: number) => {
    state.selectIndex = index;

    switch (state.selectIndex) {
        case 0: {
            state.allType = [1, 2, 3, 4, 5, 6];
            break;
        }
        case 1: {
            state.allType = [1];
            break;
        }
        case 2: {
            state.allType = [6];
            break;
        }
        case 3: {
            state.allType = [2];
            break;
        }
        case 4: {
            state.allType = [4];
            break;
        }
        case 5: {
            state.allType = [5];
            break;
        }
        case 6: {
            state.allType = [3];
            break;
        }
    }

    state.cp = 1;
    searchData();
};


const getPageCount = () => {
    if (state.listCount % state.ps == 0) {
        return state.listCount / state.ps;
    } else {
        return Math.floor(state.listCount / state.ps) + 1;
    }
};

const handleLast = () => {
    if (state.cp <= 1) {
        state.cp = 1;
        return;
    }

    state.cp -= 1;
    searchData();
};

const handleNext = () => {
    if (state.cp >= getPageCount()) {
        state.cp = getPageCount();
        return;
    }

    state.cp += 1;
    searchData();
};

const goReadPageById = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

defineExpose({
    initLabel,
    initLabelById
});
</script>

<style lang="scss" scoped>
.knowledge-relation {
    background-color: #ffffff;

    .header {
        height: 94px;
        margin-left: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 2px solid #f2e6ce;

        .han {
            color: #2b333b;
            font-size: 28px;
        }

        .meng {
            margin-left: 16px;
            box-sizing: border-box;
            height: 100%;
            padding-top: 21px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 26px;
            color: #787878;
        }
    }

    .items {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        background: #ffffff;
        overflow: hidden;
        border-bottom: 2px solid #f2e6ce;

        .item {
            width: 214px;
            height: 160px;
            cursor: pointer;
            position: relative;

            &.select {
                background-color: #f2e6ce;
                font-weight: 600;

                .han {
                    font-weight: 600 !important;

                    span {
                        color: #2b333b !important;
                    }
                }
            }

            &:first-child {
                .meng {
                    margin-top: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    padding-left: 0;
                    padding-right: 30px;
                }
            }

            .han {
                font-size: 24px;
                color: #2b333b;
                margin-top: 36px;
                height: 24px;
                line-height: 24px;
                font-weight: 500;
                transition: all 0.2s;

                .box {
                    position: relative;

                    span {
                        color: #387db4;
                    }

                    .meng {
                        position: absolute;
                        left: 0px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-top: 8px;

                        span {
                            font-size: 22px;
                            line-height: 26px;
                            color: #2b333b;
                        }
                    }
                }
            }

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                width: 2px;
                height: 80px;
                background-color: #f5f5f5;
                right: 0px;
                top: 33px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    .r {
        width: 100%;

        .t {
            width: 100%;
            height: 64px;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);

            display: flex;
            justify-content: flex-start;
            align-items: center;

            .px {
                margin-left: 24px;
                margin-right: 16px;
                color: #b7bbbf;
                font-size: 18px;
            }

            .item {
                color: #2b333b;
                width: 106px;
                text-align: center;
                height: 32px;
                line-height: 32px;
                font-size: 18px;
                cursor: pointer;
                box-sizing: border-box;
                border: 1px solid #dadada;
                display: flex;
                justify-content: center;
                align-items: center;

                &.select {
                    color: #ffffff;
                    background-color: #6e8b74;
                    border: 1px solid #6e8b74;
                }
                
                &.sort1 {
                    .icon {
                        transform: rotate(180deg);
                    }
                }

                &:last-child {
                    width: 82px;
                }
            }
        }

        .b {
            margin-top: 16px;
            background-color: #ffffff;

            .theader {
                height: 64px;
                line-height: 64px;
                border-bottom: 1px solid #d9dde1;
                display: flex;
                justify-content: flex-start;

                .th {
                    font-size: 20px;
                    color: #596067;
                    box-sizing: border-box;

                    &.th1 {
                        text-align: left;
                        width: 618px;
                        padding-left: 170px;
                    }

                    &.th2 {
                        width: 196px;
                        text-align: center;
                    }

                    &.th3 {
                        width: 230px;
                        text-align: center;
                    }

                    &.th4 {
                        width: 176px;
                        text-align: center;
                    }

                    &.th5 {
                        width: 150px;
                        text-align: center;
                    }
                }
            }

            .tbody {
                box-sizing: border-box;

                .item-empty {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    border-bottom: 1px solid #d9dde1;
                    text-align: center;
                }

                .item {
                    height: 84px;
                    line-height: 84px;
                    font-size: 20px;
                    color: #2b333b;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #d9dde1;

                    &:hover {
                        background-color: #EBEBEB;
                    }

                    .index {
                        width: 60px;
                    }

                    .title {
                        width: 558px;
                        text-align: left;
                        cursor: pointer;
                    }

                    .lanmu {
                        width: 196px;
                    }

                    .time {
                        width: 230px;
                    }

                    .type {
                        width: 176px;
                    }

                    .read {
                        width: 150px;
                    }

                    .icons {
                        padding-left: 16px;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: nowrap;
                        cursor: pointer;

                        .icon {
                            width: 22px;
                            height: 22px;
                            margin-right: 8px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .pager {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                margin-top: 40px;
                padding-bottom: 24px;

                .btn {
                    width: 80px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    color: #2b333b;
                    font-size: 14px;
                    box-sizing: border-box;
                    border: 1px solid #2b333b;
                    border-radius: 4px;
                    cursor: pointer;

                    &.disabled {
                        color: #b7bbbf;
                        border: 1px solid #b7bbbf;
                    }
                }

                .count {
                    font-size: 14px;
                    color: #2b333b;
                    margin-left: 40px;
                    margin-right: 40px;
                }
            }
        }
    }
}
</style>
