/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-28 14:42:40
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-01-28 14:49:25
 */
import { ElLoading } from 'element-plus'

const showLoading = () => {
    return ElLoading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0' })
}

const hideLoading = (ins: any) => {
    ins.close()
} 

export default { showLoading, hideLoading }