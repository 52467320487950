<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-07 09:23:12
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-11 14:57:56
-->
<template>
    <div class="footer">
        <div class="box">
            <div class="l">
                <img src="@/assets/images/header/logo.png" />
            </div>
            <div class="c">
                <div class="f1">
                    <div class="m" @click="goOur">关于我们</div>
                    <div class="divider"></div>
                    <div class="m" @click="goUserCenter">个人中心</div>
                    <div class="divider"></div>
                    <div class="m" @click="goNoticeList">系统公告</div>
                </div>
                <div class="f f2">公司地址：内蒙古呼和浩特市新城区新华大街16号</div>
                <div class="f f3">
                    ©2023-2026 内蒙古画报 版权所有 <a>京ICP ************号</a>
                </div>
                <div class="f f4"><a>京公网安备 *****************号</a></div>
            </div>
            <div class="r">
                <div class="img-box">
                    <img src="@/assets/images/home/qrlarge.png" />
                </div>
                <div>小程序二维码</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import routerUtil from "@/common/router";
import store from "@/pinia";
import { defineComponent, ref, onMounted, computed, reactive, watch } from "vue";
const userStore = store.user() as any;

const state = reactive({
    hasLogin: computed(() => {
        return userStore.hasLogin;
    })
});

const goOur = () => {
    routerUtil.openNewWindow({
        path: `/introduction`,
    });
}

const goUserCenter = () => {
    if(state.hasLogin) {
        routerUtil.openNewWindow({
            path: `/user`,
        });
    } else {
        userStore.showLogin()
    }
}

const goNoticeList = () => {
    routerUtil.openNewWindow({
        path: `/noticelist`,
    });
}
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    height: 240px;
    background-color: #6e8b74;

    .box {
        width: 1126px;
        height: 160px;
        margin: 0px auto;
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .l {
            margin-right: 60px;
            display: flex;
            justify-items: flex-start;
            align-items: center;
            img {
                width: 304px;
                height: 128px;
            }
        }

        .c {
            width: 632px;
            .f1 {
                padding-bottom: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .m {
                    margin-left: 12px;
                    margin-right: 12px;
                    color: #ffffff;
                    font-size: 14px;
                    cursor: pointer;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                .divider {
                    height: 16px;
                    border-left: 1px solid #ffffff;
                }
            }

            .f {
                text-align: left;
                font-size: 14px;
                line-height: 28px;
                color: #ffffff;

                a {
                    cursor: pointer;
                    color: #ffffff;
                }
            }

            .f2 {
                margin-top: 0px;
            }

            .f3 {
                margin-top: 0px;
            }

            .f4 {
                margin-top: 0px;
            }
        }

        .r {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .img-box {
                width: 130px;
                height: 130px;
                border-radius: 8px;
                margin-bottom: 16px;
                padding: 8px;
                overflow: hidden;
                box-sizing: border-box;
                background-color: #ffffff;
            }

            img {
                width: 114px;
                height: 114px;
            }

            div {
                font-size: 14px;
                line-height: 14px;
                color: #ffffff;
            }
        }
    }
}
</style>
