<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-28 16:24:42
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-11 14:38:19
-->
<template>
    <div class="knowlede-label" id="knowlede-label" v-if="state.label">
        <div class="header">
            <template v-if="state.label.Type == 1">
                <img class="icon" src="@/assets/images/common/weizhishi.png" />
            </template>
            <template v-else-if="state.label.Type == 2">
                <img class="icon" src="@/assets/images/common/weiziyuan.png" />
            </template>
            <template v-else-if="state.label.Type == 3">
                <img class="icon" src="@/assets/images/common/weiwangzhan.png" />
            </template>
            <div class="mtitle">
                <ellipsis-view :title="state.label.Name"></ellipsis-view>
            </div>
            <div class="type">
                <ellipsis-view :title="'类型：' + state.label.TypeName"></ellipsis-view>
            </div>
        </div>
        <template v-if="state.label.Type == 1">
            <div class="content">
                <div class="text">
                    {{ state.label.Content ? state.label.Content : "暂无" }}
                </div>
            </div>
        </template>
        <template v-else-if="state.label.Type == 2">
            <div class="content">
                <template v-if="state?.item?.ObjectType == 1">
                    <!-- 画报 -->
                    <div class="main1">
                        <div class="p1">
                            <div class="l">
                                <img class="video" v-lazy="state.item.Cover" />
                            </div>
                            <div class="r">
                                <div class="title">
                                    <ellipsis-view :title="state.item.Title"></ellipsis-view>
                                </div>
                                <div class="meng mFont">
                                    <span class="meng-vertical" v-for="(item, index) in getMWords(
                                        state.item.MTitle
                                    )" :key="index">{{ item }}</span>
                                </div>
                                <div class="divider"></div>
                                <div class="items" v-if="state.item">
                                    <div class="total">总共{{ state.total }}期</div>
                                    <div class="item one-line">
                                        <div class="name">
                                            出版发行单位：{{
                                                state.item.Publisher
                                                ? state.item.Publisher
                                                : "暂无"
                                            }}
                                        </div>
                                    </div>
                                    <div class="item one-line">
                                        ISSN：{{
                                            state.item.Issn ? state.item.Issn : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line" v-if="getPresident().length > 0">
                                        <div class="name">社长：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getPresident()"
                                                :key="index">
                                                {{ item.PropertyValue }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item one-line" v-if="getEditor().length > 0">
                                        <div class="name">本期负责编辑：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getEditor()" :key="index">
                                                {{ item.PropertyValue }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="more" @click="goReadPage">
                                    <span>打开</span>
                                    <img src="@/assets/images/home/gengduo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="state?.item?.ObjectType == 2">
                    <!-- 书刊 -->
                    <div class="main2" v-if="state.item">
                        <div class="p1">
                            <div class="l">
                                <img class="video" v-lazy="state.item.Cover" />
                            </div>
                            <div class="r">
                                <div class="title">
                                    <ellipsis-view :title="state.item.Title"></ellipsis-view>
                                </div>
                                <div class="meng mFont">
                                    <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                        :key="index">{{ item }}</span>
                                </div>
                                <div class="divider"></div>
                                <div class="items" v-if="state.item">
                                    <div class="item one-line">
                                        作&emsp;&emsp;者：{{ state.item.Author ? state.item.Author : "暂无" }}
                                    </div>
                                    <div class="item one-line">
                                        出&ensp;版&ensp;社：{{ state.item.Publisher ? state.item.Publisher : "暂无" }}
                                    </div>
                                    <div class="item one-line">
                                        出版时间：{{ state.item.SPublishDate ? state.item.SPublishDate : "暂无" }}
                                    </div>
                                    <div class="item one-line" v-if="getEditor().length > 0">
                                        <div class="name">主&emsp;&emsp;编：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getEditor()" :key="index">
                                                {{ item.PropertyValue }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item one-line">
                                        I&ensp;S&ensp;B&ensp;N：{{ state.item.Isbn ? state.item.Isbn : "暂无" }}
                                    </div>
                                </div>

                                <div class="more" @click="goReadPage">
                                    <span>打开</span>
                                    <img src="@/assets/images/home/gengduo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="state?.item?.ObjectType == 3">
                    <!-- 音频 -->
                    <div class="main3" v-if="state.item">
                        <div class="p1">
                            <div class="l">
                                <img v-if="state.item.Cover" class="video" v-lazy="state.item.Cover" />
                                <img v-else class="video" src="@/assets/images/audio/yinpin.png" />
                            </div>
                            <div class="r">
                                <div class="title">
                                    <ellipsis-view :title="state.item.Title"></ellipsis-view>
                                </div>
                                <div class="meng mFont">
                                    <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                        :key="index">{{ item }}</span>
                                </div>
                                <div class="divider"></div>
                                <div class="items" v-if="state.item">
                                    <div class="item one-line" v-if="getPhotographer().length > 0">
                                        <div class="name">作&emsp;&emsp;者：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getPhotographer()"
                                                :key="index">
                                                {{ item.PropertyValue }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item one-line" v-else>
                                        <div class="name">作&emsp;&emsp;者：暂无</div>
                                    </div>
                                    <div class="item one-line">
                                        录制时间：{{
                                            state.item.SPublishDate
                                            ? state.item.SPublishDate
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line">
                                        <div class="name">
                                            语&emsp;&emsp;种：{{
                                                state.item.Language ? state.item.Language : "暂无"
                                            }}
                                        </div>
                                    </div>
                                    <div class="item one-line">
                                        所属分类：{{
                                            state.item.CategoryName
                                            ? state.item.CategoryName
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line" v-if="getBelongData().length > 0">
                                        <div class="name">所属资源：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getBelongData()"
                                                :key="index">
                                                {{ item.Title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="more" @click="goReadPage">
                                    <span>打开</span>
                                    <img src="@/assets/images/home/gengduo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="state?.item?.ObjectType == 4">
                    <!-- 图片 -->
                    <div class="main4" v-if="state.item">
                        <div class="p1">
                            <div class="l">
                                <img class="video" v-lazy="state.item.Cover" />
                            </div>
                            <div class="r">
                                <div class="title">
                                    <ellipsis-view :title="state.item.Title"></ellipsis-view>
                                </div>
                                <div class="meng mFont">
                                    <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                        :key="index">{{ item }}</span>
                                </div>
                                <div class="divider"></div>
                                <div class="items" v-if="state.item">
                                    <div class="item one-line">
                                        <div class="name">
                                            作&emsp;&emsp;者：{{
                                                state.item.Author ? state.item.Author : "暂无"
                                            }}
                                        </div>
                                    </div>
                                    <div class="item one-line">
                                        拍摄时间：{{
                                            state.item.SPublishDate
                                            ? state.item.SPublishDate
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line">
                                        所属分类：{{
                                            state.item.CategoryName
                                            ? state.item.CategoryName
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line" v-if="getBelongData().length > 0">
                                        <div class="name">所属资源：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getBelongData()"
                                                :key="index">
                                                {{ item.Title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="more" @click="goReadPage">
                                    <span>打开</span>
                                    <img src="@/assets/images/home/gengduo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="state?.item?.ObjectType == 5">
                    <!-- 视频 -->
                    <div class="main5" v-if="state.item">
                        <div class="p1">
                            <div class="l">
                                <img class="video" v-lazy="state.item.Cover" />
                            </div>
                            <div class="r">
                                <div class="title">
                                    <ellipsis-view :title="state.item.Title"></ellipsis-view>
                                </div>
                                <div class="meng mFont">
                                    <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                        :key="index">{{ item }}</span>
                                </div>
                                <div class="divider"></div>
                                <div class="items" v-if="state.item">
                                    <div class="item one-line" v-if="getPhotographer().length > 0">
                                        <div class="name">拍&ensp;摄&ensp;者：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getPhotographer()"
                                                :key="index">
                                                {{ item.PropertyValue }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item one-line" v-else>
                                        <div class="name">拍&ensp;摄&ensp;者：暂无</div>
                                    </div>
                                    <div class="item one-line">
                                        拍摄时长：{{
                                            state.item.SDuration ? state.item.SDuration : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line">
                                        拍摄时间：{{
                                            state.item.SPublishDate
                                            ? state.item.SPublishDate
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line">
                                        所属分类：{{
                                            state.item.CategoryName
                                            ? state.item.CategoryName
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line" v-if="getBelongData().length > 0">
                                        <div class="name">所属资源：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getBelongData()"
                                                :key="index">
                                                {{ item.Title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="more" @click="goReadPage">
                                    <span>打开</span>
                                    <img src="@/assets/images/home/gengduo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="state?.item?.ObjectType == 6">
                    <!-- 画报文章 -->
                    <div class="main6" v-if="state.item">
                        <div class="top">
                            <div class="title">
                                <ellipsis-view :title="state.item.Title"></ellipsis-view>
                            </div>
                            <div class="meng mFont">
                                <span class="meng-vertical" v-for="(item, index) in getMWords(state.item.MTitle)"
                                    :key="index">{{ item }}</span>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="left">
                                <div class="items" v-if="state.item">
                                    <div class="item one-line">
                                        <div class="name">
                                            作&emsp;&emsp;者：{{
                                                state.item.Author ? state.item.Author : "暂无"
                                            }}
                                        </div>
                                    </div>
                                    <div class="item one-line">
                                        所属画报：{{
                                            state.item.ParentName
                                            ? state.item.ParentName
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line">
                                        所属栏目：{{
                                            state.item.ColumnName
                                            ? state.item.ColumnName
                                            : "暂无"
                                        }}
                                    </div>
                                    <div class="item one-line" v-if="getBelongData().length > 0">
                                        <div class="name">所属资源：</div>
                                        <div class="values">
                                            <div class="value one-line" v-for="(item, index) in getBelongData()"
                                                :key="index">
                                                {{ item.Title }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item one-line">
                                        资源类型：{{
                                            state.item.ObjectTypeName
                                            ? state.item.ObjectTypeName
                                            : "暂无"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="icons">
                                    <template v-for="(item, index) in state.item?.RelationData" :key="index">
                                        <div v-if="item.ObjectType == 5" class="icon"
                                            @click.stop.prevent="goReadPageById(item.StrId)">
                                            <!-- 视频 -->
                                            <el-tooltip :content="item.Title" effect="light">
                                                <img src="@/assets/images/search/shipin.png" />
                                            </el-tooltip>
                                        </div>
                                        <div v-else-if="item.ObjectType == 3 && item.Language == '蒙文'" class="icon"
                                            @click.stop.prevent="goReadPageById(item.StrId)">
                                            <!-- 蒙文 -->
                                            <el-tooltip :content="item.Title" effect="light">
                                                <img src="@/assets/images/search/meng.png" />
                                            </el-tooltip>
                                        </div>
                                        <div v-else-if="item.ObjectType == 3 && item.Language != '蒙文'" class="icon"
                                            @click.stop.prevent="goReadPageById(item.StrId)">
                                            <!-- 其他 -->
                                            <el-tooltip :content="item.Title" effect="light">
                                                <img src="@/assets/images/search/han.png" />
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </div>
                                <div class="more" @click="goReadPage">
                                    <span>打开</span>
                                    <img src="@/assets/images/home/gengduo.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else-if="state.label.Type == 3">
            <div class="content">
                <div class="link">
                    <a target="_blank" :href="state.label.Url">点击打开网址</a>
                    <span @click="copySuccess" class="url one-line" id="copy-btn" :data-clipboard-text="state.label.Url">{{ state.label.Url }}</span>
                </div>
                <div class="text">
                    {{ state.label.Content ? state.label.Content : "暂无" }}
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineExpose } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";
import { getToken } from "@/request";
import EllipsisView from "@/components/common/EllipsisView.vue";
import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'

const emit = defineEmits(["handleLabelComplete"]);

const state = reactive({
    label: {} as any,
    item: null as any,
    relateList: [] as any[],

    total: 0,
});

const contentInfoPageList = () => {
    api.contentInfoPageList(
        { objectType: 1 },
        (res: any) => {
            state.total = res.RecordCount;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoRelatedContentList = () => {
    api.contentInfoRelatedContentList(
        { id: state.item.StrId, objectType: 6 },
        (res: any) => {
            state.relateList = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
}

const copySuccess = () => {
    ElMessage.success('网址已复制到剪贴板')
}

const initLabel = (label: any) => {
    state.label = label;

    setTimeout(() => {
        new ClipboardJS('#copy-btn')
    }, 500)

    // 资源标签
    if (state.label.Type == 2) {
        api.contentInfoInfo(
            { id: state.label.ContenId },
            (res: any) => {
                state.item = res;

                if (state?.item?.ObjectType == 1) {
                    contentInfoPageList();
                } else if (state?.item?.ObjectType == 6) {
                    contentInfoRelatedContentList()
                }
            },
            (err: any) => {
                console.log(err);
            }
        );
    }
};


const initLabelById = (labelId: string) => {
    api.labelInfo({
        id: labelId
    }, (resp: any) => {
        state.label = resp

        setTimeout(() => {
            new ClipboardJS('#copy-btn')
        }, 500)

        emit("handleLabelComplete", state.label)

        // 资源标签
        if (state.label.Type == 2) {
            api.contentInfoInfo(
                { id: state.label.ContenId },
                (res: any) => {
                    state.item = res;

                    if (state?.item?.ObjectType == 1) {
                        contentInfoPageList();
                    } else if (state?.item?.ObjectType == 6) {
                        contentInfoRelatedContentList()
                    }
                },
                (err: any) => {
                    console.log(err);
                }
            );
        }
    }, (err: any) => {
        console.log(err)
    })
};

const getPresident = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let president = state.item.ExternalData.filter((item: any, index: number) => {
                return item.PropertyName == "president";
            });

            if (president.length > 0) {
                return president;
            }
        }
    }

    return [];
};

const getEditor = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let editor = state.item.ExternalData.filter((item: any, index: number) => {
                return item.PropertyName == "editor";
            });

            if (editor.length > 0) {
                return editor;
            }
        }
    }

    return [];
};

const getPhotographer = () => {
    if (state.item && state.item.ExternalData) {
        if (state.item.ExternalData.length == 0) {
            return "";
        } else {
            let photographer = state.item.ExternalData.filter(
                (item: any, index: number) => {
                    return item.PropertyName == "photographer";
                }
            );

            if (photographer.length > 0) {
                return photographer;
            }
        }
    }

    return [];
};

const getBelongData = () => {
    if (state.item && state.item.BelongData) {
        if (state.item.BelongData.length == 0) {
            return "";
        } else {
            let photographer = state.item.BelongData;

            if (photographer.length > 0) {
                return photographer;
            }
        }
    }

    return [];
};

const getMWords = (m: string) => {
    if (m && typeof m == "string") {
        return m.split(" ");
    } else {
        return [];
    }
};

const goReadPage = () => {
    routerUtil.goReadPage(state.item.StrId, getToken());
};

const goReadPageById = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

defineExpose({
    initLabel,
    initLabelById
});
</script>

<style lang="scss" scoped>


.knowlede-label {
    border-radius: 8px;

    .header {
        height: 88px;
        line-height: 88px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: linear-gradient(#97c7a1, #6e8b74);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .icon {
            width: 40px;
            height: 40px;
            margin-left: 20px;
        }

        .mtitle {
            color: #ffffff;
            margin-left: 16px;
            font-size: 24px;
            font-weight: 600;
            max-width: 1020px;
        }

        .type {
            padding-top: 1px;
            margin-left: 24px;
            color: #ffffff;
            font-size: 20px;
            max-width: 240px;
            line-height: 24px;
        }
    }

    .content {
        padding: 32px;
        font-size: 20px;
        line-height: 40px;
        color: #2b333b;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(43, 51, 59, 0.08);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        text-align: left;
        text-indent: 40px;
        text-align: justify;

        .link {
            text-align: left;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .url {
                display: inline-block;
                max-width: 1200px;
                text-indent: 0;
                margin-left: 32px;
                color: #666666;
                cursor: pointer;
            }
        }

        .main1 {
            text-indent: 0;

            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 0px;
                padding-left: 180px;

                .l {
                    width: 492px;
                    height: 492px;
                    margin-right: 70px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 328px;
                        height: 438px;
                        object-fit: contain;
                        border-radius: 8px;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        max-width: 640px;
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .total {
                            margin-top: 32px;
                            font-size: 24px;
                            font-weight: 600;
                            color: #2b333b;
                            margin-bottom: 24px;
                        }

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 32px;
                        margin-top: 40px;
                        border-radius: 16px;
                        background-color: #f2e6ce;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        transition: all 0.2s;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.2);
                        }

                        span {
                            display: inline;
                        }

                        img {
                            margin-left: 2px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .main2 {
            text-indent: 0;

            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 0px;
                padding-left: 180px;

                .l {
                    width: 492px;
                    height: 492px;
                    margin-right: 70px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 328px;
                        height: 438px;
                        object-fit: contain;
                        border-radius: 8px;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        max-width: 640px;
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .total {
                            margin-top: 32px;
                            font-size: 24px;
                            font-weight: 600;
                            color: #2b333b;
                            margin-bottom: 24px;
                        }

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 32px;
                        margin-top: 40px;
                        border-radius: 16px;
                        background-color: #f2e6ce;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        transition: all 0.2s;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.2);
                        }

                        span {
                            display: inline;
                        }

                        img {
                            margin-left: 2px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .main3 {
            text-indent: 0;

            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 0px;
                padding-left: 100px;

                .l {
                    width: 492px;
                    height: 492px;
                    margin-right: 70px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 384px;
                        height: 384px;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        max-width: 640px;
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 32px;
                        margin-top: 40px;
                        border-radius: 16px;
                        background-color: #f2e6ce;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        transition: all 0.2s;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.2);
                        }

                        span {
                            display: inline;
                        }

                        img {
                            margin-left: 2px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .main4 {
            text-indent: 0;

            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 40px;
                padding-left: 100px;

                .l {
                    width: 492px;
                    height: 492px;
                    margin-right: 70px;
                    background-color: #f8f8f8;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .video {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border-radius: 8px;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        max-width: 640px;
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 32px;
                        margin-top: 40px;
                        border-radius: 16px;
                        background-color: #f2e6ce;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        transition: all 0.2s;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.2);
                        }

                        span {
                            display: inline;
                        }

                        img {
                            margin-left: 2px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .main5 {
            text-indent: 0;

            .p1 {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: 100px;

                .l {
                    width: 492px;
                    height: 492px;
                    margin-right: 70px;
                    background-color: #f8f8f8;
                    display: flex;
                    align-items: center;

                    .video {
                        width: 100%;
                        height: 265px;
                        object-fit: cover;
                    }
                }

                .r {
                    padding-top: 8px;
                    padding-bottom: 40px;

                    .title {
                        font-size: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-weight: 600;
                        color: #2b333b;
                        text-align: left;
                        max-width: 640px;
                    }

                    .meng {
                        margin-top: 16px;
                        box-sizing: border-box;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        color: #000000;
                        font-size: 22px;
                        line-height: 30px;

                        span {
                            line-height: 28px;
                        }
                    }

                    .divider {
                        margin-top: 28px;
                        margin-left: 20px;
                        width: 560px;
                        border-bottom: 1px solid #dbdbdb;
                    }

                    .items {
                        margin-top: 32px;
                        text-align: left;

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }

                    .more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 32px;
                        margin-top: 40px;
                        border-radius: 16px;
                        background-color: #f2e6ce;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        transition: all 0.2s;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.2);
                        }

                        span {
                            display: inline;
                        }

                        img {
                            margin-left: 2px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }

        .main6 {
            text-indent: 0;
            margin-left: 8px;
            margin-right: 8px;

            .top {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 40px;
                border-bottom: 1px solid #D9DDE1;

                .title {
                    font-size: 32px;
                    height: 32px;
                    line-height: 32px;
                    font-weight: 600;
                    color: #2b333b;
                    text-align: left;
                    max-width: 640px;
                }

                .meng {
                    margin-top: 16px;
                    box-sizing: border-box;
                    height: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    color: #000000;
                    font-size: 22px;
                    line-height: 30px;

                    span {
                        line-height: 28px;
                    }
                }
            }

            .bottom {
                margin-bottom: -32px;
                display: flex;

                .left {
                    box-sizing: border-box;
                    width: 870px;
                    padding-left: 100px;
                    padding-bottom: 76px;
                    border-right: 1px solid #DBDBDB;

                    .items {
                        padding-top: 20px;
                        text-align: left;

                        .item {
                            font-size: 20px;
                            line-height: 40px;
                            color: #2b333b;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;

                            .name {
                                display: inline-block;
                            }

                            .values {
                                display: inline-block;

                                .value {
                                    max-width: 560px;
                                }
                            }
                        }
                    }
                }

                .right {
                    display: inline-block;

                    .icons {
                        margin-top: 32px;
                        margin-left: 44px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .icon {
                            width: 40px;
                            height: 40px;
                            margin-right: 20px;
                            cursor: pointer;
                        }

                        .icon img {
                            width: 40px;
                            height: 40px;
                            border-radius: 4px;
                        }
                    }

                    .more {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        height: 32px;
                        margin-top: 40px;
                        margin-left: 44px;
                        border-radius: 16px;
                        background-color: #f2e6ce;
                        font-size: 16px;
                        color: #000000;
                        cursor: pointer;

                        transition: all 0.2s;
                        transform: scale(1);

                        &:hover {
                            transform: scale(1.2);
                        }

                        span {
                            display: inline;
                        }

                        img {
                            margin-left: 2px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}
</style>