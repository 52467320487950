/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-20 16:40:32
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-18 10:27:53
 */
const designWidth = 1920
const targetWidth = 1600

const getMargin = (brotherHeight: number) => {
    if (window.innerWidth > 1920) {
        return brotherHeight * (window.innerWidth / 1600 - 1) * -1
    }

    return 0
}

const getMarginBody = (brotherHeight: number) => {
    if (window.innerWidth > 1920) {
        return (brotherHeight - 18) * (window.innerWidth / 1600 - 1) * -1
    }

    return 0
}

const getScale = () => {
    if (window.innerWidth > 1920) {
        return 1600 / window.innerWidth;
    }

    return 1;
};

const px2rem = (px: number) => {
    return px / window.innerWidth * 100
}

const getScaleBodyStyle = (brotherHeight: number = 0) => {
    if(brotherHeight != 0) {
        return {
            transform: `scale(${ getScale() })`,
            'transform-origin': '50% 0%',
            'margin-top': `${ getMarginBody(brotherHeight) }px`
        }
    }
    
    return {
        transform: `scale(${ getScale() })`,
        'transform-origin': '50% 0%',
    }
}

const getScaleStyle = (brotherHeight: number = 0) => {
    if(brotherHeight != 0) {
        return {
            transform: `scale(${ getScale() })`,
            'transform-origin': '50% 0%',
            'margin-top': `${ getMargin(brotherHeight) }px`
        }
    }
    
    return {
        transform: `scale(${ getScale() })`,
        'transform-origin': '50% 0%',
    }
}

const getScaleYStyle = (brotherHeight: number) => {
    if(brotherHeight != 0) {
        return {
            transform: `scaleY(${ getScale() })`,
            'transform-origin': '50% 0%',
            'margin-top': `${ getMargin(brotherHeight) }px`
        }
    }
    
    return {
        transform: `scale(${ getScale() })`,
        'transform-origin': '50% 0%',
    }
}

const getScaleXStyle = (brotherHeight: number) => {
    if(brotherHeight != 0) {
        return {
            transform: `scaleX(${ getScale() })`,
            'transform-origin': '50% 0%',
            'margin-top': `${ getMargin(brotherHeight) }px`
        }
    }
    
    return {
        transform: `scale(${ getScale() })`,
        'transform-origin': '50% 0%',
    }
}

const getMarginTopStyle = (brotherHeight: number) => {
    return {
        'margin-top': `${ getMargin(brotherHeight) }px`
    }
}

const getMarginBottomStyle = (mineHeight: number) => {
    return {
        'margin-bottom': `${ getMargin(mineHeight) }px`
    }
}

export {
    getScale,
    px2rem,
    getScaleStyle,
    getScaleXStyle,
    getScaleYStyle,
    getMarginTopStyle,
    getMarginBottomStyle,
    getScaleBodyStyle
}