<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-26 20:13:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-15 10:39:33
-->
<template>
    <div class="search-search">
        <el-input ref="input" class="input" v-model="state._keyword" placeholder="搜索更多资源" @change="keywordChange" @focus="state.isFocus = true" @blur="state.isFocus = false"
            @keyup.enter="handleSearch">
            <template #append>
                <!-- <el-date-picker
                    ref="datePicker"
                    class="date-picker"
                    v-model="state._date"
                    type="daterange"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    unlink-panels
                    @change="dateChange">
                </el-date-picker> -->
                <vue-date-picker v-model="state._date" class="date-picker" ref="datePicker" locale="cn"
                    :text-input="textInputOptions" :enable-time-picker="false" @update:model-value="dateChange" range
                    :preview-format="previewFormat">
                    <template #action-buttons>
                        <p class="custom-select" @click="dateConfirm">确定</p>
                    </template>
                    <template #arrow-left>
                        <img class="slot-icon" src="@/assets/images/common/zuojiantou2.png"/>
                    </template>
                    <template #arrow-right>
                        <img class="slot-icon" src="@/assets/images/common/youjiantou2.png"/>
                    </template>
                </vue-date-picker>
                <div class="mrange" @click="showDatePicker">
                    <span v-if="state._date?.length" class="black">
                        {{ moment(state._date[0]).format('YYYY年MM月DD日') }}-{{ moment(state._date[1]).format('YYYY年MM月DD日')
                        }}
                    </span>
                    <span v-else>发布时间</span>
                    <el-icon class="icon" v-if="state._date?.length" @click.prevent.stop="clearDate">
                        <CircleClose />
                    </el-icon>
                    <ei-icon class="icon" v-else>
                        <ArrowDownBold />
                    </ei-icon>
                </div>
                <el-button @click="handleSearch">
                    <img src="@/assets/images/home/sousuo.png" />
                </el-button>
            </template>
        </el-input>
        <div class="clear-box">
            <div>{{ state._keyword }}</div>
            <el-icon class="clear" v-if="state._keyword && !state.isFocus" @click.prevent.stop="clearKeyword">
                <CircleClose />
            </el-icon>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const textInputOptions = {

}

const state = reactive({
    _keyword: "",
    _date: "",
    _previewDate: [],

    isFocus: false,
});

const props = defineProps({
    keyword: {
        type: String,
        default: "",
    },
    date: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["update:keyword", "update:date", "search"]);

const keywordChange = () => {
    emit("update:keyword", state._keyword);
}

const dateConfirm = () => {
    if(state._previewDate.length == 2) {
        (datePicker.value as any).selectDate()
    } else {
        (datePicker.value as any).closeMenu()
    }
}

const dateChange = () => {
    emit("update:date", state._date);
    emit("search");
}

const previewFormat = (date: any) => {
    state._previewDate = date

    let result = ''

    for (let i = 0; i < date.length; i++) {
        const item = date[i];
        const day = item.getDate();
        const month = item.getMonth() + 1;
        const year = item.getFullYear();

        result += `${year}年${month}月${day}日`;
        if(i < 1) {
            result += ' - '
        }
    }
    
    return result
}

const clearDate = () => {
    state._date = ""
    emit("update:date", state._date);
    emit("search");
}

const clearKeyword = () => {
    state._keyword = ""
    emit("update:keyword", state._keyword);
    emit("search");
}

const input = ref(0)
const handleSearch = () => {
    (input.value as any).blur()
    emit("update:keyword", state._keyword);
    emit("search");
};

const datePicker = ref(0)
const showDatePicker = () => {
    (datePicker.value as any).openMenu()
}

watch(props, (newVal, oldVal) => {
    state._keyword = newVal.keyword
})

onMounted(() => {
    state._keyword = props.keyword;
    state._date = props.date;
});
</script>

<style lang="scss" scoped>
.search-search {
    width: 100%;
    height: 320px;
    background-color: #6e8b74;

    .clear-box {
        position: absolute;
        top: 300px;
        left: 370px;
        z-index: 1;
        font-size: 20px;
        letter-spacing: 0px;
        display: flex;
        align-items: center;
        color: transparent;
        max-width: 700px;
        font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;

        .clear {
            position: absolute;
            right: -36px;
            top: -168px;
            cursor: pointer;
            color: #2B333B;
        }
    }

    .input {
        position: relative;
        box-sizing: border-box;
        width: 1200px;
        height: 64px;
        margin: 0 auto;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.04);
        margin-top: 110px;

        :deep(.el-input__wrapper) {
            box-sizing: border-box;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: none;
            box-shadow: none !important;
            font-size: 20px;
            padding-left: 18px;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
        }

        $place-holder-color: #a5a5a5;
        $place-holder-size: 20px;

        :deep(.el-input__inner) {
            font-size: $place-holder-size;
            padding-right: 480px;
            height: 100%;
        }

        :deep(.el-input__inner::placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-webkit-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-moz-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-ms-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
            margin-left: 22px;
        }

        :deep(.el-input-group__append) {
            position: absolute;
            z-index: 9;
            display: flex;
            justify-content: flex-end;
            top: 0px;
            right: 0px;
            width: 400px;
            height: 64px;
            padding: 0;
            box-sizing: border-box;
            border: none;
            border-radius: 0;
            box-shadow: none !important;
            background-color: transparent;

            .el-button {
                width: 106px;
                height: 67px;
                line-height: 67px;
                border-radius: 0px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-top: 0px;
                margin-right: -1px;
                background-color: #6e8b74;
                padding: 0;
                border: 1px solid #6e8b74;

                img {
                    width: 28px;
                    height: 28px;
                }

                .el-icon {
                    color: #ffffff;
                    font-size: 28px;
                }
            }

            .el-date-editor {
                display: inline-block;
                width: 0px;
                margin-top: 0px;
                height: 62px;
                line-height: 62px;
                padding-left: 0;
                padding-right: 0;
                position: absolute;
                right: 176px;
                overflow: hidden;
            }

            .el-range__icon {
                display: none;
            }

            .el-range-input {
                font-size: 22px;
            }

            .el-range-separator {
                width: 20px;
            }

            .el-range-input {
                width: 140px;
                font-size: 22px;
            }

            :deep(.el-range-input::placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
            }

            :deep(.el-range-input::-webkit-input-placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
            }

            :deep(.el-range-input::-moz-placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
            }

            :deep(.el-range-input::-ms-input-placeholder) {
                color: $place-holder-color;
                font-size: $place-holder-size;
                margin-left: 22px;
            }

            .date-picker {
                position: absolute;
                width: 0;
                height: 0;
                right: 178px;
                bottom: 36px;

                .dp__input_icon_pad {
                    width: 0;
                    height: 0;
                    padding: 0;
                    border: none;
                }

                .dp__input_icon {
                    display: none;
                }

                .dp__icon {
                    display: none;
                }
            }
        }

        .mrange {
            height: 32px;
            line-height: 32px;
            width: 98px;
            font-size: 16px;
            position: absolute;
            right: 122px;
            border-left: 2px solid #f5f5f5;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #787878;
            cursor: pointer;

            span {
                display: inline-block;
                margin-right: 8px;

                &.black {
                    color: #2B333B;
                }
            }

            .icon {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
            }
        }
    }

    :deep(.el-button > span) {
        width: 100%;
        height: 100%;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .custom-select {
        cursor: pointer;
        width: 100%;
    }

    :deep(.dp__action_row) {
        flex-direction: column;
    }

    :deep(.dp__action_row) {
        width: 100% !important;
    }

    :deep(.dp__selection_preview) {
        font-size: 16px;
        display: block;
    }

    :deep(.dp__action_buttons) {
        width: 100%;
    }

    :deep(.dp--arrow-btn-nav) {
        border: none !important;
        outline: none !important;
    }

    .slot-icon {
        width: 24px;
        height: 24px;
    }
}</style>
