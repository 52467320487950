/*
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-15 19:33:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-24 10:19:09
 */
import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import {createPinia} from "pinia"
import {usePersist} from "pinia-use-persist"
import router from './router'
import i18n from './language'
import 'virtual:svg-icons-register'
import VueLazyLoad from 'vue-lazyload'
import SvgIcon from '@/components/common/SvgIcon.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import EventBus from '@/common/eventBus'

let app = createApp(App)
const pinia = createPinia()
pinia.use(usePersist)

app.component('svg-icon', SvgIcon)
app.component('VueDatePicker', VueDatePicker)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.emitter = EventBus

app.use(pinia)
app.use(router)
app.use(i18n)
app.use(VueLazyLoad, {
    preload: 1,
    error: '',
    loading: new URL('./assets/images/loading.png', import.meta.url).href,
    attempt: 3
})

app.mount('#app')
