<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-26 20:13:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-04-15 10:41:09
-->
<template>
    <div class="book-search">
        <div class="l">
            <div class="han">书刊</div>
            <div class="divider"></div>
            <div class="meng mFont">
                <span class="meng-vertical">ᠨᠣᠮ</span>
                <span class="meng-vertical">ᠰᠡᠳᠭᠦᠯ</span>
            </div>
        </div>
        <div class="r">
            <el-input class="input" v-model="state._keyword" placeholder="搜索更多书刊" @keyup.enter="handleSearch">
                <template #append>
                    <el-button @click="handleSearch">
                        <img src="@/assets/images/home/sousuo.png" />
                    </el-button>
                </template>
            </el-input>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps } from "vue";
import { Search } from "@element-plus/icons-vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";

const state = reactive({
    _keyword: "",
    _date: "",
});

const props = defineProps({
    keyword: {
        type: String,
        default: "",
    },
    date: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["update:keyword", "update:date", "search"]);
const router = useRouter()

const goBookSearch = () => {
    routerUtil.openNewWindow({
        path: `/search`,
        query: {
            keyword: state._keyword,
            index: 2
        }
    } as any);
};

const handleSearch = () => {
    emit("update:keyword", state._keyword);
    emit("search", state._keyword);
    goBookSearch()
};

onMounted(() => {
    state._keyword = props.keyword;
    state._date = props.date;
});
</script>

<style lang="scss" scoped>
.book-search {
    width: 100%;
    height: 240px;
    padding-left: 332px;
    padding-right: 337px;
    box-sizing: border-box;
    background-color: #f0f8f1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .l {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .han {
            height: 86px;
            line-height: 86px;
            margin-right: 32px;
            font-size: 40px;
            color: #2b333b;
        }

        .divider {
            width: 2px;
            height: 48px;
            background-color: #2b333b;
            border-radius: 2px;
            margin-right: 16px;
        }

        .meng {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #2b333b;
            font-size: 26px;

            span {
                line-height: 26px;
            }
        }
    }

    .input {
        position: relative;
        box-sizing: border-box;
        width: 600px;
        height: 64px;
        margin: 0 auto;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.04);
        margin-top: 88px;

        :deep(.el-input__wrapper) {
            box-sizing: border-box;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: none;
            box-shadow: none !important;
            font-size: 20px;
            padding-left: 18px;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
        }

        $place-holder-color: #a5a5a5;
        $place-holder-size: 20px;

        :deep(.el-input__inner) {
            font-size: $place-holder-size;
            height: 100%;
        }

        :deep(.el-input__inner::placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-webkit-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-moz-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
        }

        :deep(.el-input__inner::-ms-input-placeholder) {
            color: $place-holder-color;
            font-size: $place-holder-size;
            margin-left: 22px;
        }

        :deep(.el-input-group__append) {
            position: absolute;
            z-index: 9;
            display: flex;
            justify-content: flex-end;
            top: 0px;
            right: 0px;
            height: 64px;
            padding: 0;
            box-sizing: border-box;
            border: none;
            border-radius: 0;
            box-shadow: none !important;
            background-color: transparent;

            .el-button {
                width: 109px;
                height: 67px;
                line-height: 67px;
                border-radius: 0px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-top: 0px;
                margin-right: -1px;
                background-color: #6e8b74;
                padding: 0;
                border: 1px solid #6e8b74;

                img {
                    width: 28px;
                    height: 28px;
                }
                .el-icon {
                    color: #ffffff;
                    font-size: 28px;
                }
            }
        }
    }

    :deep(.el-button > span) {
        width: 100%;
        height: 100%;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
