<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-06 18:34:30
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-28 20:04:18
-->
<template>
    <el-config-provider :locale="locale">
        <div v-if="state.showPage">
            <Header />
            <router-view />
        </div>
    </el-config-provider>
</template>

<script lang="ts" setup>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";

import store from "@/pinia";
import { storeToRefs } from "pinia";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import { onMounted } from "vue";

const locale = zhCn;
const userStore = store.user();

const state = reactive({
    showPage: true,
    time: 0,
    timer: null as any,
    oldWidth: 0
});

const clearTimer = () => {
    clearInterval(state.timer)
}

const setNewTimer = () => {
    state.time = 0
    state.timer = setInterval(() => {
        state.time += 10
        
        if(state.time >= 500) {
            clearInterval(state.timer)
            state.showPage = false
            setTimeout(() => {
                state.showPage = true
            }, 1)
        }
    }, 10)
}

window.onresize = () => {
    // if(state.oldWidth != window.innerWidth) {
    //     state.oldWidth = window.innerWidth
    //     clearTimer()
    //     setNewTimer()
    // }
}

userStore.getUserInfo();

onMounted(() => {
    state.oldWidth = window.innerWidth
})
</script>

<style>
@import url(@/assets/css/style.css);
@import url(@/assets/ttf/meng.css);
@import url(@/assets/ttf/fonts.css);

@import url(@/assets/css/swiper/swiper.css);
@import url(@/assets/css/swiper/navigation.css);

body {
    width: 100%;
    display: block;
    font-family: "Microsoft YaHei", "PingFangSC-Regular", "思源黑体";
    margin: 0;
    padding: 0;
}

#app {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
}
</style>
