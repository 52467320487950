<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-26 20:08:20
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-29 21:15:42
-->
<template>
    <div class="search">
        <div class="box" ref="box" :style="getScaleBodyStyle(160)">
            <search-search v-model:keyword="state.keyword" v-model:date="state.date" @search="handleSearch"></search-search>
            <search-all ref="searchAll" @handleChange="handleChange"></search-all>
            <search-body ref="searchBody" class="mbody" @handleComplete="handleComplete"></search-body>
        </div>

        <footer-footer :style="getMarginTopStyle(state.boxHeight)"></footer-footer>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import api from "@/common/api";
import { defineComponent, ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import SearchSearch from "@/components/page/search/SearchSearch.vue";
import SearchAll from "@/components/page/search/SearchAll.vue";
import SearchBody from "@/components/page/search/SearchBody.vue";
import { useRoute, useRouter } from "vue-router";
import { getToken } from "@/request";
import { getScaleStyle, getScaleYStyle, getMarginTopStyle, getScaleBodyStyle } from "@/utils/scale";
import FooterFooter from "@/components/footer/Footer.vue";

const router = useRouter();
const userStore = store.user() as any;
const app = getCurrentInstance()
const emitter = app?.appContext.config.globalProperties.emitter

const state = reactive({
    keyword: "",
    date: "",
    id: '-1',
    boxHeight: 0,

    selectIndex: 0,
    allType: [2, 3, 4, 5, 6],
});

const box = ref(0);
const searchAll = ref(0);
const searchBody = ref(0);

const handleSearch = () => {
    (searchAll.value as any).initData(state.selectIndex, state.allType.join(","), state.keyword, state.date, true);
};

const handleChange = (p1: number, p2: [], flag: boolean, type = 'select') => {
    state.selectIndex = p1;
    state.allType = p2;

    (searchBody.value as any).initData(
        state.selectIndex,
        state.keyword,
        state.date,
        state.id,
        flag
    );

    (searchBody.value as any).initCountData(
        state.keyword,
        state.date,
        true,
        type
    );
};

const handleComplete = () => {
    setTimeout(() => {
        state.boxHeight = (box.value as any)?.getBoundingClientRect().height;
    }, 10);
};

emitter.on('handleComplete', handleComplete)

const route = useRoute() as any

onMounted(() => {
    state.keyword = route.query.keyword ? route.query.keyword : '';
    state.selectIndex = route.query.index ? Number(route.query.index) : 0;
    state.id = route.query.id ? route.query.id : '-1';

    (searchAll.value as any).initData(state.selectIndex, state.allType.join(","), state.keyword, state.date, true);
});
</script>

<style lang="scss" scoped>
.search {
    width: 100%;

    .box {
        width: 100%;
        margin: 0 auto;
        background: #f0f0f0;

        .mbody {
            margin-top: 32px;
            padding-bottom: 80px;
        }
    }
}
</style>
