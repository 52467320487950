<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 11:23:05
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-03 18:30:17
-->
<template>
    <div class="image-banner">
        <el-carousel class="carousel" arrow="never">
            <img class="img" src="@/assets/images/image/banner.png" />
        </el-carousel>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from "vue";
import moment from "moment";
import api from "@/common/api";

const state = reactive({
    banners: [] as any[],
});

const bannerInfoList = () => {
    api.bannerInfoList(
        {},
        (res: any) => {
            state.banners = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

onMounted(() => {
    
});
</script>

<style lang="scss" scoped>
.image-banner {
    width: 100%;
    overflow-x: hidden;
    .carousel {
        width: 100%;
        height: 450px;
    }

    :deep(.el-carousel__item) {
        width: 100%;
        height: 450px;
    }

    .img {
        width: 100%;
        height: 450px;
    }

    $indicator-size: 16px;
    $indicator-active-size: 24px;

    :deep(.el-carousel__indicators--horizontal) {
        bottom: 0px;
    }

    :deep(.el-carousel__indicator--horizontal) {
        button {
            position: relative;
            box-sizing: border-box;
            width: $indicator-size;
            height: $indicator-size;
            border-radius: 50%;
            background-color: #d9dbe7;
            opacity: 1;
            margin-left: 2px;
            margin-right: 2px;
            margin-bottom: 12px;
            transition: all 0.2s;
        }

        button:after {
            content: "";
            display: inline-block;
            position: absolute;
            box-sizing: border-box;
            left: -4px;
            top: -4px;
            width: $indicator-active-size;
            height: $indicator-active-size;
            border-radius: 50%;
            border: 1px solid transparent;
            transition: all 0.2s;
        }
    }

    :deep(.el-carousel__indicator--horizontal.is-active) {
        button {
            background-color: #ccb16e;
        }

        button:after {
            border: 1px solid #ccb16e;
        }
    }

    :deep(.el-carousel__button:focus) {
        outline: none;
    }
}
</style>
