<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 20:01:15
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-02-27 13:08:05
-->
<template>
    <div class="video-detail-labels" v-if="state.firstInit || state.labels.length > 0">
        <div class="header">
            <div class="han">关联标签</div>
            <div class="meng mFont">
                <span class="meng-vertical">ᠬᠣᠯᠪᠣᠭᠳᠠᠬᠤ</span>
                <span class="meng-vertical">ᠱᠣᠰᠢᠭ᠎ᠠ</span>
            </div>
        </div>
        <div class="body">
            <div class="box-labels">
                <div ref="labels" class="labels">
                    
                </div>
            </div>
        </div>

        <el-dialog
            v-model="state.showLabel"
            append-to-body
            class="video-detail-labels-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :style="getScaleStyle()"
        >
            <div class="video-detail-labels-dialog-content">
                <img
                    class="video-detail-labels-dialog-close"
                    src="@/assets/images/common/close.png"
                    @click="state.showLabel = false"
                />

                <div class="video-detail-labels-dialog-header">
                    <img
                        class="video-detail-labels-dialog-weizhishi"
                        src="@/assets/images/common/weizhishi.png"
                        @click="state.showLabel = false"
                    />
                    <div class="video-detail-labels-dialog-name one-line">
                        {{ state.selectItem.Name }}
                    </div>
                    <div class="video-detail-labels-dialog-type one-line">
                        类型：{{ state.selectItem.TypeName }}
                    </div>
                </div>
                <div class="video-detail-labels-dialog-body">
                    {{ state.labelInfo.Content ? state.labelInfo.Content : "暂无" }}
                </div>
                <div class="video-detail-labels-dialog-relation">
                    <div class="video-detail-labels-dialog-relation-btn" @click="goLabel(state.selectItem)">相关资源</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, defineEmits, defineProps, ref } from "vue";
import moment from "moment";
import api from "@/common/api";
import mpx2vw from "@/utils/px2vw";
import { getScaleStyle } from "@/utils/scale";
import echartsUtil from '@/utils/echarts'
import routerUtil from "@/common/router";

const route = useRoute() as any;

const state = reactive({
    id: "",
    selectItem: {} as any,
    labelCount: 0,
    labels: [] as any[],
    
    showLabels: false,
    showLabel: false,
    labelInfo: {} as any,
    
    firstInit: true
});

const labels = ref(0)

const labelContentLabel = () => {
    api.labelContentLabel(
        { contentId: state.id },
        (res: any) => {
            state.labels = res;
            state.labelCount = state.labels.length

            state.labels.sort((a: any, b: any) => {
                return b.Cites - a.Cites;
            });

            echartsUtil.initEcharts(labels.value as any, state.labels, (mecharts: any, option: any, params: any) => {
                let series = option.series[0]

                showLabelItem(series.data[params.dataIndex].item)
            })

            state.firstInit = false
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const showLabelItem = (item: any) => {
    labelInfo(item);
    state.selectItem = item;
    state.showLabel = true;
};

const router = useRouter()
const goLabel = (item: any) => {
    routerUtil.openNewWindow({
        path: "/knowledge",
        query: {
            id: item.StrId
        }
    });
}

const labelInfo = (item: any) => {
    api.labelInfo(
        { id: item.StrId },
        (res: any) => {
            state.labelInfo = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

onMounted(() => {
    state.id = route.query.id;
    labelContentLabel();
});
</script>

<style lang="scss" scoped>
.video-detail-labels {
    width: 1500px;
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;

    .header {
        position: relative;
        width: 100%;
        height: 96px;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9dde1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .han {
            display: inline-block;
            height: 96px;
            line-height: 96px;
            margin-right: 16px;
            font-size: 28px;
            color: #2b333b;
        }

        .meng {
            box-sizing: border-box;
            height: 100%;
            padding-top: 26px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            color: #787878;
            font-size: 20px;

            span {
                line-height: 28px;
            }
        }
    }

    .body {
        box-sizing: border-box;
        width: 100%;
        padding: 0px 0px;
        position: relative;

        .box-labels {
            width: 100%;
            height: 560px;
            background-color: #f0f8f1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: -1px;
                bottom: 0;
                background-image: url("@/assets/images/home/stars.png");
                background-position: 50% 50%;
                background-size: 100% 100%;
            }

            .labels {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 560px;
                z-index: 99;
            }
        }
    }
}
</style>

<style lang="scss">
.video-detail-labels-dialog {
    width: 1304px;
    transform-origin: center !important;
    border-radius: 8px;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 100%;
        padding: 0;

        .video-detail-labels-dialog-content {
            width: 100%;
            padding-top: 72px;
            box-sizing: border-box;
            position: relative;

            .video-detail-labels-dialog-close {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 32px;
                top: 24px;
                cursor: pointer;
            }

            .video-detail-labels-dialog-header {
                margin-left: 32px;
                margin-right: 32px;
                border-radius: 8px 8px 0px 0px;
                height: 88px;
                background: linear-gradient(to right, #97c7a1, #6e8b74);
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .video-detail-labels-dialog-weizhishi {
                    width: 40px;
                    height: 35px;
                    margin-left: 20px;
                    margin-right: 16px;
                }

                .video-detail-labels-dialog-name {
                    max-width: 720px;
                    font-size: 24px;
                    color: #ffffff;
                    font-weight: 600;
                    margin-right: 24px;
                }

                .video-detail-labels-dialog-type {
                    max-width: 200px;
                    font-size: 22px;
                    color: #ffffff;
                    font-weight: 500;
                }
            }

            .video-detail-labels-dialog-body {
                font-size: 20px;
                line-height: 48px;
                color: #2b333b;
                text-align: left;
                text-align: justify;
                text-indent: 44px;
                letter-spacing: 1px;
                padding-left: 64px;
                padding-right: 64px;
                margin-top: 32px;
                margin-bottom: 40px;
            }

            .video-detail-labels-dialog-relation {
                display: flex;
                justify-content: center;
                padding-bottom: 60px;

                .video-detail-labels-dialog-relation-btn {
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 8px;
                    background-color: #6e8b74;
                    font-size: 16px;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
