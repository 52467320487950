<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2023-12-07 09:23:12
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-11 09:22:10
-->
<template>
    <div>
        <el-dialog
            v-model="state._showLoginDialog"
            title=""
            append-to-body
            :draggable="false"
            :close-on-click-modal="false"
            @close="closeDialog"
            class="login-dialog"
            :style="getScaleStyle()"
        >
            <div class="content">
                <div class="menus">
                    <template v-if="state.menuSelect == 0 || state.menuSelect == 1">
                        <div
                            class="menu"
                            :class="{ select: state.menuSelect == 0 }"
                            @click="state.menuSelect = 0"
                        >
                            登录
                        </div>
                        <div
                            class="menu"
                            :class="{ select: state.menuSelect == 1 }"
                            @click="state.menuSelect = 1"
                        >
                            注册
                        </div>
                    </template>
                    <template v-else-if="state.menuSelect == 2">
                        <div
                            class="menu"
                            :class="{ select: state.menuSelect == 2 }"
                            @click="state.menuSelect = 0"
                        >
                            找回密码

                            <el-icon class="find-close" @click="showLoginForm"
                                ><Close
                            /></el-icon>
                        </div>
                    </template>
                </div>
                <template v-if="state.menuSelect == 0">
                    <div class="form-item form1">
                        <el-input
                            class="minput"
                            v-model="state.acLogin"
                            placeholder="请输入手机号"
                        ></el-input>
                    </div>
                    <div class="form-item form2">
                        <el-input
                            class="minput"
                            type="password"
                            v-model="state.pwLogin"
                            placeholder="请输入密码"
                        >
                        </el-input>
                    </div>
                    <div class="forget">
                        <span @click="showFindForm"> 忘记密码？</span>
                    </div>

                    <div class="dialog-footer">
                        <div class="submit" @click="loginSubmit">登录</div>
                        <div class="read">
                            <el-checkbox
                                class="check"
                                v-model="state.checkedLogin"
                                label=""
                                size="large"
                            />
                            已阅读 <span @click="goYinSi">《用户隐私协议》</span> 和
                            <span @click="goFuWu">《用户服务协议》</span>
                        </div>
                    </div>
                </template>
                <template v-else-if="state.menuSelect == 1">
                    <div class="form-item form3" :class="{ err: state.acRegisErrText }">
                        <el-input
                            class="minput"
                            :class="{ focus: state.acRegisFocus }"
                            v-model="state.acRegis"
                            placeholder="请输入手机号"
                            @focus="state.acRegisFocus = true"
                            @blur="state.acRegisFocus = false"
                            @change="state.acRegisErrText = ''"
                        ></el-input>
                    </div>
                    <div class="err" v-if="state.acRegisErrText">
                        {{ state.acRegisErrText }}
                    </div>
                    <div class="form-item form4" :class="{ err: state.codeRegisErrText }">
                        <el-input
                            class="minput code"
                            :class="{ focus: state.codeRegisFocus }"
                            v-model="state.codeRegis"
                            placeholder="验证码"
                            @focus="state.codeRegisFocus = true"
                            @blur="state.codeRegisFocus = false"
                            @change="state.codeRegisErrText = ''"
                        >
                            <template #append>
                                <div class="send" @click="smsCodeSend('1')">
                                    {{
                                        state.codeSecond > 0
                                            ? `${state.codeSecond}秒`
                                            : "获取验证码"
                                    }}
                                </div>
                            </template>
                        </el-input>
                    </div>
                    <div class="err" v-if="state.codeRegisErrText">
                        {{ state.codeRegisErrText }}
                    </div>
                    <div class="form-item form5" :class="{ err: state.pwRegisErrText }">
                        <el-input
                            class="minput"
                            :class="{ focus: state.pwRegisFocus }"
                            type="password"
                            v-model="state.pwRegis"
                            placeholder="请输入密码"
                            @focus="state.pwRegisFocus = true"
                            @blur="state.pwRegisFocus = false"
                            @change="state.pwRegisErrText = ''"
                        >
                        </el-input>
                    </div>
                    <div class="err" v-if="state.pwRegisErrText">
                        {{ state.pwRegisErrText }}
                    </div>

                    <div class="dialog-footer">
                        <div class="submit" @click="regisSubmit">注册</div>
                        <div class="read">
                            <el-checkbox
                                class="check"
                                v-model="state.checkedRegis"
                                label=""
                                size="large"
                            />
                            已阅读 <span @click="goYinSi">《用户隐私协议》</span> 和
                            <span @click="goFuWu">《用户服务协议》</span>
                        </div>
                    </div>
                </template>
                <template v-else-if="state.menuSelect == 2">
                    <div class="form-item form3" :class="{ err: state.acFindErrText }">
                        <el-input
                            class="minput"
                            :class="{ focus: state.acFindFocus }"
                            v-model="state.acFind"
                            placeholder="请输入手机号"
                            @focus="state.acFindFocus = true"
                            @blur="state.acFindFocus = false"
                            @change="state.acFindErrText = ''"
                        ></el-input>
                    </div>
                    <div class="err" v-if="state.acFindErrText">
                        {{ state.acFindErrText }}
                    </div>
                    <div class="form-item form4" :class="{ err: state.codeFindErrText }">
                        <el-input
                            class="minput code"
                            :class="{ focus: state.codeFindFocus }"
                            v-model="state.codeFind"
                            placeholder="验证码"
                            @focus="state.codeFindFocus = true"
                            @blur="state.codeFindFocus = false"
                            @change="state.codeFindErrText = ''"
                        >
                            <template #append>
                                <div class="send" @click="smsCodeSend('2')">
                                    {{
                                        state.codeFindSecond > 0
                                            ? `${state.codeFindSecond}秒`
                                            : "获取验证码"
                                    }}
                                </div>
                            </template>
                        </el-input>
                    </div>
                    <div class="err" v-if="state.codeFindErrText">
                        {{ state.codeFindErrText }}
                    </div>
                    <div class="form-item form5" :class="{ err: state.pwFindErrText }">
                        <el-input
                            class="minput"
                            :class="{ focus: state.pwFindFocus }"
                            type="password"
                            v-model="state.pwFind"
                            placeholder="请输入密码"
                            @focus="state.pwFindFocus = true"
                            @blur="state.pwFindFocus = false"
                            @change="state.pwFindErrText = ''"
                        >
                        </el-input>
                    </div>
                    <div class="err" v-if="state.pwFindErrText">
                        {{ state.pwFindErrText }}
                    </div>

                    <div class="form-item form5" :class="{ err: state.pwFindConfirmErrText }">
                        <el-input
                            class="minput"
                            :class="{ focus: state.pwFindConfirmFocus }"
                            type="password"
                            v-model="state.pwFindConfirm"
                            placeholder="请确认密码"
                            @focus="state.pwFindConfirmFocus = true"
                            @blur="state.pwFindConfirmFocus = false"
                            @change="state.pwFindConfirmErrText = ''"
                        >
                        </el-input>
                    </div>
                    <div class="err" v-if="state.pwFindConfirmErrText">
                        {{ state.pwFindConfirmErrText }}
                    </div>

                    <div class="dialog-footer">
                        <div class="submit" @click="findSubmit">确定</div>
                    </div>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import store from "@/pinia";
import { storeToRefs } from "pinia";
import api from "@/common/api";
import { ref, onMounted, computed, reactive, watch, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import { getScaleStyle } from "@/utils/scale";

const { proxy }: any = getCurrentInstance();

const props = defineProps({
    showLoginDialog: {
        default: false,
    },
});

const state = reactive({
    _showLoginDialog: false,
    acLogin: "",
    pwLogin: "",
    checkedLogin: false,
    acRegis: "",
    acRegisErrText: "",
    acRegisFocus: false,
    codeRegis: "",
    codeRegisErrText: "",
    codeRegisFocus: false,
    codeSecond: 0,
    pwRegis: "",
    pwRegisErrText: "",
    pwRegisFocus: false,
    checkedRegis: false,
    menuSelect: 0,

    acFind: "",
    acFindErrText: "",
    acFindFocus: false,
    codeFind: "",
    codeFindErrText: "",
    codeFindFocus: false,
    codeFindSecond: 0,
    pwFind: "",
    pwFindErrText: "",
    pwFindFocus: false,
    pwFindConfirm: "",
    pwFindConfirmErrText: "",
    pwFindConfirmFocus: false,
});

const userStore = store.user();
const { hasLogin, userInfo } = storeToRefs(userStore);

watch(
    () => props.showLoginDialog as any,
    (newVal: any) => {
        state._showLoginDialog = newVal;
    }
);

const checkFormLogin = () => {
    if (!state.acLogin) {
        ElMessage.warning("请输入账号");
        return false;
    }

    if (!state.pwLogin) {
        ElMessage.warning("请输入密码");
        return false;
    }

    if (!state.checkedLogin) {
        ElMessage.warning("请阅读协议");
        return false;
    }

    return true;
};

const loginSubmit = () => {
    if (checkFormLogin()) {
        api.authLogin(
            state.acLogin,
            state.pwLogin,
            (res: any) => {
                userStore.setUserInfo(res);
                userStore.hideLogin();
                closeDialog();
                window.location.reload()
            },
            (err: any) => {
                ElMessage.error(err);
            }
        );
    }
};

const checkMobile = (str: string) => {
    let re = /^1\d{10}$/;
    if (re.test(str)) {
        return true;
    } else {
        return false;
    }
};

const smsCodeSend = (type: string) => {
    if (type == "1") {
        if (state.codeSecond > 0) {
            ElMessage.warning(`请 ${state.codeSecond} 后重新发送`);
            return;
        }

        if (!checkMobile(state.acRegis)) {
            state.acRegisErrText = "手机号格式错误";
            return;
        }
    } else if (type == "2") {
        if (state.codeFindSecond > 0) {
            ElMessage.warning(`请 ${state.codeFindSecond} 后重新发送`);
            return;
        }

        if (!checkMobile(state.acFind)) {
            state.acFindErrText = "手机号格式错误";
            return;
        }
    }

    let mobile = "";
    if (type == "1") {
        mobile = state.acRegis;
    } else if (type == "2") {
        mobile = state.acFind;
    }

    api.smsCodeSend(
        {
            mobile,
            type,
        },
        (res: any) => {
            ElMessage.success("验证码发送成功");
            if (type == "1") {
                state.codeSecond = 60;
                let timer = setInterval(() => {
                    if (state.codeSecond <= 0) {
                        state.codeSecond = 0;
                        clearInterval(timer);
                    } else {
                        state.codeSecond--;
                    }
                }, 1000);
            } else if (type == "2") {
                state.codeFindSecond = 60;
                let timer = setInterval(() => {
                    if (state.codeFindSecond <= 0) {
                        state.codeFindSecond = 0;
                        clearInterval(timer);
                    } else {
                        state.codeFindSecond--;
                    }
                }, 1000);
            }
        },
        (err: any) => {
            ElMessage.error(err);
        }
    );
};

const checkFormRegis = () => {
    if (!state.acRegis) {
        state.acRegisErrText = "请输入手机号";
        return false;
    }

    if (!checkMobile(state.acRegis)) {
        state.acRegisErrText = "手机号格式错误";
        return false;
    }

    if (!state.codeRegis) {
        state.codeRegisErrText = "请输入验证码";
        return false;
    }

    if (!state.pwRegis) {
        state.pwRegisErrText = "请输入密码";
        return false;
    }

    if (!state.checkedRegis) {
        ElMessage.warning("请阅读协议");
        return false;
    }

    return true;
};

const regisSubmit = () => {
    if (checkFormRegis()) {
        api.authRegister(
            {
                mobile: state.acRegis,
                code: state.codeRegis,
                password: state.pwRegis,
            },
            (res: any) => {
                ElMessage.success("注册成功，请登录");
                state.acRegis = "";
                state.codeRegis = "";
                state.pwRegis = "";
                state.menuSelect = 0;
            },
            (err: any) => {
                ElMessage.error(err);
            }
        );
    }
};

const checkFormFind = () => {
    if (!state.acFind) {
        state.acFindErrText = "请输入手机号";
        return false;
    }

    if (!checkMobile(state.acFind)) {
        state.acFindErrText = "手机号格式错误";
        return false;
    }

    if (!state.codeFind) {
        state.codeFindErrText = "请输入验证码";
        return false;
    }

    if (!state.pwFind) {
        state.pwFindErrText = "请输入密码";
        return false;
    }

    if (!state.pwFindConfirm) {
        state.pwFindConfirmErrText = "请确认密码";
        return false;
    }

    if(state.pwFind != state.pwFindConfirm) {
        state.pwFindConfirmErrText = "两次输入的密码不一致";
        return false;
    }

    return true;
};

const showLoginForm = () => {
    state.menuSelect = 0;
};

const showFindForm = () => {
    state.menuSelect = 2;
};

const findSubmit = () => {
    if (checkFormFind()) {
        api.userForgetPassword(
            {
                mobile: state.acFind,
                code: state.codeFind,
                password: state.pwFind,
            },
            (res: any) => {
                ElMessage.success("密码修改成功，请登录");
                state.acFind = "";
                state.codeFind = "";
                state.pwFind = "";
                state.menuSelect = 0;
            },
            (err: any) => {
                ElMessage.error(err);
            }
        );
    }
};

const closeDialog = () => {
    userStore.hideLogin();
};

const goYinSi = () => {
    const url = "https://hb.api.golden-prairie.com/auth/privacy.html";

    window.open(url, "_blank");
};

const goFuWu = () => {
    const url = "https://hb.api.golden-prairie.com/auth/agreement.html";

    window.open(url, "_blank");
};

onMounted(() => {});
</script>

<style lang="scss">
.login-dialog {
    width: 640px;
    min-height: 500px;
    border: 1px soild #d9dde1;
    border-radius: 8px;
    transform-origin: 50% 50%;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__headerbtn {
        display: none;
    }

    .dialog-header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;
    }

    .content {
        .menus {
            padding-top: 40px;
            margin-top: 4px;
            font-size: 22px;
            color: #333333;
            display: flex;
            justify-content: center;
            position: relative;

            .menu {
                cursor: pointer;
                &:first-child {
                    margin-right: 24px;
                }

                &:last-child {
                    margin-left: 24px;
                }

                font-size: 24px;
                color: #b7bbbf;
                font-weight: 600;

                &.select {
                    color: #2b333b;
                }

                .find-close {
                    font-size: 20px;
                    position: absolute;
                    right: 40px;
                    top: 40px;
                }
            }
        }

        .form-item {
            display: flex;
            justify-content: center;
            overflow: visible;

            &.form1 {
                margin-top: 48px;
                &.err {
                    margin-left: 0;
                    .minput {
                        box-shadow: 0 0 0 1px #e00916;
                    }
                }
            }

            &.form2 {
                margin-top: 24px;
                &.err {
                    margin-left: 0;
                    .minput {
                        box-shadow: 0 0 0 1px #e00916;
                    }
                }
            }

            &.form3 {
                margin-top: 48px;
                &.err {
                    margin-left: 0;
                    .minput {
                        box-shadow: 0 0 0 1px #e00916;
                    }
                }
            }

            &.form4 {
                margin-top: 16px;

                &.err {
                    margin-left: 0;
                    .minput {
                        box-shadow: 0 0 0 1px #e00916;
                    }
                }
            }

            &.form5 {
                margin-top: 16px;
                &.err {
                    margin-left: 0;
                    .minput {
                        box-shadow: 0 0 0 1px #e00916;
                    }
                }
            }
        }

        .err {
            margin-left: 130px;
            margin-top: 8px;
            font-size: 16px;
            color: #e00916;
        }

        .el-input__inner {
            overflow: visible;
            width: 100%;
            height: 100%;
            padding-left: 16px;
            background-color: transparent;
            border-radius: 8px;
        }

        .el-input__inner::placeholder {
            color: rgb(194, 194, 194);
        }

        .el-input__wrapper {
            padding-left: 16px;
            box-shadow: none;
            padding: 0;
            border: none;
            border-radius: 8px;
            box-sizing: border-box;
            height: 48px;
            line-height: 48px;
        }

        .minput {
            width: 380px;
            font-size: 16px;
            overflow: visible;
            border-radius: 8px;
            box-shadow: 0 0 0 1px #d9dde1;

            .send {
                box-sizing: border-box;
                line-height: 48px;
                height: 48px;
                width: 88px;
                color: #387db4;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                background-color: #ffffff;
                border-radius: 8px;
                cursor: pointer;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 2px;
                    height: 32px;
                    border-radius: 1px;
                    z-index: 1;
                    left: 0px;
                    top: 7px;
                    background-color: #f5f5f5;
                }
            }

            &.code {
                .el-input__wrapper,
                .el-input__inner {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: 0;
                }

                .el-input-group__append {
                    box-sizing: border-box;
                    padding: 0;
                    height: 48px;
                    min-height: 48px;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                .el-input-group__append {
                    box-shadow: none;
                    background-color: transparent;
                    border-left: none;
                }

                .el-input__wrapper.is-focus {
                    box-shadow: none;
                }

                .el-input__wrapper.is-focus + .el-input-group__append {
                    box-shadow: none;
                }
            }

            &.focus {
                border-radius: 8px;
                box-shadow: 0 0 0 1px #4567fc;
            }
        }

        .forget {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            width: 380px;
            margin: 0 auto;
            color: #b7bbbf;
            margin-top: 16px;
            cursor: pointer;
        }
    }

    .el-dialog__footer {
        display: none;
    }

    .dialog-footer {
        .read {
            display: flex;
            align-items: center;
            color: #787878;
            margin-left: 138px;
            font-size: 14px;
            padding-bottom: 85px;

            span {
                color: #0c3680;
                cursor: pointer;
            }

            .el-checkbox__input .el-checkbox__inner {
                border-radius: 50%;
                border-color: #787878;
                width: 16px;
                height: 16px;

                &::after {
                    left: 5px;
                    top: 2px;
                    height: 8px;
                    width: 3px;
                }
            }

            .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #0c3680;
            }

            .el-checkbox__inner:hover {
                border-color: #0c3680;
            }

            .el-checkbox {
                height: 40px;
                line-height: 40px;
            }
        }

        .submit {
            width: 380px;
            height: 46px;
            line-height: 46px;
            background-color: #6e8b74;
            color: #ffffff;
            text-align: center;
            margin: 0 auto;
            cursor: pointer;
            margin-top: 24px;
            margin-bottom: 16px;
            border-radius: 8px;
            font-size: 16px;
        }
    }
}
</style>
