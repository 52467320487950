<!--
 * @Descripttion: 
 * @version: 
 * @Author: QianDuan
 * @Date: 2024-01-16 21:41:18
 * @LastEditors: QianDuan
 * @LastEditTime: 2024-03-08 10:44:56
-->
<template>
    <div class="home-huabao">
        <div class="top">
            <div class="l1">
                <div class="han">
                    <div>融</div>
                    <div>合</div>
                    <div>画</div>
                    <div>报</div>
                </div>
                <div class="meng mFont meng-vertical">ᠬᠠᠮ ᠵᠢᠷᠤᠭᠲᠤ ᠰᠡᠳᠭᠦᠯ</div>
                <div class="more" @click="goMagazineList">
                    <span>更多</span>
                    <img src="@/assets/images/home/gengduo.png" />
                </div>
            </div>
            <div class="l2" @click="goMagazine(state.latest)">
                <img class="cover" :src="state.latest.Cover" />
            </div>
            <div class="r">
                <div class="rtop">
                    <div class="rtop-l">
                        <template
                            v-for="(item, index) in state.latest.CtalogueInfos"
                            :key="index"
                        >
                            <div v-if="index < 3" class="item"  @click="goReadPage(item.StrChapterId)">
                                <el-tooltip :content="item.Title" effect="light">
                                    <span
                                        class="one-line"
                                        ref="tips"
                                        :style="{ opacity: state.opacitys[index] }"
                                        :data-index="index"
                                        >{{ item.Title }}</span
                                    >
                                </el-tooltip>
                                <span
                                    class="one-line"
                                    ref="notips"
                                    :style="{ opacity: state.opacitys[index] }"
                                    :data-index="index"
                                    >{{ item.Title }}</span
                                >
                            </div>
                        </template>
                    </div>
                    <div class="rtop-r">
                        <div class="year">{{ state.latest.Year }}年</div>
                        <div class="issue">
                            {{ state.latest.Issue }}
                        </div>
                    </div>
                </div>
                <div class="rbottom">
                    <template v-for="(item, index) in state.related" :key="index">
                        <img class="item" v-lazy="item.Cover" @click="goReadPage(item.StrId)" />
                    </template>
                </div>
            </div>
        </div>
        <div>
            <div class="bottom">
                <div class="box">
                    <template v-for="(item, index) in state.latestList" :key="index">
                        <img class="item" v-lazy="item.Cover" @click="goMagazine(item)" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch, ref } from "vue";
import moment from "moment";
import api from "@/common/api";
import routerUtil from "@/common/router";
import { getToken } from "@/request";
import { getMarginTopStyle } from "@/utils/scale";

const state = reactive({
    latest: {} as any,
    related: [] as any[],
    latestList: [] as any[],
    opacitys: [] as number[],
});

const contentInfoNewData = () => {
    api.contentInfoNewData(
        { objectType: 1 },
        (res: any) => {
            state.latest = res;
            state.latest.CtalogueInfos.forEach(() => {
                state.opacitys.push(0);
            });
            initToolTip();

            contentInfoRelatedContentList();
            contentInfoNewList();
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoRelatedContentList = () => {
    api.contentInfoRelatedContentList(
        { id: state.latest.StrId, top: 3, objectType: 4 },
        (res: any) => {
            state.related = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const contentInfoNewList = () => {
    api.contentInfoNewList(
        { top: 4, objectType: 1, removeFirst: true },
        (res: any) => {
            state.latestList = res;
        },
        (err: any) => {
            console.log(err);
        }
    );
};

const goReadPage = (id: string) => {
    routerUtil.goReadPage(id, getToken());
};

const tips = ref([]);
const notips = ref([]);

const initToolTip = () => {
    setTimeout(() => {
        console.log(notips.value);
        for (let i = 0; i < notips.value.length; i++) {
            const nitem = notips.value[i] as any;
            const titem = tips.value[i] as any;

            if (nitem.clientWidth < nitem.scrollWidth) {
                const dIndex = Number(nitem.dataset.index);
                nitem.remove();
                state.opacitys[dIndex] = 1;
            } else {
                const dIndex = Number(nitem.dataset.index);
                titem.remove();
                state.opacitys[dIndex] = 1;
            }
        }
    }, 500);
};

const router = useRouter();

const goMagazineList = () => {
    routerUtil.openNewWindow({
        path: `/magazine`
    });
}

const goMagazine = (item: any) => {
    routerUtil.openNewWindow({
        path: `/magazinedetail`,
        query: {
            id: item.StrId,
        },
    }, true);
};

onMounted(() => {
    contentInfoNewData();
});
</script>

<style lang="scss" scoped>
.home-huabao {
    width: 100%;
    height: 100%;
    position: relative;

    .top {
        display: flex;
        justify-content: flex-start;

        .l1 {
            width: 264px;
            height: 328px;
            margin-top: 44px;
            position: relative;

            .han {
                div {
                    display: block;
                    font-size: 32px;
                    color: #2b333b;
                    text-align: left;
                    width: 164px;
                    height: 44px;
                    line-height: 44px;
                    box-sizing: border-box;
                    padding-left: 44px;
                    font-weight: 600;
                    margin-top: 2px;

                    &:first-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        color: #ffffff;
                        background-color: #6e8b74;
                    }
                }
            }

            .meng {
                position: absolute;
                left: 96px;
                top: 64px;
                font-size: 26px;
                height: 200px;
                text-align: left;
                color: #2b333b;
            }

            .more {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 28px;
                left: 74px;
                bottom: 20px;
                border-radius: 14px;
                background-color: #f2e6ce;
                font-size: 14px;
                color: #000000;
                cursor: pointer;
                transition: all 0.2s;
                transform: scale(1);

                &:hover {
                    transform: scale(1.2);
                }

                img {
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .l2 {
            margin-left: 32px;
            width: 440px;
            height: 586px;

            .cover {
                width: 100%;
                height: 100%;
                margin-top: 0px;
                border-radius: 8px;
                transition: all 0.2s;
                box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.24);
                transform: translateY(0);
                cursor: pointer;

                &:hover {
                    transform: translateY(-24px);
                }
            }
        }

        .r {
            margin-left: 40px;
            margin-top: 112px;

            .rtop {
                display: flex;

                .rtop-l {
                    width: 476px;
                    box-sizing: border-box;
                    padding-right: 20px;

                    .item {
                        width: 100%;
                        text-align: left;
                        font-size: 24px;
                        height: 50px;
                        line-height: 50px;
                        color: #2b333b;
                        cursor: pointer;

                        span {
                            display: block;
                            width: 100%;
                        }
                    }
                }

                .rtop-r {
                    margin-left: 12px;

                    .year {
                        font-size: 36px;
                        line-height: 56px;
                        color: #2b333b;
                        text-align: right;
                        font-weight: 600;
                    }

                    .issue {
                        font-size: 36px;
                        line-height: 56px;
                        color: #2b333b;
                        text-align: right;
                        font-weight: 600;
                    }
                }
            }

            .rbottom {
                widows: 636px;
                margin-top: 102px;
                display: flex;
                justify-content: space-between;

                .item {
                    width: 200px;
                    height: 148px;
                    border-radius: 8px;
                    cursor: pointer;
                }
            }
        }
    }

    .bottom {
        position: relative;
        box-sizing: border-box;
        width: 1500px;
        margin-top: 64px;
        padding-left: 41px;
        padding-right: 41px;

        &::after {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 322px;
            z-index: -1;
            content: "";
            width: 100%;
            height: 200px;
            background-image: url("@/assets/images/home/huabaodiwen.png");
            background-size: 1500px 200px;
        }

        .box {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .item {
                width: 332px;
                height: 442px;
                margin-top: 0px;
                border-radius: 8px;
                transition: all 0.2s;
                cursor: pointer;
                transform: translateY(0);

                &:hover {
                    transform: translateY(-24px);
                }
            }
        }
    }
}
</style>
